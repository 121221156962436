import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../../CheckBox/CheckBox';

const FormatBool = ({ value, onChange }) => (
    <div style={{ textAlign: 'center' }}>
        <CheckBox checked={!!value} size={CheckBox.SIZE.BIG} color={CheckBox.COLOR.DARK} onChange={onChange} />
    </div>
);

FormatBool.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default FormatBool;
