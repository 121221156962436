import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './HoverableList.module.scss';

const HoverableList = ({ list, actions, isSelected, children, columns = 2, idProp = 'id' }) => {
    return (
        <div className={styles.list} style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
            {list.map(item => (
                <div key={item[idProp]} className={cn(styles.wrapper, isSelected(item) && styles.selected)}>
                    {children({ itemClass: styles.item, item })}
                    {!!actions && <div className={styles.actions}>{actions({ item })}</div>}
                </div>
            ))}
        </div>
    );
};

HoverableList.propTypes = {
    list: PropTypes.array.isRequired,
    actions: PropTypes.func,
    isSelected: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    columns: PropTypes.number,
    idProp: PropTypes.string,
};

export default HoverableList;
