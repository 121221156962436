export const OPEN_STATUSES = [
    'SALES_FORCE_OPPORTUNITY_PROSPECTING',
    'SALES_FORCE_OPPORTUNITY_DEMO',
    'SALES_FORCE_OPPORTUNITY_STATEMENT_ANALYSIS',
    'SALES_FORCE_OPPORTUNITY_PROPOSAL',
    'SALES_FORCE_OPPORTUNITY_APPLICATION',
    'SALES_FORCE_OPPORTUNITY_QA',
    'SALES_FORCE_OPPORTUNITY_APPLICATION_INTAKE',
    'SALES_FORCE_OPPORTUNITY_UNDERWRITING',
    'SALES_FORCE_OPPORTUNITY_ACCOUNT_STAGING',
    'SALES_FORCE_OPPORTUNITY_REASSIGNED',
    'SALES_FORCE_OPPORTUNITY_COMPLETED',
    'SALES_FORCE_OPPORTUNITY_CUSTOMER_WALK_THROUGH',
    'SALES_FORCE_OPPORTUNITY_30_DAY_CHECK',
    'SALES_FORCE_OPPORTUNITY_KICK_OFF',
    'SALES_FORCE_OPPORTUNITY_GO_LIVE',
    'SALES_FORCE_OPPORTUNITY_60_DAY_CHECK',
    'SALES_FORCE_OPPORTUNITY_UNKNOWN',
];

export const FILTER_STATUSES = [
    'SALES_FORCE_OPPORTUNITY_PROSPECTING',
    'SALES_FORCE_OPPORTUNITY_DEMO',
    'SALES_FORCE_OPPORTUNITY_STATEMENT_ANALYSIS',
    'SALES_FORCE_OPPORTUNITY_PROPOSAL',
    'SALES_FORCE_OPPORTUNITY_APPLICATION',
    'SALES_FORCE_OPPORTUNITY_QA',
    'SALES_FORCE_OPPORTUNITY_APPLICATION_INTAKE',
    'SALES_FORCE_OPPORTUNITY_UNDERWRITING',
    'SALES_FORCE_OPPORTUNITY_ACCOUNT_STAGING',
    'SALES_FORCE_OPPORTUNITY_UNKNOWN',
];

export const CLOSED_STATUSES = [
    'SALES_FORCE_OPPORTUNITY_CLOSED_WON',
    'SALES_FORCE_OPPORTUNITY_CLOSED_LOST',
];

export const SALES_FORCE_OPPORTUNITY_UNKNOWN = 'SALES_FORCE_OPPORTUNITY_UNKNOWN';

export const CLOSED_STATUSES_MAP = {
    'closed-won': 'SALES_FORCE_OPPORTUNITY_CLOSED_WON',
    'closed-lost': 'SALES_FORCE_OPPORTUNITY_CLOSED_LOST'
};
