import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './FormButtons.module.css';

/**
 * Wrapper for form buttons. Use it for forms and page actions.
 */
const FormButtons = ({ children, small, tiny, topMargin, className }) => (
    <div
        className={cn(
            styles.root,
            small && styles.small,
            tiny && styles.tiny,
            topMargin && styles.topMargin,
            className,
        )}
    >
        {children}
    </div>
);

FormButtons.propTypes = {
    children: PropTypes.any,
    /**
     * Add top margin
     */
    topMargin: PropTypes.bool,
    /**
     * Add small top padding
     */
    small: PropTypes.bool,
    /**
     * Add tiny top padding
     */
    tiny: PropTypes.bool,

    className: PropTypes.string,
};

FormButtons.leftButton = styles.leftButton;

export default FormButtons;
