export const TOGGLE_GROUPS_STATUS = {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
};

export const TOGGLE_GROUPS_STATUSES = [TOGGLE_GROUPS_STATUS.ACTIVE, TOGGLE_GROUPS_STATUS.ARCHIVED];

export const CAMPAIGNS_STATUS = {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
};

export const CAMPAIGNS_STATUSES = [CAMPAIGNS_STATUS.ACTIVE, CAMPAIGNS_STATUS.ARCHIVED];

export const TOGGLE_OPTIONS_STATUS = {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
};

export const TOGGLE_OPTIONS_STATUSES = [TOGGLE_OPTIONS_STATUS.ACTIVE, TOGGLE_OPTIONS_STATUS.ARCHIVED];

export const TOGGLE_OPTIONS_TYPE = {
    IMAGE: 'image',
    TEXT: 'text',
    LINK: 'link',
};

export const ACTIVITY_GROUPS_STATUS = {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
};

export const ASSET_LIBRARY_RESOURCE_STATUS = {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
};

export const ACTIVITY_GROUPS_STATUSES = [ACTIVITY_GROUPS_STATUS.ACTIVE, ACTIVITY_GROUPS_STATUS.ARCHIVED];

export const TEMPLATE_TYPES_STATUS = {
    ACTIVE: 'active',
    BEING_DESIGNED: 'being_designed',
    ARCHIVED: 'archived',
};

export const TEMPLATE_TYPES_STATUSES = [TEMPLATE_TYPES_STATUS.ACTIVE, TEMPLATE_TYPES_STATUS.ARCHIVED];

export const LOYALTY_BANNERS_STATUS = {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
};

export const LOYALTY_BANNERS_STATUSES = [LOYALTY_BANNERS_STATUS.ACTIVE, LOYALTY_BANNERS_STATUS.ARCHIVED];

export const LOYALTY_REWARDS_STATUS = {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
    BEING_PROCESSED: 'being_processed',
};

export const LOYALTY_REWARDS_STATUSES = [
    LOYALTY_REWARDS_STATUS.ACTIVE,
    LOYALTY_REWARDS_STATUS.ARCHIVED,
    LOYALTY_REWARDS_STATUS.BEING_PROCESSED,
];

export const LOYALTY_REWARDS_INSTANCE_STATUS = {
    VISIBLE: 'visible',
    INVISIBLE: 'invisible',
};

export const LOYALTY_REWARDS_INSTANCE_STATUSES = [
    LOYALTY_REWARDS_INSTANCE_STATUS.VISIBLE,
    LOYALTY_REWARDS_INSTANCE_STATUS.INVISIBLE,
];

export const LOYALTY_REWARDS_INSTANCE_TYPE = {
    MARKETING: 'marketing',
    SALES: 'sales',
};

export const LOYALTY_REWARDS_INSTANCE_TYPES = [
    LOYALTY_REWARDS_INSTANCE_TYPE.MARKETING,
    LOYALTY_REWARDS_INSTANCE_TYPE.SALES,
];

export const SURVEY_STATUS = {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
};

export const USERS_STATUS = {
    UNAPPROVED: 'unapproved',
    REJECTED: 'rejected',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    DELETED: 'deleted',
    PENDING: 'pending',
};

export const AREA_OPS = {
    ADD: 'add',
    REMOVE: 'remove',
    MOVE_UP: 'up',
    MOVE_DOWN: 'down',
};

export const USER_PERMISSIONS = {
    INVITE_NEW_USERS: 'Invite new users',
};
