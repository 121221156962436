import React, { FC, lazy } from 'react';
import { Route } from 'react-router-dom';

interface SubAppProps {
    path: string;
    name: string;
}

const TEMPLATE_BUILDER = lazy(() => import(`./template-builder`));

const getComponent = (name: string): React.LazyExoticComponent<React.ComponentType<any>> => {
    switch (name) {
        case 'template-builder':
            return TEMPLATE_BUILDER;
        default:
            throw new Error(`Unsupported app: '${name}'`);
    }
};

const SubApp: FC<SubAppProps> = ({ path, name }) => (
    <Route
        path={path}
        component={
            // @ts-ignore
            process.env.APP_EXCLUDE.includes(name)
                ? () => (
                      <div className="container" style={{ paddingTop: 40 }}>
                          This app has been excluded in <code>APP_EXCLUDE</code> env variable.
                      </div>
                  )
                : getComponent(name)
        }
    />
);

export default SubApp;
