import { combineReducers } from 'redux';
import ACTIONS from './constants';

const dataInitialState = {
    data: {},
    loading: false,
};

const partnerReducer = (state = dataInitialState, action) => {
    switch (action.type) {
        case ACTIONS.partner.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.partner.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const notificationsInitialState = {
    data: [],
    loading: false,
};

const notificationsReducer = (state = notificationsInitialState, action) => {
    switch (action.type) {
        case ACTIONS.notifications.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.notifications.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const cartInitialState = {
    data: [],
    back: '',
};

const cartReducer = (state = cartInitialState, action) => {
    switch (action.type) {
        case ACTIONS.cart.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.cart.setBack: {
            return {
                ...state,
                back: action.back,
            };
        }

        default: {
            return state;
        }
    }
};

const listReducer = (state = dataInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const pointsTransfersReducer = (state = dataInitialState, action) => {
    switch (action.type) {
        case ACTIONS.pointsTransfers.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.pointsTransfers.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    partner: partnerReducer,
    cart: cartReducer,
    list: listReducer,
    pointsTransfers: pointsTransfersReducer,
    notifications: notificationsReducer,
});
