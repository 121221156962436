import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import styles from './CheckBox.module.css';

const SIZE = {
    DEFAULT: 'default',
    BIG: 'big',
};

const COLOR = {
    DARK: 'dark',
    CLIENT: 'client',
};

function CheckBox({ checked, size, onChange, disabled, color, label, className }) {
    const _className = cn(
        styles.checkBox,
        checked && styles.checked,
        styles[`size-${size}`],
        styles[`color-${color}`],
        color === COLOR.CLIENT && checked && 'client-main-colour-background client-main-colour-border',
        className
    );

    const handleChange = disabled || !onChange ? undefined : () => onChange(!checked);;

    const checkBox = (
        <span className={_className} onClick={label ? undefined : handleChange}>
            <Icon kind="tick" className={styles.icon} />
        </span>
    );

    if (label) {
        return (
            <span className={cn(styles.checkBoxRow, disabled && styles.disabled)} onClick={handleChange}>
                {checkBox}
                {label}
            </span>
        );
    }

    return checkBox;
}

CheckBox.SIZE = SIZE;
CheckBox.COLOR = COLOR;

CheckBox.defaultProps = {
    size: SIZE.DEFAULT,
    color: COLOR.CLIENT,
};

CheckBox.propTypes = {
    /**
     * Class name
     */
    className: PropTypes.string,
    /**
     * Is checked
     */
    checked: PropTypes.bool,
    /**
     * Check box size: default | big
     */
    size: PropTypes.string,
    /**
     * Check box color: dark | client
     */
    color: PropTypes.string,
    /**
     * Gets called when the user change che value
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     */
    onChange: PropTypes.func,
    /**
     * Is disabled
     */
    disabled: PropTypes.bool,
    /**
     * Rentered text next to check box
     */
    label: PropTypes.node,
};

export default CheckBox;
