import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import EmptyContent from 'components/EmptyContent/EmptyContent';
import useApi from 'hooks/useApi';
import { t } from 'utils';
import ImageTypeSelect from './ImageTypeSelect';

const ImageTypeSwap = ({ onSave, value, dataKey }) => {
    const { id, templateId } = useParams();

    const { loading, response } = useApi({
        slug: 'partnermarketing_template_image_options_api',
        slugParams: { campaignInstanceId: id, templateId, groupName: dataKey },
        cache: true,
    });

    return (
        <ImageTypeSelect
            loading={loading}
            onSave={onSave}
            images={response?.imageOptions || []}
            value={value}
            empty={
                <EmptyContent
                    image={EmptyContent.IMAGE.DOCUMENTS}
                    text={t('template.image.swappable_option_no_options')}
                />
            }
        />
    );
};

ImageTypeSwap.propTypes = {
    onSave: PropTypes.func.isRequired,
    dataKey: PropTypes.string.isRequired,
    value: PropTypes.string,
};

export default ImageTypeSwap;
