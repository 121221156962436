import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './TwoCols.module.scss';

const TwoColsLeft = ({ actions, side }) => (
    <div className={styles.left} id="components-two-cols-left">
        {!!side && <div className={cn(styles.leftContent, !actions && styles.noActions)}>{side}</div>}
        {!!actions && <div className={styles.leftActions}>{actions}</div>}
    </div>
);

TwoColsLeft.propTypes = {
    side: PropTypes.node,
    actions: PropTypes.node,
};

export default TwoColsLeft;
