import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { matchPath } from 'react-router-dom';
import cn from 'classnames';
import { t } from 'utils';
import { isUndefined } from 'lodash';
import PmLink from '../../../PmLink/PmLink';
import styles from '../../Header.module.css';
import DropDown from '../../../DropDown/DropDown';
import history from '../../../../history';

/**
 * Helper component for Menu
 * @ignore
 */
export function Separator({ visible }) {
    if (!visible) {
        return null;
    }

    return (
        <li className={styles.separator}>
            <hr className={styles.dark} />
        </li>
    );
}
Separator.defaultProps = {
    visible: true,
};
Separator.propTypes = {
    visible: PropTypes.bool,
};

/**
 * Helper component for Menu
 * @ignore
 */
export function Grouping({ visible, title }) {
    if (!visible) {
        return null;
    }
    return <li className={styles.grouping}>{title}</li>;
}
Grouping.defaultProps = {
    visible: true,
};
Grouping.propTypes = {
    visible: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

const isMatchLocation = path => {
    if (!path || path === '/') {
        return history.location.pathname === path;
    }

    return matchPath(history.location.pathname, path);
};

const currentClass = children => {
    let isCurrent = false;
    if (isString(children)) {
        isCurrent = isMatchLocation(children);
    } else {
        isCurrent =
            React.Children.map(children, child => child && child.props && child.props.to).filter(path =>
                isMatchLocation(path)
            ).length > 0;
    }

    return isCurrent ? `${styles.current} client-navigation-colour-border-pseudo` : '';
};

/**
 * Helper component for Menu
 * @ignore
 */
export function Link({ visible, title, description, to, topLevel, withArrow, cssClassName }) {
    if (!visible) {
        return null;
    }
    const className = topLevel ? styles.link : cn(styles.link, 'client-paragraph-bold', cssClassName);

    return (
        <li className={currentClass(to)}>
            <PmLink to={to} className={className}>
                {title}
                {!!withArrow && <div className={cn(styles.arrowRight, 'client-paragraph')} />}
            </PmLink>
            {!!description && <p className={cn(styles.linkDescription, 'client-paragraph')}>{description}</p>}
        </li>
    );
}
Link.defaultProps = {
    visible: true,
    topLevel: false,
};
Link.propTypes = {
    cssClassName: PropTypes.string,
    description: PropTypes.string,
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    topLevel: PropTypes.bool,
    visible: PropTypes.bool,
    withArrow: PropTypes.bool,
};

/**
 * Helper component for Menu
 * @ignore
 */
export function DropDownLink({ isWide, visible, title, children, noPadding }) {
    if (!visible) {
        return null;
    }
    return (
        <li className={currentClass(children)}>
            <DropDown
                withArrow
                menuChildren={children}
                isWide={isWide}
                menuClassName={cn(styles.menuDropDown, noPadding && styles.noPadding, 'dropdown-menu')}
                placeholderClassName={styles.mainItem}
            >
                {title}
            </DropDown>
        </li>
    );
}
DropDownLink.defaultProps = {
    visible: true,
    isWide: false,
};
DropDownLink.propTypes = {
    noPadding: PropTypes.bool,
    visible: PropTypes.bool,
    isWide: PropTypes.bool,
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
};

export function SideMenu({ rows }) {
    const COLUMN_ITEMS_NUM = 5;
    const [activeRow, setActiveRow] = useState(Object.keys(rows)[0]);
    const itemsNum = rows[activeRow]?.items.length ?? 0;
    const columnNum = Math.ceil(itemsNum / COLUMN_ITEMS_NUM);
    const rightSideColumns = [];
    for (let i = 0; i < columnNum; i++) {
        rightSideColumns[i] = rows[activeRow]?.items.slice(i * COLUMN_ITEMS_NUM, (i + 1) * COLUMN_ITEMS_NUM);
    }

    return (
        <div className={styles.sideMenu}>
            <div className={styles.sideMenuLeft}>
                <ul>
                    {Object.entries(rows).map(
                        ([key, row]) =>
                            (isUndefined(row.visible) || !!row.visible) && (
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                <li
                                    key={key}
                                    className={cn(
                                        styles.leftSideItem,
                                        'client-sub-header',
                                        key === activeRow ? 'active' : ''
                                    )}
                                    onClick={() => setActiveRow(key)}
                                >
                                    {t(row.menuTitle)}
                                </li>
                            )
                    )}
                </ul>
            </div>
            <div className={styles.sideMenuRight}>
                {!!rows[activeRow]?.items.length && (
                    <DropDownSection title={t(rows[activeRow].title)} className={styles.forSideMenuRight}>
                        <Separator />
                        <div className={styles.sideMenuColumns}>
                            {!!rightSideColumns.length &&
                                rightSideColumns.map((column, colIndex) => (
                                    <ul key={`column-${colIndex}`} className={styles.sideMenuColumn}>
                                        {column.map(
                                            (item, index) =>
                                                (isUndefined(item.visible) || !!item.visible) && (
                                                    <Link
                                                        cssClassName={styles.rightSideLink}
                                                        withArrow
                                                        key={`side-menu-item-${index}`}
                                                        title={t(item.title)}
                                                        description={item.description ? t(item.description) : ''}
                                                        to={item.to}
                                                    />
                                                )
                                        )}
                                    </ul>
                                ))}
                        </div>
                    </DropDownSection>
                )}
            </div>
        </div>
    );
}

SideMenu.propTypes = {
    rows: PropTypes.objectOf(
        PropTypes.shape({
            menuTitle: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    description: PropTypes.string.isRequired,
                    to: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ).isRequired,
};

export function DropDownSection({ visible, title, description, children, className }) {
    if (!visible) {
        return null;
    }

    return (
        <ul className={cn(styles.menuSection, className)}>
            <li>
                <dl>
                    <dt className="client-sub-header-bold">{title}</dt>
                    {!!description && <dd className="client-paragraph">{description}</dd>}
                </dl>
            </li>

            {children}
        </ul>
    );
}

DropDownSection.defaultProps = {
    visible: true,
};
DropDownSection.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool,
};

export function Columns({ size, children }) {
    if (!children) {
        return null;
    }

    return <li className={styles[`columns-${size}`]}>{children}</li>;
}

Columns.defaultProps = {
    size: 1,
};
Columns.propTypes = {
    children: PropTypes.any.isRequired,
    size: PropTypes.number,
};
