import { useCallback, useState } from 'react';

const useToggle = (initialState = false): [boolean, () => void] => {
    const [flag, setFlag] = useState(initialState);
    const toggleFlag = useCallback(() => setFlag(prevFlag => !prevFlag), [setFlag]);

    return [flag, toggleFlag];
};

export default useToggle;
