import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isUndefined from 'lodash/isUndefined';
import Button from 'components/Button/Button';
import PmLink from 'components/PmLink/PmLink';
import styles from './Tabs.module.scss';

const KIND = {
    WIDE: 'wide',
    DARK: 'dark',
};

const Tabs = ({ items, className, kind, rightContent, onChange, onDelete, active, prefix }) => {
    const [processing, setProcessing] = useState(null);
    return (
        <div className={cn(styles.root, styles[`kind-${kind}`], className)}>
            {!!rightContent && <div className={styles.right}>{rightContent}</div>}
            <ul className={styles.tabs}>
                {!!prefix && (
                    <li className={styles.prefix}>
                        <span className={styles.link}>{prefix}</span>
                    </li>
                )}
                {items.map((item, key) => {
                    if (!item) {
                        return null;
                    }

                    const onClick = onChange && !item.disabled ? () => onChange(item) : undefined;

                    const activeClassName = cn(
                        styles.active,
                        kind === KIND.WIDE && 'client-10pc-colour-background client-main-colour-border'
                    );
                    let Component = PmLink;
                    let props = {
                        to: item.to,
                        activeClassName,
                        className: cn(styles.link, item.key && item.key === active ? activeClassName : ''),
                        onClick,
                    };

                    if (!item.to) {
                        Component = 'a';
                        props = {
                            onClick,
                            className: cn(styles.link, item.value === active ? activeClassName : ''),
                        };
                    }

                    const isProcessing = item.toggleDisabled ? item.value === processing : false;
                    const handleToggleDisabled = async () => {
                        setProcessing(item.value);
                        await item.toggleDisabled(item);
                        setProcessing(null);
                    };

                    const handleDelete = async () => {
                        setProcessing(item.value);
                        await onDelete(item);
                        setProcessing(null);
                    };
                    const showDelete = !!onDelete && !item.static;

                    return (
                        <li
                            className={cn(
                                styles.tab,
                                item.disabled && styles.disabled,
                                (item.toggleDisabled || showDelete) && styles.withAction
                            )}
                            key={key}
                        >
                            <Component {...props} data-tip={item.tip}>
                                <span>{item.label}</span>
                                {!!item.toggleDisabled && (
                                    <Button
                                        color={Button.COLOR.NONE}
                                        disabled={isProcessing}
                                        iconSpin={isProcessing}
                                        className={cn(styles.tabAction, item.disabled ? styles.add : styles.remove)}
                                        xxs
                                        icon={(isProcessing && 'loading') || (item.disabled ? 'plus' : 'cross')}
                                        onClick={handleToggleDisabled}
                                    />
                                )}
                                {showDelete && (
                                    <Button
                                        color={Button.COLOR.NONE}
                                        disabled={isProcessing}
                                        iconSpin={isProcessing}
                                        className={cn(styles.tabAction, styles.remove)}
                                        xxs
                                        icon={isProcessing ? 'loading' : 'cross'}
                                        onClick={handleDelete}
                                    />
                                )}
                                {!isUndefined(item.count) && <span className={styles.count}>{item.count}</span>}
                                {kind === KIND.WIDE && (
                                    <span className={styles.arrow}>
                                        <span className="client-10pc-colour-background client-main-colour-border" />
                                    </span>
                                )}
                            </Component>
                        </li>
                    );
                })}
            </ul>

            <div className={styles.border} />
        </div>
    );
};

Tabs.propTypes = {
    rightContent: PropTypes.node,
    prefix: PropTypes.node,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string,
            label: PropTypes.node.isRequired,
            value: PropTypes.any,
            count: PropTypes.number,
            tip: PropTypes.string,
            disabled: PropTypes.bool,
            static: PropTypes.bool,
            toggleDisabled: PropTypes.func,
        })
    ).isRequired,
    className: PropTypes.string,
    kind: PropTypes.string,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    active: PropTypes.any,
};

Tabs.KIND = KIND;

export default Tabs;
