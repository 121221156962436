import { useEffect, useContext } from 'react';
import layoutContext from '../context/layoutContext';

const useExtendedLayout = () => {
    const { setExtended } = useContext(layoutContext);
    useEffect(() => {
        setExtended(true);
        return () => setExtended(false);
    }, [setExtended]);
};

export default useExtendedLayout;
