import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const Language = PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
});

export const TemplateDataArea = PropTypes.shape({
    id: PropTypes.number.isRequired,
    position: PropTypes.number,
    isDeletable: PropTypes.bool,
    isDuplicate: PropTypes.bool,
    isActive: PropTypes.bool,
});
