import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import get from 'lodash/get';
import Button from '../Button/Button';
import styles from './Table.module.css';
import Icon from '../Icon/Icon';
import CheckBox from '../CheckBox/CheckBox';

const TableRowActions = ({ rowActionsClassName, rowActions, dataItem, index }) => {
    if (!rowActions.length) {
        return null;
    }

    return (
        <td className={cn(styles.col, styles.colActions, rowActionsClassName)}>
            {rowActions.map((rowAction, actionIndex) => {
                if (!rowAction || (rowAction.displayCallback && !rowAction.displayCallback(dataItem))) {
                    return null;
                }
                const actionKey = rowAction.title || actionIndex;

                if (rowAction.children) {
                    return <Fragment key={actionKey}>{rowAction.children(dataItem)}</Fragment>;
                }

                const actionProps = {};
                if (rowAction.link) {
                    actionProps.link = rowAction.link(dataItem);
                }

                if (rowAction.href) {
                    actionProps.href = rowAction.href(dataItem);
                }

                if (rowAction.onClick) {
                    actionProps.onClick = () => {
                        ReactTooltip.hide();
                        rowAction.onClick(dataItem, index)();
                    };
                }

                return (
                    <Button
                        tiny
                        tip={rowAction.title}
                        tipClass={rowAction.tipClass}
                        key={actionKey}
                        className={cn(styles.actionButton, rowAction.className)}
                        {...rowAction}
                        {...actionProps}
                    />
                );
            })}
        </td>
    );
};

TableRowActions.propTypes = {
    dataItem: PropTypes.any,
    rowActions: PropTypes.array,
    rowActionsClassName: PropTypes.string,
};

TableRowActions.defaultProps = {
    rowActions: [],
};

const TableRow = ({
    className,
    columns,
    dataItem,
    rowActions,
    innerRef,
    provided,
    swappable,
    rowActionsClassName,
    hasRowActions,
    hasBatchActions,
    onSelectRow,
    selected,
    index,
}) => (
    <tr className={className} ref={innerRef} {...provided.draggableProps}>
        {hasBatchActions && (
            <td className={cn(styles.col, styles.batchCol)} onClick={() => onSelectRow(dataItem)}>
                <CheckBox checked={selected} />
            </td>
        )}
        {columns.map((column, columnKey) => {
            let renderedItem = '';
            const value = get(dataItem, column.key);

            if (column.renderer) {
                const CurrentComponent = column.renderer;
                renderedItem = (
                    <CurrentComponent
                        value={value}
                        row={dataItem}
                        column={column.key}
                        params={column.params}
                        index={index}
                    />
                );
            } else {
                renderedItem = <span className={styles.value}>{value}</span>;
            }

            return (
                <td key={columnKey} className={cn(styles.col, column.className)}>
                    {swappable && !columnKey && (
                        <span {...provided.dragHandleProps} className={styles.colSwap}>
                            <Icon kind="drag" />
                        </span>
                    )}
                    {renderedItem}
                </td>
            );
        })}

        {hasRowActions && (
            <TableRowActions
                rowActions={rowActions}
                dataItem={dataItem}
                rowActionsClassName={rowActionsClassName}
                index={index}
            />
        )}
    </tr>
);

TableRow.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.array,
    rowActions: PropTypes.array,
    dataItem: PropTypes.any,
    hasRowActions: PropTypes.bool,
    rowActionsClassName: PropTypes.string,
    hasBatchActions: PropTypes.bool,
    onSelectRow: PropTypes.func,
    selected: PropTypes.bool,
    index: PropTypes.number,
};

TableRow.defaultProps = {
    columns: [],
};

export default TableRow;
