export default {
    list: {
        load: 'LANGUAGE_LIST_LOAD',
        success: 'LANGUAGE_LIST_SUCCESS',
    },
    translations: {
        templateType: {
            load: 'LANGUAGE_TRANSLATIONS_TEMPLATE_TYPE_LOAD',
            success: 'LANGUAGE_TRANSLATIONS_TEMPLATE_TYPE_SUCCESS',
        },
        templateStatus: {
            load: 'LANGUAGE_TRANSLATIONS_TEMPLATE_STATUS_LOAD',
            success: 'LANGUAGE_TRANSLATIONS_TEMPLATE_STATUS_SUCCESS',
        },
    },
};
