import merge from 'lodash/merge';
import ACTIONS from './constants';

const initialState = {
    prevPathname: '',
    pathname: window.location.pathname,
    current: {
        data: {},
        loading: true,
    },
    upsert: {
        data: {},
        loading: true,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.getByToken.load: {
            return {
                ...state,
                current: {
                    ...state.current,
                    loading: true,
                },
            };
        }

        case ACTIONS.getByToken.success: {
            return {
                ...state,
                current: {
                    data: action.data,
                    loading: false,
                },
            };
        }

        case ACTIONS.updateCurrentData: {
            return {
                ...state,
                current: {
                    data: merge({}, state.current.data, action.data),
                    loading: false,
                },
            };
        }

        case ACTIONS.upsert.load: {
            return {
                ...state,
                upsert: {
                    ...state.upsert,
                    loading: true,
                },
            };
        }

        case ACTIONS.upsert.success: {
            return {
                ...state,
                upsert: {
                    data: action.data,
                    loading: false,
                },
            };
        }

        case ACTIONS.setPathName: {
            return {
                ...state,
                prevPathname: state.pathname !== action.pathname ? state.pathname : state.prevPathname,
                pathname: action.pathname,
            };
        }

        case ACTIONS.toggleBasket: {
            return {
                ...state,
                current: {
                    data: {
                        ...state.current.data,
                        basketItemsCount: action.num,
                    },
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default reducer;
