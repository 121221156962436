import Button from 'components/Button/Button';
import React from 'react';
import { t } from 'utils';
import styles from './ImageTypeField.module.scss';

type ImageTypeUploadSelectProps = {
    preview: string;
    onSelect: () => void;
    onReset: () => void;
};
export default function ImageTypeUploadSelect({ preview, onSelect, onReset }: ImageTypeUploadSelectProps): JSX.Element {
    return (
        <>
            <button className={styles.imagePreview} type="button" onClick={onSelect}>
                <img src={preview} alt="Preview" />
            </button>
            <div className={styles.actions}>
                <Button icon="updated" color={Button.COLOR.GRAY} small onClick={onReset}>
                    {t('template.template_wrapper.start_again')}
                </Button>
            </div>
        </>
    );
}
