import React, { Component } from 'react';
import { Field } from 'formik';
import range from 'lodash/range';
import padStart from 'lodash/padStart';
import Select from './Select';
import styles from './Input.module.css';

const HOURS = range(1, 13).map((hour) => ({
    id: hour,
    text: padStart(hour, 2, '0'),
}));

const getMinutesRange = full => full ? range(0, 60) : range(0, 46, 15);

const getMinutes = full => getMinutesRange(full).map((minute) => ({
    id: minute,
    text: padStart(minute, 2, '0'),
}));

const AM_PM = [
    { id: 'am', text: 'AM' },
    { id: 'pm', text: 'PM' },
];

class TimePicker extends Component {
    getFieldName = (suffix) => `${this.props.baseName}.${suffix}`;

    render() {
        return (
            <div className={styles.timePicker}>
                <Field
                    name={this.getFieldName('hours')}
                    component={Select}
                    data={HOURS}
                    textField="text"
                    valueField="id"
                    className={styles.input}
                />
                <Field
                    name={this.getFieldName('minutes')}
                    component={Select}
                    data={getMinutes(this.props.full)}
                    textField="text"
                    valueField="id"
                    className={styles.input}
                />
                <Field
                    name={this.getFieldName('amPm')}
                    component={Select}
                    data={AM_PM}
                    textField="text"
                    valueField="id"
                    className={styles.input}
                />
            </div>
        );
    }
}

export default TimePicker;
