import { ITableFetchableColumnRendererParams } from 'components/Table/TableFetchable';
import { t } from 'utils';

export default function FormatOption(prefix: string, emptyValue = '') {
    return function Formatter({ value }: ITableFetchableColumnRendererParams): string {
        if (!value) {
            return emptyValue;
        }

        return t(`${prefix}.${value}`);
    };
}
