/* eslint react/no-danger: 0 */
import React from 'react';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import Panel from 'components/Panel/Panel';
import { prettyFileSize, t } from 'utils';
import { AssetLibraryAsset } from 'propTypes/assetLibrary';
import styles from './View.module.css';

const makeHtml = html =>
    html.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\\n/g, '<br />').replace(/\n/g, '<br />');

const AssetDetails = ({ asset }) => {
    const groups = groupBy(asset.filterEntries, item => item.group.name);
    const details = [];
    forEach(groups, (items, label) => {
        details.push({
            label,
            value: items.map(({ value }) => value).join(', '),
        });
    });

    if (asset.type) {
        details.push({
            label: t('asset_library.browse.one_asset.type'),
            value: asset.type.name,
        });
        details.push({
            label: t('asset_library.browse.one_asset.language'),
            value: asset.language.language.name,
        });
    }

    if (asset.fileSize) {
        details.push({
            label: t('asset_library.browse.one_asset.file_size'),
            value: prettyFileSize(asset.fileSize),
        });
    }

    return (
        <div className={styles.assetDetails}>
            <table>
                <tbody>
                    {details.map(({ label, value }) => (
                        <tr key={label}>
                            <th>{`${label}:`}</th>
                            <td>{value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className={styles.assetDescription} dangerouslySetInnerHTML={{ __html: asset.description }} />

            {!!asset.restrictionDetails && (
                <Panel type="warning" title={t('asset_library.browse.one_asset.restriction_details')}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: makeHtml(asset.restrictionDetails),
                        }}
                    />
                </Panel>
            )}
        </div>
    );
};

AssetDetails.propTypes = {
    asset: AssetLibraryAsset,
};

export default AssetDetails;
