import React from 'react';
import { connect } from 'react-redux';
import styles from './BackToIdpButton.module.css';
import { isSSO, isSsoEnabled, isSsoBackToIdpEnabled, getBackToIdpSettings } from '../../../../selectors/userSelectors';
import Button from '../../../Button/Button';

const BackToIdpButton = ({ backToIdpSettings, isSSOUser, isSSOEnabled, isSsoBackToIdpEnabled }) => {
    if (!isSSOUser || !isSSOEnabled || !isSsoBackToIdpEnabled) {
        return null;
    }

    return (
        <Button
            href={backToIdpSettings.url}
            color={Button.COLOR.NONE}
            className={`${styles.backButton} client-top-nav-text-colour`}
            icon="arrow-left"
        >
            {backToIdpSettings.title}
        </Button>
    );
};

const mapStateToProps = state => ({
    backToIdpSettings: getBackToIdpSettings(state),
    isSSOUser: isSSO(state),
    isSSOEnabled: isSsoEnabled(state),
    isSsoBackToIdpEnabled: isSsoBackToIdpEnabled(state),
});

export default connect(mapStateToProps)(BackToIdpButton);
