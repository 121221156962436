export default {
    item: {
        reset: 'OPPORTUNITIES_LEAD_ITEM_RESET',
        setData: 'OPPORTUNITIES_LEAD_ITEM_SET_DATA',
        setLoading: 'OPPORTUNITIES_LEAD_ITEM_SET_LOADING',
    },
    list: {
        setData: 'OPPORTUNITIES_LEAD_LIST_SET_DATA',
        setLoading: 'OPPORTUNITIES_LEAD_LIST_SET_LOADING',
    },
    report: {
        setData: 'OPPORTUNITIES_LEAD_REPORT_SET_DATA',
        setLoading: 'OPPORTUNITIES_LEAD_REPORT_SET_LOADING',
    },
};
