import { getCurrentUser } from 'selectors/userSelectors';
import {
    Api,
    multiselectArrayToString,
    dateToApiString,
    stringToMultiselectArray,
    stringToDate,
    client,
    t,
} from 'utils';
import { dialog, error } from 'store/alert/actions';
import * as userActions from 'store/user/actions';
import ACTIONS from './constants';

export const hideNewCountNotification = () => ({
    type: ACTIONS.notification.newCountHide,
});

// TODO: This should be separate endpoint which contains spiff data only.
export const getSpiffData = () => dispatch => dispatch(userActions.getByToken());

const processPagination = params => {
    const limit = params.pageSize || 10;
    const offset = ((params.pageNumber || 1) - 1) * limit;

    return {
        limit,
        offset,
    };
};

const processReport = params => {
    if (params.isReport) {
        return { isReport: true };
    }

    return {};
};

/*
 * Plan actions
 */

const getPlanStorageKey = () => (dispatch, getState) => `spiff_plan_${getCurrentUser(getState()).id}`;

const processReceivedPlanData = data => {
    // Process data to display on form.
    const extended = {};
    if (data.country) {
        extended.country = stringToMultiselectArray(data.country);
    }
    if (data.executionStart) {
        extended.executionStart = stringToDate(data.executionStart);
    }
    if (data.executionEnd) {
        extended.executionEnd = stringToDate(data.executionEnd);
    }

    return {
        ...data,
        ...extended,
    };
};

export const setPlanData = data => ({
    type: ACTIONS.plan.setData,
    data: processReceivedPlanData(data),
});

const setPlanLoading = loading => ({
    type: ACTIONS.plan.setLoading,
    loading,
});

const processPlanDataForSave = data => ({
    ...data,
    country: multiselectArrayToString(data.country),
    executionStart: dateToApiString(data.executionStart),
    executionEnd: dateToApiString(data.executionEnd),
});

export const extendPlanData = data => (dispatch, getState) => {
    dispatch({
        type: ACTIONS.plan.extendData,
        data,
    });

    const {
        spiff: {
            plan: { data: planData },
        },
    } = getState();
    if (!planData.id) {
        localStorage.setItem(dispatch(getPlanStorageKey()), JSON.stringify(processPlanDataForSave(planData)));
    }
};

const getLocalPlan = () => dispatch => {
    let data = localStorage.getItem(dispatch(getPlanStorageKey()));
    try {
        data = JSON.parse(data) || {};
    } catch (e) {
        data = {};
    }
    return data;
};

export const getCurrentPlan = () => async dispatch => {
    let result = null;
    try {
        result = await Api.get(Api.route('partnermarketing_spiff_plan_get_current_api'));
    } catch (e) {
        result = dispatch(getLocalPlan());
    }
    dispatch(setPlanData(result));
    return result;
};

export const getPlan = id => async dispatch => {
    let result = null;
    dispatch(setPlanLoading(true));
    try {
        result = await Api.get(Api.route('partnermarketing_spiff_plan_get_api', { id }));
    } catch (e) {
        dispatch(error(t('spiff.errors.cant_get_plan')));
    }
    dispatch(setPlanData(result));
    return result;
};

export const savePlan = () => async (dispatch, getState) => {
    const {
        spiff: { plan },
    } = getState();
    const data = processPlanDataForSave(plan.data);

    let result = null;
    try {
        if (data.id) {
            result = await Api.put(Api.route('partnermarketing_spiff_plan_update_api', { id: data.id }), data);
        } else {
            result = await Api.post(Api.route('partnermarketing_spiff_plan_create_api'), data);
        }
        dispatch(setPlanData(result));
        localStorage.removeItem(dispatch(getPlanStorageKey()));
    } catch (e) {
        dispatch(error(t('spiff.errors.cant_save_plan')));
    }
    return result;
};

export const submitPlan = () => async dispatch => {
    dispatch(setPlanLoading(true));
    let result = null;
    await dispatch(savePlan());
    try {
        result = await Api.post(Api.route('partnermarketing_spiff_plan_submit_api'));
    } catch (e) {
        dispatch(error(t('general.errors.cant_load_list')));
    }
    dispatch(setPlanData(result));
    return result;
};

const setPlanListData = data => ({
    type: ACTIONS.planList.setData,
    data,
});

const setPlanListLoading = loading => ({
    type: ACTIONS.planList.setLoading,
    loading,
});

export const getPlanList =
    (status, requestParams = {}) =>
    async (dispatch, getState) => {
        let result = null;
        dispatch(setPlanListLoading(true));
        const params = {
            status,
            ...processPagination(requestParams),
            ...processReport(requestParams),
        };
        const { search } = getState().spiff.planList;
        if (search) {
            params.text = search;
        }
        try {
            result = await Api.get(Api.route('partnermarketing_spiff_plan_get_list_api', params));
        } catch (e) {
            dispatch(error(t('general.errors.cant_load_list')));
            dispatch(setPlanListLoading(false));
            throw e;
        }
        dispatch(setPlanListData(result));
        return result;
    };

export const approvePlan = () => async (dispatch, getState) => {
    const {
        spiff: {
            plan: {
                data: { id },
            },
        },
    } = getState();
    let result = null;
    dispatch(setPlanLoading(true));
    try {
        result = await Api.post(Api.route('partnermarketing_spiff_plan_approve_api', { id }));
        await dispatch(getSpiffData());
    } catch (e) {
        dispatch(error(t('spiff.errors.cant_approve_plan'), 2));
    }
    dispatch(setPlanLoading(false));
    return result;
};

export const declinePlan = reason => async (dispatch, getState) => {
    const {
        spiff: {
            plan: {
                data: { id },
            },
        },
    } = getState();
    let result = null;
    dispatch(setPlanLoading(true));
    try {
        result = await Api.post(Api.route('partnermarketing_spiff_plan_decline_api', { id }), { reason });
        await dispatch(getSpiffData());
    } catch (e) {
        dispatch(error(t('spiff.errors.cant_decline_plan'), 2));
    }
    dispatch(setPlanLoading(false));
    return result;
};

export const setPlanSearch = search => ({
    type: ACTIONS.planList.setSearch,
    search,
});

/*
 *
 *
 * Claim actions
 *
 *
 */
const setClaimLoading = loading => ({
    type: ACTIONS.claim.setLoading,
    loading,
});

export const setClaimData = data => ({
    type: ACTIONS.claim.setData,
    data,
});

export const extendClaimData = data => ({
    type: ACTIONS.claim.extendData,
    data,
});

export const getCurrentClaim = () => async dispatch => {
    let result;
    try {
        result = await Api.get(Api.route('partnermarketing_spiff_claim_get_current_api'));
    } catch (e) {
        result = {};
    }
    dispatch(setClaimData(result));
    return result;
};

export const getClaim = id => async dispatch => {
    let result;
    dispatch(setClaimLoading(true));
    try {
        result = await Api.get(Api.route('partnermarketing_spiff_claim_get_api', { id }));
    } catch (e) {
        dispatch(error(t('spiff.errors.cant_get_plan')));
        throw e;
    }
    dispatch(setClaimData(result));
    return result;
};

export const saveClaim = () => async (dispatch, getState) => {
    const {
        spiff: {
            claim: { data },
        },
    } = getState();

    dispatch(setClaimLoading(true));
    let result = null;
    try {
        if (data.id) {
            if (!(data.promo instanceof File)) {
                delete data.promo;
            }
            if (!(data.salesReport instanceof File)) {
                delete data.salesReport;
            }
            result = await Api.upload(Api.route('partnermarketing_spiff_claim_update_api', { id: data.id }), data, {
                method: 'post',
            });
        } else {
            result = await Api.upload(Api.route('partnermarketing_spiff_claim_create_api'), data, { method: 'post' });
        }
        dispatch(setClaimData(result));
        return result;
    } catch (e) {
        dispatch(setClaimLoading(false));
        dispatch(error(t('spiff.errors.cant_save_plan')));
        throw e;
    }
};

export const submitClaim = () => async dispatch => {
    dispatch(setClaimLoading(true));
    await dispatch(saveClaim());
    dispatch(setClaimLoading(true));
    try {
        const result = await Api.post(Api.route('partnermarketing_spiff_claim_submit_api'));
        dispatch(setClaimData(result));
        return result;
    } catch (e) {
        dispatch(setClaimLoading(false));
        dispatch(error(t('spiff.errors.cant_submit_plan')));
        throw e;
    }
};

const setClaimListData = data => ({
    type: ACTIONS.claimList.setData,
    data,
});

export const updateClaimListData = item => ({
    type: ACTIONS.claimList.updateData,
    item,
});

export const setClaimListLoading = loading => ({
    type: ACTIONS.claimList.setLoading,
    loading,
});

export const getClaimList =
    (status, requestParams = {}) =>
    async (dispatch, getState) => {
        let result = null;
        dispatch(setClaimListLoading(true));
        const params = {
            status,
            ...processPagination(requestParams),
            ...processReport(requestParams),
        };
        const { search } = getState().spiff.claimList;
        if (search) {
            params.text = search;
        }
        try {
            result = await Api.get(Api.route('partnermarketing_spiff_claim_get_list_api', params));
        } catch (e) {
            dispatch(error(t('general.errors.cant_load_list')));
            dispatch(setClaimListLoading(false));
            throw e;
        }
        dispatch(setClaimListData(result));
        return result;
    };

export const approveClaim = () => async (dispatch, getState) => {
    const {
        spiff: {
            claim: {
                data: { id },
            },
        },
    } = getState();
    let result = null;
    dispatch(setClaimLoading(true));
    try {
        result = await Api.post(Api.route('partnermarketing_spiff_claim_approve_api', { id }));
        await dispatch(getSpiffData());
    } catch (e) {
        dispatch(error(t('spiff.errors.cant_approve_claim'), 2));
    }
    dispatch(setClaimLoading(false));
    return result;
};

export const declineClaim = reason => async (dispatch, getState) => {
    const {
        spiff: {
            claim: {
                data: { id },
            },
        },
    } = getState();
    let result = null;
    dispatch(setClaimLoading(true));
    try {
        result = await Api.post(Api.route('partnermarketing_spiff_claim_decline_api', { id }), { reason });
        await dispatch(getSpiffData());
    } catch (e) {
        dispatch(error(t('spiff.errors.cant_decline_claim'), 2));
    }
    dispatch(setClaimLoading(false));
    return result;
};

export const escalateClaim = escalateReason => async (dispatch, getState) => {
    const {
        spiff: {
            claim: {
                data: { id },
            },
        },
    } = getState();
    let result = null;
    dispatch(setClaimLoading(true));
    try {
        result = await Api.post(Api.route('partnermarketing_spiff_claim_escalate_api', { id }), { escalateReason });
        await dispatch(getSpiffData());
    } catch (e) {
        dispatch(error(t('spiff.errors.cant_escalate_claim'), 2));
    }
    dispatch(setClaimLoading(false));
    return result;
};

export const approvePromo = () => async (dispatch, getState) => {
    const {
        spiff: {
            claim: {
                data: { id },
            },
        },
    } = getState();
    let result = null;
    dispatch(setClaimLoading(true));
    try {
        result = await Api.post(Api.route('partnermarketing_spiff_claim_approve_promo_api', { id }));
        await dispatch(getSpiffData());
    } catch (e) {
        dispatch(error(t('spiff.errors.cant_approve_promo_claim')));
    }
    dispatch(setClaimLoading(false));
    return result;
};

export const declinePromoClaim = reason => async (dispatch, getState) => {
    const {
        spiff: {
            claim: {
                data: { id },
            },
        },
    } = getState();
    let result = null;
    dispatch(setClaimLoading(true));
    try {
        result = await Api.post(Api.route('partnermarketing_spiff_claim_decline_promo_api', { id }), { reason });
        await dispatch(getSpiffData());
    } catch (e) {
        dispatch(error(t('spiff.errors.cant_escalate_claim'), 2));
    }
    dispatch(setClaimLoading(false));
    return result;
};

export const downloadClaimReport =
    (id, status, isReport = false) =>
    async dispatch => {
        dispatch(setClaimListLoading(true));
        await Api.oneTimeAuthRedirect('partnermarketing_spiff_claim_get_report_api', { id, status, isReport });
        dispatch(setClaimListLoading(false));
    };

export const downloadPlanReport =
    (id, status, isReport = false) =>
    async dispatch => {
        dispatch(setPlanListLoading(true));
        await Api.oneTimeAuthRedirect('partnermarketing_spiff_plan_get_report_api', { id, status, isReport });
        dispatch(setPlanListLoading(false));
    };

export const deleteClaim = (id, name, tab) => dispatch => {
    dispatch(
        dialog(t('general.message.delete_entry_title', { name }), t('delete_dialog_description'), 'warning', 'Remove')
    ).then(async () => {
        dispatch(setClaimListLoading(true));
        try {
            await Api.delete(Api.route('partnermarketing_spiff_claim_delete_api', { id }));
            dispatch(getClaimList(tab));
        } catch (e) {
            dispatch(error(t('spiff.errors.cant_delete_claim')));
            dispatch(setClaimListLoading(false));
            throw e;
        }
    });
};

export const deletePlan = (id, name, tab) => dispatch => {
    dispatch(
        dialog(t('general.message.delete_entry_title', { name }), t('delete_dialog_description'), 'warning', 'Remove')
    ).then(async () => {
        dispatch(setPlanListLoading(true));
        try {
            await Api.delete(Api.route('partnermarketing_spiff_plan_delete_api', { id }));
            dispatch(getPlanList(tab));
        } catch (e) {
            dispatch(error(t('spiff.errors.cant_delete_claim')));
            dispatch(setPlanListLoading(false));
            throw e;
        }
    });
};

export const setClaimSearch = search => ({
    type: ACTIONS.claimList.setSearch,
    search,
});

const setArchivedListData = data => ({
    type: ACTIONS.archivedList.setData,
    data,
});

const setArchivedListLoading = loading => ({
    type: ACTIONS.archivedList.setLoading,
    loading,
});

export const getArchivedList = () => async dispatch => {
    let result = null;
    dispatch(setArchivedListLoading(true));
    try {
        result = await Api.get(
            Api.route('partnermarketing_spiff_get_submission_archived_plan_claims_api', { clientId: client.getId() })
        );
    } catch (e) {
        dispatch(error(t('general.errors.cant_load_list')));
        dispatch(setArchivedListLoading(false));
        throw e;
    }
    dispatch(setArchivedListData(result));
    return result;
};

export const claimBatchUpdate = (ids, data) => async dispatch => {
    let result = null;
    dispatch(setClaimListLoading(true));
    try {
        result = await Api.post(Api.route('partnermarketing_spiff_claim_bulk_update_api'), { ids, data });
    } catch (e) {
        dispatch(error(t('general.errors.cant_finish_batch_update')));
        dispatch(setClaimListLoading(false));
        throw e;
    }
    dispatch(setClaimListLoading(false));
    return result;
};

export const planBatchUpdate = (ids, data) => async dispatch => {
    let result = null;
    dispatch(setPlanListLoading(true));
    try {
        result = await Api.post(Api.route('partnermarketing_spiff_plan_bulk_update_api'), { ids, data });
    } catch (e) {
        dispatch(error(t('general.errors.cant_finish_batch_update')));
        dispatch(setPlanListLoading(false));
        throw e;
    }
    dispatch(setPlanListLoading(false));
    return result;
};
