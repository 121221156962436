import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { FORMAT } from '../../constants';

/**
 * Render input for date selection. Value should be of Date type.
 */
class DatePicker extends Component {
    state = {
        open: false,
    };

    toggleOpen = (open) => this.setState({ open });

    onChange = (value) => {
        const { id, name, onChange } = this.props;
        // Make it compatible with Formik.
        onChange({ target: { id, name, value } });
    };

    render() {
        const { open } = this.state;
        return (
            <DateTimePicker
                {...this.props}
                inputProps={{
                    readOnly: true,
                    onClick: () => this.toggleOpen(open ? false : 'date'),
                }}
                format={FORMAT.DATE}
                time={false}
                open={open}
                onToggle={this.toggleOpen}
                onChange={this.onChange}
            />
        );
    }
}

DatePicker.propTypes = {
    /**
     * Field name.
     */
    name: PropTypes.string.isRequired,
    /**
     * Field value. Should be string or date.
     */
    value: PropTypes.instanceOf(Date).isRequired,
    /**
     * Gets called when value was changed.
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Field id.
     */
    id: PropTypes.string.isRequired,
};

export default DatePicker;
