import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';
import groupBy from "lodash/groupBy";
import { SCHEDULED_TEMPLATES } from "../../../constants";

const getCampaignInstance = state => state.campaignInstance.item;
const getCoBrandedTemplate = state => state.campaignInstance.coBrandedTemplate;
const getTemplateInstance = state => state.campaignInstance.templateItem;

export const campaignInstanceLoadingSelector = createSelector([getCampaignInstance], instance => instance.loading);
export const coBrandedTemplateLoadingSelector = createSelector([getCoBrandedTemplate], data => data.loading);
export const campaignInstanceSelector = createSelector([getCampaignInstance], instance => instance.data);
export const campaignInstanceNameSelector = createSelector([campaignInstanceSelector], data => {
    const languageId = data.systemLanguage?.id;
    if (languageId) {
        const language = data.campaign.languages.filter(({ language: { id } }) => id === languageId).pop();
        return language?.name || '';
    }

    return '';
});
export const campaignInstanceTemplateGroupsSelector = createSelector([campaignInstanceSelector], campaignInstance => {
    if (!campaignInstance.templates || !campaignInstance.templates.length) {
        return [];
    }

    const templateGroups = groupBy(campaignInstance.templates, ({ type }) =>
        SCHEDULED_TEMPLATES.indexOf(type) !== -1 ? 'scheduled' : 'general'
    );

    return ['general', 'scheduled']
        .map(groupName => {
            let groupTemplates = [];
            if (templateGroups[groupName]) {
                groupTemplates = templateGroups[groupName].map(template => ({ template })) || [];
                groupTemplates.sort((a, b) => a.displayOrder - b.displayOrder);
            }
            return {
                groupName,
                groupTemplates,
            };
        })
        .filter(({ groupTemplates }) => !!groupTemplates.length);
});

export const templateInstanceLoadingSelector = createSelector([getTemplateInstance], template => template.loading);
export const templateInstanceSelector = createSelector([getTemplateInstance], template => ({
    ...template.data,
    pages: template.data.pages || [],
}));

const getToggleMasterNamesForItems = items => (items || []).filter(({ toggle }) => toggle).map(({ toggle }) => toggle);

export const toggleGroupsSelector = createSelector([campaignInstanceSelector], campaignInstance =>
    (campaignInstance.activeToggleGroups || []).map(toggleGroup => {
        const categoryOptions = toggleGroup.activeOptions || [];
        return {
            categoryOptions,
            placeholderOptions: categoryOptions.filter(({ isPlaceholder }) => isPlaceholder),
            name: toggleGroup.name,
            instructionalCopy: toggleGroup.instructionalCopy,
            masterName: toggleGroup.masterName,
        };
    })
);

export const toggleGroupsUsedInTemplateSelector = createSelector(
    [toggleGroupsSelector, templateInstanceSelector],
    (toggleGroups, template) => {
        const templateToggleMasterNames = uniq([
            ...getToggleMasterNamesForItems(template.data.texts),
            ...getToggleMasterNamesForItems(template.data.links),
            ...getToggleMasterNamesForItems(template.data.images),
        ]);

        if (!templateToggleMasterNames.length || !toggleGroups.length) {
            return [];
        }

        const usedGroups = toggleGroups.filter(
            ({ masterName }) => templateToggleMasterNames.indexOf(masterName) !== -1
        );

        return usedGroups.length ? usedGroups : [toggleGroups[0]];
    }
);

export const templateInstanceColourOptionsSelector = createSelector([templateInstanceSelector], template =>
    template.templateColours.reduce(
        (prev, { name, options, type }) => ({
            ...prev,
            [name]: { options: options.map(({ value }) => value), type },
        }),
        {}
    )
);
