import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconStack from 'components/IconStack/IconStack';

import styles from './InfoBlock.module.scss';

/**
 * Information block area
 */
const InfoBlock = ({
    actions,
    children,
    className,
    iconColor,
    innerIcon,
    size = '1',
    showIcon = true,
    title,
    titleClassName,
    messageClassName,
}) => (
    <div className={cn(styles.wrapper, className)}>
        {showIcon && (
            <IconStack
                className={cn(styles.icon, iconColor ? styles[`icon-${iconColor}`] : 'client-main-colour')}
                size={size}
                outer="bg-circle"
                inner={innerIcon || 'info'}
            />
        )}
        <div className={cn(styles.message, messageClassName)}>
            <div className={styles.text}>
                {!!title && <h2 className={cn('sub-title', styles.title, titleClassName)}>{title}</h2>}
                {children}
            </div>
            {!!actions && <div className={styles.actions}>{actions}</div>}
        </div>
    </div>
);

InfoBlock.ICON_COLORS = {
    GREEN: 'green',
};

InfoBlock.propTypes = {
    actions: PropTypes.node,
    children: PropTypes.any,
    className: PropTypes.string,
    iconColor: PropTypes.string,
    innerIcon: PropTypes.string,
    showIcon: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.node,
    titleClassName: PropTypes.string,
    messageClassName: PropTypes.string,
};

export default InfoBlock;
