import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import EmptyContent from 'components/EmptyContent/EmptyContent';
import useApi from 'hooks/useApi';
import { t } from 'utils';
import ImageTypeSelect from './ImageTypeSelect';
import styles from './ImageTypeField.module.scss';

const ImageTypeIdentifier = ({ onSave, value }) => {
    const { loading, response } = useApi({
        slug: 'partnermarketing_identifier_get_api',
        slugParams: { filtered: true },
        cache: true,
    });

    const images = useMemo(
        () =>
            (response || []).reduce((prev, item) => {
                if (!item.isAvailable) {
                    return prev;
                }
                return [
                    ...prev,
                    { id: `${item.id}_bw`, value: item.previewImageUrlBlackAndWhite },
                    { id: `${item.id}_bwr`, value: item.previewImageUrlBlackAndWhiteReversed, className: styles.dark },
                ];
            }, []),
        [response]
    );

    return (
        <ImageTypeSelect
            onSave={onSave}
            images={images}
            loading={loading}
            value={value}
            empty={
                <EmptyContent image={EmptyContent.IMAGE.IDENTIFIER} text={t('template.image.no_identifiers_created')} />
            }
        />
    );
};

ImageTypeIdentifier.propTypes = {
    onSave: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default ImageTypeIdentifier;
