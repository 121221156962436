import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTypes } from "pages/asset/library/redux/actions";
import { ASSET_LIBRARY_RESOURCE_STATUS } from "../../manage/manageConstants";
import { getCurrentUser } from "../../../selectors/userSelectors";

const useResourceTypes = () => {
    const [types, setTypes] = useState([]);
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser);

    if (!currentUser.client || !currentUser.client.isAssetLibraryEnabled) {
        return {
            types
        };
    }

    const fetchTypes = useCallback(() => {
        dispatch(getTypes()).then(data => setTypes(
            data.types.filter(type => type.status === ASSET_LIBRARY_RESOURCE_STATUS.ACTIVE)
        ));
    }, []);

    useEffect(() => {
        fetchTypes();
    }, []);

    return {
        types
    };
};

export default useResourceTypes;