import PropTypes from 'prop-types';
import { t } from '../../../utils';

const FormatYesNo = ({ value }) => (value ? t('general.labels.yes') : t('general.labels.no'));

FormatYesNo.propTypes = {
    value: PropTypes.any,
};

export default FormatYesNo;
