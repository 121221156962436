import isArray from 'lodash/isArray';
import { SOCIAL_POST_IMAGE_NAME, TEMPLATE_TYPE } from 'constants.js';
import { isSocialTemplate } from 'pages/campaignInstance/edit/helpers';

export const OPTIONS_GROUPS = {
    MAIN: 'main',
    COLOURS: 'colours',
    IMAGES: 'images',
    TEXTS: 'texts',
    LINKS: 'links',
    SOCIAL_ICONS: 'socialIcons',
};

export const SETTINGS_GROUPS = {
    MARGIN: 'margin',
    PADDING: 'padding',
    HORIZONTAL_ALIGMENT: 'horizontal_aligment',
    VERTICAL_ALIGMENT: 'vertical_aligment',
};

export const ICON_MAP = {
    [OPTIONS_GROUPS.MAIN]: 'settings',
    [OPTIONS_GROUPS.COLOURS]: 'edit-colour',
    [OPTIONS_GROUPS.IMAGES]: 'edit-image',
    [OPTIONS_GROUPS.TEXTS]: 'edit-text',
    [OPTIONS_GROUPS.LINKS]: 'edit-link',
    [OPTIONS_GROUPS.SOCIAL_ICONS]: 'edit-link',
    [SETTINGS_GROUPS.MARGIN]: 'global',
    [SETTINGS_GROUPS.PADDING]: 'global',
    [SETTINGS_GROUPS.HORIZONTAL_ALIGMENT]: 'drag',
    [SETTINGS_GROUPS.VERTICAL_ALIGMENT]: 'drag',
    DELETED: 'disabled',
};

export const MAIN_FIELD_NAMES = {
    TITLE: 'title',
    SUBJECT: 'subject',
    TOGGLE_GROUP: 'toggle_group',
    PDF_FILE: 'pdf_file',
};

export const MISSED_FIELD_NAME = 'highlightFields';

export const EMAIL_SUBJECT_TEXT_FIELD_NAME = 'email.subject';

const excludedTexts = ['currentyear', EMAIL_SUBJECT_TEXT_FIELD_NAME];
const excludedImages = ['partner.logo', 'business.logo', 'client.logo'];
export const isEditableDataItem = (dataType, item, isCampaignInstance) => {
    if (
        !item ||
        item.hideDefaultTemplate ||
        item.isToggleable ||
        item.toggle ||
        (isCampaignInstance && (item.isEditable === false || item.isDeleted))
    ) {
        return false;
    }

    switch (dataType) {
        case OPTIONS_GROUPS.LINKS:
            return !isCampaignInstance || !(item.isLinkEditable === false && item.isTextEditable === false);
        case OPTIONS_GROUPS.TEXTS:
            return excludedTexts.indexOf(item.name?.toLowerCase()) === -1;
        case OPTIONS_GROUPS.IMAGES:
            if (
                item.templateType &&
                isSocialTemplate({ type: item.templateType }) &&
                item.name === SOCIAL_POST_IMAGE_NAME &&
                item.isEditable === false
            ) {
                return false;
            }
            return excludedImages.indexOf(item.name) === -1;
        default:
            return true;
    }
};

/**
 * Return default data value to use.
 */
export const getDefaultDataValue = data => {
    let el;
    let isPlainText = true;
    const { name } = data;
    switch (data.dataType) {
        case OPTIONS_GROUPS.TEXTS:
            // Find related element in template HTML.
            el = document.querySelector(`#template [pm-text="${name}"]`);
            isPlainText = !data.isRichText;
            break;
        case OPTIONS_GROUPS.LINKS:
            el = document.querySelector(`#template [pm-link="${name}"]`);
            break;
        default:
            throw new Error(`Unsupported dataType: ${data.dataType}`);
    }

    if (!el) {
        return '';
    }

    el = el.cloneNode(true);
    // Remove editable overlay.
    el.removeChild(el.lastChild);
    return ((isPlainText ? el.innerText : el.innerHTML) || '').trim();
};

/**
 * Returns specific values for given data item and template type from available toggle group items
 * @param dataItem - single item from data.* groups.
 * @param toggleItems - options to look in.
 * @param templateType - template type to restrict options.
 * @returns {*|{value: *}|(*&{text, type, url})|(*&{value})}
 */
export const getToggleItemValues = (dataItem, toggleItems, templateType) => {
    const [type, index] = dataItem.name.split('-').slice(-2);
    const toggleItemName = `${type}-${index}`;
    const toggleItem = isArray(toggleItems)
        ? toggleItems.filter(({ name }) => name === toggleItemName)[0]?.value // This is template
        : toggleItems[type]?.[`${dataItem.toggle}-${toggleItemName}`]; // This is template instance
    if (toggleItem) {
        switch (type) {
            case 'text':
                return {
                    value: toggleItem.value,
                };
            case 'link':
                return {
                    type: toggleItem.type,
                    text: toggleItem.text,
                    url: toggleItem.value,
                };
            case 'image': {
                const image = toggleItem.filter(({ assetType }) => assetType === templateType)[0]?.image;
                return { value: image || dataItem.value };
            }
            default:
                throw new Error(`Unknown toggle item type ${type}`);
        }
    }
    return {};
};

export const canBeBroadcasted = type =>
    [
        TEMPLATE_TYPE.EMAIL,
        TEMPLATE_TYPE.TWITTER_POST_TEMPLATE,
        TEMPLATE_TYPE.LINKEDIN_POST_TEMPLATE,
        TEMPLATE_TYPE.FACEBOOK_POST_TEMPLATE,
    ].indexOf(type) !== -1;

export const hasPublishOptions = type =>
    [
        TEMPLATE_TYPE.LANDING_PAGE,
        TEMPLATE_TYPE.EMAIL,
        TEMPLATE_TYPE.PDF_TEMPLATE,
        TEMPLATE_TYPE.ANIMATED_BANNER,
        TEMPLATE_TYPE.STATIC_BANNER,
    ].indexOf(type) !== -1;

export const hasAssetRequestOption = type =>
    [TEMPLATE_TYPE.PDF_TEMPLATE, TEMPLATE_TYPE.ANIMATED_BANNER, TEMPLATE_TYPE.STATIC_BANNER].indexOf(type) !== -1;

export const hasPlatformOption = type => [TEMPLATE_TYPE.LANDING_PAGE, TEMPLATE_TYPE.EMAIL].indexOf(type) !== -1;

export const hasDownloadSourceOption = type => [TEMPLATE_TYPE.LANDING_PAGE, TEMPLATE_TYPE.EMAIL].indexOf(type) !== -1;

export const hasIFrameEmbedOption = type => [TEMPLATE_TYPE.LANDING_PAGE].indexOf(type) !== -1;

export const hasPrintOption = type => type === TEMPLATE_TYPE.PDF_TEMPLATE;

export const hasLinkOptionsSet = type =>
    [TEMPLATE_TYPE.ANIMATED_BANNER, TEMPLATE_TYPE.STATIC_BANNER].indexOf(type) !== -1;

export const hasOneLinkOption = type =>
    [
        TEMPLATE_TYPE.TWITTER_POST_TEMPLATE,
        TEMPLATE_TYPE.LINKEDIN_POST_TEMPLATE,
        TEMPLATE_TYPE.FACEBOOK_POST_TEMPLATE,
    ].indexOf(type) !== -1;

export const hasFixedLinkOption = type => type === TEMPLATE_TYPE.STATIC_BANNER;

export const hasLinkOptions = type => hasLinkOptionsSet(type) || hasOneLinkOption(type);

/**
 * Tests a htmlparser2 node and returns whether is it a text node at the start and end of the line containing only
 * white space. This allows these node types to be excluded from the rendering because they are unnecessary.
 *
 * @param {Object} node The element object as created by htmlparser2
 * @returns {boolean} Whether the node is an empty text node
 */
export function isEmptyTextNode(node) {
    return node.type === 'text' && /\r?\n/.test(node.data) && node.data.trim() === '';
}

/**
 * Processes the nodes generated by htmlparser2 and convert them all into React elements
 *
 * @param {Object[]} nodes List of nodes to process
 * @param {Function} transform Transform function to optionally apply to nodes
 * @returns {React.Element[]} The list of processed React elements
 */
export function processNodes(nodes, transform) {
    return nodes
        .filter(node => !isEmptyTextNode(node))
        .map((node, index) => {
            return transform(node, index);
        });
}
