import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { binding, lineBreakMatcher } from 'components/Input/quill/SoftLineBreak';
import clearFormatting from 'components/Input/quill/clearFormatting';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichText.css';
import RichTextToolbar from 'components/Input/RichTextToolbar';
import { shortCodesHandler } from 'components/Input/quill/ShortCodesSelect';
import styles from './Input.module.css';

const { Quill } = ReactQuill;

Quill.register(Quill.import('attributors/style/direction'), true);
Quill.register(Quill.import('attributors/style/align'), true);

const modules = {
    toolbar: {
        container: '',
        handlers: {
            'short-codes': shortCodesHandler,
        },
    },
    clipboard: {
        matchers: [
            ['BR', lineBreakMatcher],
            [Node.ELEMENT_NODE, clearFormatting],
        ],
        matchVisual: false,
    },
    keyboard: {
        bindings: {
            ...binding,
        },
    },
};

const getCharsLeft = (editor, maxChars) => maxChars - (editor?.getLength() || 0);

/**
 * Rich text editor
 */
function RichText({
    value = '<p><br class="softbreak"></p>',
    className,
    placeholder,
    onChange,
    name,
    id,
    maxChars,
    isEmail = false,
    showShortCodes = false,
}) {
    const editorRef = useRef(null);
    const editorId = useMemo(() => `${Math.random()}`.replace('0.', 'rich-text-'), []);
    const toolbarId = `${editorId}-tb`;
    const _modules = useMemo(
        () => ({
            ...modules,
            toolbar: {
                ...modules.toolbar,
                container: `#${toolbarId}`,
            },
        }),
        [toolbarId]
    );
    const _onChange = _value => {
        let v = _value;
        if (maxChars && editorRef.current) {
            const editor = editorRef.current.getEditor();
            if (getCharsLeft(editor, maxChars) < 0) {
                // Index is zero-based.
                editor.deleteText(maxChars - 1, editor.getLength());
                v = editor.root.innerHTML;
            }
        }
        onChange({
            target: {
                id,
                name,
                value: v,
            },
        });
    };

    return (
        <div id={editorId} className="quill">
            <RichTextToolbar id={toolbarId} isEmail={isEmail} showShortCodes={showShortCodes} />
            <ReactQuill
                value={value || ''}
                modules={_modules}
                className={cn(!!maxChars && styles.limitedRichText, className)}
                onChange={_onChange}
                placeholder={placeholder}
                ref={editorRef}
                bounds={`#${editorId}`}
            />
            {!!maxChars && (
                <div className={styles.charCounter}>
                    <div>{Math.max(0, getCharsLeft(editorRef.current?.getEditor(), maxChars))}</div>
                </div>
            )}
        </div>
    );
}

RichText.propTypes = {
    /**
     * Class name
     */
    className: PropTypes.string,
    /**
     * Placeholder
     */
    placeholder: PropTypes.string,
    /**
     * Max chars limit
     */
    maxChars: PropTypes.number,
    /**
     * Id attr
     */
    id: PropTypes.string.isRequired,
    /**
     * Value attr
     */
    value: PropTypes.string,
    /**
     * Name attr
     */
    name: PropTypes.string.isRequired,
    /**
     * Gets called when the user change the rich text
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Flag to display the short codes dropdown
     */
    showShortCodes: PropTypes.bool,
    /**
     * Flag to display email specific details
     */
    isEmail: PropTypes.bool,
};

export default RichText;
