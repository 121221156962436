import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Row from '../FlexBox/components/Row';
import Col from '../FlexBox/components/Col';
import { t } from '../../utils';
import styles from './ProgressBar.module.css';

const ProgressBar = ({ progress, showText, prefix }) => {
    let percent = 0;
    if (!Number.isNaN(parseFloat(progress))) {
        if (progress < 0) {
            percent = 0;
        } else if (progress > 100) {
            percent = 100;
        } else {
            percent = Math.round(progress);
        }
    }

    const bar = (
        <Row className={styles.progressBar}>
            <Col md={showText ? 9 : 11}>
                <div className={styles.outer}>
                    <div
                        className={cn(styles.inner, styles.static, 'client-main-colour-background')}
                        style={{ width: `${percent}%` }}
                    />
                </div>
            </Col>
            <Col md={showText ? 3 : 1}>
                <span className={styles.percentage}>
                    {!!showText && <span>{t('campaign.templates_publication.publication_progress')}</span>}
                    {`${percent}%`}
                </span>
            </Col>
        </Row>
    );

    return (
        <Col className={styles.progressWrapper}>
            {!prefix ? (
                bar
            ) : (
                <Row className={styles.progressBar}>
                    <Col md={3} className={styles.column}>
                        {prefix}
                    </Col>
                    <Col md={9} className={styles.column}>
                        {bar}
                    </Col>
                </Row>
            )}
        </Col>
    );
};

ProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
    showText: PropTypes.bool,
    prefix: PropTypes.string,
};

export default ProgressBar;
