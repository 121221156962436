import { createBrowserHistory } from 'history';
import isObject from 'lodash/isObject';
import { PASSWORD_CHANGE_KEY, ROUTE } from 'constants.js';

export const isPasswordUpdateRequired = () => !!window.localStorage.getItem(PASSWORD_CHANGE_KEY);
const shouldRestrictNavigation = () => isPasswordUpdateRequired();

// It should be createBrowserHistory, when will be out of iframe.
const history = createBrowserHistory();

const historyPush = history.push;

history.push = (path, state) => {
    if (![ROUTE.logout, ROUTE.account.password].includes(path.pathname) && shouldRestrictNavigation()) {
        return;
    }
    let _path = path;
    const search = state?.search || state?.searchParams;
    if (search) {
        _path = !isObject(path) ? { pathname: path } : path;
        _path.search = decodeURIComponent(`?${new URLSearchParams(search)}`);
    }

    historyPush(_path, state);
};

window.reactHistory = history;

export default history;
