import { Api } from 'utils';
import { error } from 'store/alert/actions';
import ACTIONS from './constants';

const requestList = () => ({
    type: ACTIONS.list.load,
});
const successList = data => ({
    type: ACTIONS.list.success,
    data,
});
export const list = () => async dispatch => {
    dispatch(requestList());
    let result = [];
    try {
        result = await Api.get(Api.route('partnermarketing_utility_get_timezones'));
        dispatch(successList(result));
    } catch (e) {
        dispatch(error("Can't retrieve list."));
    }
    return result;
};
