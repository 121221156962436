import React, { FC } from 'react';
import { ITableFetchableColumnRendererParams } from 'components/Table/TableFetchable';
import styles from './FormatColoursPreview.module.scss';

type TFormatColoursPreviewValue = string[];
const MAX_COLOURS = 5;

const FormatColoursPreview: FC<ITableFetchableColumnRendererParams<TFormatColoursPreviewValue>> = ({ value }) => {
    if (!value || !value.length) {
        return null;
    }

    const visible = value.length > MAX_COLOURS ? value.slice(0, MAX_COLOURS) : value;
    return (
        <>
            {visible.map((colour, index) => (
                <span key={`${colour}-${index}`} style={{ backgroundColor: colour }} className={styles.colour} />
            ))}
            {visible.length < value.length && <span className={styles.more}>+{value.length - visible.length}</span>}
        </>
    );
};

export default FormatColoursPreview;
