import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import DropDown from 'components/DropDown/DropDown';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import { switchRole as switchRoleAction } from 'pages/manage/Users/redux/actions';
import { getCurrentUser, getCurrentUserRolesMap } from 'selectors/userSelectors';
import { t } from 'utils';
import styles from './RoleSwitcher.module.css';

class RoleSwitcher extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        roles: PropTypes.object,
        switchRole: PropTypes.func.isRequired,
    };

    switchRole = role => {
        const { currentUser, switchRole } = this.props;
        switchRole(currentUser.client.id, role, currentUser.displayLanguage.language.code);
    };

    render() {
        const { currentUser, roles } = this.props;

        if (isEmpty(currentUser) || (!roles.isBaseVendor && !roles.isBaseReportManager)) {
            return null;
        }

        const userRoles = currentUser.roles;
        const { rolesToLoginAs } = currentUser;

        const currentRole = t(`pmApp.client.controller.ClientHeaderController.${userRoles[0].role.toLowerCase()}`);
        const rolesToSwitch = [];
        rolesToLoginAs.forEach(role => {
            const roleTranslationKey = role.toLowerCase();
            if (role !== userRoles[0].role) {
                rolesToSwitch.push({
                    onClick: () => this.switchRole(role),
                    label: t(`pmApp.client.controller.ClientHeaderController.${roleTranslationKey}`),
                });
            }
        });

        return (
            <DropDown items={rolesToSwitch} noIcon className={styles.switcherDropDown}>
                <Button
                    bordered
                    className={`${styles.switcherDropDownButton} client-top-nav-text-colour`}
                    color={Button.COLOR.GRAY}
                >
                    {currentRole}
                    <Icon kind="arrow-down" className={styles.switcherDropDownArrow} />
                </Button>
            </DropDown>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: getCurrentUser(state),
    roles: getCurrentUserRolesMap(state),
});

const mapDispatchToProps = {
    switchRole: switchRoleAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleSwitcher);
