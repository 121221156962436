import { Api, client } from '../../utils';

export const getRegions = (params = {}) => {
    const options = {
        id: client.getId(),
        ...params,
    };
    return Api.get(Api.route('partnermarketing_client_get_regions_api', options));
};

export const getTiers = (params = {}) => {
    const options = {
        id: client.getId(),
        ...params,
    };
    return Api.get(Api.route('partnermarketing_client_get_tiers_api', options));
};

export const getApprovalGroups = (params = {}) => {
    const options = {
        id: client.getId(),
        ...params,
    };
    return Api.get(Api.route('partnermarketing_client_get_approval_groups_api', options));
};

export const getFilterGroups = (params = {}) => {
    return Api.get(Api.route('partnermarketing_filter_group_list_api', params));
};

export const getCampaigns = (params = {}) => {
    return Api.get(Api.route('partnermarketing_client_get_campaigns_api', params));
};

export const getAssetLibraryAsses = (params = {}) => {
    return Api.get(Api.route('partnermarketing_asset_library_assets', params));
};

export const getPermissions = (params = {}) => {
    const options = {
        id: client.getId(),
        ...params,
    };
    return Api.get(Api.route('partnermarketing_client_get_permissions_api', options));
};

export const getRewardClubModules = (params = {}) => {
    const options = {
        id: client.getId(),
        ...params,
    };
    return Api.get(Api.route('partnermarketing_client_get_reward_club_modules_api', options));
};

export const getRewardClubRewards = (params = {}) => {
    return Api.get(Api.route('partnermarketing_loyalty_reward_club_list_api', params));
};

export const getClientSystemLanguages = (params = {}) => {
    const options = {
        id: client.getId(),
        ...params,
    };
    return Api.get(Api.route('partnermarketing_client_get_languages_api', options));
};

export const getIdentifierBuilderSettings = () => {
    return Api.get(Api.route('partnermarketing_client_get_identifier_builder_settings_api'));
};

let timeZonesPromise = null;
export const getTimeZones = () => {
    if (!timeZonesPromise) {
        timeZonesPromise = Api.get(Api.route('partnermarketing_utility_get_timezones'));
    }
    return timeZonesPromise;
};

export const getAgencies = () => Api.get(Api.route('partnermarketing_agency_list_api'));
