import { combineReducers } from 'redux';
import ACTIONS from './constants';

const reportInitialState = {
    data: [],
    loading: false,
    counts: {},
};

const reportReducer = (state = reportInitialState, action) => {
    switch (action.type) {
        case ACTIONS.report.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.report.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.report.setCounts: {
            return {
                ...state,
                counts: action.counts,
            };
        }

        default: {
            return state;
        }
    }
};

const partnerLPReportSummaryInitialState = {
    loading: false,
    data: {
        templateSummary: {
            assets: [],
        },
        summary: {
            count: 0,
            landingPageVisits: 0,
            emailClickCount: 0,
            newLeadCount: 0,
            emailOpensCount: 0,
            emailSentCount: 0,
            landingPageFormFillsCount: 0,
            landingPageVisitCount: 0,
            newLeadsAsPercentOfPartnerTotal: 0,
            partnerLeadCount: 0,
            uniqueLeadsEmailedCount: 0,
        },
    },
};

const partnerLPReportSummaryReducer = (state = partnerLPReportSummaryInitialState, action) => {
    switch (action.type) {
        case ACTIONS.partnerLPReportSummary.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.partnerLPReportSummary.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const partnerLPFormFieldsInitialState = {
    loading: false,
    data: [],
};

const partnerLPFormFieldsReducer = (state = partnerLPFormFieldsInitialState, action) => {
    switch (action.type) {
        case ACTIONS.partnerLPReportFormFields.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.partnerLPReportFormFields.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const partnerLPReportKeenIoInitialState = {
    loading: false,
    data: {
        visitors: 0,
        totalVisitors: 0,
        formComplete: 0,
        didntTouchForm: 0,
        formStarted: 0,
        conversionRate: 0,
    },
};

const partnerLPReportKeenIoReducer = (state = partnerLPReportKeenIoInitialState, action) => {
    switch (action.type) {
        case ACTIONS.partnerLPReportKeenIO.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.partnerLPReportKeenIO.setData: {
            const [
                { result: totalVisitors = 0 },
                {
                    result: [visitors = 0, touchedForm = 0, completedForm = 0],
                },
            ] = action.data;

            return {
                ...state,
                data: {
                    visitors,
                    totalVisitors,
                    formComplete: completedForm,
                    didntTouchForm: visitors ? (visitors - touchedForm) / visitors : 0,
                    formStarted: visitors ? touchedForm / visitors : 0,
                    conversionRate: visitors ? completedForm / visitors : 0,
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    report: reportReducer,
    partnerLPReportKeenIo: partnerLPReportKeenIoReducer,
    partnerLPReportSummary: partnerLPReportSummaryReducer,
    partnerLPFormFields: partnerLPFormFieldsReducer,
});
