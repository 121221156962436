import { ROUTE } from 'constants.js';
import { CAMPAIGNS_STATUS } from '../../../../pages/manage/manageConstants';

export default {
    general: {
        menuTitle: 'client._general_dropdown.section_title.general_settings',
        title: 'client_set_up_dropdown.side_menu.general.descr',
        items: [
            {
                title: 'client._designer_set_up_dropdown.featured_campaigns',
                description: 'client._designer_set_up_dropdown.featured_campaigns_descr',
                to: ROUTE.manage.featuredCampaigns.list,
            },
            {
                title: 'client._designer_set_up_dropdown.settings',
                description: 'client._designer_set_up_dropdown.settings_descr',
                to: ROUTE.campaignBuilderSetup.groups,
            },
            {
                title: 'client._designer_set_up_dropdown.manage_fonts',
                description: 'client._designer_set_up_dropdown.manage_fonts_descr',
                to: ROUTE.manageFonts,
            },
            {
                title: 'client._designer_set_up_dropdown.manage_template_text_styles',
                description: 'client._designer_set_up_dropdown.manage_template_text_styles_descr',
                to: ROUTE.templateBuilder.textStyles.list(),
            },
            {
                title: 'client._designer_set_up_dropdown.manage_template_colours',
                description: 'client._designer_set_up_dropdown.manage_template_colours_descr',
                to: ROUTE.templateBuilder.colourGroups.list(),
            },
            {
                title: 'client._designer_set_up_dropdown.manage_template_ctas',
                description: 'client._designer_set_up_dropdown.manage_template_ctas_descr',
                to: ROUTE.templateBuilder.buttons.list(),
            },
            {
                title: 'client._designer_set_up_dropdown.manage_toggle_groups',
                description: 'client._designer_set_up_dropdown.manage_toggle_groups_descr',
                to: ROUTE.manage.toggleGroups.list,
            },
            {
                title: 'client._designer_set_up_dropdown.manage_template_forms',
                description: 'client._designer_set_up_dropdown.manage_template_forms_descr',
                to: ROUTE.templateBuilder.forms.list(),
            },
            {
                title: 'client._designer_set_up_dropdown.manage_template_footers',
                description: 'client._designer_set_up_dropdown.manage_template_footers_descr',
                to: ROUTE.templateBuilder.footers.list(),
            },
        ],
    },
    marketing: {
        menuTitle: 'client_set_up_dropdown.side_menu.marketing_activities.title',
        title: 'client_set_up_dropdown.side_menu.marketing_activities.descr',
        items: [
            {
                title: 'client._set_up_dropdown.marketing_activities_manage',
                description: 'client._set_up_dropdown.marketing_activities_manage_descr',
                to: ROUTE.marketingStoreSetUpActivities,
            },
            {
                title: 'client._set_up_dropdown.marketing_activity_groups.title',
                description: 'client._set_up_dropdown.marketing_activity_groups_descr',
                to: ROUTE.marketingStoreSetUpActivityGroups,
            },
        ],
    },
    campaigns: {
        menuTitle: 'client._general_dropdown.section_title.marketing_campaigns_and_assets',
        title: 'client._general_dropdown.section_title.marketing_campaigns_and_assets_descr',
        items: [
            {
                title: 'client._designer_set_up_dropdown.manage_campaigns',
                description: 'client._designer_set_up_dropdown.manage_campaigns_descr',
                to: ROUTE.manageCampaigns(CAMPAIGNS_STATUS.ACTIVE),
            },
            {
                title: 'client._global_admin_set_up_dropdown.manage_co_branded_assets',
                description: 'client._global_admin_set_up_dropdown.manage_co_branded_assets_descr',
                to: ROUTE.manageTemplates.list,
            },
            {
                title: 'client._designer_set_up_dropdown.manage_asset_templates',
                description: 'client._designer_set_up_dropdown.manage_asset_templates_descr',
                to: ROUTE.templateBuilder.templates.list(),
            },
            {
                title: 'manage.template_types.manage',
                description: '',
                to: ROUTE.manage.templateTypes.list,
            },
        ],
    },
    resources: {
        menuTitle: 'client._general_dropdown.section_title.resources',
        title: 'client._general_dropdown.section_description.resources',
        items: [
            {
                title: 'client._global_admin_set_up_dropdown.manage_resources',
                description: 'client._general_dropdown.section_description.resources',
                to: ROUTE.assetLibrarySetUpListAssets,
            },
            {
                title: 'client._global_admin_set_up_dropdown.manage_resource_types',
                description: 'client._global_admin_set_up_dropdown.manage_resource_types_descr',
                to: ROUTE.assetLibrarySetUpTypes,
            },
        ],
    },
    social: {
        menuTitle: 'client._general_dropdown.section_title.social_media_posts',
        title: 'client._general_dropdown.section_description.social_media_posts',
        items: [
            {
                title: 'client._vendor_set_up_dropdown.manage_social_posts',
                description: 'client._vendor_set_up_dropdown.manage_social_posts_descr',
                to: ROUTE.manage.socialPosts.listStatus('active'),
            },
        ],
    },
    requests: {
        menuTitle: 'client._general_dropdown.section_title.manage_requests',
        title: 'client._general_dropdown.section_description.manage_requests',
        items: [
            {
                title: 'client._designer_set_up_dropdown.asset_requests',
                description: 'client._designer_set_up_dropdown.asset_requests_descr',
                to: ROUTE.manage.assetRequests,
            },
        ],
    },
    loyalty: {
        menuTitle: 'client._vendor_set_up_dropdown.loyalty_program',
        title: 'client._general_dropdown.section_description.loyalty_program_2',
        items: [
            {
                title: 'client._vendor_set_up_dropdown.manage_banners',
                description: 'client._vendor_set_up_dropdown.manage_banners_descr',
                to: ROUTE.manage.loyaltyBanners.listStatus('active'),
            },
        ],
    },
};
