import { Api, t, client } from 'utils';
import { dialog, error, success } from 'store/alert/actions';
import { getRolesAllowedToAssignedToUsers } from 'store/user/actions';
import { getCompanies } from 'store/company/actions';
import { list as getCountryCodeList } from 'store/countryCode/actions';
import * as clientActions from 'store/client/actions';
import { USERS_STATUS } from '../../manageConstants';
import ACTIONS from './constants';

const setListData = data => ({
    type: ACTIONS.list.setData,
    data,
});

const setListLoading = loading => ({
    type: ACTIONS.list.setLoading,
    loading,
});

export const getList = filters => async dispatch => {
    let result = null;
    const options = {
        id: client.getId(),
        region: '',
        tier: '',
        country: '',
        permission: '',
        roles: [],
        status: '',
        company: 0,
        text: '',
        sort: '',
        reverse: 0,
        offset: 0,
        limit: 10,
        ...filters,
    };
    dispatch(setListLoading(true));
    try {
        result = await Api.get(Api.route('partnermarketing_client_users_api', options));
        dispatch(setListLoading(false));
    } catch (e) {
        dispatch(error(t('general.errors.cant_load_list')));
        dispatch(setListLoading(false));
        throw e;
    }
    dispatch(setListData(result));
    return result;
};

export const downloadCsv = filters => async () => {
    const options = {
        id: client.getId(),
        region: '',
        tier: '',
        country: '',
        permission: '',
        roles: [],
        status: '',
        text: '',
        sort: '',
        reverse: 0,
        _format: 'csv',
        ...filters,
    };

    await Api.oneTimeAuthRedirect('partnermarketing_client_users_csv', options);
};

const setFiltersLoading = loading => ({
    type: ACTIONS.filters.setLoading,
    loading,
});

const setFiltersData = data => ({
    type: ACTIONS.filters.setData,
    data,
});

export const getFilters =
    (status, isGlobalAdmin = false) =>
    async dispatch => {
        let result = {};
        dispatch(setFiltersLoading(true));
        try {
            const userStatus = status !== USERS_STATUS.UNAPPROVED ? status : '';
            const rolesPromise = getRolesAllowedToAssignedToUsers(userStatus).then(data => ({
                visibleRoles: data.filter(role => !userStatus || role.members > 0),
                allRoles: data,
            }));

            const companiesPromise = getCompanies(client.getId(), { userStatus, withMembers: true }).then(data => ({
                companies: data.filter(company => !userStatus || company.members > 0),
            }));

            const countriesPromise = dispatch(getCountryCodeList()).then(data => ({
                countries: data,
            }));

            const tiersPromise = clientActions.getTiers({ userStatus }).then(data => ({
                tiers: data.filter(region => !userStatus || region.userTotal > 0),
                allTiers: data,
            }));

            const regionsPromise = clientActions.getRegions({ userStatus }).then(data => ({
                regions: data.filter(tier => !userStatus || tier.userTotal > 0),
                allRegions: data,
            }));

            const approvalGroupsPromise = clientActions.getApprovalGroups({ userStatus }).then(data => ({
                approvalGroups: data,
            }));

            let permissionsPromise = Promise.resolve({});
            if (isGlobalAdmin) {
                permissionsPromise = clientActions.getPermissions({ userStatus }).then(data => ({
                    permissions: data,
                }));
            }

            result = await Promise.all([
                rolesPromise,
                companiesPromise,
                tiersPromise,
                permissionsPromise,
                countriesPromise,
                regionsPromise,
                approvalGroupsPromise,
            ]);
            result = result.reduce((prev, cur) => ({ ...prev, ...cur }), {});

            if (isGlobalAdmin) {
                result.permissions.push({
                    id: 'master_vendor',
                    name: t('user.user_edit_settings.master_vendor'),
                });
                result.permissions.push({
                    id: 'concierge_access',
                    name: t('user.user_edit_settings.concierge_access'),
                });
                result.permissions.push({
                    id: 'master_asset_producer',
                    name: t('user.user_edit_settings.master_asset_producer'),
                });
                if (client.isSocialMediaPostsEnabled()) {
                    result.permissions.push({
                        id: 'social_post_creator',
                        name: t('user.user_edit_settings.social_post_creator'),
                    });
                }
            }

            dispatch(setFiltersLoading(false));
        } catch (e) {
            dispatch(error(t('general.errors.cant_load_filters')));
            dispatch(setFiltersLoading(false));
            throw e;
        }
        dispatch(setFiltersData(result));
        return result;
    };

const setStatusesData = data => ({
    type: ACTIONS.statuses.setData,
    data,
});

const setStatusesLoading = loading => ({
    type: ACTIONS.statuses.setLoading,
    loading,
});

export const getStatuses = () => async (dispatch, getState) => {
    let result = null;

    const {
        manage: {
            users: {
                statuses: { data },
            },
        },
    } = getState();

    if (data.length) {
        return Promise.resolve(data);
    }

    dispatch(setStatusesLoading(true));
    try {
        result = await Api.get(Api.route('partnermarketing_user_get_statuses_api'));
        dispatch(setStatusesLoading(false));
    } catch (e) {
        dispatch(error(t('general.errors.cant_load_list')));
        dispatch(setStatusesLoading(false));
        throw e;
    }
    dispatch(setStatusesData(result));
    return result;
};

export const resetItem = () => ({
    type: ACTIONS.item.reset,
});

const setItemData = data => ({
    type: ACTIONS.item.setData,
    data,
});

const setItemLoading = loading => ({
    type: ACTIONS.item.setLoading,
    loading,
});

export const getItem = id => async dispatch => {
    let result = null;
    if (!Number.isNaN(id)) {
        dispatch(setItemLoading(true));
        try {
            result = await Api.get(Api.route('partnermarketing_user_get_api', { id }));
            dispatch(setItemLoading(false));
        } catch (e) {
            dispatch(error(t('general.errors.cant_load_item')));
            dispatch(setItemLoading(false));
            throw e;
        }
        dispatch(setItemData(result));
    }
    return result;
};

export const saveItem =
    ({ id, ...data }) =>
    async dispatch => {
        let result = null;
        dispatch(setItemLoading(true));
        try {
            if (id) {
                result = await Api.put(Api.route('partnermarketing_user_update_api', { id }), data);
            } else {
                result = await Api.post(Api.route('partnermarketing_user_create_api'), data);
            }
            dispatch(setItemData(result));
            dispatch(setItemLoading(false));
        } catch (e) {
            dispatch(setItemLoading(false));
            dispatch(error(t('manage.users.cant_save_user')));
            throw e;
        }
        return result;
    };

export const deleteItem =
    ({ id, email }) =>
    dispatch =>
        dispatch(
            dialog(
                t('pmApp.user.controller.UserListController.delete_dialog_message', { email }),
                t('pmApp.user.controller.UserListController.delete_dialog_description'),
                'warning',
                t('pmApp.user.controller.UserListController.delete_dialog_button')
            )
        ).then(async () => {
            dispatch(setListLoading(true));
            try {
                await Api.delete(Api.route('partnermarketing_user_delete_api', { id }));
                dispatch(setListLoading(false));
            } catch (e) {
                dispatch(error(t('manage.users.cant_delete_user')));
                throw e;
            }
        });

export const loginAsOtherUser =
    ({ id, otat }) =>
    async dispatch => {
        let response = null;
        dispatch(setListLoading(true));
        try {
            response = await Api.post(Api.route('partnermarketing_authentication_login_as_other_user'), {
                userId: id,
                otat,
            });
            if (response.redirectUrl) {
                delete localStorage.apiAuth;
                window.location = response.redirectUrl;
            } else {
                response.apiAuth.wasId = response.was.id;
                if (response.backAuth) {
                    response.apiAuth.backAuthToken = response.backAuth.oneTimeAuthToken;
                }
                response.apiAuth.redirectUrl = '/';
                localStorage.setItem('apiAuth', JSON.stringify(response.apiAuth));
                window.location = response.apiAuth.redirectUrl;
            }
        } catch (e) {
            dispatch(setListLoading(false));
            dispatch(error(t('pmApp.user.controller.UserListController.cant_login_as_user')));
            throw e;
        }
        return response;
    };

export const switchRole = (clientId, role, languageCode) => async dispatch => {
    let response = null;
    dispatch(setListLoading(true));
    try {
        response = await Api.get(
            Api.route('partnermarketing_globaladmin_get_login_link', { id: clientId, roleName: role })
        );
        window.open(`${response.redirectUrl}&displayLanguage=${languageCode}`, '_top');
    } catch (e) {
        dispatch(setListLoading(false));
        dispatch(error(t('pmApp.client.controller.ClientHeaderController.cant_login_as')));
        throw e;
    }
    return response;
};

export const cancelInvite =
    ({ id }) =>
    async dispatch => {
        let result = null;
        dispatch(setListLoading(true));
        try {
            result = await Api.post(Api.route('partnermarketing_user_cancel_invite_api', { userId: id }));
            dispatch(success(t('pmApp.user.controller.UserListController.invite_canceled')));
        } catch (e) {
            dispatch(setListLoading(false));
            dispatch(error(t('pmApp.user.controller.UserListController.cant_invite_cancel')));
            throw e;
        }
        return result;
    };

export const reInviteColleague =
    ({ id, email }) =>
    async dispatch => {
        let result = null;
        dispatch(setListLoading(true));
        try {
            result = await Api.post(Api.route('partnermarketing_user_re_invite_colleague_api', { userId: id }));
            dispatch(setListLoading(false));
            dispatch(success(t('pmApp.user.controller.UserListController.invite_sent', { email })));
        } catch (e) {
            dispatch(setListLoading(false));
            dispatch(error(t('pmApp.user.controller.UserListController.cant_resend_invite')));
            throw e;
        }
        return result;
    };

export const userReject =
    ({ id }) =>
    async dispatch => {
        let result = null;
        dispatch(setListLoading(true));
        try {
            result = await Api.put(Api.route('partnermarketing_reject_user_api', { id }));
        } catch (e) {
            dispatch(setListLoading(false));
            throw e;
        }
        return result;
    };

export const userApprove = (id, data) => async dispatch => {
    let result = null;
    dispatch(setListLoading(true));
    try {
        result = await Api.put(Api.route('partnermarketing_approve_user_api', { id }), data);
    } catch (e) {
        dispatch(setListLoading(false));
        throw e;
    }
    return result;
};

export const inviteColleague = data => async dispatch => {
    let result = null;
    try {
        result = await Api.post(Api.route('partnermarketing_user_invite_colleague_api'), data);
    } catch (e) {
        e.json().then(json => {
            let errorMessage = "Can't invite user.";
            if (json.error) {
                const errors = json.error.split('ERROR:');
                if (errors[1]) {
                    [, errorMessage] = errors;
                }
            }
            dispatch(error(errorMessage));
        });
        throw e;
    }
    return result;
};

export const inviteUser = data => async dispatch => {
    let result = null;
    try {
        result = await Api.post(Api.route('partnermarketing_user_invite_api'), data);
    } catch (e) {
        e.json().then(json => {
            let errorMessage = "Can't invite user.";
            if (json.error) {
                const errors = json.error.split('ERROR:');
                if (errors[1]) {
                    [, errorMessage] = errors;
                }
            }
            dispatch(error(errorMessage));
        });
        throw e;
    }
    return result;
};

export const inviteMultipleUser = data => async dispatch => {
    let uploadResult = null;
    let inviteResult = null;
    try {
        uploadResult = await Api.upload(Api.route('partnermarketing_user_invite_upload_api'), data, { method: 'post' });
        if (uploadResult.text) {
            inviteResult = await Api.post(Api.route('partnermarketing_user_invite_multiple_api'), {
                csvFileContent: uploadResult.text,
            });
        }
    } catch (e) {
        e.json().then(json => {
            let errorMessage = "Can't upload invite multiple user file.";
            if (json.error) {
                const errors = json.error.split('ERROR:');
                if (errors[1]) {
                    [, errorMessage] = errors;
                }
            }
            dispatch(error(errorMessage));
        });
        throw e;
    }
    return inviteResult;
};

export const checkUploadProgress = jobHandle => async dispatch => {
    let result = null;
    try {
        result = await Api.get(
            Api.route('partnermarketing_user_invite_multiple_progress_api', { uploadId: jobHandle })
        );
    } catch (e) {
        e.json().then(json => {
            let errorMessage = "Can't check invite multiple progress.";
            if (json.error) {
                const errors = json.error.split('ERROR:');
                if (errors[1]) {
                    [, errorMessage] = errors;
                }
            }
            dispatch(error(errorMessage));
        });
        throw e;
    }
    return result;
};

export const batchUpdate = params => async dispatch => {
    let result = null;

    try {
        result = await Api.put(Api.route('partnermarketing_user_bunch_update_api'), params);
    } catch (e) {
        dispatch(error("Can't finish update."));
        throw e;
    }
    return result;
};

export const reScheduleMultipleUsersInvite = params => async dispatch => {
    let result = null;

    try {
        result = await Api.post(Api.route('partnermarketing_user_re_schedule_invite_multiple_api'), params);
    } catch (e) {
        dispatch(error("Can't finish reSchedule invite."));
        throw e;
    }
    return result;
};
