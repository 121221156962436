export const OPTIONS = {
    designerStatus: 'designerStatus',
    type: 'type',
    label: 'label',
    templateType: 'templateType',
};

export const ACTIONS = {
    options: {
        data: 'MANAGE_TEMPLATES_OPTIONS_DATA',
        loading: 'MANAGE_TEMPLATES_OPTIONS_LOADING',
    },
};
