import { useListsContext } from '@TB/pages/templates/context/ListsContext';
import { useMemo } from 'react';
import { SelectDefaultDataItem } from 'types';

export default function usePagesOptions(): SelectDefaultDataItem[] {
    const { pages } = useListsContext();

    return useMemo(
        () =>
            pages
                .filter(p => !p.isActive)
                .map(p => ({
                    label: p.label,
                    value: p.label,
                })),
        [pages]
    );
}
