export default {
    plan: {
        extendData: 'SPIFF_PLAN_EXTEND_DATA',
        setData: 'SPIFF_PLAN_SET_DATA',
        setLoading: 'SPIFF_PLAN_SET_LOADING',
    },
    claim: {
        extendData: 'SPIFF_CLAIM_EXTEND_DATA',
        setData: 'SPIFF_CLAIM_SET_DATA',
        setLoading: 'SPIFF_CLAIM_SET_LOADING',
    },
    planList: {
        setSearch: 'SPIFF_PLAN_LIST_SET_SEARCH',
        setData: 'SPIFF_PLAN_LIST_SET_DATA',
        setLoading: 'SPIFF_PLAN_LIST_SET_LOADING',
    },
    claimList: {
        setSearch: 'SPIFF_CLAIM_LIST_SET_SEARCH',
        setData: 'SPIFF_CLAIM_LIST_SET_DATA',
        updateData: 'SPIFF_CLAIM_LIST_UPDATE_DATA',
        setLoading: 'SPIFF_CLAIM_LIST_SET_LOADING',
    },
    archivedList: {
        setData: 'SPIFF_ARCHIVED_LIST_SET_DATA',
        setLoading: 'SPIFF_ARCHIVED_LIST_SET_LOADING',
    },
    notification: {
        newCountHide: 'SPIFF_NOTIFICATION_NEW_COUNT_HIDE',
    },
};
