import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './InfoList.module.scss';

const InfoList = ({ list, title, last }) => (
    <div className={cn(styles.wrapper, last ? styles.last : '')}>
        <h2 className={styles.title}>{title}</h2>
        {list.map(item => (
            <div className={styles.listItem}>
                <div className={styles.label}>{item.label}</div>
                <div className={styles.value}>{item.value}</div>
            </div>
        ))}
    </div>
);

InfoList.propTypes = {
    title: PropTypes.string,
    list: PropTypes.object,
    last: PropTypes.bool,
};

export default InfoList;
