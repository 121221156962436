import PropTypes from 'prop-types';
import { t } from '../../../utils';
import { USER_ROLE } from '../../../constants';

const FormatUserRole = ({ value, params }) => {
    if (params && params.combineConcierge && value.role === USER_ROLE.ROLE_CONCIERGE) {
        // For some cases we need to combine internal and external concierges.
        return t('user.role.ROLE_CONCIERGE_COMBINED');
    }
    return t(`user.role.${value.role.toUpperCase()}`);
};

FormatUserRole.propTypes = {
    value: PropTypes.shape({ role: PropTypes.string.isRequired }),
    params: PropTypes.shape({ combineConcierge: PropTypes.bool }),
};

export default FormatUserRole;
