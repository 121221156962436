import { TBComponentVersion } from '@TB/types';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import LoadingButton from 'components/LoadingButton/LoadingButton';
import { FORMAT } from 'constants.js';
import moment from 'moment/moment';
import React, { ChangeEventHandler } from 'react';
import { t } from 'utils';
import styles from './Versions.module.scss';

type VersionsProps = {
    updatedAt: number | string;
    list: Pick<TBComponentVersion, 'id' | 'version'>[];
    currentVersion: string;
    onAdd?: () => void;
    onChange: ChangeEventHandler;
    adding?: boolean;
    isDisabled?: boolean;
};

export default function Versions({
    updatedAt,
    list,
    currentVersion,
    onChange,
    onAdd,
    adding = false,
    isDisabled = false,
}: VersionsProps) {
    const updatedDate = moment(typeof updatedAt === 'number' ? updatedAt * 1000 : updatedAt).format(FORMAT.DATE_TIME);
    return (
        <div className={styles.version}>
            <span>
                {t('campaign.my_campaigns.last_updated')}
                &nbsp;
                {updatedDate}
            </span>
            <Input
                rootClassName={styles.input}
                type={Input.TYPE.SELECT}
                padding={Input.PADDING.NONE}
                data={list.map(({ version, id: value }) => ({
                    value,
                    label: t('campaign.version', { version }),
                }))}
                field={{
                    value: currentVersion,
                    onChange,
                }}
                disabled={isDisabled}
            />
            {!!onAdd && (
                <LoadingButton
                    color={Button.COLOR.PRIMARY}
                    icon="plus"
                    loading={adding}
                    tiny
                    onClick={onAdd}
                    disabled={isDisabled}
                />
            )}
        </div>
    );
}
