import { Api } from 'utils';
import { error } from 'store/alert/actions';
import { getDisplayLanguage } from 'selectors/userSelectors';
import ACTIONS from './constants';

const requestList = () => ({
    type: ACTIONS.list.load,
});
const successList = (displayLanguage, data) => ({
    type: ACTIONS.list.success,
    displayLanguage,
    data,
});
export const list = () => async (dispatch, getState) => {
    const language = getDisplayLanguage(getState());
    const {
        countryCode: { list: l },
    } = getState();
    if (l.displayLanguage === language && l.data.length) {
        return l.data;
    }

    dispatch(requestList());
    let result = [];
    try {
        result = await Api.get(Api.route('partnermarketing_utility_get_country_codes_translated', { language }));
        dispatch(successList(language, result));
    } catch (e) {
        dispatch(error("Can't retrieve list."));
    }
    return result;
};
