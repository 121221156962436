export default {
    list: {
        setData: 'OPPORTUNITIES_LIST_SET_DATA',
        setLoading: 'OPPORTUNITIES_LIST_SET_LOADING',
    },
    item: {
        reset: 'OPPORTUNITIES_ITEM_RESET',
        setData: 'OPPORTUNITIES_ITEM_SET_DATA',
        setError: 'OPPORTUNITIES_ITEM_SET_ERROR',
        setLoading: 'OPPORTUNITIES_ITEM_SET_LOADING',
    },
};
