import { abosulteReditect, Api, t } from 'utils';
import { error } from 'store/alert/actions';
import { getCoBrandedAssetLinkSelector, selectAssetLibraryList } from './selectors';
import ACTIONS from './constants';

const setItemData = data => ({
    type: ACTIONS.item.setData,
    data,
});

const setItemLoading = loading => ({
    type: ACTIONS.item.setLoading,
    loading,
});

export const getItem =
    (assetId, view = 'asset_view') =>
    async dispatch => {
        let result = null;
        dispatch(setItemLoading(true));
        try {
            result = await Api.get(Api.route('partnermarketing_asset_library_assets_get_one', { assetId }), {
                view,
            });
        } catch (e) {
            dispatch(error(t('generic_error_messages.an_error_occured_try_again')));
            dispatch(setItemLoading(false));
            throw e;
        }
        dispatch(setItemData(result.asset));
        return result;
    };

const setListData = (data, counts, append) => ({
    type: ACTIONS.list.setData,
    data,
    counts,
    append,
});

const setListLoading = loading => ({
    type: ACTIONS.list.setLoading,
    loading,
});

export const getList =
    (params = {}, append = false) =>
    async dispatch => {
        let result = null;

        dispatch(setListLoading(true));
        try {
            result = await Api.get(Api.route('partnermarketing_asset_library_assets', params));
        } catch (e) {
            dispatch(setListLoading(false));
            dispatch(error(t('general.errors.cant_load_list')));
            throw e;
        }
        dispatch(setListData(result.assets || [], result.count, params.offset ? append : false));

        return result;
    };

export const updateList = assets => async (dispatch, getState) => {
    const list = selectAssetLibraryList(getState());
    const assetsMap = assets.reduce((result, asset) => ({ ...result, [asset.id]: asset }), {});
    dispatch(setListData(list.map(asset => assetsMap[asset.id] || asset)));
};

export const trackDownload = assetId => async () => {
    await Api.put(Api.route('partnermarketing_asset_library_assets_track_download', { assetId }));
};

const setTypesData = data => ({
    type: ACTIONS.types.setData,
    data,
});

const setTypesLoading = loading => ({
    type: ACTIONS.types.setLoading,
    loading,
});

export const getTypes = () => async dispatch => {
    let result = null;
    dispatch(setTypesLoading(true));
    try {
        result = await Api.get(Api.route('partnermarketing_asset_library_types'));
    } catch (e) {
        dispatch(error(t('general.errors.cant_load_types')));
        dispatch(setTypesLoading(false));
        throw e;
    }
    dispatch(setTypesData(result));

    return result;
};

const setCoBrandedAssetLoading = loading => ({
    type: ACTIONS.coBrandedAsset.setLoading,
    loading,
});

export const getCoBrandedAssetStatus = (uuid, resolve, reject) => {
    setTimeout(async () => {
        try {
            const resp = await Api.get(Api.route('partnermarketing_asset_library_co_branded_asset_status', { uuid }));

            if (resp.ready) {
                resolve(resp.pdf);
            } else {
                getCoBrandedAssetStatus(uuid, resolve, reject);
            }
        } catch (e) {
            reject(e);
        }
    }, 2000);
};

const checkCoBrandedAssetStatus = uuid => () =>
    new Promise((resolve, reject) => {
        getCoBrandedAssetStatus(uuid, resolve, reject);
    });

export const getCoBrandedAsset = id => async dispatch => {
    dispatch(setCoBrandedAssetLoading(true));
    try {
        const token = await Api.getOneTimeAuthToken();
        const route = Api.route('partnermarketing_asset_library_co_branded_asset_view', { id });
        const uuid = await Api.get(`${route}?${token.urlQueryString}`);
        await dispatch(checkCoBrandedAssetStatus(uuid.uuid));
        abosulteReditect(
            Api.route('partnermarketing_asset_library_co_branded_asset_download', { uuid: uuid.uuid, id })
        );
    } catch (e) {
        dispatch(error(t('asset_library.errors.cant_load_cobranded_asset')));
        dispatch(setCoBrandedAssetLoading(false));
        throw e;
    }
    dispatch(setCoBrandedAssetLoading(false));
};

const setCoBrandedAssetLinkLoading = loading => ({
    type: ACTIONS.coBrandedAssetLink.setLoading,
    loading,
});

const setCoBrandedAssetLinkData = data => ({
    type: ACTIONS.coBrandedAssetLink.setData,
    data,
});

export const generateCoBrandedAssetLink = id => async dispatch => {
    dispatch(setCoBrandedAssetLinkLoading(true));
    try {
        const token = await Api.getOneTimeAuthToken();
        const route = Api.route('partnermarketing_asset_library_co_branded_asset_view', { id });
        let result = await Api.get(`${route}?${token.urlQueryString}&type=link`);
        if (!result.id) {
            await dispatch(checkCoBrandedAssetStatus(result.uuid));
            result = await Api.get(
                Api.route('partnermarketing_asset_library_co_branded_asset_asset_link', { id, uuid: result.uuid })
            );
        }
        dispatch(setCoBrandedAssetLinkData(result));
        return result;
    } catch (e) {
        dispatch(error(t('asset_library.errors.cant_generate_cobranded_asset_link')));
        dispatch(setCoBrandedAssetLinkLoading(false));
        throw e;
    }
};

export const getCoBrandedAssetLink = (id, uuid) => async (dispatch, getState) => {
    let link = getCoBrandedAssetLinkSelector(getState());
    if (link.asset.id === id && link.hash === uuid) {
        return link;
    }

    dispatch(setCoBrandedAssetLinkLoading(true));
    try {
        link = await Api.get(Api.route('partnermarketing_asset_library_co_branded_asset_asset_link', { id, uuid }));
        dispatch(setCoBrandedAssetLinkData(link));
    } catch (e) {
        dispatch(error(t('asset_library.errors.cant_load_cobranded_asset_link')));
        dispatch(setCoBrandedAssetLinkLoading(false));
        throw e;
    }

    return link;
};

export const downloadAsset = id => async () => {
    await Api.oneTimeAuthRedirect('partnermarketing_asset_library_download_asset_image', { id });
};

export const downloadResource = id => async () => {
    await Api.oneTimeAuthRedirect('partnermarketing_asset_library_resource_download', { id });
};
