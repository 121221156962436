import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Field, useField } from 'formik';
import isUndefined from 'lodash/isUndefined';
import Input from 'components/Input/Input';
import Icon from 'components/Icon/Icon';
import styles from './SocialIconField.module.scss';

const SocialIconField = ({ icon, prefix, value, fallback }) => {
    const [{ name }, , { setValue }] = useField(`value.${icon}`);
    const checked = !isUndefined(value);
    const onSelectToggle = () => setValue(checked ? undefined : fallback);
    const onChange = e => {
        const val = e.target.value || '';
        setValue(val.indexOf(prefix) !== -1 ? val : `${prefix}${val}`);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.iconRow}>
                <Input
                    type={Input.TYPE.CHECKBOX}
                    name={`social-icon-${icon}`}
                    label={<Icon kind={icon} className={cn(styles.icon, styles[icon], !checked && styles.disabled)} />}
                    padding={checked ? Input.PADDING.SMALL : Input.PADDING.NONE}
                    checked={checked}
                    field={{ onChange: onSelectToggle }}
                />
            </div>
            {checked && (
                <>
                    <Input
                        padding={Input.PADDING.NONE}
                        prefix={prefix}
                        inlinePrefix
                        field={{ value: value.replace(prefix, ''), onChange }}
                    />
                    <Field component={Input} type="hidden" name={name} hideValidation padding={Input.PADDING.NONE} />
                </>
            )}
        </div>
    );
};

SocialIconField.propTypes = {
    icon: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    value: PropTypes.string,
    fallback: PropTypes.string,
};

export default SocialIconField;
