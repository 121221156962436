import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { Field } from 'formik';
import React from 'react';
import { t } from 'utils';
import styles from './ImageTypeField.module.scss';

type ImageTypeUploadPreviewProps = {
    preview: string;
    onCancel: () => void;
    onUpload: () => void;
};
export default function ImageTypeUploadPreview({
    preview,
    onCancel,
    onUpload,
}: ImageTypeUploadPreviewProps): JSX.Element {
    return (
        <>
            <div className={styles.imagePreview}>
                <img src={preview} alt="Preview" />
            </div>
            <Field
                name="hasRights"
                component={Input}
                type={Input.TYPE.CHECKBOX}
                label={t('template.template_wrapper.i_have_right_to_use_image')}
                padding={Input.PADDING.SMALL}
            />
            <div className={styles.actions}>
                <Button icon="cross" color={Button.COLOR.GRAY} small onClick={onCancel}>
                    {t('general.button.cancel')}
                </Button>
                <Button icon="upload" color={Button.COLOR.PRIMARY} small onClick={onUpload}>
                    {t('general.tooltip.upload')}
                </Button>
            </div>
        </>
    );
}
