export default {
    creatable: {
        setData: 'CAMPAIGN_CREATABLE_SET_DATA',
        setLoading: 'CAMPAIGN_CREATABLE_SET_LOADING',
    },
    filters: {
        setData: 'CAMPAIGN_FILTERS_SET_DATA',
        setLoading: 'CAMPAIGN_FILTERS_SET_LOADING',
    },
    searchOptions: {
        setData: 'CAMPAIGN_SEARCH_OPTIONS_SET_DATA',
        restoreData: 'CAMPAIGN_SEARCH_OPTIONS_RESTORE_DATA',
        setLoading: 'CAMPAIGN_SEARCH_OPTIONS_SET_LOADING',
    },
    campaign: {
        setData: 'CAMPAIGN_CAMPAIGN_SET_DATA',
        setLoading: 'CAMPAIGN_CAMPAIGN_SET_LOADING',
    },
    rootCampaigns: {
        setData: 'CAMPAIGN_ROOT_CAMPAIGNS_SET_DATA',
        setLoading: 'CAMPAIGN_ROOT_CAMPAIGNS_SET_LOADING',
    },
};
