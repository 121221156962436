export default {
    item: {
        setData: 'ASSET_REQUEST_ITEM_SET_DATA',
        updateData: 'ASSET_REQUEST_ITEM_UPDATE_DATA',
        setLoading: 'ASSET_REQUEST_ITEM_SET_LOADING',
    },
    logo: {
        setLoading: 'ASSET_REQUEST_LOGO_SET_LOADING',
    },
    ownList: {
        setData: 'ASSET_REQUEST_OWN_LIST_SET_DATA',
        setLoading: 'ASSET_REQUEST_OWN_LIST_SET_LOADING',
    },
    list: {
        setData: 'ASSET_REQUEST_LIST_SET_DATA',
        setLoading: 'ASSET_REQUEST_LIST_SET_LOADING',
    },
};
