import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useField, useFormikContext } from 'formik';
import useApiUpdateTemplateDataAreas from 'apiRequests/useApiUpdateTemplateDataAreas';
import useApiToggleTemplatePage from 'apiRequests/useApiToggleTemplatePage';
import Button from 'components/Button/Button';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import useIsInstanceEditor from 'hooks/useIsInstanceEditor';
import { parseStyles } from 'pages/campaignInstance/edit/helpers';
import { AREA_OPS } from 'pages/manage/manageConstants';
import { TemplateDataArea } from 'propTypes/utility';
import { t } from 'utils';
import styles from './EditableArea.module.scss';

const AREAS_KEY = 'areas';

const useEditableArea = (area, attribs, areas) => {
    const [{ value: data }, , { setValue }] = useField('data');
    const { loading, apiUpdateTemplateDataAreas } = useApiUpdateTemplateDataAreas(area);
    const {
        values: { pages },
        setFieldValue,
    } = useFormikContext();
    const areaPages = (pages || []).filter(p => p.name === attribs['data-page']);
    const areaPageId = areaPages.length === 1 ? areaPages[0].parentId || areaPages[0].id : null;
    const { apiToggleTemplatePage } = useApiToggleTemplatePage();

    const sendRequest = async op => {
        let result;
        try {
            result = await apiUpdateTemplateDataAreas({ op });
        } catch {
            return;
        }

        const _data = {
            ...data,
            [AREAS_KEY]: result[AREAS_KEY],
        };
        delete result[AREAS_KEY];

        if (op === AREA_OPS.ADD) {
            if (area.isActive) {
                // We are duplicating existing area, add new fields.
                Object.entries(result).forEach(([group, values]) => {
                    _data[group] = [...(_data[group] || []), ...values];
                });
            }
        } else if (op === AREA_OPS.REMOVE && areas.length > 1) {
            // Need to clean up the data to remove references.
            Object.keys(result).forEach(group => {
                _data[group] = (_data[group] || []).filter(({ areaId }) => areaId !== area.id);
            });
        }
        setValue(_data);
    };

    const toggleAreaPage = async op => {
        if (!areaPageId) {
            return;
        }

        try {
            const result = await apiToggleTemplatePage({ action: op }, { pageId: areaPageId });
            setFieldValue(
                'pages',
                pages.map(page => (page.parentId === areaPageId || page.id === areaPageId ? result : page)),
                false
            );
        } catch {
            return;
        }
    };

    return {
        loading,
        onRemove: () => {
            sendRequest(AREA_OPS.REMOVE);
            toggleAreaPage(AREA_OPS.REMOVE);
        },
        onAdd: () => {
            sendRequest(AREA_OPS.ADD);
            toggleAreaPage(AREA_OPS.ADD);
        },
        onCopy: () => sendRequest(AREA_OPS.ADD),
        onMoveUp: () => sendRequest(AREA_OPS.MOVE_UP),
        onMoveDown: () => sendRequest(AREA_OPS.MOVE_DOWN),
    };
};

const EditableArea = ({ node, area, areas, children }) => {
    const Tag = node.name;
    const { style, class: _class, ...attribs } = node.attribs;
    const { position, isDuplicate, isDeletable, isActive } = area;
    const { loading, onRemove, onAdd, onCopy, onMoveUp, onMoveDown } = useEditableArea(area, attribs, areas);
    // Actions should be disabled for designer editor.
    const disabled = !useIsInstanceEditor();

    const actions = [];
    if (isDuplicate) {
        if (area.position !== areas.length && position !== null) {
            actions.push({
                disabled,
                icon: 'arrow-down',
                key: 'down',
                tip: t('pmApp.campaign.areas.move_down'),
                onClick: onMoveDown,
            });
        }
        if (position !== 1 && position !== null) {
            actions.push({
                icon: 'arrow-up',
                key: 'up',
                tip: t('pmApp.campaign.areas.move_up'),
                onClick: onMoveUp,
                disabled,
            });
        }
        actions.push({ icon: 'copy', key: 'copy', tip: t('general.tooltip.copy'), onClick: onCopy, disabled });
    }
    if (isDeletable) {
        actions.push(
            isActive
                ? { icon: 'trash', key: 'remove', tip: t('general.tooltip.remove'), onClick: onRemove, disabled }
                : { icon: 'plus', key: 'add', tip: t('general.tooltip.add'), onClick: onAdd, disabled }
        );
        if (!isActive) {
            actions.push({ icon: 'disabled', key: 'disabled', className: cn(styles.button, styles.error) });
        }
    }

    return (
        <Tag
            {...attribs}
            style={style ? parseStyles(style) : undefined}
            className={cn(_class, styles.wrapper, !isActive && styles.deleted)}
        >
            {children}
            {!!actions.length && (
                <>
                    <div className={styles.actions}>
                        {actions.map(action => (
                            <Button className={styles.button} {...action} />
                        ))}
                    </div>
                    <div className={cn(styles.overlay, loading && styles.loading)}>
                        <LoadingContainer loading={loading} className={styles.loader} />
                    </div>
                </>
            )}
        </Tag>
    );
};

EditableArea.propTypes = {
    area: TemplateDataArea.isRequired,
    areas: PropTypes.arrayOf(TemplateDataArea).isRequired,
    node: PropTypes.object.isRequired,
    children: PropTypes.node,
};

export default EditableArea;
