import React from 'react';
import PropTypes from 'prop-types';
import { AREA_REGEXP } from 'constants.js';
import useDataArea from 'pages/manage/Templates/Template/hooks/useDataArea';
import EditableArea from 'pages/manage/Templates/Template/components/EditableArea/EditableArea';
import { domToReact } from 'html-react-parser';

const getEditableAttr = node => {
    return ['pm-text', 'pm-link', 'pm-image', 'pm-social-icons'].filter(attr => !!node.attribs[attr]).pop();
};

const processChildren = (node, area) => {
    if (!node) {
        return node;
    }

    if (Array.isArray(node)) {
        return node.map(child => processChildren(child, area));
    }

    if (node.type === 'tag') {
        let _node = {
            ...node,
            children: processChildren(node.children, area),
        };
        const attr = getEditableAttr(node);
        if (attr) {
            const prefix = `area_${area.id}_`;
            _node = {
                ..._node,
                attribs: {
                    ..._node.attribs,
                    'pm-data-key-prefix': prefix,
                    [attr]: _node.attribs[attr].replace(AREA_REGEXP, prefix),
                },
            };
        }

        return _node;
    }

    return node;
};

const convertNode = (node, area) => ({
    ...node,
    attribs: {
        ...node.attribs,
        'pm-area-id': area.id,
        'pm-area-position': area.position,
    },
    children: processChildren(node.children, area),
});

export default function EditableAreas({ node, transform }) {
    const areaId = parseInt(node.attribs['pm-area-id'], 10);
    const [, areas] = useDataArea(areaId);

    // We should process first area only. Rest of areas will came from useDataArea hook.
    if (parseInt(node.attribs['pm-area-position'], 10) > 1) {
        return null;
    }

    return areas.map(area => {
        const areaNode = convertNode(node, area);
        return (
            <EditableArea node={areaNode} area={area} areas={areas} key={area.id}>
                {domToReact(areaNode.children, { replace: transform })}
            </EditableArea>
        );
    });
}

EditableAreas.propTypes = {
    node: PropTypes.object.isRequired,
    transform: PropTypes.func.isRequired,
};
