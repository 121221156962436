import { DomUtils } from 'htmlparser2';
import { AREA_REGEXP, SOCIAL_TEMPLATES, TEMPLATE_TYPE } from 'constants.js';
import useDataArea from 'pages/manage/Templates/Template/hooks/useDataArea';

export const LINK_OPTIONS = {
    URL: 'url',
    EMAIL: 'email',
    UPLOAD: 'upload',
};

export const isEditable = node => (node.attribs.editable || '').toLowerCase() === 'true';

export const isLinkTextEditable = node => (node.attribs['text-editable'] || '').toLowerCase() === 'true';

export const isLinkUrlEditable = node => (node.attribs['link-editable'] || '').toLowerCase() === 'true';

export const isTextMatch = node => (node.attribs['text-match'] || '').toLowerCase() === 'true';

export const getMinLength = (node, minLength = 0) =>
    parseInt(node.attribs['min-length'] || node.attribs['text-min-length'] || minLength, 10) || minLength;

export const getMaxLength = (node, maxLength = 200) =>
    parseInt(node.attribs['max-length'] || node.attribs['text-max-length'] || maxLength, 10) || maxLength;

export const isSemiGlobal = (dataName, templateDataItemNames) =>
    // Used more than in one template.
    Object.keys(templateDataItemNames).filter(key => templateDataItemNames[key].indexOf(dataName) !== -1).length > 1;

export const isGlobal = dataName => dataName.indexOf('global_') === 0;

export const renderChildren = (node, options = {}) => (node ? DomUtils.getInnerHTML(node, options) : '');

export const findChildren = (node, test) => DomUtils.findOne(test, node, true);

export const isSocialTemplate = template => SOCIAL_TEMPLATES.indexOf(template.type) !== -1;

export const isPrintTemplate = template => template.type === TEMPLATE_TYPE.COBRANDED_PDF;

export const parseStyles = styles =>
    styles
        .split(';')
        .filter(style => style.split(':')[0] && style.split(':')[1])
        .map(style => [
            style
                .split(':')[0]
                .trim()
                .replace(/-./g, c => c.substr(1).toUpperCase()),
            style.split(':').slice(1).join(':').trim(),
        ])
        .reduce(
            (styleObj, style) => ({
                ...styleObj,
                [style[0]]: style[1],
            }),
            {}
        );

export const prettyName = _string => {
    let string = typeof _string === 'undefined' || _string === null ? '' : String(_string);

    if (string.length === 0) {
        return string;
    }

    const [, areaId] = string.match(AREA_REGEXP) || [];
    if (areaId) {
        const [area] = useDataArea(parseInt(areaId, 10));
        if (area) {
            string = string.replace(AREA_REGEXP, `Area ${area.name} \u2014 `);
        }
    }

    return string
        .toString()
        .replace(/[_-]./g, string2 => string2.charAt(1).toUpperCase())
        .replace(/([a-z])([0-9A-Z])/g, '$1 $2')
        .toLowerCase()
        .replace(/^[a-z]/, ch => ch.toUpperCase());
};

export const getOrderedPages = pages => {
    if (!pages?.length) {
        return [];
    }

    const _pages = [...pages];
    return _pages.sort((a, b) => {
        if (a.isOptional && !b.isOptional) {
            return 1;
        }

        if (!a.isOptional && b.isOptional) {
            return -1;
        }

        if (a.fileName.toLowerCase() === 'index.html' && b.fileName.toLowerCase() !== 'index.html') {
            return -1;
        }

        if (a.fileName.toLowerCase() !== 'index.html' && b.fileName.toLowerCase() === 'index.html') {
            return 1;
        }

        return 0;
    });
};

export const getAreaIdFromPrefix = str => {
    const match = (str || '').match(AREA_REGEXP);
    return match && match[1] ? parseInt(match[1], 10) : null;
};
