import { matchPath, useLocation } from 'react-router-dom';
import { ROUTE } from 'constants.js';
import useIsSocialPostInstanceEditor from 'hooks/useIsSocialPostInstanceEditor';

const useIsInstanceEditor = () => {
    const location = useLocation();
    const isSocialPostInstance = useIsSocialPostInstanceEditor();

    return isSocialPostInstance || !!matchPath(location.pathname, ROUTE.campaign.create.customiseTemplate());
};

export default useIsInstanceEditor;
