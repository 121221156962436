import React, { Fragment } from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';
import List from 'react-widgets/lib/List';
import styles from './Input.module.css';
import { Waypoint } from '../../utils';
import LoadingContainer from '../LoadingContainer/LoadingContainer';

const ListItem = ({ text, searchTerm }) => {
    if (searchTerm) {
        const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
        return parts.map((part, i) =>
            part.toLowerCase() === searchTerm.toLowerCase() ? <strong key={i}>{part}</strong> : part,
        );
    }

    return text;
};

class ListComponent extends List {
    renderLoader() {
        const { loadMore, loading } = this.props;
        return (
            <Fragment key="loader">
                {!loading && <Waypoint onEnter={loadMore} />}
                <LoadingContainer small loading className={styles.loader} />
            </Fragment>
        );
    }

    renderOption(item, index) {
        const { data, dataComplete, loadMore, selectedItem, valueAccessor } = this.props;
        const _item = selectedItem && valueAccessor(selectedItem) === valueAccessor(item) ? selectedItem : item;
        const isLast = index === data.length - 1;
        if (isLast && !dataComplete && loadMore) {
            return (
                <Fragment key="loaderContainer">
                    {super.renderOption(_item, index)}
                    {this.renderLoader()}
                </Fragment>
            );
        }

        return super.renderOption(_item, index);
    }

    mapItems(fn) {
        const { loading, data } = this.props;
        if (loading && !data.length) {
            return [this.renderLoader()];
        }
        return super.mapItems(fn);
    }
}

export default ({ loadMore, dataComplete, loading, ...props }) => (
    <DropdownList
        {...props}
        listComponent={ListComponent}
        listProps={{ loadMore, dataComplete, loading }}
        itemComponent={ListItem}
    />
);
