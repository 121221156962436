import React from 'react';
import { setLocale } from 'yup';
import { t, lang } from './utils/lang';

export default React.createContext();

const BASE_LOCALE = 'en_GB';

export const getTranslations = async () => {
    const response = await fetch(`${process.env.PUBLIC_URL}/i18n/web_app.${lang()}.json`);
    const translations = await response.json();
    let baseTranslations = translations;

    if (BASE_LOCALE !== lang()) {
        const baseResponse = await fetch(`${process.env.PUBLIC_URL}/i18n/web_app.${BASE_LOCALE}.json`);
        baseTranslations = await baseResponse.json();
    }

    window.translations = translations;
    window.baseTranslations = baseTranslations;

    setLocale({
        mixed: {
            required: t('general.errors.required'),
        },
        string: {
            email: t('user.account_your_details.enter_valid_email'),
            // eslint-disable-next-line
            min: t('general.errors.min_chars', { number: '${min}' }),
            // eslint-disable-next-line
            max: t('general.errors.max_chars', { number: '${max}' }),
        },
    });

    return translations;
};
