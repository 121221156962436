export default {
    setPathName: 'USER_SET_PATH_NAME',
    updateCurrentData: 'USER_UPDATE_CURRENT_DATA',
    toggleBasket: 'USER_TOGGLE_BASKET',
    getByToken: {
        load: 'USER_GET_BY_TOKEN_LOAD',
        success: 'USER_GET_BY_TOKEN_SUCCESS',
        error: 'USER_GET_BY_TOKEN_ERROR',
    },
    upsert: {
        load: 'USER_UPSERT_LOAD',
        success: 'USER_UPSERT_SUCCESS',
        error: 'USER_UPSERT_ERROR',
    },
    disconnectSocialNetwork: {
        load: 'USER_DISCONNECT_SOCIAL_NETWORK_LOAD',
    },
};
