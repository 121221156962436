import Icon from 'components/Icon/Icon';
import React, { ReactNode } from 'react';
import styles from './ButtonMore.module.scss';

type ButtonMoreItemProps = {
    icon?: string;
    label: ReactNode;
};
export default function ButtonMoreItem({ icon, label }: ButtonMoreItemProps): JSX.Element {
    return (
        <span className={styles.item}>
            {!!icon && <Icon kind={icon} className={styles.icon} />}
            {label}
        </span>
    );
}
