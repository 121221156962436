import React, { JSX } from 'react';
import Button from 'components/Button/Button';

type LoadingButtonProps = {
    loading: boolean;
    disabled?: boolean;
    icon?: string;
    [key: string]: any;
};
export default function LoadingButton({ loading, disabled, icon, ...rest }: LoadingButtonProps): JSX.Element {
    return <Button {...rest} disabled={disabled || loading} iconSpin={loading} icon={loading ? 'loading' : icon} />;
}
