import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { getCurrentUser } from 'selectors/userSelectors';
import { t } from 'utils';
import Input from 'components/Input/Input';
import Tabs from 'components/Tabs/Tabs';
import ImageTypeUpload from './ImageTypeUpload';
import ImageTypeLibrary from './ImageTypeLibrary';
import ImageTypeSwap from './ImageTypeSwap';
import ImageTypeIdentifier from './ImageTypeIdentifier';
import styles from './ImageTypeField.module.scss';

export const TABS = {
    UPLOAD: 'upload',
    LIBRARY: 'library',
    SWAP: 'swap',
    IDENTIFIER: 'identifier',
};

const TabComponents = {
    [TABS.UPLOAD]: ImageTypeUpload,
    [TABS.LIBRARY]: ImageTypeLibrary,
    [TABS.SWAP]: ImageTypeSwap,
    [TABS.IDENTIFIER]: ImageTypeIdentifier,
};

const getImageOptions = (currentUser, imageData) => {
    const { isEditable, isIdentifierEnabled, isSwappable, isToggleable, isUploadable } = imageData;

    const options = [];

    // Setup which image options are available
    if (isEditable) {
        // ensure the client has identifierBuilderEnabled as well.
        if (isIdentifierEnabled && !!currentUser.isIdentifierBuilderEnabled) {
            options.push('identifier');
        }
        if (isUploadable) {
            options.push(TABS.UPLOAD);
            options.push(TABS.LIBRARY);
        }
        if (isSwappable) {
            options.push(TABS.SWAP);
        }
        if (isToggleable) {
            options.push('toggle');
        }
    }

    return options.map(key => ({
        label: t(`template.template_wrapper.${key}_tab`),
        value: key,
    }));
};

const ImageTypeField = ({ imageOptions, valueField = 'value', nameField = 'name' }) => {
    const { values, setFieldValue } = useFormikContext();

    const options = getImageOptions(useSelector(getCurrentUser), imageOptions || values);

    const [activeTab, setActiveTab] = useState(options[0]?.value);
    const onTabChange = ({ value }) => setActiveTab(value);
    const ActiveComponent = activeTab ? TabComponents[activeTab] : '';

    const onSave = value => {
        if (activeTab === TABS.UPLOAD) {
            setActiveTab(TABS.LIBRARY);
        }
        setFieldValue(valueField, value, false);
    };

    return (
        <>
            {options.length > 1 && (
                <Tabs items={options} onChange={onTabChange} active={activeTab} className={styles.tabs} />
            )}
            {!!ActiveComponent && (
                <ActiveComponent onSave={onSave} value={get(values, valueField)} dataKey={get(values, nameField)} />
            )}
            <Field component={Input} type="hidden" name={valueField} hideValidation padding={Input.PADDING.NONE} />
        </>
    );
};

ImageTypeField.propTypes = {
    imageOptions: PropTypes.shape({
        isEditable: PropTypes.bool,
        isIdentifierEnabled: PropTypes.bool,
        isSwappable: PropTypes.bool,
        isToggleable: PropTypes.bool,
        isUploadable: PropTypes.bool,
    }),
    valueField: PropTypes.string,
    nameField: PropTypes.string,
};

export default ImageTypeField;
