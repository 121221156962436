import PropTypes from 'prop-types';
import React from 'react';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import styles from './Table.module.css';

const TableLoader = ({ columns, hasRowActions, hasBatchActions }) => (
    <tr>
        <td colSpan={columns.length + hasRowActions + hasBatchActions} className={styles.datagridLoader}>
            <LoadingContainer className={styles.datagridLoading} loading />
        </td>
    </tr>
);

TableLoader.propTypes = {
    columns: PropTypes.array,
    hasRowActions: PropTypes.bool,
    hasBatchActions: PropTypes.bool,
};

TableLoader.defaultProps = {
    columns: [],
    hasRowActions: false,
};

export default TableLoader;
