import isObject from 'lodash/isObject';
import { Api, t, uniqValues } from 'utils';
import { error, dialog } from 'store/alert/actions';
import { getCoBrandedAssetStatus } from 'pages/asset/library/redux/actions';
import { EMAIL_SUBJECT_TEXT_FIELD_NAME } from 'pages/manage/Templates/utils';
import { campaignInstanceSelector } from './selectors';
import ACTIONS from './constants';

const setActivitiesCountData = data => ({
    type: ACTIONS.activitiesCount.setData,
    data,
});

const setActivitiesCountLoading = loading => ({
    type: ACTIONS.activitiesCount.setLoading,
    loading,
});

const setCampaignInstanceData = data => ({
    type: ACTIONS.item.setData,
    data,
});

const setCampaignInstanceLoading = loading => ({
    type: ACTIONS.item.setLoading,
    loading,
});

const setTemplateInstanceData = data => ({
    type: ACTIONS.templateItem.setData,
    data,
});

const setTemplateInstanceLoading = loading => ({
    type: ACTIONS.templateItem.setLoading,
    loading,
});

const setAccountManagerLoading = loading => ({
    type: ACTIONS.accountManager.setLoading,
    loading,
});

const setAccountManagerData = data => ({
    type: ACTIONS.accountManager.setData,
    data,
});

export const getActivitiesCount = () => async dispatch => {
    let result = null;
    dispatch(setActivitiesCountLoading(true));

    const apiAuth = JSON.parse(localStorage.getItem('apiAuth'));
    const params = {};
    if (apiAuth.wasId) {
        params.createdBy = apiAuth.wasId;
    }

    try {
        result = await Api.get(Api.route('partnermarketing_campaign_instance_get_all_activites_count_api', params));
    } catch (e) {
        dispatch(error("Can't load activities count."));
        dispatch(setActivitiesCountLoading(false));
        throw e;
    }
    dispatch(setActivitiesCountData(result));
    dispatch(setActivitiesCountLoading(false));

    return result;
};

const setListData = data => ({
    type: ACTIONS.list.setData,
    data,
});

const setListSummary = summary => ({
    type: ACTIONS.list.setSummary,
    summary,
});

const setListTotal = total => ({
    type: ACTIONS.list.setTotal,
    total,
});

const setListLoading = loading => ({
    type: ACTIONS.list.setLoading,
    loading,
});

export const getList =
    (params = {}) =>
    async dispatch => {
        let result = null;
        dispatch(setListLoading(true));
        try {
            result = await Api.get(Api.route('partnermarketing_campaign_instance_get_all_api', params));
        } catch (e) {
            dispatch(setListLoading(false));
            dispatch(error("Can't load campaign instances list."));
            throw e;
        }
        dispatch(setListData(result.data));
        dispatch(setListSummary(result.summary));
        dispatch(setListTotal(result.count));
        dispatch(setListLoading(false));

        return result;
    };

export const getCampaignInstance =
    (id, forceQuery = false) =>
    async (dispatch, getState) => {
        let result = null;

        const campaignInstance = campaignInstanceSelector(getState());
        if (!forceQuery && String(campaignInstance.id) === String(id)) {
            return Promise.resolve(campaignInstance);
        }

        dispatch(setCampaignInstanceLoading(true));
        try {
            result = await Api.get(Api.route('partnermarketing_campaign_instance_get_api', { id }));
        } catch (e) {
            dispatch(error("Can't get campaign instance."));
            dispatch(setCampaignInstanceLoading(false));
            throw e;
        }

        dispatch(setCampaignInstanceData(result));

        return result;
    };

export const getTemplateInstancePromise = (campaignInstanceId, templateId) =>
    Api.get(Api.route('partnermarketing_template_get_api', { campaignInstanceId, templateId }));

export const getTemplateInstance = (campaignInstanceId, templateId) => async dispatch => {
    let result = null;

    dispatch(setTemplateInstanceLoading(true));
    try {
        result = await getTemplateInstancePromise(campaignInstanceId, templateId);
    } catch (e) {
        dispatch(error("Can't get template instance."));
        dispatch(setTemplateInstanceLoading(false));
        throw e;
    }

    dispatch(setTemplateInstanceData(result));

    return result;
};

export const removeCampaignInstance = (id, name, filters) => async dispatch => {
    dispatch(
        dialog(
            t('pmApp.campaign.controller.MyCampaignDetailsController.delete_confirm', { name }),
            t('pmApp.campaign.controller.MyCampaignDetailsController.delete_confirm_body'),
            'warning',
            t('general.button.continue')
        )
    ).then(async () => {
        dispatch(setCampaignInstanceLoading(true));
        try {
            await Api.delete(Api.route('partnermarketing_campaign_instance_delete_api', { id }));
        } catch (e) {
            dispatch(error("Can't remove campaign instance."));
            throw e;
        }
        dispatch(
            getList({
                ...filters,
                view: 'list',
            })
        );
    });
};

export const checkCampaignName = (name, ignoreCampaignInstanceId) => async dispatch => {
    const campaignInstanceId = ignoreCampaignInstanceId || null;
    let result = null;
    try {
        result = await Api.get(
            Api.route('partnermarketing_campaign_instance_check_name_api', {
                name,
                ignoreCampaignInstanceId: campaignInstanceId,
            })
        );
    } catch (e) {
        dispatch(error("Can't check name."));
        throw e;
    }
    return result;
};

export const upgradeTemplate = (campaignInstanceId, templateInstanceId) => async dispatch => {
    let result = null;

    dispatch(setCampaignInstanceLoading(true));
    try {
        result = await Api.post(
            Api.route('partnermarketing_campaign_instance_upgrade_template', {
                campaignInstanceId,
                templateInstanceId,
            })
        );
        dispatch(setCampaignInstanceLoading(false));
    } catch (e) {
        dispatch(error("Can't upgrade template."));
        dispatch(setCampaignInstanceLoading(false));
        throw e;
    }

    return result;
};

export const republishTemplate = (campaignInstanceId, templateInstanceId) => async dispatch => {
    let result = null;

    dispatch(setCampaignInstanceLoading(true));
    try {
        result = await Api.post(
            Api.route('partnermarketing_campaign_instance_republish_template', {
                campaignInstanceId,
                templateInstanceId,
            })
        );
        dispatch(setCampaignInstanceLoading(false));
    } catch (e) {
        dispatch(error("Can't republish template."));
        dispatch(setCampaignInstanceLoading(false));
        throw e;
    }

    return result;
};

export const checkCampaignLandingPagePath =
    (landingPagePath, ignoreCampaignInstanceId = null) =>
    async dispatch => {
        let result = null;
        try {
            result = await Api.get(
                Api.route('partnermarketing_campaign_instance_check_landing_page_path_api', {
                    landingPagePath,
                    ignoreCampaignInstanceId,
                })
            );
        } catch (e) {
            dispatch(error("Can't check landing page path."));
            throw e;
        }
        return result;
    };

export const create =
    (id, languageId, name, templates = '', extraData = {}) =>
    async dispatch => {
        const data = {
            name,
            ...extraData,
        };
        if (templates) {
            data.templateIds = templates.split(',');
        }
        const apiAuth = JSON.parse(localStorage.getItem('apiAuth'));
        data.createdByUser = apiAuth.wasId ? apiAuth.wasId : apiAuth.userId;

        dispatch(setCampaignInstanceLoading(true));
        let result = null;
        try {
            result = await Api.post(
                Api.route('partnermarketing_campaign_create_instance_api', {
                    id,
                    languageId,
                }),
                data
            );
            dispatch(setCampaignInstanceData(result));
        } catch (e) {
            dispatch(setCampaignInstanceLoading(false));
            dispatch(error(t('pmApp.campaign.controller.CampaignInstanceDetailsController.cant_create_new_campaign')));
            throw e;
        }

        return result;
    };

export const update = (id, data) => async dispatch => {
    dispatch(setCampaignInstanceLoading(true));
    let result = null;
    try {
        result = await Api.put(Api.route('partnermarketing_campaign_instance_update_api', { id }), data);
        dispatch(setCampaignInstanceData(result));
    } catch (e) {
        dispatch(setCampaignInstanceLoading(false));
        dispatch(error(t('pmApp.campaign.controller.CampaignInstanceDetailsController.cant_save_campaign')));
        throw e;
    }

    return result;
};

export const archiveCampaign = (campaignInstance, filters) => async dispatch => {
    dispatch(
        dialog(
            t('campaign.my_campaigns.archive_campaign'),
            t('campaign.my_campaigns.are_you_sure_archive_campaign', {
                campaign_name: campaignInstance.name,
            }),
            'warning',
            t('general.button.continue')
        )
    ).then(async () => {
        dispatch(setCampaignInstanceLoading(true));
        try {
            await Api.post(
                Api.route('partnermarketing_campaign_instance_archive', {
                    campaignInstanceId: campaignInstance.id,
                })
            );
        } catch (e) {
            dispatch(error("Can't archive campaign."));
            throw e;
        }
        dispatch(
            getList({
                ...filters,
                view: 'list',
            })
        );
    });
};

export const unarchiveCampaign = (campaignInstance, filters) => async dispatch => {
    dispatch(
        dialog(
            t('campaign.my_campaigns.unarchive_campaign'),
            t('campaign.my_campaigns.are_you_sure_unarchive_campaign', {
                campaign_name: campaignInstance.name,
            }),
            'warning',
            t('general.button.continue')
        )
    ).then(async () => {
        dispatch(setCampaignInstanceLoading(true));
        try {
            await Api.post(
                Api.route('partnermarketing_campaign_instance_unarchive', {
                    campaignInstanceId: campaignInstance.id,
                })
            );
        } catch (e) {
            dispatch(error("Can't unarchive campaign."));
            throw e;
        }
        dispatch(
            getList({
                ...filters,
                view: 'list',
            })
        );
    });
};

export const getCampaignInstanceAccountManager = campaignInstanceId => async dispatch => {
    let result = null;
    dispatch(setAccountManagerLoading(true));
    try {
        result = await Api.get(
            Api.route('partnermarketing_campaign_instance_get_account_manager_api', {
                id: campaignInstanceId,
            })
        );
    } catch (e) {
        dispatch(error("Can't load account manager data."));
        dispatch(setAccountManagerLoading(false));
        throw e;
    }
    dispatch(setAccountManagerData(result));
    dispatch(setAccountManagerLoading(false));

    return result;
};

const getAreaElementDefaultName = name => {
    const nameArray = name.split('_');

    if (nameArray[0] && nameArray[0] === 'area') {
        return name.replace(`${nameArray[0]}_`, '').replace(`${nameArray[1]}_`, '');
    }

    return name;
};

const setCoBrandedTemplateLoading = loading => ({
    type: ACTIONS.coBrandedTemplate.setLoading,
    loading,
});

export const getCoBrandedTemplateStatus = (uuid, id, resolve, reject) => {
    setTimeout(async () => {
        try {
            const resp = await Api.get(
                Api.route('partnermarketing_campaign_template_preview_co_branded_pdf_download', { uuid, id })
            );

            if (resp.ready) {
                resolve(resp.pdf);
            } else {
                getCoBrandedTemplateStatus(uuid, resolve, reject);
            }
        } catch (e) {
            reject(e);
        }
    }, 2000);
};

const checkCoBrandedTemplateStatus = uuid => () =>
    new Promise((resolve, reject) => {
        getCoBrandedAssetStatus(uuid, resolve, reject);
    });

export const getCoBrandedTemplate =
    (templateId, campaignId, data = null) =>
    async dispatch => {
        dispatch(setCoBrandedTemplateLoading(true));
        try {
            const uuid = await Api.get(
                Api.route('partnermarketing_download_co_branded_pdf_asset_preview_uuid', {
                    templateId,
                    campaignId,
                }),
                { data }
            );
            await dispatch(checkCoBrandedTemplateStatus(uuid.uuid));
            dispatch(setCoBrandedTemplateLoading(false));
            return Api.route('partnermarketing_campaign_template_preview_co_branded_pdf_download', {
                uuid: uuid.uuid,
                id: templateId,
            });
        } catch (e) {
            dispatch(error("Can't load Co-Branded asset."));
            dispatch(setCoBrandedTemplateLoading(false));
            throw e;
        }
    };

export const getCoBrandedSocialPostImage =
    (socialPostId, data = null) =>
    async dispatch => {
        try {
            const uuid = await Api.get(
                Api.route('partnermarketing_social_post_instance_preview_uuid', { id: socialPostId }),
                { data }
            );
            await dispatch(checkCoBrandedTemplateStatus(uuid.uuid));
            return Api.route('partnermarketing_social_post_instance_download', { uuid: uuid.uuid });
        } catch (e) {
            dispatch(error("Can't load SocialPost Co-Branded image."));
            throw e;
        }
    };

const splitString = (value, index) => ({
    group: `${value.substring(0, index)}s`,
    key: value.substring(index + 1),
});

const cleanUpBEMissedData = (beData, feData) =>
    beData.filter(beDataItem => {
        const index = beDataItem.indexOf('.');
        const beItem = splitString(beDataItem, index);
        const ignoredValues = ['instructionalCopy'];

        let stillMissed = true;
        if (feData[beItem.group]) {
            feData[beItem.group].forEach(feItem => {
                const feItemName = getAreaElementDefaultName(feItem.name);
                if (feItemName === beItem.key) {
                    let _value =
                        beItem.group === 'links' && feItem.url ? feItem.url.replace(/https?:\/\//, '') : feItem.value;
                    // Reset default identifier image value if identifier in general is enabled and image is active.
                    if (
                        beItem.group === 'images' &&
                        feItem.isIdentifierEnabled &&
                        feItem.isActive &&
                        _value.indexOf('template-files') !== -1
                    ) {
                        _value = null;
                    }
                    if (isObject(_value)) {
                        const __values = Object.keys(_value)
                            .filter(key => ignoredValues.indexOf(key) === -1)
                            .map(e => _value[e]);
                        stillMissed =
                            (beItem.group !== 'socialIcons' && Object.keys(_value).length === 0) ||
                            (beItem.group === 'socialIcons' &&
                                __values.some(_objItemValue => Object.values(feItem.icons).includes(_objItemValue))) ||
                            __values.some(_objItemValue => !_objItemValue);
                    } else {
                        stillMissed = !_value;
                    }
                }
            });
        }

        return stillMissed;
    });

export const updateTemplateData = (campaignInstanceId, templateInstanceId, templateData) => async dispatch => {
    let editedMissedData = null;
    try {
        const result = await Api.get(
            Api.route('partnermarketing_template_instance_get_missing_data', {
                id: templateInstanceId,
            })
        );
        const missingData = result.missingDataValues.map(({ missing }) => missing);
        editedMissedData = uniqValues(cleanUpBEMissedData(missingData, templateData.data));
    } catch (e) {
        dispatch(error("Can't get missed data."));
        throw e;
    }

    let stop = false;
    if (editedMissedData.length) {
        try {
            await dispatch(
                dialog(
                    t('pmApp.template.controller.TemplateWrapperController.missing_data_dialog.title', {
                        num: editedMissedData.length,
                    }),
                    t('pmApp.template.controller.TemplateWrapperController.missing_data_dialog.description'),
                    'warning',
                    {
                        text: t('general.button.continue_editing'),
                        icon: 'edit-text',
                    },
                    {
                        text: t('general.button.edit_later'),
                        icon: 'save-exit',
                    }
                )
            );
            stop = true;
        } catch (e) {
            // Do nothing.
        }
    }

    if (stop) {
        throw Object({ highlight: true, fields: editedMissedData });
    }

    if (templateData.subject) {
        // eslint-disable-next-line no-param-reassign
        templateData = {
            ...templateData,
            data: {
                ...templateData.data,
                texts: templateData.data.texts.map(text =>
                    text.name === EMAIL_SUBJECT_TEXT_FIELD_NAME ? { ...text, value: templateData.subject } : text
                ),
            },
        };
    }

    try {
        const result = await Api.put(
            Api.route('partnermarketing_template_update_api', {
                campaignInstanceId,
                templateId: templateInstanceId,
            }),
            templateData
        );

        return result;
    } catch (e) {
        dispatch(error("Can't update template data."));
        throw e;
    }
};
