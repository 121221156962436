import useApi from 'hooks/useApi';

const useApiSalesResources = () => {
    const { response, loading } = useApi({
        slug: 'partnermarketing_sales_resources_get_api',
        method: 'GET',
        cache: true,
        lazy: false,
    });

    return {
        salesResources: response || [],
        loading,
    };
};

export default useApiSalesResources;
