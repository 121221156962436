import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import styles from './ExtendedTopBar.module.scss';

const ExtendedTopBar = ({ loading, title, subTitle, actions, className }) => (
    <LoadingContainer loading={loading} className={cn(styles.wrapper, className)}>
        <div className={styles.title}>
            <div className={styles.campaign}>{title}</div>
            <div className={styles.template}>{subTitle}</div>
        </div>
        <div className={styles.actions}>{actions}</div>
    </LoadingContainer>
);

ExtendedTopBar.propTypes = {
    loading: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    actions: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default ExtendedTopBar;
