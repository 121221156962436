import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import styles from './Pill.module.scss';

/**
 * Display pill
 */
const Pill = ({ type, children, className, tip, bordered }) => {
    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return (
        <div data-tip={tip} className={cn(styles.wrapper, type, styles[type], bordered && styles.bordered, className)}>
            {children}
        </div>
    );
};

Pill.propTypes = {
    /**
     * Pill type
     */
    type: PropTypes.string,
    /**
     * Use bordered style for pill
     */
    bordered: PropTypes.bool,
    /**
     * Pill class name
     */
    className: PropTypes.string,

    tip: PropTypes.string,
    /**
     * Pill text
     */
    children: PropTypes.any,
};

Pill.TYPE = {
    PRIMARY: 'primary',
    SUCCESS: 'success',
    ERROR: 'error',
    GRAY: 'gray',
    TERTIARY: 'tertiary',
    WARNING: 'warning',
};

export default Pill;
