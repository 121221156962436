import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import kbIcon from './knowledgebase.png';

import styles from './InfoLink.module.scss';

const TYPES = {
    KNOWLEDGEBASE: 'knowledgebase',
};

const SIZES = {
    REGULAR: 'regular',
    SMALL: 'small',
};

const MAP = {
    [TYPES.KNOWLEDGEBASE]: kbIcon,
};

/**
 * Information link with image icon
 */
const InfoLink = ({ link, info, icon, className, size = SIZES.REGULAR }) => {
    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return (
        <a
            href={link}
            className={cn(styles.infoLink, styles[`size-${size}`], 'client-10pc-colour-background', className)}
            target="_blank"
            rel="noopener noreferrer"
            data-tip={info}
        >
            <img src={MAP[icon]} alt={icon} />
        </a>
    );
};

InfoLink.propTypes = {
    link: PropTypes.string,
    info: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf(Object.values(SIZES)),
};

InfoLink.TYPES = TYPES;
InfoLink.SIZES = SIZES;

export default InfoLink;
