/* eslint no-console: 0 */
import { useEffect } from 'react';

const originalConsoleError = console.error;

const useDisableValidateDOMNesting = () => {
    useEffect(() => {
        console.error = (...args) => {
            if (`${args[0]}`.indexOf('validateDOMNesting') !== -1) {
                return;
            }
            originalConsoleError(...args);
        };
        return () => {
            console.error = originalConsoleError;
        };
    });
};

export default useDisableValidateDOMNesting;
