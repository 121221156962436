import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import styles from './ArrowButton.module.css';

/**
 * Collapsible area arrow
 */
const ArrowButton = ({ isOpen, onClick, className, disabled }) => (
    <button
        type="button"
        className={cn(styles.button, disabled ? styles.disabled : 'client-30pc-colour-background', className)}
        onClick={disabled ? undefined : onClick}
    >
        <Icon kind={isOpen ? 'arrow-up' : 'arrow-down'} className={cn(styles.icon)} />
    </button>
);

ArrowButton.propTypes = {
    /**
     * Additional class name
     */
    className: PropTypes.string,
    /**
     * Flag to change arrow position. `true` - arrow down, `false` - arrow left
     */
    isOpen: PropTypes.bool,
    /**
     * Is disabled
     */
    disabled: PropTypes.bool,
    /**
     * Gets called when the user clicks on the button
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     */
    onClick: PropTypes.func,
};

export default ArrowButton;
