import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import { t } from '../../utils';
import { getCurrentUser, getCurrentUserRolesMap } from '../../selectors/userSelectors';
import IconStack from '../IconStack/IconStack';
import Button from '../Button/Button';
import { loginAsOtherUser as loginAsOtherUserAction } from '../../pages/manage/Users/redux/actions';
import styles from './LoggedInAsBar.module.css';

class LoggedInAsBar extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        roles: PropTypes.object,
        loginAsOtherUser: PropTypes.func,
    };

    logOut = () => {
        const { loginAsOtherUser } = this.props;
        const apiAuth = JSON.parse(localStorage.getItem('apiAuth'));
        const loginBackUserId = apiAuth.wasId;
        if (apiAuth.backAuthToken) {
            loginAsOtherUser({ id: loginBackUserId, otat: apiAuth.backAuthToken });
        }
    };

    render() {
        const { currentUser, roles } = this.props;

        const apiAuth = JSON.parse(localStorage.getItem('apiAuth'));
        if (roles.isBaseConciergeInternal || isUndefined(apiAuth.wasId) || isUndefined(apiAuth.backAuthToken)) {
            return null;
        }

        return (
            <div className={cn(styles.barWrapper)}>
                <div className={cn('container', styles.barWrapperContainer)}>
                    <div className={styles.info}>
                        <IconStack
                            className={cn('client-main-colour', styles.iconBlock)}
                            size="1"
                            outer="bg-circle"
                            inner="info"
                        />
                        {t('base.client_loggedin_header.logged_in_as', { name: currentUser.email })}
                    </div>
                    <Button color={Button.COLOR.PRIMARY} onClick={this.logOut} tiny icon="arrow-right" rightIcon>
                        {t('base.client_loggedin_header.logout_of_user')}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
    roles: getCurrentUserRolesMap(state),
});

const mapDispatchToProps = (dispatch) => ({
    loginAsOtherUser: (user) => dispatch(loginAsOtherUserAction(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInAsBar);
