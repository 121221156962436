import Icon from 'components/Icon/Icon';
import { ITableFetchableColumnRendererParams } from 'components/Table/TableFetchable';
import React, { JSX } from 'react';
import { t } from 'utils';
import styles from 'components/Table/Formatters/FormatTooltipWrapper.module.scss';

export default function FormatVersion({ value, row }: ITableFetchableColumnRendererParams): JSX.Element {
    const text = `v${value}`;
    if (row.hasDraft) {
        return (
            <div
                className={styles.versionWrapper}
                data-tip={t('template_builder.templates.draft.dialog.title')}
                data-class={`react-tooltip ${styles.tip}`}
            >
                {text}
                <Icon kind="changes" />
            </div>
        );
    }

    return <span>{text}</span>;
}
