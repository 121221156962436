import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './FieldRow.module.css';

/**
 * Internal component to display multiple items in one row.
 */
const FieldRow = ({ className, children }) => <div className={cn(styles.wrapper, className)}>{children}</div>;

FieldRow.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
};

export default FieldRow;
