import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import styles from './StripeTitle.module.scss';

const StripeTitle = ({ title, icon, children }) => (
    <>
        {!!title && (
            <p className={styles.title}>
                {title}
                {!!icon && <Icon kind={icon} className={styles.icon} />}
            </p>
        )}
        {!!children && <div className={styles.text}>{children}</div>}
    </>
);

StripeTitle.propTypes = {
    title: PropTypes.node,
    icon: PropTypes.string,
    children: PropTypes.node,
};

export default StripeTitle;
