import { combineReducers } from 'redux';
import ACTIONS from './constants';

const planInitialState = {
    data: {},
    loading: true,
};

const planReducer = (state = planInitialState, action) => {
    switch (action.type) {
        case ACTIONS.plan.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }
        case ACTIONS.plan.setData: {
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        }

        case ACTIONS.plan.extendData: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data,
                },
            };
        }

        default: {
            return state;
        }
    }
};

const planListInitialState = {
    data: {
        result: [],
        total: 0,
    },
    search: '',
    loading: false,
};

const planListReducer = (state = planListInitialState, action) => {
    switch (action.type) {
        case ACTIONS.planList.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.planList.setSearch: {
            return {
                ...state,
                search: action.search,
            };
        }

        case ACTIONS.planList.setData: {
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const claimInitialState = {
    data: {},
    loading: true,
};

const claimReducer = (state = claimInitialState, action) => {
    switch (action.type) {
        case ACTIONS.claim.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }
        case ACTIONS.claim.setData: {
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        }

        case ACTIONS.claim.extendData: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data,
                },
            };
        }

        default: {
            return state;
        }
    }
};

const claimListInitialState = {
    data: {
        result: [],
        total: 0,
    },
    search: '',
    loading: false,
};

const claimListReducer = (state = claimListInitialState, action) => {
    switch (action.type) {
        case ACTIONS.claimList.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.claimList.setSearch: {
            return {
                ...state,
                search: action.search,
            };
        }

        case ACTIONS.claimList.setData: {
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        }

        case ACTIONS.claimList.updateData: {
            return {
                ...state,
                data: {
                    ...state.data,
                    result: state.data.result.map(item => (item.id === action.item.id ? action.item : item)),
                },
            };
        }

        default: {
            return state;
        }
    }
};

const notificationInitialState = {
    newCountShow: true,
};

const notificationReducer = (state = notificationInitialState, action) => {
    switch (action.type) {
        case ACTIONS.notification.newCountHide: {
            return {
                ...state,
                newCountShow: false,
            };
        }

        default: {
            return state;
        }
    }
};

const archivedListInitialState = {
    data: [],
    search: '',
    loading: false,
};

const archivedListReducer = (state = archivedListInitialState, action) => {
    switch (action.type) {
        case ACTIONS.archivedList.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.archivedList.setData: {
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    plan: planReducer,
    claim: claimReducer,
    planList: planListReducer,
    claimList: claimListReducer,
    notification: notificationReducer,
    archived: archivedListReducer,
});
