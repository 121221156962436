import { combineReducers } from 'redux';
import ACTIONS from './constants';

const listInitialState = {
    data: {
        packages: [],
    },
    loading: false,
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    list: listReducer,
});
