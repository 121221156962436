export default {
    list: {
        setData: 'MANAGE_CAMPAIGNS_LIST_SET_DATA',
        setLoading: 'MANAGE_CAMPAIGNS_LIST_SET_LOADING',
    },
    filters: {
        setData: 'MANAGE_CAMPAIGN_FILTERS_SET_DATA',
        setLoading: 'MANAGE_CAMPAIGN_FILTERS_SET_LOADING',
    },
    item: {
        reset: 'MANAGE_CAMPAIGN_ITEM_RESET',
        setData: 'MANAGE_CAMPAIGN_ITEM_SET_DATA',
        setError: 'MANAGE_CAMPAIGN_ITEM_SET_ERROR',
        setLoading: 'MANAGE_CAMPAIGN_ITEM_SET_LOADING',
    },
};
