import isEmpty from 'lodash/isEmpty';
import { Api, client } from 'utils';
import { error } from 'store/alert/actions';
import ACTIONS from './constants';

const requestList = () => ({
    type: ACTIONS.list.load,
});
const successList = (clientId, data) => ({
    type: ACTIONS.list.success,
    clientId,
    data,
});
export const list = () => async (dispatch, getState) => {
    const clientId = client.getId();

    const {
        language: {
            list: { data },
        },
    } = getState();

    if (data.length) {
        return Promise.resolve(data);
    }

    dispatch(requestList());
    let result = [];
    try {
        result = await Api.get(Api.route('partnermarketing_client_get_languages_api', { id: clientId }));
        dispatch(successList(clientId, result));
    } catch (e) {
        dispatch(error("Can't retrieve list."));
    }
    return result;
};

const requestTemplateTypeTranslations = () => ({
    type: ACTIONS.translations.templateType.load,
});
const successTemplateTypeTranslations = data => ({
    type: ACTIONS.translations.templateType.success,
    data,
});
export const templateTypeTranslations = () => async (dispatch, getState) => {
    dispatch(requestTemplateTypeTranslations());
    const {
        language: {
            templateTypeTranslations: { data },
        },
    } = getState();
    if (!isEmpty(data)) {
        return data;
    }
    let result = {};
    try {
        result = await Api.get(Api.route('partnermarketing_template_translation_pretty_type'));
        dispatch(successTemplateTypeTranslations(result));
    } catch (e) {
        dispatch(error("Can't retrieve templateTypeTranslations."));
    }
    return result;
};

const requestTemplateStatusTranslations = () => ({
    type: ACTIONS.translations.templateStatus.load,
});
const successTemplateStatusTranslations = data => ({
    type: ACTIONS.translations.templateStatus.success,
    data,
});
export const templateStatusTranslations = () => async (dispatch, getState) => {
    dispatch(requestTemplateStatusTranslations());
    const {
        language: {
            templateStatusTranslations: { data },
        },
    } = getState();
    if (!isEmpty(data)) {
        return data;
    }
    let result = {};
    try {
        result = await Api.get(Api.route('partnermarketing_template_translation_pretty_status'));
        dispatch(successTemplateStatusTranslations(result));
    } catch (e) {
        dispatch(error("Can't retrieve templateStatusTranslations."));
    }
    return result;
};
