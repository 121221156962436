import {
    TBButton,
    TBColourGroup,
    TBForm,
    TBPersistedFooter,
    TBTemplatePage,
    TBTextStyle,
} from 'apps/template-builder/types';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';

export type ListsContextData = {
    colourGroups: TBColourGroup[];
    buttons: TBButton[];
    footers: TBPersistedFooter[];
    forms: TBForm[];
    pages: TBTemplatePage[];
    textStyles: TBTextStyle[];
};

const ListsContext = createContext({
    colourGroups: [] as ListsContextData['colourGroups'],
    buttons: [] as ListsContextData['buttons'],
    footers: [] as ListsContextData['footers'],
    forms: [] as ListsContextData['forms'],
    pages: [] as ListsContextData['pages'],
    textStyles: [] as ListsContextData['textStyles'],
});

export function useListsContext(): ListsContextData {
    return useContext(ListsContext);
}

type ListsContextProviderProps = ListsContextData & {
    children: ReactNode;
};

export default function ListsContextProvider({
    colourGroups,
    buttons,
    footers,
    forms,
    pages,
    textStyles,
    children,
}: ListsContextProviderProps): JSX.Element {
    const data = useMemo(
        () => ({ colourGroups, buttons, footers, forms, pages, textStyles }),
        [colourGroups, buttons, footers, forms, pages, textStyles]
    );
    return <ListsContext.Provider value={data}>{children}</ListsContext.Provider>;
}
