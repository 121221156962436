// Based on https://github.com/bghveding/use-fetch/blob/6a4d6e495c7106c860e789b1b5bf0bedf973e3a4/src/useFetch.js
import { useRef, useCallback, useEffect, useReducer } from 'react';
import md5 from 'crypto-js/md5';
import { Api } from '../utils';

const defaultOnError = error => console.error(error);
const defaultOnSuccess = () => null;

function reducer(state, action) {
    switch (action.type) {
        case 'in-flight': {
            // Avoid updating state unnecessarily
            // By returning unchanged state React won't re-render
            if (state.fetching === true) {
                return state;
            }

            return {
                fetching: true,
                response: null,
                error: null,
            };
        }

        case 'response': {
            return {
                error: null,
                fetching: action.payload.fetching,
                response: action.payload.response,
            };
        }

        case 'error':
            return {
                ...state,
                fetching: false,
                error: action.payload.error,
            };

        default:
            return state;
    }
}

const getRequestKey = data => JSON.stringify(data);

const isReadRequest = method => {
    const m = method.toUpperCase();

    return m === 'GET' || m === 'HEAD' || m === 'OPTIONS';
};

const requestsCache = {};

const useApi = ({
    slug,
    slugParams = {},
    method = 'GET',
    lazy = null,
    data = {},
    requestKey = null,
    onError = defaultOnError,
    onSuccess = defaultOnSuccess,
    cache = false,
}) => {
    const abortControllerRef = useRef();

    const isLazy = lazy === null ? !isReadRequest(method) : lazy;

    const [state, dispatch] = useReducer(reducer, {
        response: null,
        fetching: !isLazy,
        error: null,
    });

    // Builds a key based on URL, method and headers.
    // requestKey is used to determine if the request parameters have changed
    // and as key in the response cache.
    const finalRequestKey = requestKey || getRequestKey({ slug, slugParams, method: method.toLowerCase(), data });

    function setFetching() {
        dispatch({ type: 'in-flight' });
    }

    function setResponse(response, fetching = false) {
        dispatch({ type: 'response', payload: { response, fetching } });
    }

    function setError(error) {
        dispatch({ type: 'error', payload: { error } });
    }

    const cancelRunningRequest = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    };

    const doFetch = useCallback(
        (extraData = {}, extraSlugParams = {}) => {
            cancelRunningRequest();

            const route = Api.route(slug, { ...slugParams, ...extraSlugParams });
            const _data = { ...data, ...extraData };

            const cacheKey = md5(getRequestKey({ route, method: method.toLowerCase(), data: _data }));

            abortControllerRef.current = new AbortController();

            const promise =
                cache && requestsCache[cacheKey]
                    ? Promise.resolve(requestsCache[cacheKey])
                    : Api[method.toLowerCase()](route, _data, { signal: abortControllerRef.current.signal });

            setFetching(true);

            return promise
                .then(response => {
                    if (cache) {
                        requestsCache[cacheKey] = response;
                    }
                    onSuccess(response);
                    setResponse(response);

                    return response;
                })
                .catch(error => {
                    if (!abortControllerRef.current.signal.aborted) {
                        setError(error);
                    }

                    onError(error);

                    return error;
                })
                .finally(() => {
                    // Remove the abort controller now that the request is done
                    abortControllerRef.current = null;
                });
        },
        [finalRequestKey]
    );

    // Start requesting onMount if not lazy
    // Start requesting if isLazy goes from true to false
    // Start requesting every time the request key changes if not lazy
    useEffect(() => {
        // Do not start request automatically when in lazy mode
        if (isLazy === true) {
            return;
        }

        doFetch();
    }, [doFetch, isLazy]);

    // Cancel any running request when unmounting to avoid updating state after component has unmounted
    // This can happen if a request's promise resolves after component unmounts
    useEffect(
        () => () => {
            cancelRunningRequest();
        },
        []
    );

    return {
        response: state.response,
        loading: state.fetching,
        error: state.error,
        requestKey: finalRequestKey,
        doFetch,
        setResponse,
    };
};

export default useApi;
