import React from 'react';
import { Field, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import Input from 'components/Input/Input';
import useApiTemplateAssetLibrary from 'pages/manage/Templates/Template/hooks/useApiTemplateAssetLibrary';
import { Api, t } from 'utils';
import { LINK_TYPE, LINK_URL, LINK_TYPE_ASSET_LIBRARY_ALL_ASSETS, REDIRECT_URL_NAME } from 'constants.js';
import LinkTypeSalesResource from 'components/EditTemplate/LinkTypeField/LinkTypeSalesResource';
import PropTypes from 'prop-types';
import LinkTypePage from 'apps/template-builder/pages/templates/components/LinkTypePage/LinkTypePage';
import styles from './LinkTypeField.module.scss';

const EMAIL_URL_TYPE_DATA = () => [
    { label: t('user.account_company_details.company_email'), value: LINK_URL.COMPANY_EMAIL },
    { label: t('template.default.link.personal_email'), value: LINK_URL.PERSONAL_EMAIL },
    { label: t('template.template_form.link_options.other'), value: LINK_URL.OTHER },
];

const URL_URL_TYPE_DATA = () => [
    { label: t('template.default.link.company_url'), value: LINK_URL.COMPANY_URL },
    { label: t('template.default.link.company_privacy'), value: LINK_URL.PRIVACY_POLICY },
    { label: t('template.template_form.link_options.other'), value: LINK_URL.OTHER },
];

const LinkTypeFieldExtra = ({ value, type, disabled, fields }) => {
    const { values, setFieldValue } = useFormikContext();
    const uploadUrl = Api.route('partnermarketing_template_upload_link_file', { linkId: values.id });
    const { campaignId, templateId } = useParams();
    const { templateAssetLibraryAssets } = useApiTemplateAssetLibrary(
        campaignId,
        templateId,
        value !== LINK_TYPE.ASSET_LIBRARY
    );

    const getRedirectUrlTypeData = options =>
        options.map(o => ({
            label: t(`template.template_form.link_options.${o}`),
            value: LINK_URL[o.toUpperCase()],
            key: `redirect_url_type_${o}`,
        }));
    const getUrlTypeData = urlFieldType =>
        REDIRECT_URL_NAME === urlFieldType ? getRedirectUrlTypeData(values.redirectLinkOptions) : URL_URL_TYPE_DATA();
    const checkTextMatch = ({ target: { value: url } }) => {
        if (values.isTextMatch) {
            setFieldValue('text', url, false);
        }
    };
    const changeUrlType = ({ target: { value: urlType } }) => {
        setFieldValue(fields.urlField, urlType === LINK_URL.OTHER ? '' : urlType, false);
    };
    const isEmailOrUrl = value === LINK_TYPE.EMAIL || value === LINK_TYPE.URL || value === LINK_TYPE.PARTNER_URL;
    const showUrl = !isEmailOrUrl || get(values, fields.urlTypeField) === LINK_URL.OTHER;
    const urlFieldName = type === 'fallback' ? fields.fallbackUrlField : fields.urlField;

    let props = {};
    if (value === LINK_TYPE.EMAIL) {
        props = {
            label: t('template.default.link.other_email'),
            labelWidth: Input.LABEL_WIDTH.FULL,
            prefix: 'mailto:',
            className: styles.emailUrl,
            onChange: checkTextMatch,
        };
    } else if (value === LINK_TYPE.URL) {
        props = {
            label: t('template.default.link.other_url'),
            labelWidth: Input.LABEL_WIDTH.FULL,
            onChange: checkTextMatch,
        };
    } else if (value === LINK_TYPE.UPLOAD) {
        props = {
            type: Input.TYPE.FILE,
            uploadParamName: 'file[file]',
            fileTypes: '',
            uploadUrl,
            uploadResultKey: 'fileUrl',
        };
    } else if (value === LINK_TYPE.ASSET_LIBRARY) {
        props = {
            type: Input.TYPE.SELECT,
            placeholder: t('general.labels.please_select'),
            onChange: e => {
                setFieldValue(fields.linkEditableField, e.target.value === LINK_TYPE_ASSET_LIBRARY_ALL_ASSETS);
            },
            data: [
                ...(type === 'fallback'
                    ? []
                    : [{ label: 'All relevant assets', value: LINK_TYPE_ASSET_LIBRARY_ALL_ASSETS }]),
                ...templateAssetLibraryAssets.map(({ id, name }) => ({ label: name, value: id })),
            ],
        };
    }

    if (value === LINK_TYPE.SALES_RESOURCE) {
        return <LinkTypeSalesResource />;
    }

    if (value === LINK_TYPE.PAGE) {
        return <LinkTypePage changeUrlType={changeUrlType} fields={fields} />;
    }

    const urlValue = get(values, fields.urlField);

    return (
        <>
            {isEmailOrUrl && (
                <Field
                    component={Input}
                    type={Input.TYPE.SELECT}
                    placeholder={t('general.labels.please_select')}
                    name={fields.urlTypeField}
                    required
                    disabled={disabled}
                    padding={showUrl ? Input.PADDING.SMALL : Input.PADDING.NONE}
                    onChange={changeUrlType}
                    data={value === LINK_TYPE.EMAIL ? EMAIL_URL_TYPE_DATA() : getUrlTypeData(values.name)}
                />
            )}
            {showUrl && (
                <Field
                    component={Input}
                    name={urlFieldName}
                    required
                    padding={Input.PADDING.NONE}
                    disabled={disabled}
                    {...props}
                />
            )}
            {value === LINK_TYPE.UPLOAD && !!urlValue && (
                <a className="link" href={urlValue} target="_blank" rel="noreferrer">
                    {t('template.asset_requests.view.uploaded_files')}
                </a>
            )}
        </>
    );
};

LinkTypeFieldExtra.propTypes = {
    value: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    fields: PropTypes.shape({
        urlField: PropTypes.string.isRequired,
        urlTypeField: PropTypes.string.isRequired,
        fallbackTypeField: PropTypes.string.isRequired,
        fallbackUrlField: PropTypes.string.isRequired,
        linkEditableField: PropTypes.string.isRequired,
    }),
};

export default LinkTypeFieldExtra;
