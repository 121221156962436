import { useField } from 'formik';
import isUndefined from 'lodash/isUndefined';
import { getAreaIdFromPrefix } from 'pages/campaignInstance/edit/helpers';

/**
 * Uses data field.
 *
 * @param {('texts'|'links'|'images'|'categories')} type
 * @param {string} dataKey
 * @param {Object} options [{}] extra options
 * @param {string=} options.suffix [''] suffix to add when getting the field
 * @return [{FieldInputProps<any>}, (value:*, shouldValidate?: boolean) => void]
 */
const useDataField = (type, dataKey, options = {}) => {
    const [{ value: data }] = useField(`data.${type}`);
    const dataKeyIndex = data?.findIndex(({ name }) => name === dataKey);

    if (dataKeyIndex === -1) {
        console.warn('Field does not exist', { type, dataKey, options });
    }
    const [itemField, , { setValue }] = useField(`data.${type}[${dataKeyIndex}]${options.suffix || ''}`);

    return [itemField, setValue];
};

export default useDataField;
