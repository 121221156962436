export default {
    collateral: {
        setData: 'SALES_RESOURCES_COLLATERAL_SET_DATA',
        setLoading: 'SALES_RESOURCES_COLLATERAL_SET_LOADING',
    },
    report: {
        setData: 'SALES_RESOURCES_REPORT_SET_DATA',
        setLoading: 'SALES_RESOURCES_REPORT_SET_LOADING',
        setCsvLoading: 'SALES_RESOURCES_REPORT_SET_CSV_LOADING',
    },
    snippet: {
        setData: 'SALES_RESOURCES_SNIPPET_SET_DATA',
        setLoading: 'SALES_RESOURCES_SNIPPET_SET_LOADING',
    },
};
