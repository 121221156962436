import { Dispatch, useMemo, useReducer } from 'react';

enum ACTIONS {
    SET_PREVIEW = 'set_preview',
    RESET = 'reset',
    SET_URL = 'set_url',
}

export enum STEPS {
    UPLOAD = 'upload',
    CONFIRM = 'confirm',
    SELECT = 'select',
}

type FileUploadCallback = () => void;

type ImageTypeUploadReducerState = {
    preview: string;
    uploadCallback: FileUploadCallback | null;
    url: string;
    step: STEPS;
};

type ImageTypeUploadReducerResult = {
    state: ImageTypeUploadReducerState;
    setPreview: (fileContent: SetPreviewAction['fileContent'], callback: SetPreviewAction['callback']) => void;
    reset: () => void;
    setUrl: (url: SetURLAction['url']) => void;
};

type SetPreviewAction = {
    type: ACTIONS.SET_PREVIEW;
    fileContent: string;
    callback: FileUploadCallback;
};
type ResetAction = {
    type: ACTIONS.RESET;
};
type SetURLAction = {
    type: ACTIONS.SET_URL;
    url: string;
};

type ReducerAction = SetPreviewAction | ResetAction | SetURLAction;

export const INITIAL_STATE: ImageTypeUploadReducerState = {
    preview: '',
    uploadCallback: null,
    url: '',
    step: STEPS.UPLOAD,
};

function imageTypeUploadReducer(
    state: ImageTypeUploadReducerState,
    action: ReducerAction
): ImageTypeUploadReducerState {
    switch (action.type) {
        case ACTIONS.SET_PREVIEW:
            return {
                ...state,
                preview: action.fileContent,
                uploadCallback: action.callback,
                step: STEPS.CONFIRM,
            };
        case ACTIONS.SET_URL:
            return {
                ...state,
                url: action.url,
                step: STEPS.SELECT,
            };
        case ACTIONS.RESET:
            return INITIAL_STATE;
        default:
            return state;
    }
}

function getActions(dispatch: Dispatch<ReducerAction>): Omit<ImageTypeUploadReducerResult, 'state'> {
    return {
        setPreview: (fileContent, callback): void => dispatch({ type: ACTIONS.SET_PREVIEW, fileContent, callback }),
        reset: (): void => dispatch({ type: ACTIONS.RESET }),
        setUrl: (url): void => dispatch({ type: ACTIONS.SET_URL, url }),
    };
}

export default function useImageTypeUploadReducer(): ImageTypeUploadReducerResult {
    const [state, dispatch] = useReducer(imageTypeUploadReducer, INITIAL_STATE);
    const actions = useMemo(() => getActions(dispatch), []);

    return useMemo(
        () => ({
            state,
            ...actions,
        }),
        [state, actions]
    );
}
