import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Input.module.css';

const ColourSwitch = ({ options, onChange, value, name, disabled, className }) => {
    const onClick = option => () => onChange({ target: { name, value: option } });
    return (
        <div className={cn(styles.colourSwitchWrapper, className)}>
            {options.map(option => (
                <span
                    key={option}
                    className={cn(styles.colour, value === option && styles.active)}
                    style={{ backgroundColor: option }}
                    onClick={disabled ? undefined : onClick(option)}
                />
            ))}
        </div>
    );
};

ColourSwitch.propTypes = {
    /**
     * Class name.
     */
    className: PropTypes.string,
    /**
     * Field name.
     */
    name: PropTypes.string,
    /**
     * Field value. Should be string.
     */
    value: PropTypes.string,
    /**
     * Gets called when value was changed.
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Is disabled.
     */
    disabled: PropTypes.bool,
    /**
     * Switch options.
     */
    options: PropTypes.arrayOf(PropTypes.string),
};

export default ColourSwitch;
