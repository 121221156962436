import moment from 'moment';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import round from 'lodash/round';
import omitBy from 'lodash/omitBy';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import isFunction from 'lodash/isFunction';
import { MAIN_FIELD_NAMES } from 'pages/manage/Templates/utils';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import history from '../history';

import { t } from './lang';

export { default as Api } from './Api';
export { default as client } from './client';
export { t, lang } from './lang';
export { default as Waypoint } from './Waypoint';
export { default as broadcastDate } from './broadcastDate';
export { getClearBitPerson, getClearBitCompany } from 'utils/clearbit';

export const switchBodyClass = (addClass, className) =>
    document.querySelector('body').classList[addClass ? 'add' : 'remove'](className);

export const dateToApiString = value => (value ? moment(value).format('YYYY-MM-DD') : value);

export const stringToDate = value => (value ? moment(value).toDate() : value);

export const fromNow = (value, period = 'days') => moment().diff(moment(value), period);

export const multiselectArrayToString = (value, valueField = 'value', separator = ',') =>
    value ? value.map(item => (isObject(item) ? item[valueField] : item)).join(separator) : value;

export const stringToMultiselectArray = (value, separator = ',') => (value ? value.split(separator) : value);

export const getFileContent = (file, type = 'readAsDataURL') =>
    new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = evt => resolve(evt.target.result);
        reader[type](file);
    });

export const downloadContent = (content, fileName, mimeType = 'application/octet-stream') => {
    const a = document.createElement('a');

    if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
            new Blob([content], {
                type: mimeType,
            }),
            fileName
        );
    } else if (URL && 'download' in a) {
        // html5 A[download]
        const fileAsBlob = new Blob([content], { type: mimeType });
        const downloadLink = document.createElement('a');
        downloadLink.download = fileName;
        downloadLink.href = window.URL.createObjectURL(fileAsBlob);
        downloadLink.onclick = event => document.body.removeChild(event.target);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();

        window.URL.revokeObjectURL(downloadLink.href);
    } else {
        // Only this mime type is supported
        window.location.href = `data:application/octet-stream,${encodeURIComponent(content)}`;
    }
};

export const toAbsoluteUrl = url => (window.location = url);

export const getAssetUrl = url => {
    const pmHost = window.location.host.replace('react', 'assets');
    return `//${pmHost}${url}`;
};

export const pluralize = (number, one, two) => {
    if (number === 1) {
        return one;
    }

    return two;
};

export const tPluralize = (number, tPrefix, params = {}) => {
    if (number === 1) {
        return t(`${tPrefix}.one`, params);
    }

    return t(`${tPrefix}.other`, params);
};

export const abosulteReditect = (path, target = '_self') => window.open(path, target);

export const scrollTo = (el, pos) => {
    let lastPosition = 0;
    let positionsWasEqual = false;

    const scrollToRepeat = (element, position) => {
        let scrollPosition = element.scrollTop;

        if (!positionsWasEqual) {
            positionsWasEqual = lastPosition === scrollPosition;
        } else if (lastPosition !== scrollPosition) {
            // Manual scroll was happened. Cancel the scrollTo execution.
            return;
        }

        scrollPosition += (position - scrollPosition) * 0.3;

        if (Math.abs(scrollPosition - position) < 5) {
            // eslint-disable-next-line
            element.scrollTop = position;
            lastPosition = 0;
            positionsWasEqual = false;
        } else {
            // eslint-disable-next-line
            element.scrollTop = scrollPosition;
            lastPosition = element.scrollTop;

            setTimeout(() => scrollToRepeat(element, position), 40);
        }
    };

    scrollToRepeat(el, pos);
};

export const hasUserRole = (name, userRoles) => userRoles.filter(({ role }) => role === name).length === 1;

export const getRoleById = (id, roles) => roles.find(role => role.id === id);

export const sortByDisplayOrder = (data, id, to) => {
    let met = false;
    let order = 0;
    const list = data.map((item, index) => {
        met = met || id === item.id;
        if (index === to && !met) {
            order++;
        }
        const displayOrder = item.id !== id ? order++ : to;
        if (index === to && met) {
            order++;
        }

        return {
            ...item,
            displayOrder,
        };
    });
    list.sort((a, b) => a.displayOrder - b.displayOrder);

    return list;
};

export const trim = string => (string ? string.replace(/^\s+|\s+$/gm, '') : '');

export const levenshtein = (str1, str2) => {
    const cost = [];
    const n = str1.length;
    const m = str2.length;
    let i;
    let j;

    const minimum = (a, b, c) => {
        let min = a;
        if (b < min) {
            min = b;
        }
        if (c < min) {
            min = c;
        }
        return min;
    };

    if (n === 0) {
        return;
    }
    if (m === 0) {
        return;
    }

    for (i = 0; i <= n; i++) {
        cost[i] = [];
    }

    for (i = 0; i <= n; i++) {
        cost[i][0] = i;
    }

    for (j = 0; j <= m; j++) {
        cost[0][j] = j;
    }

    for (i = 1; i <= n; i++) {
        const x = str1.charAt(i - 1);
        for (j = 1; j <= m; j++) {
            const y = str2.charAt(j - 1);
            if (x === y) {
                cost[i][j] = cost[i - 1][j - 1];
            } else {
                cost[i][j] = 1 + minimum(cost[i - 1][j - 1], cost[i][j - 1], cost[i - 1][j]);
            }
        }
    }

    return cost[n][m];
};

export const stringifyCSV = (table, replacer) => {
    const _replacer = replacer || ((r, c, v) => v);
    let csv = '';
    let c;
    let cc;
    let r;
    const rr = table.length;
    let cell;
    for (r = 0; r < rr; ++r) {
        if (r) {
            csv += '\r\n';
        }
        for (c = 0, cc = table[r].length; c < cc; ++c) {
            if (c) {
                csv += ',';
            }
            cell = _replacer(r, c, table[r][c]);
            if (/[,\r\n"]/.test(cell)) {
                cell = `"${cell.replace(/"/g, '""')}"`;
            }
            csv += cell || cell === 0 ? cell : '';
        }
    }
    return csv;
};

export const parseCSV = (csv, reviver) => {
    const _reviver = reviver || ((r, c, v) => v);
    const chars = csv.split('');
    let c = 0;
    const cc = chars.length;
    let start;
    let end;
    const table = [];
    let row;
    while (c < cc) {
        table.push((row = []));
        while (c < cc && chars[c] !== '\r' && chars[c] !== '\n') {
            start = end = c;
            if (chars[c] === '"') {
                start = end = ++c;
                while (c < cc) {
                    if (chars[c] === '"') {
                        if (chars[c + 1] !== '"') {
                            break;
                        } else {
                            chars[++c] = '';
                        } // unescape ""
                    }
                    end = ++c;
                }
                if (chars[c] === '"') {
                    ++c;
                }
                while (c < cc && chars[c] !== '\r' && chars[c] !== '\n' && chars[c] !== ',') {
                    ++c;
                }
            } else {
                while (c < cc && chars[c] !== '\r' && chars[c] !== '\n' && chars[c] !== ',') {
                    end = ++c;
                }
            }
            row.push(_reviver(table.length - 1, row.length, chars.slice(start, end).join('')));
            if (chars[c] === ',') {
                ++c;
            }
        }
        if (chars[c] === '\r') {
            ++c;
        }
        if (chars[c] === '\n') {
            ++c;
        }
    }
    return table;
};

export const prettyFileSize = fileSize => {
    let fileSizeInBytes = Number(fileSize);
    if (Number.isNaN(fileSizeInBytes) || fileSizeInBytes < 1) {
        return '';
    }

    let i = -1;
    const byteUnits = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes /= 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return `${Math.max(fileSizeInBytes, 0.1).toFixed(1)} ${byteUnits[i]}`;
};

const getNumber = value => (isString(value) ? parseFloat(value) : value) || 0;

export const numberFormatter = (value, precision = 0) => {
    const [int, frac] =
        precision === -1
            ? getNumber(value).toString(10).split('.')
            : round(getNumber(value), precision).toFixed(precision).split('.');
    const intFormatted = int.replace(/./g, (c, i, a) => (i && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c));
    return `${value < 0 ? '-' : ''}${intFormatted}${precision && frac ? `.${frac}` : ''}`;
};

export const moneyFormatter = (value, precision = 2, currency = '$') => {
    const sign = value < 0 ? '-' : '';
    const formattedValue = numberFormatter(Math.abs(getNumber(value)), precision);

    return `${sign}${currency}${formattedValue}`;
};

export const percentFormatter = (value, precision = 1) => `${numberFormatter(getNumber(value) * 100, precision)}%`;

export const formatCompanyDetails = companyDetails => {
    const companyAddress = companyDetails.address || '';
    return [
        companyDetails.name,
        companyAddress.streetFirstLine,
        companyAddress.streetSecondLine,
        companyAddress.city,
        companyAddress.state,
        companyAddress.postcode,
        companyAddress.country ? companyAddress.country.code : '',
    ]
        .filter(v => v)
        .join(', ');
};

// eslint-disable-next-line no-bitwise
export const hashCode = str =>
    Array.from(str)
        .reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0)
        .toString();

/**
 * Get first defined obj property from the props list (string). If nothing matched it will use defaultValue.
 * @param {Object} obj
 * @param {string|string[]|Function} props
 * @param {*} defaultValue
 * @returns {*}
 */
const getFirstMatched = (obj, props, defaultValue) =>
    isFunction(props)
        ? props(obj)
        : (!isArray(props) ? [props] : props)
              .map(prop => get(obj, prop))
              .reduce((res, val) => (res === defaultValue && !isUndefined(val) ? val : res), defaultValue);

/**
 * Build data for select Input.
 *
 * @param {Object[]} options
 * @param {string|string[]|Function} label
 * @param {string|string[]|Function} value
 * @returns {{label: *, value: *}[]}
 */
export const toSelectData = (options, label = 'name', value = 'id') =>
    (options || []).map((option, index) => ({
        value: getFirstMatched(option, value, index),
        label: getFirstMatched(option, label, '-'),
    }));

export const objectCleanEmpty = obj => omitBy(obj, v => !v && v !== 0);

export const mapEmptyValueToNull = obj => {
    const _mapEmptyValueToNull = object => {
        Object.keys(object).forEach(key => {
            if (object.hasOwnProperty(key)) {
                if (object[key] !== null && typeof object[key] === 'object') {
                    object[key] = _mapEmptyValueToNull(object[key]);
                }

                if (object[key] === '') {
                    object[key] = null;
                }
            }
        });

        return object;
    };

    return _mapEmptyValueToNull(obj);
};
export const stripTags = (html, lineBreaks = true) => {
    const div = document.createElement('div');
    let _html = html || '';
    if (lineBreaks) {
        _html = _html.replace(/<\/(p|li)>/gm, '\n');
    }
    div.innerHTML = _html;
    return div.textContent || div.innerText || '';
};

export const rgbToHex = (r, g, b) => `#${[r, g, b].map(x => ((x || 0) % 256).toString(16).padStart(2, '0')).join('')}`;

export const hexToRgb = hex =>
    [
        ...(hex
            .substring(1)
            .match(/.{2}/g)
            ?.map(x => {
                const v = parseInt(x, 16);
                return Number.isNaN(v) ? undefined : v;
            }) || []),
        undefined,
        undefined,
        undefined,
    ].slice(0, 3);

export const moveArrayElement = (arr, from, to) => {
    const a = [...arr];
    a.splice(from, 1);
    a.splice(to, 0, arr[from]);
    return a;
};

export const loadScript = src =>
    new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;

        document.body.appendChild(script);
    });

export const loadStyles = src => {
    const link = document.createElement('link');
    link.href = src;
    link.media = 'screen';
    link.rel = 'stylesheet';
    link.type = 'text/css';

    document.head.appendChild(link);
};

export const getCookie = name => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(`${name}=`) === 0) {
            return cookie.substring(`${name}=`.length, cookie.length);
        }
    }
    return null;
};

export const setCookie = (name, value, ageInDays) => {
    let expires;
    if (ageInDays) {
        const date = new Date();
        date.setTime(date.getTime() + ageInDays * 24 * 60 * 60 * 1000);
        expires = `;expires=${date.toGMTString()}`;
    } else {
        expires = '';
    }

    const secure = window.location.protocol === 'https:' ? ';secure' : '';

    document.cookie = `${name}=${value}${expires};path=/${secure}`;
};

export const getSearchParams = () => {
    const { state, search } = history.location;
    return state?.search || state?.searchParams || Object.fromEntries(new URLSearchParams(search));
};

export const isToggleFieldName = fieldName => fieldName.indexOf(MAIN_FIELD_NAMES.TOGGLE_GROUP) === 0;

export const uniqValues = a => a.filter((value, index, self) => self.indexOf(value) === index);

export const getFileBlob = async fileUrl =>
    fetch(fileUrl)
        .then(r => r.blob())
        .catch(() => console.error(`Error getFileBlob for ${fileUrl}`));

export const getImageBase64 = fileUrl =>
    new Promise((resolve, reject) => {
        getFileBlob(fileUrl)
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);

                reader.onload = () => resolve(reader.result);
                reader.onerror = error => {
                    console.log('getImageBase64 Error: ', error);
                    reject();
                };
            })
            .catch(() => {
                console.error(`Error getImageBase64 for ${fileUrl}`);
                reject();
            });
    });

export const getEnv = () => ({
    isProd: !!window.location.hostname.match('^((?!stage|qa).)*partnermarketing.com'),
    isStage: !!window.location.hostname.match('stage.partnermarketing.com'),
    isQA: !!window.location.hostname.match('qa.partnermarketing.com'),
    isDev: !!window.location.hostname.match('partnermarketing.test'),
});

export const fitSize = (objDim, fitDim) => {
    const r = objDim.width / objDim.height;
    let w = 0;
    let h = 0;
    if (fitDim.width) {
        w = fitDim.width;
        h = w / r;
    }
    if (fitDim.height && (!h || h > fitDim.height)) {
        h = fitDim.height;
        w = h * r;
    }

    return { width: w, height: h };
};

export const isNumeric = n => !Number.isNaN(parseFloat(n)) && Number.isFinite(n);

export const openNewTab = content => {
    const newWindow = window.open();
    if (newWindow) {
        newWindow.document.write(content);
    }
};

/**
 * Deep diff between two object
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object|null}        Return a new object who represent the diff
 */
export function objectsDiff(base, object) {
    const diff = transform(
        object,
        (result, value, key) => {
            if (!isEqual(value, base[key])) {
                // eslint-disable-next-line no-param-reassign
                result[key] = isObject(value) && isObject(base[key]) ? objectsDiff(base[key], value) : value;
            }
        },
        {}
    );

    return isEmpty(diff) ? null : diff;
}
