import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Snackbar.module.css';
import IconStack from '../IconStack/IconStack';

const TYPE = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
};

const Snackbar = ({ type, children, className, dark }) => {
    let icon;
    switch (type) {
        case TYPE.ERROR: {
            icon = <IconStack size="1" outer="bg-circle" inner="exclamation" className={styles.icon} />;
            break;
        }
        default:
            icon = null;
    }
    return (
        <div
            className={cn(
                styles.wrapper,
                styles[type],
                dark && styles.dark,
                dark && 'client-secondary-colour-background',
                className,
            )}
        >
            {icon}
            <div>{children}</div>
        </div>
    );
};

Snackbar.TYPE = TYPE;

Snackbar.propTypes = {
    type: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    className: PropTypes.string,
    children: PropTypes.any,
    dark: PropTypes.bool,
};

export default Snackbar;
