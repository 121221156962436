import React, { FC } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import Icon from 'components/Icon/Icon';
import styles from './ExtendedEditorNavBar.module.scss';

type ExtendedEditorNavBarProps = {
    links: ({ to?: string; icon: string } | false)[];
};

const ExtendedEditorNavBar: FC<ExtendedEditorNavBarProps> = ({ links }) => {
    const activeClass = cn('client-main-colour-background', 'client-main-colour-border-pseudo', styles.active);
    return (
        <div className={styles.wrapper}>
            {links.map(link => {
                if (!link) {
                    return null;
                }
                const icon = <Icon kind={link.icon} />;
                if (link.to) {
                    return (
                        <NavLink key={link.icon} className={styles.link} activeClassName={activeClass} to={link.to}>
                            {icon}
                        </NavLink>
                    );
                }
                return (
                    <a key={link.icon} className={cn(styles.link, 'disabled')}>
                        {icon}
                    </a>
                );
            })}
        </div>
    );
};

export default ExtendedEditorNavBar;
