import { FormikHelpers } from 'formik/dist/types';
import type { FC, ReactNode } from 'react';
import type { FormikConfig, FormikValues } from 'formik';
import type { ObjectSchema, AnyObject } from 'yup';

import React, { useState } from 'react';
import { Form, Formik } from 'formik';

export type WizardStep<T extends AnyObject> = {
    step: FC;
    onSubmit?: FormikConfig<T>['onSubmit'];
    isLastStep?: (values: T, formikHelpers: FormikHelpers<T>) => boolean;
    validationSchema?: ObjectSchema<T>;
};

type WizardActionsDetails = {
    WizardStep: FC;
    onPrev: VoidFunction;
    stepNumber: number;
    isLastStep: boolean;
    disabled: boolean;
};

type WizardProps<T> = {
    initialValues: T;
    onSubmit: FormikConfig<T>['onSubmit'];
    children: (details: WizardActionsDetails) => ReactNode;
    steps: WizardStep<FormikValues>[];
    addFormWrapper?: boolean;
};

export default function Wizard<T extends FormikValues>({
    children,
    initialValues,
    onSubmit,
    steps,
    addFormWrapper = true,
}: WizardProps<T>) {
    const [stepNumber, setStepNumber] = useState(0);
    const [snapshot, setSnapshot] = useState<T>(initialValues);

    const step = steps[stepNumber];
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps - 1;

    const next = values => {
        setSnapshot(values);
        setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    };

    const previous = values => {
        setSnapshot(values);
        setStepNumber(Math.max(stepNumber - 1, 0));
    };

    const handleSubmit = async (values, bag) => {
        if (step.onSubmit) {
            await step.onSubmit(values, bag);
        }
        if (isLastStep || step.isLastStep?.(values, bag)) {
            onSubmit(values, bag);
            return;
        }

        await bag.setTouched({});
        next(values);
    };

    const Wrapper = addFormWrapper ? Form : React.Fragment;

    return (
        <Formik initialValues={snapshot} onSubmit={handleSubmit} validationSchema={step.validationSchema}>
            {formik => (
                <Wrapper>
                    {children({
                        onPrev: () => previous(formik.values),
                        stepNumber,
                        isLastStep,
                        disabled: formik.isSubmitting,
                        WizardStep: step.step,
                    })}
                </Wrapper>
            )}
        </Formik>
    );
}
