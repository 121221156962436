import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { t } from '../../utils';
import styles from './Toggle.module.scss';

const Toggle = ({ active, onChange }) => (
    <span
        className={cn(styles.wrapper, active && 'client-main-colour-background', active && styles.active)}
        onClick={onChange}
    >
        {!!active && t('general.labels.turn_on')}
        <span className={styles.circle} />
        {!active && t('general.labels.turn_off')}
    </span>
);

Toggle.propTypes = {
    active: PropTypes.bool,
    onChange: PropTypes.func,
};

export default Toggle;
