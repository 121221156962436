import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Divider.module.css';

const Divider = ({ className }) => <hr className={cn(styles.wrapper, className)} />;

Divider.propTypes = {
    className: PropTypes.string,
};

export default Divider;
