import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const RouteRedirect = ({ path, to }) => <Redirect path={path} to={to} push={false} />;

RouteRedirect.propTypes = {
    path: PropTypes.string,
    to: PropTypes.string,
};

export default RouteRedirect;
