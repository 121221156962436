import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Multiselect from 'react-widgets/lib/Multiselect';
import List from 'react-widgets/lib/List';
import { t } from 'utils';
import styles from './Input.module.css';
import Button from '../Button/Button';

class ListComponent extends List {
    render() {
        return (
            <>
                <div className={styles.multiSelectButtons}>
                    <Button
                        tiny
                        color={Button.COLOR.GRAY}
                        icon="tick"
                        className={styles.multiSelectButton}
                        onClick={this.props.selectAll}
                    >
                        {t('general.labels.all')}
                    </Button>
                    <Button
                        tiny
                        color={Button.COLOR.GRAY}
                        icon="cross"
                        className={styles.multiSelectButton}
                        onClick={this.props.selectNone}
                    >
                        {t('general.labels.none')}
                    </Button>
                    <Button
                        tiny
                        color={Button.COLOR.GRAY}
                        icon="lock"
                        className={styles.multiSelectButton}
                        onClick={this.props.reset}
                    >
                        {t('pmApp.directive.iStevenMultiSelect.reset')}
                    </Button>
                </div>
                {super.render()}
            </>
        );
    }
}

export default function MultiSelect(props) {
    const initialValue = useMemo(
        () =>
            props.value?.map(value => ({
                [props.valueField]: value,
            })) || [],
        // No deps. It should be initial value.
        []
    );

    const handleChange = value => {
        const { id, name, onChange, valueField, field } = props;
        // Make it compatible with Formik.
        const e = { target: { id, name, value: value.map(item => item[valueField]) } };
        onChange(e);
        if (field && field.onChange) {
            field.onChange(e);
        }
    };

    return (
        <Multiselect
            {...props}
            onChange={handleChange}
            listComponent={ListComponent}
            listProps={{
                selectAll: () => handleChange(props.data),
                selectNone: () => handleChange([]),
                reset: () => handleChange(initialValue),
            }}
        />
    );
}

MultiSelect.defaultProps = {
    textField: 'label',
    valueField: 'value',
};

MultiSelect.propTypes = {
    ...Multiselect.propTypes,
    textField: PropTypes.string,
    valueField: PropTypes.string,
};
