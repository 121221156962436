/* eslint react/no-danger: 0 */

import React from 'react';
import each from 'lodash/each';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';

export const CURRENT_LOCALE_KEY = 'CurrentLanguage';

export const lang = newLanguage => {
    let currentDisplayLanguage = window.pm2appLanguage.getDisplayLanguage();
    if (newLanguage) {
        window.pm2appLanguage.setDisplayLanguage(newLanguage);

        currentDisplayLanguage = newLanguage;
    }

    return currentDisplayLanguage;
};

const getHTML = (html, str) => {
    if (html === 'as_text') {
        return str;
    }
    return <span dangerouslySetInnerHTML={{ __html: str }} />;
};

/**
 * Translates the given string.
 *
 * @param path
 * @param {Object|boolean} data Could be an object with key|value to make replacement in translates string
 * @param {'as_text'|boolean} html should the translation be element (boolean) or html-string
 * @returns {*}
 */
export const t = (path, data = {}, html = false) => {
    let str = get(window.translations, path, `#${path}#`);
    if (str === `#${path}#`) {
        str = get(window.baseTranslations, path, `#${path}#`);
    }
    str = str.replace(new RegExp('{{\\s+', 'g'), '{{').replace(new RegExp('\\s+}}', 'g'), '}}');
    const hasNewLines = str.indexOf('\n') !== -1;
    if (hasNewLines) {
        str = str.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }

    if (data === true || (isEmpty(data) && hasNewLines)) {
        return getHTML(html, str);
    }

    if (!isEmpty(data)) {
        const objectReplacers = [];
        each(data, (replacer, placeholder) => {
            const placeholderStr = `{{${placeholder}}}`;
            const pos = str.indexOf(placeholderStr);
            if (pos !== -1) {
                if (!replacer || typeof replacer === 'string' || typeof replacer === 'number') {
                    const regex = new RegExp(placeholderStr, 'gi');
                    str = str.replace(regex, replacer);
                } else {
                    objectReplacers.push({ replacer, placeholderStr });
                }
            }
        });

        if (objectReplacers.length) {
            str = [str];
            objectReplacers.forEach(({ replacer, placeholderStr }) => {
                str = flatten(
                    str.map(part => {
                        if (typeof part !== 'string' || part.indexOf(placeholderStr) === -1) {
                            return [part];
                        }

                        const pos = part.indexOf(placeholderStr);
                        return [part.substr(0, pos), replacer, part.substr(pos + placeholderStr.length)];
                    })
                );
            });
        }
    }

    return html ? getHTML(html, str) : str;
};
