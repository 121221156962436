import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import HoverableList from 'components/HoverableList/HoverableList';
import EmptyContent from 'components/EmptyContent/EmptyContent';
import { removeUserImage } from 'store/user/actions';
import { selectCurrentUserImages } from 'selectors/userSelectors';
import { t } from 'utils';
import styles from './ImageTypeField.module.scss';

const ImageTypeLibrary = ({ onSave, value }) => {
    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    const selectImage = image => () => onSave(image.fileSystemUrl);

    const dispatch = useDispatch();
    const removeImage = image => () => dispatch(removeUserImage(image)).then(() => ReactTooltip.hide());
    const images = useSelector(selectCurrentUserImages);

    if (!images.length) {
        return (
            <EmptyContent
                image={EmptyContent.IMAGE.DOCUMENTS}
                text={t('template.template_wrapper.edit_image_library_empty')}
            />
        );
    }

    return (
        <>
            <p className={styles.text}>{t('template.template_wrapper.edit_image_library_select')}</p>
            <HoverableList
                isSelected={image => value === image.fileSystemUrl}
                list={images}
                actions={({ item: image }) => (
                    <>
                        <Button color={Button.COLOR.GRAY} tiny icon="trash" onClick={removeImage(image)}>
                            {t('general.button.delete')}
                        </Button>
                        <Button color={Button.COLOR.PRIMARY} tiny icon="tick" onClick={selectImage(image)}>
                            {t('general.button.select')}
                        </Button>
                    </>
                )}
            >
                {({ itemClass, item: image }) => (
                    <span className={itemClass}>
                        <img src={image.fileSystemUrl} alt="" />
                    </span>
                )}
            </HoverableList>
        </>
    );
};

ImageTypeLibrary.propTypes = {
    onSave: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default ImageTypeLibrary;
