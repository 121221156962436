import { Api, abosulteReditect } from 'utils';
import { error, clear } from 'store/alert/actions';
import { lang } from 'utils/lang';
import { getCurrentUser, getDisplayLanguage } from 'selectors/userSelectors';
import ACTIONS from './constants';

export const updateCurrentData = data => ({
    type: ACTIONS.updateCurrentData,
    data,
});

export const setPathName = pathname => ({
    type: ACTIONS.setPathName,
    pathname,
});

const requestUserByToken = () => ({
    type: ACTIONS.getByToken.load,
});
const successUserByToken = data => ({
    type: ACTIONS.getByToken.success,
    data,
});
const errorUserByToken = () => ({
    type: ACTIONS.getByToken.error,
});

export const getByTokenSuccess = result => async (dispatch, getState) => {
    dispatch(successUserByToken(result || {}));
    if (result) {
        lang(getDisplayLanguage(getState()));
    }
};

export const getByToken = () => async dispatch => {
    dispatch(requestUserByToken());
    let result = null;
    try {
        result = await Api.get(Api.route('partnermarketing_user_get_logged_in_api'));
        dispatch(getByTokenSuccess(result));
    } catch (e) {
        dispatch(errorUserByToken());
        dispatch(error("Can't retrieve user."));
    }
    return result;
};

const requestUpsert = () => ({
    type: ACTIONS.upsert.load,
});
const successUpsert = data => ({
    type: ACTIONS.upsert.success,
    data,
});
const errorUpsert = () => ({
    type: ACTIONS.upsert.error,
});

export const upsert = (id, data) => async (dispatch, getState) => {
    const {
        user: {
            current: {
                data: { id: currentId },
            },
        },
    } = getState();
    dispatch(clear());
    dispatch(requestUpsert());
    const url = id ? 'partnermarketing_user_update_api' : 'partnermarketing_user_create_api';
    let result = null;
    try {
        result = await Api[id ? 'put' : 'post'](Api.route(url, { id }), data);
        dispatch(successUpsert(result));
        if (currentId === id) {
            await dispatch(getByToken());
        }
    } catch (e) {
        dispatch(errorUpsert());
        dispatch(error("Can't upsert user."));
        throw e;
    }

    return result;
};

export const checkPassword = (client, email, plainPassword) => async () => {
    let result = false;
    const url = 'partnermarketing_authentication_user_check_password_api';
    try {
        result = await Api.post(Api.route(url), { client, email, plainPassword });
    } catch (e) {
        throw e;
    }

    return result;
};

export const updatePassword = (id, data) => async dispatch => {
    dispatch(clear());
    const url = 'partnermarketing_user_change_password_api';
    try {
        await Api[id ? 'put' : 'post'](Api.route(url, { id }), data);
    } catch (e) {
        dispatch(error("Sorry, we're having trouble updating your password."));
        throw e;
    }
};

export const disconnectSocialNetwork = name => async (dispatch, getState) => {
    const {
        user: {
            current: {
                data: { id: currentId },
            },
        },
    } = getState();
    let result = null;
    dispatch(requestUserByToken());

    try {
        result = await Api.put(
            Api.route('partnermarketing_user_disconnect_social_network', {
                userId: currentId,
            }),
            { socialNetwork: name }
        );
        dispatch(successUserByToken(result));
    } catch (e) {
        dispatch(error("Can't disconnect from social network."));
    }
};

export const connectSocialNetwork = (name, token) => async (dispatch, getState) => {
    const {
        user: {
            current: {
                data: { id: currentId },
            },
        },
    } = getState();
    abosulteReditect(
        Api.route('partnermarketing_user_connect_social_network', {
            socialNetwork: name,
            otat: token,
            userId: currentId,
            referer: window.location.href,
        })
    );
};

const updateLogoFileTypeMapper = {
    companyLogo: 'logoUrl',
    businessLogo: 'businessLogoUrl',
};
export const updateLogo =
    (id, values, fileType = 'companyLogo') =>
    async (dispatch, getState) => {
        let result = null;
        try {
            result = await Api.upload(
                Api.route('partnermarketing_company_update_api_logo_post', { fileType }),
                values,
                {
                    method: 'post',
                }
            );

            const {
                user: {
                    current: { data },
                },
            } = getState();

            dispatch(
                updateCurrentData({
                    ...data,
                    company: {
                        ...data.company,
                        [updateLogoFileTypeMapper[fileType]]: result.fileSystemUrl,
                    },
                })
            );
        } catch (e) {
            dispatch(error('Invalid file format. Please try again'));
            throw e;
        }

        return result;
    };

export const toggleSocialNetwork = (name, connected) => async dispatch => {
    if (connected) {
        dispatch(disconnectSocialNetwork(name));
    } else {
        const result = await Api.getOneTimeAuthToken();
        dispatch(connectSocialNetwork(name, result.oneTimeAuthToken));
    }
};

/**
 * Returns the roles that the user is allowed to assign to other users,
 * for example when editing a user or inviting a new user.
 *
 * @param  {String} userStatus restrict result with this user status
 * @param  {Boolean} ignoreAdminRestrictions ignore
 * @return {array} roles
 */
export const getRolesAllowedToAssignedToUsers = (userStatus = '', ignoreAdminRestrictions = false) => {
    const options = {};
    if (userStatus) {
        options.status = userStatus;
    } else if (ignoreAdminRestrictions) {
        options.ignoreAdminRestrictions = ignoreAdminRestrictions;
    }

    return Api.get(Api.route('partnermarketing_user_roles_allowed_to_assign', options));
};

export const toggleBasket = num => dispatch => {
    dispatch({
        type: ACTIONS.toggleBasket,
        num,
    });
};

export const trackUserActivity = properties => async () => {
    await Api.post(Api.route('partnermarketing_user_activity_track_api'), properties);
};

export const trackVersapayIncentivePopup = properties => async () => {
    await Api.post(Api.route('partnermarketing_user_track_versapay_incentive_popup'), properties);
};

export const updateSpecialisms = (id, data) => async dispatch => {
    try {
        await Api.put(Api.route('partnermarketing_user_update_specialisms_api', { userId: id }), data);
    } catch (e) {
        dispatch(error("Sorry, we're having trouble updating your specialisms."));
        throw e;
    }
};

export const trackUserEvent = properties => async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());

    await Api.post(Api.route('partnermarketing_tracking_add_partnermarketing_user_event'), {
        userId: currentUser ? currentUser.id : null,
        properties,
    });
};

export const checkNicknameUnique = nickname => async dispatch => {
    let result = false;
    try {
        result = await Api.get(
            Api.route('partnermarketing_user_check_nickname', {
                nickname,
            })
        );
    } catch (e) {
        dispatch(error("Can't check nickname."));
        throw e;
    }
    return result;
};

export const addUserImage = image => (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());

    const isExistingImage = currentUser.images.find(i => i.id === image.id);
    if (isExistingImage) {
        return;
    }

    dispatch(
        successUserByToken({
            ...currentUser,
            images: [...currentUser.images, image],
        })
    );
};

export const removeUserImage = image => async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());

    await Api.delete(Api.route('partnermarketing_user_library_remove_image', { imageId: image.id }));

    dispatch(
        successUserByToken({
            ...currentUser,
            images: currentUser.images.filter(({ id }) => id !== image.id),
        })
    );
};
