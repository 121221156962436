import React from 'react';
import cn from 'classnames';
import { t } from '../../utils';
import styles from './EmptySearch.module.scss';

const EmptySearch = ({ className }) => (
    <div className={cn(styles.filterEmpty, className)}>
        <p>
            <strong>{t('general.no_results.title')}</strong>
        </p>
        <p>
            <strong>{t('general.no_results.suggestions_bold')}</strong>
            <br />
            {t('general.no_results.suggestions_normal_weight')}
        </p>
    </div>
);

export default EmptySearch;
