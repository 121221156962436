export default {
    item: {
        setData: 'ACTIVITY_REQUEST_ITEM_SET_DATA',
        setLoading: 'ACTIVITY_REQUEST_ITEM_SET_LOADING',
    },
    list: {
        setData: 'ACTIVITY_REQUEST_LIST_SET_DATA',
        setTotal: 'ACTIVITY_REQUEST_LIST_SET_TOTAL',
        setLoading: 'ACTIVITY_REQUEST_LIST_SET_LOADING',
    },
    accountManager: {
        setData: 'ACTIVITY_REQUEST_ACCOUNT_MANAGER_DATA',
        setLoading: 'ACTIVITY_REQUEST_ACCOUNT_MANAGER_SET_LOADING',
    },
};
