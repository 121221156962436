/* eslint-disable react/no-danger */
import React from 'react';
import HtmlReactParser from 'html-react-parser';
import InfoBlock from 'components/InfoBlock/InfoBlock';
import styles from 'pages/campaignInstance/edit/Template.module.scss';
import { findChildren, renderChildren } from 'pages/campaignInstance/edit/helpers';
import EditableItem from 'pages/manage/Templates/Template/components/EditableArea/EditableItem';
import EditableAreas from 'pages/manage/Templates/Template/components/EditableArea/EditableAreas';
import EditableColour from 'pages/manage/Templates/Template/components/EditableArea/EditableColour';

export const transform = (node, index) => {
    if (node.type === 'tag') {
        if (
            node.attribs['pm-text'] ||
            node.attribs['pm-link'] ||
            node.attribs['pm-image'] ||
            node.attribs['pm-social-icons']
        ) {
            return <EditableItem node={node} transform={transform} key={index} />;
        }

        if (node.attribs.name === 'pm-colours' || node.attribs.name === 'pm-colour-picker') {
            return <EditableColour node={node} isPicker={node.attribs.name === 'pm-colour-picker'} key={index} />;
        }

        if (node.attribs['pm-area']) {
            return <EditableAreas node={node} transform={transform} key={index} />;
        }

        if (node.attribs?.id === 'editCoBrandedPdfEditor') {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
        }

        if (node.attribs?.class === 'form-hint') {
            return (
                <InfoBlock className={styles.formHint} key={index}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: renderChildren(
                                findChildren([node], el => {
                                    return el.type === 'tag' && el.attribs?.class === 'copy';
                                })
                            ),
                        }}
                    />
                </InfoBlock>
            );
        }
    }

    return undefined;
};

export const getTransformedHtml = html => {
    return HtmlReactParser(html, { replace: transform });
};
