import PropTypes from 'prop-types';
import { FilterEntry } from 'propTypes/campaign';
import { Tier } from 'propTypes/tier';
import { Region } from 'propTypes/region';

export const AssetLibraryType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string,
    workflow: PropTypes.string,
});

export const AssetLibraryAssetKeyword = PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.string,
});

export const AssetLibraryAsset = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    downloadsCount: PropTypes.number,
    hasFontRestrictions: PropTypes.bool,
    hasImageRestrictions: PropTypes.bool,
    hasPasswordProtection: PropTypes.bool,
    restrictionDetails: PropTypes.string,
    fileSystemUrl: PropTypes.string,
    fileSize: PropTypes.string,
    fileFormat: PropTypes.string,
    previewImageFileSystemUrl: PropTypes.string,
    type: AssetLibraryType,
    filterEntries: PropTypes.arrayOf(FilterEntry),
    tiers: PropTypes.arrayOf(Tier),
    regions: PropTypes.arrayOf(Region),
    keywords: PropTypes.arrayOf(AssetLibraryAssetKeyword),
});
