import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/Table/Formatters/FormatTooltipWrapper.module.scss';

export default function FormatTooltipWrapper({ value }) {
    return (
        <div className={styles.wrapper} data-tip={value} data-class={`react-tooltip ${styles.tip}`}>
            {value}
        </div>
    );
}

FormatTooltipWrapper.propTypes = {
    value: PropTypes.any,
};
