import moment from 'moment';

/**
 * Gets the hours and am/pm string for the given date
 *
 * @param {Date} date [The date to evaluate]
 * @returns {Object} [The hours and am/pm string]
 */
const getHours = date => {
    // The plus 24 is because JavaScript is terrible with negative
    // modulo arithmatic for some reason, so this forces the
    // pre-modulo result to always be above 0. Offsetting by the
    // modulo amount, 24, ensures that the end result is unaffected by
    // this.
    const hours = {
        hours: (24 + date.getUTCHours()) % 24,
        amPm: 'am',
    };

    if (hours.hours === 0) {
        hours.hours = 12;
    } else if (hours.hours > 12) {
        hours.hours -= 12;
        hours.amPm = 'pm';
    }

    return hours;
};

export default {
    /**
     * Set the minimum date for the date picker
     *
     * This is the current datetime, minus 12 hours so that other
     * timezones can be picked.
     * We've also subtracted the current timezone offset, to ensure that
     * this is valid UTC time
     *
     * @param {Date} now [If present, use this as now]
     * @returns {Object} date
     */
    getMinUTCDate: now => {
        const date = now ? new Date(now) : new Date();
        date.setMinutes(date.getMinutes() - (12 * 60 + date.getTimezoneOffset()));

        return date;
    },

    /**
     * Evaulate the the settings on the given template and return the
     * correct broadcast settings
     *
     * @param {Template} template [The template to evaulate]
     * @returns {Object} [The broadcast settings to be used]
     */
    broadcastSettingsFromTemplate: template => {
        // Now this is a story all about how Internet Explorer went and
        // flipped my life right upside down...
        //
        // ...IE11 fails hard at parsing RFC2822 dates for some reason:
        //   This is invalid apparently: 2013-12-27T15:27:34+0100
        //   Whereas this is valid:      2013-12-27T15:27:34+01:00
        //
        // According to my understanding the RFC, the first one is correct
        // so I'm not updating the backend to spit out garbage just for
        // IE's sake. Instead we'll just do a find and replace here for
        // a plus character followed by 4 digits and we'll insert the
        // colon before parsing
        const timestamp = template.broadcastDatetime.replace(/(\+|-)([0-9]{2})([0-9]{2})/, '$1$2:$3');
        const date = new Date(timestamp);

        // The utc offset in hours for the current timezone
        const utcOffset = template.broadcastTimezone.utcOffsetInHours;

        date.setUTCHours(date.getUTCHours() + utcOffset);
        const hours = getHours(date);

        return {
            timezone: template.broadcastTimezone,
            minutes: date.getMinutes(),
            hours: hours.hours,
            amPm: hours.amPm,
            date,
        };
    },

    /**
     * Gets the default broadcast settings to use
     *
     * @param {Date} _now [The current time]
     * @returns {Object} [The broadcast settings to be used]
     */
    defaultBroadcastSettings: (_now = null, original = false) => {
        const now = _now ? new Date(_now) : new Date();

        // Round current minutes to closest value in future:
        // 15, 30, 45, 60.
        let closestNextMinutes = 0;

        if (original) {
            closestNextMinutes = now.getUTCMinutes();
        } else {
            [15, 30, 45, 60].forEach(minutes => {
                const diff = minutes - now.getMinutes();
                if (!closestNextMinutes && diff >= 0) {
                    closestNextMinutes = minutes;
                }
            });
        }

        let hours = original ? now.getUTCHours() : now.getHours();
        if (closestNextMinutes === 60) {
            closestNextMinutes = 0;
            hours++;
        }

        return {
            date: now,
            hours: hours > 12 ? hours - 12 : hours,
            minutes: closestNextMinutes,
            amPm: now.getHours() >= 12 ? 'pm' : 'am',
        };
    },

    /**
     * Checks that the given user selected broadcast settings are
     * acceptable
     *
     * Mainly, that they are complete and that the time is in the
     * future. It will also infer the date to be used by angular for
     * displaying to the user, and the date to be sent to the API.
     *
     * @param {Object} settings [The broadcast settings to check]
     * @param {Date} _now [Optional. The current date]
     * @returns {Object|bool} [False if there was a problem]
     */
    checkDate: (settings, _now = null) => {
        const now = _now ? new Date(_now) : new Date();

        if (!settings.hours || !settings.timezone) {
            return false;
        }

        const date = new Date(settings.date.toISOString());
        // Setup necessary params.
        let { hours } = settings;

        if (settings.amPm === 'pm' && hours !== 12) {
            hours += 12;
        } else if (settings.amPm === 'am' && hours === 12) {
            hours = 0;
        }

        date.setUTCHours(hours - settings.timezone.utcOffsetInHours);
        date.setMinutes(settings.minutes);
        date.setSeconds(0);

        // Selected data is in the past!
        if (date <= now) {
            return false;
        }

        const displayedDate = new Date(date);
        displayedDate.setHours(displayedDate.getHours() + settings.timezone.utcOffsetInHours);

        return {
            displayedDate,
            dateForApi: date,
        };
    },

    getReportUTCDate: (days, format) => {
        const date = new Date();

        if (days) {
            date.setDate(date.getDate() + days);
        }

        return format ? moment(date).format('DD/MM/YY') : date;
    },
};
