import { combineReducers } from 'redux';
import ACTIONS from './constants';

const cartInitialState = {
    data: [],
    back: '',
};

const cartReducer = (state = cartInitialState, action) => {
    switch (action.type) {
        case ACTIONS.cart.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.cart.setBack: {
            return {
                ...state,
                back: action.back,
            };
        }

        default: {
            return state;
        }
    }
};

const dataInitialState = {
    data: {},
    loading: false,
};

const userReducer = (state = dataInitialState, action) => {
    switch (action.type) {
        case ACTIONS.user.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.user.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const leaderBoardInitialState = {
    data: [],
    loading: false,
};

const leaderBoardReducer = (state = leaderBoardInitialState, action) => {
    switch (action.type) {
        case ACTIONS.leaderBoard.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.leaderBoard.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    cart: cartReducer,
    user: userReducer,
    leaderBoard: leaderBoardReducer,
});
