import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ErrorMessage from 'components/Input/ErrorMessage';
import { getIn } from 'formik';
import isUndefined from 'lodash/isUndefined';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import DropdownList from './DropdownList';
import styles from './Input.module.css';
import ValidationIcon from './ValidationIcon';
import Textarea from './Textarea';
import RadioButtons from './RadioButtons';
import CheckboxArray from './CheckboxArray';
import FileUploader from './FileUploader';
import DatePicker from './DatePicker';
import MultiSelect from './MultiSelect';
import RichText from './RichText';
import Select from './Select';
import TimePicker from './TimePicker';
import IconStack from '../IconStack/IconStack';
import ColourPicker from './ColourPicker';
import ColourSwitch from './ColourSwitch';
import InputTooltipIcon from './InputTooltipIcon';
import Spaces from './Spaces';
import Icon from '../Icon/Icon';

const TYPE = {
    SELECT: 'select',
    TEXTAREA: 'textarea',
    RADIO: 'radio_buttons',
    CHECKBOX: 'checkbox',
    CHECKBOXARRAY: 'checkbox_array',
    FILE: 'file_uploader',
    DATE: 'date',
    MULTISELECT: 'multiselect',
    RICHTEXT: 'richtext',
    DROPDOWNLIST: 'dropdownlist',
    CUSTOM: 'custom',
    TIME: 'time',
    NUMBER: 'number',
    COLOUR: 'colour',
    COLOUR_SWITCH: 'colour_switch',
    SPACES: 'spaces',
};

const LABEL_WIDTH = {
    FULL: 'full',
    HALF: 'half',
};

const PADDING = {
    NONE: 'none',
    SMALL: 'small',
    REGULAR: 'regular',
};

const EXCLUDE_INPUT_CLASS = [
    TYPE.TIME,
    TYPE.RADIO,
    TYPE.CUSTOM,
    TYPE.RICHTEXT,
    TYPE.CHECKBOX,
    TYPE.COLOUR,
    TYPE.COLOUR_SWITCH,
    TYPE.SPACES,
];

const getFieldErrorByPath = (errors, fieldName) => {
    if (!fieldName) {
        return;
    }

    const splitRegexp = /\.|\[|\]/
    const keys = fieldName.split(splitRegexp).filter(el => el);
    const fieldNamePath = keys.join('.');

    let currentObj = errors;
    const errPath = keys.reduce((path, key) => {
        if (!currentObj || !currentObj[key]) {
            return path;
        }

        currentObj = (typeof currentObj[key] === 'object') ? currentObj[key] : undefined;
        const glue = !!currentObj ? '.' : '';
        return path + key  + glue;
    }, '');

    return fieldNamePath === errPath ? getIn(errors, fieldName) : undefined;
}

const Input = props => {
    const {
        innerRef,
        padding,
        hideValidation,
        hideValidationMessage,
        rootClassName,
        label,
        labelIcon,
        labelWidth,
        labelValue,
        className,
        type,
        tooltip,
        tooltipClass,
        tooltipPlace,
        tipIcon,
        tipClass,
        tipInnerClass,
        prefix,
        inlinePrefix,
        prefixClass,
        forceValidation,
        required, // just remove this value from rest.
        form: { touched, errors, initialValues, customErrors = {} }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        ...rest
    } = props;
    const field = rest.field || rest;
    rest.form = props.form;

    let input = null;

    if (!rest.id) {
        rest.id = `input_${field.name || rest.name}`;
    }

    const fieldError = field.name && (getIn(customErrors, field.name) || getFieldErrorByPath(errors, field.name));
    const initialValue = initialValues && getIn(initialValues, field.name);
    const fieldTouched =
        (!!getIn(touched, field.name) && (forceValidation || !isEqual(initialValue, field.value))) ||
        props.form.submitCount > 0;

    const inputClassName = cn(
        EXCLUDE_INPUT_CLASS.indexOf(type) === -1 && styles.input,
        rest.disabled && styles.inputDisabled,
        fieldTouched && fieldError && styles.inputInvalid,
        type === TYPE.DROPDOWNLIST && styles.dropdownList,
        className,
        (isUndefined(field.value) || field.value === '') && styles.inputEmpty
    );

    switch (type) {
        case TYPE.SPACES: {
            input = <Spaces {...field} {...rest} value={field.value || ''} className={inputClassName} />;
            break;
        }
        case TYPE.COLOUR: {
            input = <ColourPicker {...field} {...rest} value={field.value || ''} className={inputClassName} />;
            break;
        }
        case TYPE.COLOUR_SWITCH: {
            input = <ColourSwitch {...field} {...rest} value={field.value || ''} className={inputClassName} />;
            break;
        }
        case TYPE.SELECT: {
            input = <Select {...field} {...rest} className={inputClassName} />;
            break;
        }
        case TYPE.TEXTAREA:
            input = <Textarea {...field} {...rest} value={field.value || ''} className={inputClassName} />;
            break;
        case TYPE.RADIO:
            input = (
                <RadioButtons
                    {...field}
                    {...rest}
                    className={inputClassName}
                    tooltip={tooltip}
                    tipClass={tipClass}
                    tipIcon={tipIcon}
                    tipInnerClass={tipInnerClass}
                    value={field.value}
                />
            );
            break;
        case TYPE.CHECKBOXARRAY:
            input = <CheckboxArray {...field} {...rest} value={field.value} />;
            break;
        case TYPE.FILE:
            input = (
                <FileUploader
                    {...field}
                    {...rest}
                    form={props.form}
                    value={field.value || ''}
                    className={inputClassName}
                />
            );
            break;
        case TYPE.DATE:
            input = <DatePicker {...field} {...rest} className={inputClassName} />;
            break;
        case TYPE.TIME:
            input = <TimePicker {...field} {...rest} className={inputClassName} />;
            break;
        case TYPE.MULTISELECT:
            input = <MultiSelect {...field} {...rest} className={inputClassName} />;
            break;
        case TYPE.RICHTEXT:
            input = <RichText {...field} {...rest} className={inputClassName} />;
            break;
        case TYPE.DROPDOWNLIST:
            input = <DropdownList {...field} {...rest} className={inputClassName} />;
            break;
        case TYPE.CUSTOM: {
            if (!rest.renderer) {
                throw new Error('renderer is required prop for custom type.');
            }
            const CustomComponent = rest.renderer;
            input = <CustomComponent {...field} {...rest} fieldError={fieldError} className={inputClassName} />;
            break;
        }
        default: {
            const { form, ...other } = rest;
            input = (
                <input
                    type={type || 'text'}
                    {...field}
                    {...other}
                    onChange={e => {
                        if (field.onChange) {
                            field.onChange(e);
                        }
                        if (rest.onChange) {
                            rest.onChange(e);
                        }
                    }}
                    value={field.value || ''}
                    className={inputClassName}
                    ref={innerRef}
                />
            );
        }
    }

    const labelElement = !!label && !labelValue && (
        <label htmlFor={rest.id} className={cn(styles.label, labelWidth && styles[`labelWidth${labelWidth}`])}>
            {!!labelIcon && <Icon kind={labelIcon} dark className={styles.labelIcon} />}
            {label}
            {!!tooltip && (
                <InputTooltipIcon
                    tooltip={tooltip}
                    tooltipClass={tooltipClass}
                    tooltipPlace={tooltipPlace}
                    tipClass={tipClass}
                    tipIcon={tipIcon}
                    tipInnerClass={tipInnerClass}
                />
            )}
        </label>
    );

    const labelValueElement = !!labelValue && (
        <label htmlFor={rest.id} className={cn(styles.label, labelWidth && styles[`labelWidth${labelWidth}`])}>
            {label}
            <span className={styles.fieldValue}>{labelValue}</span>
            {!!tooltip && (
                <span data-tip={tooltip} className={cn('client-secondary-colour', styles.tipInfo)}>
                    <IconStack size="05" outer="bg-circle" inner="info" innerClassName={styles.tipInfoIcon} />
                </span>
            )}
        </label>
    );

    return (
        <div
            className={cn(
                styles.inputRoot,
                labelWidth === LABEL_WIDTH.FULL && styles.inputRootFullLabel,
                labelWidth === LABEL_WIDTH.HALF && styles.inputRootHalfLabel,
                padding && styles[`padding${padding}`],
                rootClassName
            )}
        >
            {type !== TYPE.CHECKBOX && (labelElement || labelValueElement)}
            <div className={cn(styles.inputWrapper, hideValidation && styles.noValidate)}>
                {!hideValidation && <ValidationIcon {...props} {...rest} {...{ fieldError, fieldTouched }} />}
                <div
                    className={cn(
                        type === TYPE.CHECKBOX && styles.checkboxWrapper,
                        inlinePrefix && styles.prefixWrapper
                    )}
                >
                    {!!prefix && <span className={cn(prefixClass, styles.prefix)}>{prefix}</span>}
                    {input}
                    {type === TYPE.CHECKBOX && (labelElement || labelValueElement)}
                </div>
                {!hideValidationMessage && fieldTouched && isString(fieldError) && fieldError && (
                    <ErrorMessage message={fieldError} />
                )}
            </div>
        </div>
    );
};

Input.defaultProps = {
    form: {},
    tooltipPlace: 'top',
};

Input.propTypes = {
    padding: PropTypes.string,
    hideValidation: PropTypes.bool,
    hideValidationMessage: PropTypes.bool,
    rootClassName: PropTypes.string,
    label: PropTypes.node,
    labelIcon: PropTypes.string,
    labelWidth: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipClass: PropTypes.string,
    tooltipPlace: PropTypes.string,
    tipIcon: PropTypes.string,
    tipClass: PropTypes.string,
    tipInnerClass: PropTypes.string,
    prefix: PropTypes.node,
    inlinePrefix: PropTypes.bool,
    prefixClass: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    form: PropTypes.shape({ touched: PropTypes.object, errors: PropTypes.object }).isRequired,
    horizontal: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.any })),
    field: PropTypes.shape({ value: PropTypes.any, onChange: PropTypes.func }),
};

Input.TYPE = TYPE;
Input.LABEL_WIDTH = LABEL_WIDTH;
Input.PADDING = PADDING;

export default Input;
