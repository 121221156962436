import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DropDownMenu from './DropDownMenu';
import styles from './DropDown.module.css';
import Icon from '../Icon/Icon';
import history from '../../history';

function DropDown({
    items,
    isWide,
    withArrow,
    withShadow,
    children,
    className,
    noIcon,
    menuChildren,
    menuClassName,
    placeholderClassName,
}) {
    const [isMenuVisible, setMenuVisible] = useState(false);
    const showMenu = () => setMenuVisible(true);
    const hideMenu = () => setTimeout(() => setMenuVisible(false));
    useEffect(() => history.listen(() => hideMenu()), []);

    return (
        <div className={cn(styles.root, className)}>
            <div
                className={cn(
                    styles.info,
                    placeholderClassName,
                    withArrow && isMenuVisible ? styles.current : '',
                    'client-navigation-border-bottom-colour'
                )}
                onClick={showMenu}
                role="presentation"
            >
                {children}
                {!noIcon && <Icon kind={isMenuVisible ? 'arrow-up' : 'arrow-down'} className={styles.arrow} />}
            </div>
            <DropDownMenu
                show={isMenuVisible}
                onClickOutside={hideMenu}
                items={items}
                className={cn(isWide && styles.wideMenu, withShadow && styles.withShadow, menuClassName)}
            >
                {menuChildren}
            </DropDownMenu>
        </div>
    );
}

DropDown.propTypes = {
    /**
     * array of items to be rendered in DropDownMenu. Format: {label: string, to: internal link, onClick: func }
     */
    items: PropTypes.array,
    /**
     * Content of title position
     */
    children: PropTypes.any.isRequired,
    /**
     * Hide icon when true
     */
    noIcon: PropTypes.bool,
    /**
     * Wide dropdown when true
     */
    isWide: PropTypes.bool,
    /**
     * Add an arrow down to dropdown menu
     */
    withArrow: PropTypes.bool,
    /**
     * Add shadow to dropdown menu
     */
    withShadow: PropTypes.bool,
    /**
     * Add class name to component root
     */
    className: PropTypes.string,
    /**
     * Add class name to title wrapper
     */
    placeholderClassName: PropTypes.string,
    /**
     * Add class name to menu wrapper
     */
    menuClassName: PropTypes.string,
    /**
     * Custom menu content
     */
    menuChildren: PropTypes.any,
};

export default DropDown;
