import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import useFlag from '../../hooks/useFlag';
import { pluralize, t } from '../../utils';
import styles from './Filters.module.scss';

const ResultsCount = ({ count, search, loading }) => {
    const [visible, show, hide] = useFlag(false);

    useEffect(() => {
        hide();
    }, [search, hide]);

    useEffect(() => {
        if (!loading) {
            show();
        }
    }, [count, loading, show]);

    if (!visible || !search || isUndefined(count)) {
        return null;
    }

    const pluralResults = pluralize(
        count,
        t('campaign.campaign_index.search.result_for'),
        t('campaign.campaign_index.search.results_for'),
    );

    return <div className={styles.resultsCount}>{`${count} ${pluralResults} ‘${search}’`}</div>;
};

ResultsCount.propTypes = {
    /**
     * Search data loading indicator
     */
    loading: PropTypes.bool.isRequired,
    /**
     * Search results count
     */
    count: PropTypes.number,
    /**
     * Search field value
     */
    search: PropTypes.string,
};

export default ResultsCount;
