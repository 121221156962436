import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './ModalHeader.module.css';
import IconStack from '../IconStack/IconStack';
import Icon from '../Icon/Icon';

const ICON = {
    WARNING: 'warning',
    INFO: 'info',
    BASKET: 'basket',
};

const ModalHeader = (props) => {
    const { children, icon, className, ...other } = props;
    let iconElement;
    switch (icon) {
    case ICON.WARNING:
        iconElement = (
            <IconStack
                size="2"
                outer="bg-triangle"
                inner="exclamation"
                className={styles.iconWarning}
            />
        );
        break;
    case ICON.INFO:
        iconElement = (
            <IconStack
                size="2"
                outer="bg-circle"
                inner="info"
                className={cn('client-main-colour')}
            />
        );
        break;
        case ICON.BASKET:
        iconElement = (
            <IconStack
                size="2"
                inner="basket"
            />
        );
        break;
    default:
        iconElement = icon ? <Icon kind={icon} /> : null;
    }

    return (
        <div
            className={cn(styles.wrapper, !children && !icon && 'no-title', className)}
            {...other}
        >
            <div className={styles.row}>
                {!!iconElement && <span className={styles.icon}>{iconElement}</span>}
                <div className={styles.title}>{children}</div>
            </div>
        </div>
    );
};

ModalHeader.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func,
    icon: PropTypes.string,
    className: PropTypes.string,
};

ModalHeader.ICON = ICON;

export default ModalHeader;
