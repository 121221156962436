import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import styles from './IconStack.module.css';

/**
 * Render combination of icons. May have different size.
 */
const IconStack = ({ outer, inner, size, className, innerClassName, outerClassName, outerDark, tooltip }) => (
    <span
        className={cn(styles.stack, styles[`x${size}`], className)}
        data-tip={tooltip}
        data-class="react-tooltip small"
    >
        <Icon
            kind={outer}
            className={cn(styles.outer, styles[`x${size}`], styles[`outer-${outer}`], outerClassName)}
            dark={outerDark}
        />
        <Icon kind={inner} className={cn(styles.inner, innerClassName)} />
    </span>
);

IconStack.propTypes = {
    /**
     * Outer icon. Use "kind" from Icon component.
     */
    outer: PropTypes.string.isRequired,
    /**
     * Inner icon. Use "kind" from Icon component.
     */
    inner: PropTypes.string.isRequired,
    /**
     * Size of the icon. Could be 05, 1, 2, 3, 4, 5.
     */
    size: PropTypes.string.isRequired,
    /**
     * Custom class name for component root element.
     */
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    /**
     * Flag to render dark outer icon.
     */
    outerDark: PropTypes.bool,
    /**
     * Custom class name for inner icon. Can be used to adjust position of inner icon.
     */
    innerClassName: PropTypes.string,
    /**
     * Custom class name for outer icon. Can be used to adjust position of inner icon.
     */
    outerClassName: PropTypes.string,
    /**
     * Tip to show on icon hover
     */
    tooltip: PropTypes.string,
};

export default IconStack;
