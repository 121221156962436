import Request from './Request';

class Api {
    _token = null;

    async sendDefaultRequest(method, url, data, params) {
        const request = new Request(method, url, data);
        request.token = this.token;
        request.setHeader('Content-Type', 'application/json');
        try {
            let response;
            if (params === true) {
                response = await request.executeCancelable();
            } else {
                response = await request.execute();
            }
            return response;
        } catch (err) {
            // if (err.status === 401 && response.url.indexOf(`/${API.logout}`) < 0) {
            //     logout(ERRORS.user.sessionExpired);
            // }
            throw err;
        }
    }

    route(slug, params = {}) {
        return window.Routing.generate(slug, params);
    }

    async get(url, data, params = {}) {
        return await this.sendDefaultRequest('get', url, data, params);
    }

    async post(url, data, params) {
        return this.sendDefaultRequest('post', url, data, params);
    }

    async put(url, data, params) {
        return this.sendDefaultRequest('put', url, data, params);
    }

    async patch(url, data, params) {
        return this.sendDefaultRequest('patch', url, data, params);
    }

    async delete(url, data, params) {
        return this.sendDefaultRequest('delete', url, data, params);
    }

    async upload(url, file, params = {}) {
        const data = new FormData();
        if (file instanceof File) {
            data.append(params.uploadParamName || 'file', file);
        } else {
            Object.keys(file).forEach(prop => {
                if (file[prop] instanceof File) {
                    data.append(`${prop}[${params.uploadParamName || 'file'}]`, file[prop]);
                } else {
                    data.append(prop, file[prop]);
                }
            });
        }

        const request = new Request(params.method || 'post', url);
        request.params.body = data;
        request.token = this.token;

        try {
            return await request.execute();
        } catch (err) {
            throw err;
        }
    }

    set token(token) {
        this._token = token;
    }

    get token() {
        return this._token;
    }

    getOneTimeAuthToken() {
        return this.get(this.route('partnermarketing_user_get_one_time_auth_token'));
    }

    async oneTimeAuthRedirect(slug, slugParams = {}) {
        const token = await this.getOneTimeAuthToken();
        window.location = `${this.route(slug, slugParams)}?${token.urlQueryString}`;
    }
}

export default new Api();
