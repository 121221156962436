import { combineReducers } from 'redux';
import { t } from 'utils';
import moment from 'moment';
import { FORMAT } from 'constants.js';
import { SALES_FORCE_OPPORTUNITY_UNKNOWN, CLOSED_STATUSES, CLOSED_STATUSES_MAP } from '../opportunityConstants.js';
import ACTIONS from './constants';

const listInitialState = {
    data: {
        counts: {
            open: 0,
            'closed-won': 0,
            'closed-lost': 0,
        },
        list: [],
    },
    loading: false,
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            const countsList = action.data.data.counts;
            const openCounts = Object.keys(countsList)
                .map(status => ({
                    status,
                    count: countsList[status],
                }))
                .reduce((prevValue, currItem) => {
                    const currValue = CLOSED_STATUSES.indexOf(currItem.status) === -1 ? currItem.count : 0;
                    return prevValue + currValue;
                }, 0);

            const filtersList = { ...countsList };
            delete filtersList['SALES_FORCE_OPPORTUNITY_CLOSED_WON'];
            delete filtersList['SALES_FORCE_OPPORTUNITY_CLOSED_LOST'];

            return {
                ...state,
                data: {
                    counts: {
                        open: openCounts,
                        'closed-won': !!countsList[CLOSED_STATUSES_MAP['closed-won']]
                            ? countsList[CLOSED_STATUSES_MAP['closed-won']]
                            : 0,
                        'closed-lost': !!countsList[CLOSED_STATUSES_MAP['closed-lost']]
                            ? countsList[CLOSED_STATUSES_MAP['closed-lost']]
                            : 0,
                    },
                    filters: Object.keys(filtersList),
                    list: action.data.data.opportunities,
                },
            };
        }

        default: {
            return state;
        }
    }
};

const itemInitialState = {
    data: {
        contact: {
            company: null,
            first_name: '',
            last_name: '',
            email: '',
            address: {
                first_line: '',
                second_line: '',
                city: '',
                postcode: '',
                county: '',
            },
        },
        details: {
            created_at: '',
            created_by: '',
            updated_at: '',
            updatged_by: '',
            owner: {
                name: '',
                email: '',
                phone: '',
            },
            status: SALES_FORCE_OPPORTUNITY_UNKNOWN,
        },
        lead_preferences: {
            erp_software: '',
            solutions_prefference: '',
            referral_reference: '',
            referral_partner_relationship: '',
            additional_info: null,
        },
        activities: [],
    },
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            const data = action.data.data.opportunity;
            return {
                ...state,
                data: {
                    contactInfo: [
                        {
                            label: t('opportunity.prospect.company_name'),
                            value: data.contact.company.name,
                        },
                        {
                            label: t('opportunity.prospect.first_name'),
                            value: data.contact.first_name,
                        },
                        {
                            label: t('opportunity.prospect.last_name'),
                            value: data.contact.last_name,
                        },
                        {
                            label: t('opportunity.prospect.email'),
                            value: data.contact.email,
                        },
                        {
                            label: t('opportunity.prospect.phone'),
                            value: data.contact.phone,
                        },
                        {
                            label: t('opportunity.prospect.address'),
                            value: Object.values(data.contact.address)
                                .filter(item => !!item)
                                .join(', '),
                        },
                    ],
                    leadInfo: [
                        {
                            label: t('opportunity.lead_preferences.erp_software'),
                            value: data.lead_preferences.erp_software,
                        },
                        {
                            label: t('opportunity.lead_preferences.solutions_prefference'),
                            value: data.lead_preferences.solutions_prefference,
                        },
                        {
                            label: t('opportunity.lead_preferences.referral_reference'),
                            value: data.lead_preferences.referral_reference,
                        },
                        {
                            label: t('opportunity.lead_preferences.referral_partner_relationship'),
                            value: data.lead_preferences.referral_partner_relationship,
                        },
                        {
                            label: t('opportunity.lead_preferences.additional_info'),
                            value: data.lead_preferences.additional_info,
                        },
                    ],
                    createdBy: [
                        moment(data.details.created_at).format(FORMAT.DATE_TIME_US),
                        data.details.created_by
                            ? t('general.labels.by_username', { username: data.details.created_by })
                            : '',
                    ],
                    updatedBy: [
                        moment(data.details.updated_at).format(FORMAT.DATE_TIME_US),
                        data.details.updated_by
                            ? t('general.labels.by_username', { username: data.details.updated_by })
                            : '',
                    ],
                    assignedTo: [data.details.owner.name, data.details.owner.email, data.details.owner.phone],
                    status: [t('sf.status.' + data.details.status.toLowerCase())],
                    ...data,
                },
            };
        }

        case ACTIONS.item.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    list: listReducer,
    item: itemReducer,
});
