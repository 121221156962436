import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { TEMPLATE_COLOUR_TYPE } from 'constants.js';

const DEFAULT_COLOUR = '#000000';
const SCOPE = {
    GLOBAL: 'global',
    NORMAL: 'normal',
};
const GLOBAL_PREFIX = 'global_';

const EditableColour = ({ node, isPicker }) => {
    /**
     * This is a logic duplication from the API PmColourParser to support old templates.
     * All the newly created templates should have colours prefilled.
     */
    const [{ value: colours }, , { setValue: setColours }] = useField('data.colours');
    const [{ value: templateColours }, , { setValue: setTemplateColours }] = useField('templateColours');
    const { group, content, default: defaultValue = DEFAULT_COLOUR } = node.attribs;

    const scope = group.indexOf(GLOBAL_PREFIX) === 0 ? SCOPE.GLOBAL : SCOPE.NORMAL;
    const name = scope === SCOPE.GLOBAL ? group.substring(7) : group;
    const dataKeyIndex = colours?.findIndex(c => c.name === name);
    useEffect(() => {
        if (dataKeyIndex === -1 && name) {
            const options = content ? content.split(',').map(v => v.trim()) : [];
            const value = (isPicker ? '' : options[0]) || defaultValue.trim();
            setColours([
                ...colours,
                {
                    isPicker,
                    name,
                    value,
                },
            ]);
            setTemplateColours([
                ...templateColours,
                {
                    name,
                    scope,
                    options: options.map(v => ({ value: v })),
                    type: isPicker ? TEMPLATE_COLOUR_TYPE.PICKER : TEMPLATE_COLOUR_TYPE.SWATCH,
                },
            ]);
        }
    }, [
        dataKeyIndex,
        name,
        scope,
        content,
        isPicker,
        defaultValue,
        colours,
        setColours,
        templateColours,
        setTemplateColours,
    ]);

    return null;
};

EditableColour.propTypes = {
    node: PropTypes.object.isRequired,
    isPicker: PropTypes.bool.isRequired,
};

export default EditableColour;
