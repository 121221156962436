import React, { FC } from 'react';
import cn from 'classnames';
import styles from 'components/Input/Input.module.css';

type ErrorMessageProps = {
    message: string;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => (
    <div className={cn(styles.error, styles.errorBelow, 'error-message')}>{message}</div>
);

export default ErrorMessage;
