import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/PreFilters/MainFilterGroup.module.scss';

const MainFilterGroup = ({ group, onSelect }) => (
    <div className={styles.wrapper} onClick={() => onSelect(group.id)}>
        {!!group.imageUrl && (
            <div className={styles.imgWrapper}>
                <img src={group.imageUrl} alt={group.name} />
            </div>
        )}
        <div className={styles.title}>{group.name}</div>
        {!!group.description && <div className={styles.description}>{group.description}</div>}
    </div>
);

MainFilterGroup.propTypes = {
    onSelect: PropTypes.func.isRequired,
    group: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        imageUrl: PropTypes.string,
        description: PropTypes.string,
    }),
};

export default MainFilterGroup;
