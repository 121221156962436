import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { client, t } from '../../utils';
import { getContactUsLink, getKnowledgeBaseLink, getPhoneNumber } from '../../selectors/userSelectors';
import layoutContext from '../../context/layoutContext';

import styles from './Footer.module.css';

/**
 * Global footer
 */
const Footer = ({ helpdeskBase, helpdeskLink, helpdeskPhone }) => {
    const noFooterMargin = false;
    const { extended } = useContext(layoutContext);
    return (
        <div id="pmContent">
            <footer
                className={cn(
                    'client-navigation-border-bottom-colour',
                    noFooterMargin && 'no-margin',
                    extended && styles.hidden,
                    styles.wrapper
                )}
            >
                <div className="container">
                    <div id="clientFooter" className="clearfix">
                        <div className={styles.pullRight}>
                            {client.getSubdomainSlug() == 'versapartner' && (
                                <ul className={styles.list}>
                                    <li class="last">
                                        <a
                                            href={t('base._client_logged_in_footer.versapartner_tc.link')}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t('base._client_logged_in_footer.versapartner_tc.title')}
                                        </a>
                                    </li>
                                </ul>
                            )}

                            <h4 className={styles.h4}>{t('base._client_logged_in_footer.team_here_to_help')}</h4>

                            <ul className={styles.list}>
                                <li>
                                    <a href={helpdeskBase} target="_blank" rel="noopener noreferrer">
                                        {t('base._client_logged_in_footer.knowledge_base')}
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href={helpdeskLink}>
                                        {t('base._client_logged_in_footer.contact_us')}
                                    </a>
                                </li>
                                {!!helpdeskPhone && (
                                    <li>
                                        <span>{helpdeskPhone}</span>
                                    </li>
                                )}
                            </ul>

                            <ul className={styles.list}>
                                <li>
                                    <a href={client.getTermsUrl()} target="_blank" rel="noopener noreferrer">
                                        {t('base._client_logged_in_footer.terms_of_use')}
                                    </a>
                                </li>
                                <li className="last">
                                    <a
                                        href={t('base._client_logged_in_footer.privacy_policy_link')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t('base._client_logged_in_footer.privacy_policy')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p>
                        {t('base._client_logged_in_footer.powered_by', {
                            partnermarketing: (
                                <span key="logo" className={styles.logo}>
                                    partnermarketing.com
                                </span>
                            ),
                        })}
                    </p>
                </div>
            </footer>
        </div>
    );
};

Footer.propTypes = {
    helpdeskBase: PropTypes.string,
    helpdeskLink: PropTypes.string,
    helpdeskPhone: PropTypes.string,
};

const mapStateToProps = state => ({
    helpdeskBase: getKnowledgeBaseLink(state),
    helpdeskLink: getContactUsLink(state),
    helpdeskPhone: getPhoneNumber(state),
});

export default connect(mapStateToProps)(Footer);
