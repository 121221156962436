import { combineReducers } from 'redux';
import filter from 'lodash/filter';
import ACTIONS from './constants';

const listInitialState = {
    data: {
        counts: {},
        result: [],
        total: 0,
    },
    loading: false,
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                ...state,
                data: {
                    ...action.data,
                    result: action.data.result.map(item => ({
                        ...item,
                        tiers: filter(item.tiers, tier => tier.isPermission === false),
                    })),
                },
            };
        }

        default: {
            return state;
        }
    }
};

const filtersInitialState = {
    data: {},
    loading: false,
};

const filtersReducer = (state = filtersInitialState, action) => {
    switch (action.type) {
        case ACTIONS.filters.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.filters.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const statusesInitialState = {
    data: [],
    loading: false,
};

const statusesReducer = (state = statusesInitialState, action) => {
    switch (action.type) {
        case ACTIONS.statuses.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.statuses.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const itemInitialState = {
    data: {
        regions: [],
        tiers: [],
        permissions: [],
    },
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.item.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    list: listReducer,
    filters: filtersReducer,
    statuses: statusesReducer,
    item: itemReducer,
});
