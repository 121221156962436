import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Input.module.css';

const getValues = value => {
    const parts = (value || '').split(' ');
    return parts.length < 4 ? [0, 0, 0, 0] : parts;
};

const Spaces = ({ onChange, value, name, className, disabled, isPadding }) => {
    const [values, setValues] = useState(getValues(value));

    useEffect(() => {
        setValues(getValues(value));
    }, [value, setValues]);

    const onSpaceChange = useCallback(
        index => e => {
            const v = [...values];
            v[index] = e.target.value.replace(/\s/g, '');

            onChange({ target: { name, value: v.join(' ') } });
        },
        [onChange, name, values]
    );

    return (
        <div className={cn(styles.spaces, className)}>
            {['T', 'R', 'B', 'L'].map((part, index) => (
                <span key={part} className={cn(styles.space, isPadding && styles.paddingSpace, styles[`space${part}`])}>
                    <span>{part}</span>
                    <input
                        type="text"
                        disabled={disabled}
                        className={styles.input}
                        value={values[index]}
                        onChange={onSpaceChange(index)}
                    />
                </span>
            ))}
        </div>
    );
};

Spaces.propTypes = {
    /**
     * Class name.
     */
    className: PropTypes.string,
    /**
     * Field name.
     */
    name: PropTypes.string.isRequired,
    /**
     * Field value. Should be string.
     */
    value: PropTypes.string,
    /**
     * Gets called when value was changed.
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Is padding spacing.
     */
    isPadding: PropTypes.bool,
};

export default Spaces;
