import noop from 'lodash/noop';
import { createContext, Dispatch, SetStateAction } from 'react';

export type ActiveDataContext<T> = {
    activeData: T | null;
    disableRowDrop?: boolean;
    setActiveData: Dispatch<SetStateAction<T | null>>;
};

const activeDataContext = createContext<ActiveDataContext<any>>({
    activeData: null,
    disableRowDrop: false,
    setActiveData: noop,
});

export default activeDataContext;
