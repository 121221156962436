import ModalPopup from './ModalPopup';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import ModalActions from './ModalActions';

export default {
    Popup: ModalPopup,
    Header: ModalHeader,
    Content: ModalContent,
    Actions: ModalActions,
};
