import ACTIONS from './constants';

const initialState = {
    upsert: {
        data: {},
        loading: true,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.upsert.load: {
            return {
                ...state,
                upsert: {
                    ...state.upsert,
                    loading: true,
                },
            };
        }

        case ACTIONS.upsert.success: {
            return {
                ...state,
                upsert: {
                    data: action.data,
                    loading: false,
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default reducer;
