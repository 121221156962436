import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './Switcher.module.scss';
import Icon from '../Icon/Icon';

const Switcher = ({ items, className, compact }) => (
    <div className={cn(styles.wrapper, compact && styles.compact, className)}>
        {items.map(({ link, label, isActive, onClick, icon }, index) => {
            const iconElement = icon ? <Icon kind={icon} className={styles.icon} /> : null;
            if (link) {
                return (
                    <NavLink
                        key={index}
                        to={link}
                        className={styles.item}
                        activeClassName={styles.itemActive}
                        isActive={isActive}
                        exact
                    >
                        {iconElement}
                        {label}
                    </NavLink>
                );
            }
            return (
                <button
                    type="button"
                    onClick={onClick}
                    key={index}
                    className={cn(styles.item, isActive && styles.itemActive)}
                >
                    {iconElement}
                    {label}
                </button>
            );
        })}
    </div>
);

Switcher.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string,
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func,
            isActive: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
            icon: PropTypes.string,
        })
    ).isRequired,
    className: PropTypes.string,
    compact: PropTypes.bool,
};

export default Switcher;
