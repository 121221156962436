import usePagesOptions from '@TB/pages/templates/hooks/usePagesOptions';
import Input from 'components/Input/Input';
import { Field } from 'formik';
import React from 'react';
import { t } from 'utils';

type LinkTypePageProps = {
    fields: { urlTypeField: string };
    changeUrlType: (e: object) => void;
};

export default function LinkTypePage({ fields, changeUrlType }: LinkTypePageProps): JSX.Element {
    const pagesOptions = usePagesOptions();
    return (
        <Field
            component={Input}
            type={Input.TYPE.SELECT}
            placeholder={t('general.labels.please_select')}
            name={fields.urlTypeField}
            required
            padding={Input.PADDING.SMALL}
            onChange={changeUrlType}
            data={pagesOptions}
        />
    );
}
