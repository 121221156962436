import Button from 'components/Button/Button';
import ButtonMoreItem from 'components/ButtonMore/ButtonMoreItem';
import DropDown from 'components/DropDown/DropDown';
import React, { ReactNode } from 'react';
import styles from './ButtonMore.module.scss';

type ButtonMoreItem = {
    onClick?: () => void;
    to?: string;
    label: ReactNode;
    icon?: string;
};

type ButtonMoreProps = {
    tiny?: boolean;
    items: (boolean | ButtonMoreItem)[];
};
export default function ButtonMore({ items, tiny }: ButtonMoreProps): JSX.Element {
    const actualItems = (items.filter(Boolean) as ButtonMoreItem[]).map(({ icon, label, ...rest }) => ({
        ...rest,
        label: <ButtonMoreItem icon={icon} label={label} />,
    }));
    return (
        <div className={styles.wrapper}>
            <DropDown items={actualItems} noIcon withShadow menuClassName={styles.menu}>
                <Button color={Button.COLOR.GRAY} tiny={tiny} bordered className={styles.button} icon="more" />
            </DropDown>
        </div>
    );
}
