import { useField } from 'formik';

/**
 * Uses data field.
 *
 * @param {int} areaId
 * @return [Object, Object[]]
 */
const useDataArea = areaId => {
    const [{ value }] = useField('data.areas');

    if (!areaId || !value) {
        return [null, []];
    }

    const area = value.filter(({ id }) => id === areaId).pop();
    // Filter by areaId is for campaign instances, by id -- for campaigns
    const areas = value.filter(a => (area?.areaId ? a.areaId === area.areaId : a.id === areaId));

    areas.sort((a, b) => {
        if (a.position === b.position) {
            return 0;
        }
        return a.position > b.position ? 1 : -1;
    });

    return [area, areas];
};

export default useDataArea;
