import { combineReducers } from 'redux';
import ACTIONS from './constants';

const itemInitialState = {
    data: {},
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                data: action.data,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const listInitialState = {
    data: [],
    counts: {
        active: 0,
        archived: 0,
    },
    loading: true,
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                data: action.append ? [...state.data, ...action.data] : action.data,
                counts: action.counts || state.counts,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const typesInitialState = {
    data: [],
    loading: false,
};

const typesReducer = (state = typesInitialState, action) => {
    switch (action.type) {
        case ACTIONS.types.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.types.setData: {
            return {
                data: action.data,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const coBrandedAssetInitialState = {
    loading: false,
};

const coBrandedAssetReducer = (state = coBrandedAssetInitialState, action) => {
    switch (action.type) {
        case ACTIONS.coBrandedAsset.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        default: {
            return state;
        }
    }
};

const coBrandedAssetLinkInitialState = {
    data: { asset: {} },
    loading: false,
};

const coBrandedAssetLinkReducer = (state = coBrandedAssetLinkInitialState, action) => {
    switch (action.type) {
        case ACTIONS.coBrandedAssetLink.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.coBrandedAssetLink.setData: {
            return {
                data: action.data,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    item: itemReducer,
    list: listReducer,
    types: typesReducer,
    coBrandedAsset: coBrandedAssetReducer,
    coBrandedAssetLink: coBrandedAssetLinkReducer,
});
