import cn from 'classnames';
import styles from 'components/Alert/Alert.module.css';
import Icon from 'components/Icon/Icon';
import IconStack from 'components/IconStack/IconStack';
import React, { JSX, ReactNode } from 'react';

type AlertLineKind = 'success' | 'error' | 'notify';

const ICON_MAP: Record<AlertLineKind, string> = {
    success: 'tick',
    error: 'cross',
    notify: 'info',
};

type AlertLineProps = {
    kind: AlertLineKind;
    message: ReactNode;
    className?: string;
    onRemove?: VoidFunction;
    size?: string;
};
export default function AlertLine({ kind, message, onRemove, className, size = '2' }: AlertLineProps): JSX.Element {
    return (
        <div className={cn('client-secondary-colour-background', styles.alert, styles[kind], className)}>
            <div className={styles.wrapper}>
                <IconStack outer="bg-circle" inner={ICON_MAP[kind]} size={size} />
                <p className={styles.message}>{message}</p>
            </div>
            {!!onRemove && (
                <span className={styles.right} onClick={onRemove} role="presentation">
                    <Icon kind="cross" />
                </span>
            )}
        </div>
    );
}
