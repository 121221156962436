import isUndefined from 'lodash/isUndefined';
import React, { useState, useCallback, useEffect, FC, ReactNode } from 'react';
import cn from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import Button from 'components/Button/Button';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import Page from 'components/Page/Page';
import SearchField from 'components/SearchField/SearchField';
import { getSearchParams, t } from 'utils';
import MainFilterGroup from './MainFilterGroup';
import styles from './PreFilters.module.scss';

export type Group = {
    id: number;
    name: string;
    imageUrl?: string;
    groupId?: number;
    entries?: Group[];
};

type PreFiltersProps = {
    actions?: object[];
    children?: ReactNode;
    groups: Group[];
    loading: boolean;
    onSearch(search: string): void;
    onSelectEntry(id: number): void;
    onSelectType?(id: number): void;
    showBreadcrumbs?: boolean;
    subFilterText?: string;
    text: string;
    title: string;
    buttonStyle?: string;
};

type FilterLocation = {
    search?: { activeGroupId?: string };
};

const PreFilters: FC<PreFiltersProps> = ({
    actions = [],
    loading,
    groups,
    children,
    onSearch,
    onSelectEntry,
    onSelectType,
    title,
    text,
    showBreadcrumbs,
    subFilterText,
    buttonStyle,
}) => {
    const [selection, setSelection] = useState<(number | null)[]>([null]);
    const [description, setDescription] = useState<string | undefined>(text);
    const [breadcrumbsVisible, setBreadcrumbsVisible] = useState(false);
    const { pathname, state: { search: { activeGroupId: searchGroupId } = {} } = {} } = useLocation<FilterLocation>();
    const [search, setSearch] = useState('');
    // const { loading, response } = useApi({ slug: filtersSlug });
    const { activeGroupId: queryGroupId } = getSearchParams();
    const history = useHistory();

    const finalGroupId = parseInt(searchGroupId || queryGroupId, 10);
    useEffect(() => {
        if (finalGroupId) {
            const active = groups?.filter(({ id }) => id === finalGroupId).pop();
            if (active) {
                // it's very strange, but without null back button stops working :(
                setSelection([null, finalGroupId]);
            }
        }
    }, [finalGroupId, setSelection, groups]);
    const onSelect = useCallback(
        groupId => {
            setSelection(prev => [...prev, groupId]);
            setDescription(subFilterText);
            setBreadcrumbsVisible(!!showBreadcrumbs);
        },
        [setSelection]
    );
    const onSearchChange = useCallback(
        e => {
            setSearch(e.target.value);
            onSearch(e.target.value);
        },
        [setSearch]
    );

    const onBack = useCallback(() => {
        setSelection(prev => prev.slice(0, -1));
        setBreadcrumbsVisible(false);
        history.push(pathname);
    }, [setSelection, pathname]);
    const backAction = {
        text: t('campaign.campaign_index.back'),
        color: buttonStyle && Button.COLOR[buttonStyle] ? Button.COLOR[buttonStyle] : Button.COLOR.GRAY,
        icon: 'arrow-left',
        onClick: onBack,
    };

    const activeGroupId = selection[selection.length - 1];
    const activeGroup = activeGroupId
        ? groups?.filter(({ id, groupId }) => id === activeGroupId && !isUndefined(groupId)).pop()
        : null;
    const displayedGroups = groups?.filter(({ groupId }) => groupId === activeGroupId) || [];
    const types = activeGroup ? [] : groups?.filter(({ groupId }) => isUndefined(groupId)) || [];
    const { entries = [] } = activeGroup || {};
    const count = displayedGroups.length + types.length + entries.length;
    return (
        <Page title={activeGroup ? activeGroup.name : title}
              text={description}
              actions={activeGroup ? [backAction] : actions}
              breadcrumbs={breadcrumbsVisible ? [
                  {
                      text: t('asset_library.browse.search.find_resource'),
                      onClick: onBack,
                  },
                  {
                      text: t('asset_library.browse.one_asset.category'),
                  },
              ] : undefined}
        >
            <LoadingContainer loading={loading}>
                <SearchField
                    rootClassName={styles.search}
                    name="search"
                    value={search}
                    onChange={onSearchChange}
                    placeholder={t('asset_library.manage_assets.assets.search_by_text_placeholder')}
                />
                <div className={cn(styles.wrapper, styles[`wrapper${count > 3 ? 4 : count}`])}>
                    {displayedGroups.map(group => (
                        <MainFilterGroup key={group.id} group={group} onSelect={onSelect} />
                    ))}
                    {entries.map(entry => (
                        <MainFilterGroup key={entry.id} group={entry} onSelect={onSelectEntry} />
                    ))}
                    {!!onSelectType &&
                        types.map(tp => <MainFilterGroup key={tp.id} group={tp} onSelect={onSelectType} />)}
                </div>
                {children || null}
            </LoadingContainer>
        </Page>
    );
};

export default PreFilters;
