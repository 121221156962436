import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import get from 'lodash/get';
import { languageActions } from '../../store';
import { t } from '../../utils';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import Col from '../FlexBox/components/Col';
import Row from '../FlexBox/components/Row';
import InfoBlock from '../InfoBlock/InfoBlock';
import styles from './TranslationFields.module.css';
import Input from '../Input/Input';

class TranslationFields extends Component {
    static defaultProps = {
        fields: [{ name: 'value' }],
    };

    static propTypes = {
        loading: PropTypes.bool,
        baseCode: PropTypes.string,
        data: PropTypes.array,
        values: PropTypes.object,
        getList: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        fields: PropTypes.array,
    };

    componentDidMount() {
        const { getList } = this.props;
        getList();
    }

    setAllTranslations = (value, field) => {
        const { setFieldValue, data } = this.props;
        data.forEach((language, index) => setFieldValue(`translations[${index}].${field}`, value));
    };

    onChangeDefaultOtherLanguagesToBase = (e) => {
        const { setFieldValue, values, data, baseCode, fields } = this.props;
        setFieldValue('defaultOtherLanguagesToBase', e.target.checked);
        if (e.target.checked) {
            const baseSystemLanguageId = data.filter((language) => language.language.code === baseCode).pop().id;
            const baseValue = values.translations.filter(({ language }) => language.id === baseSystemLanguageId).pop();
            fields.forEach(({ name }) => this.setAllTranslations(get(baseValue, name), name));
        }
    };

    onChangeTranslation = (e, field) => {
        const { setFieldValue, values } = this.props;
        if (values.defaultOtherLanguagesToBase) {
            this.setAllTranslations(e.target.value, field);
        } else {
            setFieldValue(e.target.name, e.target.value);
        }
    };

    render() {
        const { loading, data, baseCode, values, fields } = this.props;
        const useBase = values.defaultOtherLanguagesToBase;
        return (
            <LoadingContainer loading={loading}>
                <hr className={styles.dark} />
                <h2 className={styles.h2}>{t('template.translations_list.languages_available')}</h2>
                <Row>
                    <Col md={7}>
                        {data.map((language, index) => {
                            const fieldProps = {
                                component: Input,
                                validate: (v) => {
                                    if (useBase) {
                                        return !v && baseCode === language.language.code
                                            ? t('general.errors.required')
                                            : undefined;
                                    }
                                    return v ? undefined : t('general.errors.required');
                                },
                                required: useBase ? baseCode === language.language.code : true,
                                readOnly: useBase ? baseCode !== language.language.code : false,
                            };
                            return (
                                <Fragment key={language.id}>
                                    {fields.length > 1 && (
                                        <div className={styles.languageTitle}>{language.language.name}</div>
                                    )}
                                    {fields.map(({ name, ...field }) => (
                                        <Field
                                            key={`${language.id}-${name}`}
                                            name={`translations[${index}].${name}`}
                                            placeholder={t(
                                                'asset_library.set_up.create_or_update_tag.form.please_add_translation_placeholder',
                                            )} // eslint-disable-line
                                            label={language.language.name}
                                            onChange={(e) => this.onChangeTranslation(e, name)}
                                            {...fieldProps}
                                            {...field}
                                        />
                                    ))}
                                </Fragment>
                            );
                        })}
                        <label htmlFor="defaultOtherLanguagesToBase" className={styles.default}>
                            <input
                                type="checkbox"
                                checked={useBase}
                                onChange={this.onChangeDefaultOtherLanguagesToBase}
                                id="defaultOtherLanguagesToBase"
                            />
                            {t('template.translations_list.default_other_languages_to_base')}
                        </label>
                    </Col>
                    <Col md={5}>
                        <InfoBlock>
                            <p>{t('template.translations_list.translation_hint.please_enter')}</p>
                            <p>{t('template.translations_list.translation_hint.default_to_english')}</p>
                            <p>
                                {t(
                                    'template.translations_list.translation_hint.please_contact',
                                    {
                                        begin_link: '<a href="mailto:helpdesk@partnermarketing.com">',
                                        end_link: '</a>',
                                    },
                                    true,
                                )}
                            </p>
                        </InfoBlock>
                    </Col>
                </Row>
            </LoadingContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    baseCode: state.user.current.data.client.defaultLanguageCode,
    loading: state.language.list.loading,
    data: state.language.list.data,
});

const mapDispatchToProps = (dispatch) => ({
    getList: () => dispatch(languageActions.list()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TranslationFields);
