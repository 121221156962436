import React, { ReactNode, FC } from 'react';
import cn from 'classnames';
import { t } from 'utils';
import InfoBlock from '../InfoBlock/InfoBlock';
import Row from '../FlexBox/components/Row';
import Col from '../FlexBox/components/Col';
import styles from './FieldGroup.module.scss';
import InfoLink from '../InfoLink/InfoLink';
import IconStack from 'components/IconStack/IconStack';

interface FieldGroup {
    borderTop?: boolean;
    children?: ReactNode;
    className?: string;
    info?: ReactNode;
    infoClassName?: string;
    labelOffset?: boolean;
    left?: number;
    link?: string;
    right?: number;
    text?: ReactNode;
    title?: ReactNode;
    titleTooltip?: string;
    topInfo?: ReactNode;
}

const FieldGroup: FC<FieldGroup> = ({
    info,
    children,
    topInfo,
    left = 7,
    right = 5,
    infoClassName,
    className,
    labelOffset,
    title,
    titleTooltip,
    text,
    borderTop = false,
    link,
}) => (
    <div className={cn(styles.wrapper, borderTop && styles.borderTop, className)}>
        {!!title && (
            <h2 className="sub-title">
                {title}
                {!!titleTooltip && (
                    <span data-tip={titleTooltip} className={cn('client-secondary-colour', styles.tipInfo)}>
                        <IconStack size="05" outer="bg-circle" inner="info" innerClassName={styles.tipInfoIcon} />
                    </span>
                )}
            </h2>
        )}
        {!!text && <p>{text}</p>}
        {!!topInfo && <InfoBlock className={infoClassName}>{topInfo}</InfoBlock>}
        <Row className={className}>
            <Col md={left}>{children}</Col>
            {(!!info || !!link) && (
                <Col md={right} className={labelOffset ? styles.labelOffset : ''}>
                    {!!info && <InfoBlock className={infoClassName}>{info}</InfoBlock>}
                    {!!link && (
                        <InfoLink
                            link={link}
                            info={t('general.tooltip.knowledgebase')}
                            icon={InfoLink.TYPES.KNOWLEDGEBASE}
                        />
                    )}
                </Col>
            )}
        </Row>
    </div>
);

export default FieldGroup;
