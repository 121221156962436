import { combineReducers } from 'redux';
import groupBy from 'lodash/groupBy';
import packageReducer from '../package/redux/reducer';
import ACTIONS from './constants';

const creatableInitialState = {
    data: [],
    loading: false,
};

const creatableReducer = (state = creatableInitialState, action) => {
    switch (action.type) {
        case ACTIONS.creatable.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.creatable.setData: {
            return {
                ...state,
                data: Object.values(groupBy(action.data, ({ category }) => category)).map(items => ({
                    title: items[0].category,
                    description: items[0].categoryDescription || '',
                    items,
                })),
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const filtersInitialState = {
    data: {},
    loading: false,
};

const filtersReducer = (state = filtersInitialState, action) => {
    switch (action.type) {
        case ACTIONS.filters.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.filters.setData: {
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const itemInitialState = {
    data: {},
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.item.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    creatable: creatableReducer,
    filters: filtersReducer,
    item: itemReducer,
    package: packageReducer,
});
