import React, { Component } from 'react';
import PropTypes from 'prop-types';

function clickOutside(WrappedComponent) {
    return class ClickOutside extends Component {
        static displayName = `ClickOutside(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

        static propTypes = {
            onClickOutside: PropTypes.func.isRequired,
        };

        componentDidMount() {
            window.addEventListener('click', this.handleClickOutside, true);
        }

        componentWillUnmount() {
            window.removeEventListener('click', this.handleClickOutside, true);
        }

        handleClickOutside = (event) => {
            const { onClickOutside } = this.props;
            const element = this.wrappedComponent;
            if (!element.contains(event.target)) {
                onClickOutside(event);
            }
        };

        wrappedComponent;

        render() {
            const { onClickOutside, ...props } = this.props;
            return (
                <div
                    ref={(ref) => {
                        this.wrappedComponent = ref;
                    }}
                >
                    <WrappedComponent {...props} />
                </div>
            );
        }
    };
}

export default clickOutside;
