import ShortCodesSelect from 'components/Input/quill/ShortCodesSelect';
import React from 'react';

type RichTextToolbarProps = {
    id: string;
    showCodeBlock?: boolean;
    showShortCodes?: boolean;
    isEmail?: boolean;
};
export default function RichTextToolbar({
    id,
    showCodeBlock = false,
    showShortCodes = false,
    isEmail = false,
}: RichTextToolbarProps): JSX.Element {
    return (
        <div id={id} className="ql-toolbar ql-snow">
            <span className="ql-formats">
                <button type="button" className="ql-bold" />
                <button type="button" className="ql-italic" />
                <button type="button" className="ql-underline" />
                <button type="button" className="ql-list" value="bullet" />
            </span>
            <span className="ql-formats">
                <button type="button" className="ql-align" value="" />
                <button type="button" className="ql-align" value="center" />
                <button type="button" className="ql-align" value="right" />
                <button type="button" className="ql-align" value="justify" />
            </span>
            <span className="ql-formats">
                <button type="button" className="ql-link" />
                {showCodeBlock && (
                  <button type="button" className="ql-code-block" />
                )}
            </span>
            {showShortCodes && (
                <span className="ql-formats">
                    <ShortCodesSelect isEmail={isEmail} />
                </span>
            )}
        </div>
    );
}
