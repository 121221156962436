import { combineReducers } from 'redux';
import ACTIONS from './constants';

const itemInitialState = {
    data: {},
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const listInitialState = {
    data: [],
    loading: false,
    counts: {},
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.list.setFilters: {
            return {
                ...state,
                filters: action.data,
            };
        }

        case ACTIONS.list.setCounts: {
            return {
                ...state,
                counts: action.counts,
            };
        }

        default: {
            return state;
        }
    }
};

const leadsInitialState = {
    data: [],
    loading: false,
    counts: {},
};

const leadsReducer = (state = leadsInitialState, action) => {
    switch (action.type) {
        case ACTIONS.leads.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.leads.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.leads.setCounts: {
            return {
                ...state,
                counts: action.counts,
            };
        }

        default: {
            return state;
        }
    }
};

const fieldsInitialState = {
    data: [],
    loading: false,
};

const fieldsReducer = (state = fieldsInitialState, action) => {
    switch (action.type) {
        case ACTIONS.fields.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.fields.setData: {
            return {
                data: action.data,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const listLeadsInitialState = {
    data: [],
    loading: false,
    counts: 0,
};

const listLeadsReducer = (state = listLeadsInitialState, action) => {
    switch (action.type) {
        case ACTIONS.listLeads.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.listLeads.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.listLeads.setCounts: {
            return {
                ...state,
                counts: action.counts,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    item: itemReducer,
    list: listReducer,
    leads: leadsReducer,
    fields: fieldsReducer,
    listLeads: listLeadsReducer,
});
