import { combineReducers } from 'redux';
import { ACTIONS, OPTIONS } from './constants';

const optionsInitialState = {
    [OPTIONS.designerStatus]: {
        data: {},
        loading: false,
    },
    [OPTIONS.type]: {
        data: {},
        loading: false,
    },
    [OPTIONS.templateType]: {
        data: [],
        loading: false,
    },
    [OPTIONS.label]: {
        data: {},
        loading: false,
    },
};
const optionsReducer = (state = optionsInitialState, action) => {
    switch (action.type) {
        case ACTIONS.options.loading: {
            return {
                ...state,
                [action.kind]: {
                    data: state[action.kind].data,
                    loading: action.loading,
                },
            };
        }

        case ACTIONS.options.data: {
            return {
                ...state,
                [action.kind]: {
                    data: action.data,
                    loading: false,
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    options: optionsReducer,
});
