import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import useDisableValidateDOMNesting from 'hooks/useDisableValidateDOMNesting';
import templateStyles from 'pages/campaignInstance/edit/Template.module.scss';
import { getTransformedHtml } from 'pages/manage/Templates/Template/components/EditableArea';

const preprocessHtml = (html, formValues) => {
    let _html = html;
    (get(formValues, 'data.colours') || []).forEach(color => {
        _html = _html.replace(new RegExp(`\\$(global_)?${color.name}_colour`, 'g'), color.value);
    });
    return _html;
};

const HtmlPreview = ({ loading, templateType, html }) => {
    useDisableValidateDOMNesting();
    const { values } = useFormikContext();
    const transformedHtml = useMemo(() => {
        if (loading) {
            return null;
        }
        return getTransformedHtml(preprocessHtml(html, values));
    }, [loading, html, values]);

    return (
        <LoadingContainer
            loading={loading}
            className={cn(templateStyles.template, templateStyles.campaignEditor, templateStyles[templateType])}
        >
            {() => <div id="template">{transformedHtml}</div>}
        </LoadingContainer>
    );
};

HtmlPreview.propTypes = {
    loading: PropTypes.bool.isRequired,
    templateType: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
};

export default HtmlPreview;
