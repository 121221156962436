import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Icon.module.css';

/**
 * Render an icon. Kind is mentioned under the actual icon.
 */
export default function Icon({ kind, dark, className, tooltip }) {
    return (
        <span className={cn(styles.icon, styles[`icon-${kind}`], dark && styles.dark, className)} data-tip={tooltip} />
    );
}

Icon.propTypes = {
    /**
     * Kind of icon
     */
    kind: PropTypes.string.isRequired,
    /**
     * Flag to render icon of dark color
     */
    dark: PropTypes.bool,
    /**
     * Custom class name
     */
    className: PropTypes.string,
    /**
     * Tip to show on icon hover
     */
    tooltip: PropTypes.string,
};
