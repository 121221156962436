import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import get from 'lodash/get';
import { languageActions } from 'store';
import { t } from 'utils';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import Input from 'components/Input/Input';
import ArrowButton from 'components/ArrowButton/ArrowButton';
import RowWithInfo from 'components/FieldGroup/FieldGroup';
import styles from './TranslationCollapseFields.module.css';

class TranslationCollapseFields extends Component {
    static defaultProps = {
        fields: [{ name: 'value' }],
    };

    static propTypes = {
        loading: PropTypes.bool,
        baseCode: PropTypes.string,
        data: PropTypes.array,
        values: PropTypes.object,
        getList: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        fields: PropTypes.array,
    };

    state = {
        filtersOpen: [],
    };

    componentDidMount() {
        const { getList, baseCode } = this.props;
        getList();
        this.toggleFiltersOpen(baseCode);
    }

    onChangeTranslation = e => {
        const { setFieldValue } = this.props;
        setFieldValue(e.target.name, e.target.value);
    };

    toggleFiltersOpen = language => {
        const { filtersOpen } = this.state;
        filtersOpen[language] = !filtersOpen[language];
        this.setState(({ filtersOpen }) => ({ filtersOpen }));
    };

    setAllTranslations = (value, field) => {
        const { setFieldValue, data } = this.props;
        data.forEach((language, index) => setFieldValue(`translations[${index}].${field}`, value));
    };

    onChangeDefaultOtherLanguagesToBase = e => {
        const { setFieldValue, values, data, baseCode, fields } = this.props;
        setFieldValue('defaultOtherLanguagesToBase', e.target.checked);
        if (e.target.checked) {
            const baseSystemLanguageId = data.filter(language => language.language.code === baseCode).pop().id;
            const baseValue = values.translations.filter(({ language }) => language.id === baseSystemLanguageId).pop();
            fields.forEach(({ name }) => this.setAllTranslations(get(baseValue, name), name));
        }
    };

    render() {
        const { filtersOpen } = this.state;
        const { loading, data, fields, setFieldValue, values } = this.props;
        const useBase = values.defaultOtherLanguagesToBase;

        return (
            <LoadingContainer loading={loading}>
                {data.map((language, index) => {
                    const fieldProps = {
                        component: Input,
                    };
                    return (
                        <Fragment key={language.id}>
                            {fields.length > 1 && (
                                <div className={styles.languageTitle}>
                                    {language.language.name}
                                    <ArrowButton
                                        isOpen={filtersOpen[language.language.code]}
                                        onClick={() => this.toggleFiltersOpen(language.language.code)}
                                    />
                                </div>
                            )}
                            {!!filtersOpen[language.language.code] && (
                                <div className={styles.languageFields}>
                                    {fields.map(({ name, ...field }) => {
                                        if (field.type === Input.TYPE.FILE) {
                                            return (
                                                <>
                                                    {field.showImage && (
                                                        <img
                                                            className={styles.imagePreview}
                                                            key={`thumbnail-${language.id}-${name}`}
                                                            src={values.translations[index][name]}
                                                            alt=""
                                                        />
                                                    )}
                                                    {!field.infoText && (
                                                        <Field
                                                            key={`${language.id}-${name}`}
                                                            name={`translations[${index}].${name}`}
                                                            placeholder={t(
                                                                'asset_library.set_up.create_or_update_tag.form.please_add_translation_placeholder'
                                                            )} // eslint-disable-line
                                                            label={language.language.name}
                                                            uploadSuccess={uploadData => {
                                                                if (uploadData.status === 'success') {
                                                                    setTimeout(() => {
                                                                        setFieldValue(
                                                                            `translations[${index}].${name}`,
                                                                            uploadData.url
                                                                        );
                                                                    });
                                                                }
                                                            }}
                                                            {...fieldProps}
                                                            {...field}
                                                        />
                                                    )}
                                                    {!!field.infoText && (
                                                        <RowWithInfo info={t(field.infoText)}>
                                                            <Field
                                                                key={`${language.id}-${name}`}
                                                                name={`translations[${index}].${name}`}
                                                                placeholder={t(
                                                                    'asset_library.set_up.create_or_update_tag.form.please_add_translation_placeholder'
                                                                )} // eslint-disable-line
                                                                label={language.language.name}
                                                                uploadSuccess={uploadData => {
                                                                    if (uploadData.status === 'success') {
                                                                        setTimeout(() => {
                                                                            setFieldValue(
                                                                                `translations[${index}].${name}`,
                                                                                uploadData.url
                                                                            );
                                                                        });
                                                                    }
                                                                }}
                                                                {...fieldProps}
                                                                {...field}
                                                            />
                                                        </RowWithInfo>
                                                    )}
                                                </>
                                            );
                                        }

                                        return (
                                            <Field
                                                key={`${language.id}-${name}`}
                                                name={`translations[${index}].${name}`}
                                                placeholder={t(
                                                    'asset_library.set_up.create_or_update_tag.form.please_add_translation_placeholder'
                                                )} // eslint-disable-line
                                                label={language.language.name}
                                                onChange={e => this.onChangeTranslation(e, name)}
                                                {...fieldProps}
                                                {...field}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </Fragment>
                    );
                })}
                <label htmlFor="defaultOtherLanguagesToBase" className={styles.default}>
                    <input
                        type="checkbox"
                        checked={useBase}
                        onChange={this.onChangeDefaultOtherLanguagesToBase}
                        id="defaultOtherLanguagesToBase"
                    />
                    {t('template.translations_list.default_other_languages_to_base')}
                </label>
            </LoadingContainer>
        );
    }
}

const mapStateToProps = state => ({
    baseCode: state.user.current.data.client.defaultLanguageCode,
    loading: state.language.list.loading,
    data: state.language.list.data,
});

const mapDispatchToProps = dispatch => ({
    getList: () => dispatch(languageActions.list()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TranslationCollapseFields);
