import { combineReducers } from 'redux';
import activityGroupsReducer from '../ActivityGroups/redux/reducer';
import featuredCampaignsReducer from '../FeaturedCampaigns/redux/reducer';
import loyaltyBannersReducer from '../LoyaltyBanners/redux/reducer';
import loyaltyRewardsReducer from '../LoyaltyRewards/redux/reducer';
import agencyReducer from '../Agency/redux/reducer';
import campaignsReducer from '../Campaigns/redux/reducer';
import templatesReducer from '../Templates/redux/reducer';
import templateTypesReducer from '../TemplateTypes/redux/reducer';
import surveyReducer from '../Survey/redux/reducer';
import toggleGroupsReducer from '../ToggleGroups/redux/reducer';
import toggleOptionsReducer from '../ToggleOptions/redux/reducer';
import usersReducer from '../Users/redux/reducer';

export default combineReducers({
    activityGroups: activityGroupsReducer,
    loyaltyBanners: loyaltyBannersReducer,
    loyaltyRewards: loyaltyRewardsReducer,
    agency: agencyReducer,
    campaigns: campaignsReducer,
    templates: templatesReducer,
    templateTypes: templateTypesReducer,
    featuredCampaigns: featuredCampaignsReducer,
    survey: surveyReducer,
    toggleGroups: toggleGroupsReducer,
    toggleOptions: toggleOptionsReducer,
    users: usersReducer,
});
