import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import LoadingButton from 'components/LoadingButton/LoadingButton';
import { t } from 'utils';
import styles from './DataActions.module.scss';

const DataActions = ({ onCancel, onPreview, onSave, isSubmitting, saveButtonText }) => (
    <>
        <Button onClick={onCancel} icon="cross" small color={Button.COLOR.GRAY} tip={t('general.button.cancel')} />
        <span className={styles.spacer} />
        {!!onPreview && (
            <Button icon="view" small color={Button.COLOR.SECONDARY} onClick={onPreview}>
                {t('template.default.item.preview')}
            </Button>
        )}
        <LoadingButton loading={isSubmitting} onClick={onSave} icon="tick" small color={Button.COLOR.PRIMARY}>
            {saveButtonText || t('template.default.item.save')}
        </LoadingButton>
    </>
);

DataActions.propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onPreview: PropTypes.func,
    isSubmitting: PropTypes.bool,
    saveButtonText: PropTypes.string,
};

export default DataActions;
