export default function clearFormatting(node, delta) {
    // eslint-disable-next-line no-param-reassign
    delta.ops = delta.ops.map(op => {
        if (typeof op.insert === 'string' && typeof op.attributes === 'object') {
            // We need to ignore background and text colours.
            const { background, color, ...rest } = op.attributes;
            return {
                ...op,
                attributes: rest,
            };
        }
        return op;
    });
    return delta;
}
