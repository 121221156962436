import { useParams } from 'react-router-dom';
import useApi from 'hooks/useApi';

const useApiToggleTemplatePage = (params = {}) => {
    const { id, templateId } = useParams();
    const { doFetch, loading } = useApi({
        slug: 'partnermarketing_template_toggle_page_api',
        slugParams: {
            campaignInstanceId: id,
            templateInstanceId: templateId,
        },
        method: 'PUT',
        lazy: true,
        ...params,
    });

    return {
        apiToggleTemplatePage: doFetch,
        loading,
    };
};

export default useApiToggleTemplatePage;
