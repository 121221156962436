import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '../../../Icon/Icon';
import styles from './BasketLink.module.css';
import { ROUTE } from '../../../../constants';
import history from '../../../../history';
import { getCurrentUserRolesMap } from '../../../../selectors/userSelectors';

/**
 * Display basket icon with amount of added items. Hidden if marketing store is not enabled and for some user roles.
 */
const BasketLink = ({ customUrl, customCount, basketItemsCount, roles, showMarketingStore }) => {
    if (
        (!showMarketingStore && !customUrl) ||
        (!roles.isPartner && !roles.isConciergePartner && !roles.isDesigner && !roles.isEasyAccessPartner)
    ) {
        return null;
    }

    return (
        <a
            className={styles.link}
            href="#basket"
            onClick={(e) => {
                e.preventDefault();
                if (!!customUrl) {
                    history.push(customUrl);
                } else {
                    history.push(ROUTE.basket.root);
                }
            }}
            target="_self"
            rel="noopener noreferrer"
        >
            {!customUrl && <span className={styles.number}>{basketItemsCount}</span>}
            {!!customUrl && <span className={styles.number}>{customCount}</span>}
            <Icon dark kind="basket" className={styles.icon} />
        </a>
    );
};

BasketLink.propTypes = {
    /**
     * @ignore
     */
    basketItemsCount: PropTypes.number,
    /**
     * @ignore
     */
    roles: PropTypes.object,
    /**
     * @ignore
     */
    showMarketingStore: PropTypes.bool,
    customUrl: PropTypes.any,
    customCount: PropTypes.any,
};

const mapStateToProps = (state) => {
    const { user } = state;
    return {
        basketItemsCount: user.current.data.basketItemsCount,
        roles: getCurrentUserRolesMap(state),
        loading: user.current.loading,
        showMarketingStore: user.current.data.showMarketingStore,
    };
};

export default connect(mapStateToProps)(BasketLink);
