export default {
    getId: () => window.client.id,
    getLogo: () => window.client.logoUrl,
    getDashboardLogo: () => window.client.dashboardLogoUrl,
    getUseDashboardLogoLink: () => window.client.useDashboardLogoLink,
    getDashboardLogoLink: () => window.client.dashboardLogoLink,
    getName: () => window.client.name,
    getSubdomainSlug: () => window.client.subdomainSlug,
    getTermsUrl: () => window.client.termsUrl,
    getIsBusinessLogoEnabled: () => window.client.isBusinessLogoEnabled,
    getMainColor: () => window.client.mainColour,
    getPrimaryButtonTextColour: () => window.client.primaryButtonTextColour,
    getMainNavigationBgColour: () => window.client.mainNavigationBgColour,
    getTopNavigationBgColour: () => window.client.topNavigationBgColour,
    getMainNavigationBottomBorderColour: () => window.client.mainNavigationBottomBorderColour,
    getSubHeaderTextStyling: () => window.client.subHeaderTextStyling,
    getParagraphStyling: () => window.client.paragraphTextStyling,
    getHeaderStyling: () => window.client.headerTextStyling,
    isSSOActive: () => window.client.isSSOActive,
    isMarketingPreferencesEnabled: () => window.client.isMarketingPreferencesEnabled,
    isSocialMediaPostsEnabled: () => window.client.isSocialMediaPostsEnabled,
    getDefaultDateFormat: () => window.client.defaultDateFormat,
};
