import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { alertActions } from 'store';
import { scrollTo } from 'utils';
import AlertDialog from 'components/Alert/AlertDialog';
import AlertLine from 'components/Alert/AlertLine';
import styles from './Alert.module.css';

/**
 * Displays notification under top menu. No need to display manually.
 * Happens as reaction on dispatching of success/notify/error alert action.
 */
class Alert extends Component {
    componentDidUpdate(prevProps) {
        if (prevProps.path !== this.props.path) {
            this.props.decreaseDuration();
        }
        if (prevProps.list.length !== this.props.list.length) {
            // Evil hack because of https://code.google.com/p/chromium/issues/detail?id=440504
            const doc =
                document.documentElement.scrollTop === window.pageYOffset ? document.documentElement : document.body;
            // bodyScroll calculation from:
            // http://stackoverflow.com/questions/3464876/javascript-get-window-x-y-position-for-scroll
            const bodyScroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            const notificationOffset = this.container.offsetTop;

            if (bodyScroll < notificationOffset) {
                // Notification is already visible.
                return;
            }

            // Made scroll smoother, instead of element[0].scrollIntoView(true)
            scrollTo(doc, notificationOffset);
        }
    }

    render() {
        const { list, remove, modal } = this.props;
        return (
            <>
                <div className={cn('client-secondary-colour-background', styles.alertWrapper)}>
                    <div className="container" ref={c => (this.container = c)}>
                        {list.map(({ id, kind, message }) => (
                            <AlertLine key={id} id={id} kind={kind} message={message} onRemove={() => remove(id)} />
                        ))}
                    </div>
                </div>
                {!!modal && <AlertDialog {...modal} />}
            </>
        );
    }
}

Alert.propTypes = {
    /**
     * @ignore
     */
    path: PropTypes.string,
    /**
     * @ignore
     */
    list: PropTypes.array,
    /**
     * @ignore
     */
    remove: PropTypes.func,
    /**
     * @ignore
     */
    modal: PropTypes.object,
    /**
     * @ignore
     */
    decreaseDuration: PropTypes.func,
};

const mapStateToProps = ({ alert, user }) => ({
    path: user.pathname,
    list: alert.list,
    modal: alert.modal,
});

const mapDispatchToProps = dispatch => ({
    remove: id => dispatch(alertActions.remove(id)),
    decreaseDuration: () => dispatch(alertActions.decreaseDuration()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
