import useApi from 'hooks/useApi';

const useApiTemplateInstanceAssetLibrary = (campaignInstanceId, templateId, lazy = false) => {
    const { response, loading } = useApi({
        slug: 'partnermarketing_template_get_linked_asset_library_api',
        slugParams: {
            campaignInstanceId,
            templateId,
        },
        method: 'GET',
        cache: true,
        lazy,
    });

    return {
        templateInstanceAssetLibraryAssets: response || [],
        loading,
    };
};

export default useApiTemplateInstanceAssetLibrary;
