export default {
    list: {
        setData: 'MANAGE_SURVEY_LIST_SET_DATA',
        setLoading: 'MANAGE_SURVEY_LIST_SET_LOADING',
    },
    item: {
        reset: 'MANAGE_SURVEY_ITEM_RESET',
        setData: 'MANAGE_SURVEY_ITEM_SET_DATA',
        setError: 'MANAGE_SURVEY_ITEM_SET_ERROR',
        setLoading: 'MANAGE_SURVEY_ITEM_SET_LOADING',
    },
};
