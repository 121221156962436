import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './StatisticsFigures.module.css';

const StatisticsFigures = ({ titles, counts, classes = {} }) => {
    const activeCount = Object.keys(counts).filter(keyName => !!titles[keyName]);
    return (
        <ul className={cn(styles.wrapper, styles[`boxes-${Object.keys(activeCount).length}`])}>
            {Object.values(activeCount).map((keyName, index) => (
                <li
                    key={index}
                    className={cn(
                        styles.box,
                        styles[`box-${index + 1}`],
                        styles[`${classes[keyName]}`],
                        classes[keyName]
                    )}
                >
                    <span className={styles.title}>{titles[keyName]}</span>
                    <span className={styles.number}>{counts[keyName]}</span>
                </li>
            ))}
        </ul>
    );
};

StatisticsFigures.propTypes = {
    titles: PropTypes.object.isRequired,
    counts: PropTypes.object.isRequired,
    classes: PropTypes.object,
};

export default StatisticsFigures;
