import { useCallback } from 'react';
import { useField } from 'formik';
import { MISSED_FIELD_NAME } from 'pages/manage/Templates/utils';

const useIsHighlightField = (name, dataType) => {
    const prefixedName = `${dataType.slice(0, -1)}.${name}`;
    const [{ value: missed }, , { setValue }] = useField(MISSED_FIELD_NAME);

    const isMissed = (missed || []).filter(field => field === name || field === prefixedName).length > 0;

    const excludeFromMissed = useCallback(() => {
        setValue((missed || []).filter(field => field !== name && field !== prefixedName));
    }, [name, prefixedName, missed, setValue]);

    return [isMissed, excludeFromMissed];
};

export default useIsHighlightField;
