import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './SortableList.module.scss';
import Icon from '../Icon/Icon';

const SortableList = ({ children, droppableId, onSwap, itemClassName, handleClassName }) => (
    <DragDropContext
        onDragEnd={({ destination, source }) => {
            if (destination) {
                onSwap(source.index, destination.index);
            }
        }}
    >
        <Droppable droppableId={droppableId} direction="vertical">
            {droppableProvided => (
                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                    {children.map((item, index) => (
                        <Draggable key={index} draggableId={`${droppableId}-${item.key}`} index={index}>
                            {draggableProvided => (
                                <div
                                    className={cn(styles.draggable, itemClassName)}
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                >
                                    <span
                                        className={cn(styles.handle, handleClassName)}
                                        {...draggableProvided.dragHandleProps}
                                    >
                                        <Icon kind="drag" />
                                    </span>
                                    <div className={styles.item}>{item}</div>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>
);

SortableList.defaultProps = {
    droppableId: 'sortable-list',
};

SortableList.propTypes = {
    /**
     * Class name to add to each draggable element.
     */
    itemClassName: PropTypes.string,
    /**
     * Class name to add to draggable handle element.
     */
    handleClassName: PropTypes.string,
    droppableId: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.element),
    /**
     * This func called when swap happens. This func should receive two arguments sourceIndex and destinationIndex.
     */
    onSwap: PropTypes.func,
};

export default SortableList;
