type ClearBitPersonParams = {
    email: string;
    webhook_url?: string;
    given_name?: string;
    family_name?: string;
    ip_address?: string;
    location?: string;
    company?: string;
    company_domain?: string;
    linkedin?: string;
    twitter?: string;
    facebook?: string;
    webhook_id?: string;
    suppression?: string;
};

type ClearBitCompanyParams = {
    domain: string;
    webhook_url?: string;
    company_name?: string;
    linkedin?: string;
    twitter?: string;
    facebook?: string;
};

type ClearBitData = Record<string, string | number | boolean | object> | null;

async function getClearBitData(
    url: string,
    params: ClearBitCompanyParams | ClearBitPersonParams
): Promise<ClearBitData> {
    let data: ClearBitData = null;
    try {
        const urlParams = new URLSearchParams(params);
        const response = await fetch(`${url}?${urlParams}`, {
            method: 'GET',
            headers: {
                Authorization: `Basic ${btoa(`${process.env.CLEARBIT_KEY}:`)}`,
            },
        });
        data = await response.json();
    } catch (e) {
        console.error(e);
    }

    if (data?.error) {
        throw data;
    }

    return data;
}

export async function getClearBitPerson(params: ClearBitPersonParams): Promise<ClearBitData> {
    return getClearBitData('https://person.clearbit.com/v2/people/find', params);
}

export async function getClearBitCompany(params: ClearBitCompanyParams): Promise<ClearBitData> {
    return getClearBitData('https://company.clearbit.com/v2/companies/find', params);
}
