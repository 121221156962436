import { configureStore as baseConfigureStore } from '@reduxjs/toolkit';
import { rootReducer } from 'store/rootReducer';
import * as alertActions from 'store/alert/actions';
import * as userActions from './user/actions';
import * as countryCodeActions from './countryCode/actions';
import * as timezoneActions from './timezone/actions';
import * as languageActions from './language/actions';
import * as spiffActions from '../pages/spiff/redux/actions';
import * as companyActions from './company/actions';
import * as clientActions from './client/actions';

export const configureStore = (initialState = {}) =>
    baseConfigureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
        preloadedState: initialState,
    });

export const store = configureStore();

export { alertActions };
export { clientActions };
export { companyActions };
export { countryCodeActions };
export { timezoneActions };
export { languageActions };
export { spiffActions };
export { userActions };
