import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'utils';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import Button from 'components/Button/Button';
import HoverableList from 'components/HoverableList/HoverableList';

const ImageTypeSelect = ({ onSave, value, images, loading, empty }) => {
    const selectImage = image => () => onSave(image.value);

    return (
        <LoadingContainer loading={loading}>
            {!images.length ? (
                empty
            ) : (
                <HoverableList
                    isSelected={image => value === image.value}
                    list={images}
                    actions={({ item: image }) => (
                        <Button color={Button.COLOR.PRIMARY} tiny icon="tick" onClick={selectImage(image)}>
                            {t('general.button.select')}
                        </Button>
                    )}
                >
                    {({ itemClass, item: image }) => (
                        <span className={itemClass}>
                            <img src={image.value} alt="" className={image.className} />
                        </span>
                    )}
                </HoverableList>
            )}
        </LoadingContainer>
    );
};

ImageTypeSelect.propTypes = {
    empty: PropTypes.node.isRequired,
    images: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string.isRequired })).isRequired,
    loading: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default ImageTypeSelect;
