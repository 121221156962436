export default {
    list: {
        setData: 'MANAGE_LOYALTY_REWARDS_LIST_SET_DATA',
        setLoading: 'MANAGE_LOYALTY_REWARDS_LIST_SET_LOADING',
    },
    instancesList: {
        setData: 'MANAGE_LOYALTY_REWARDS_INSTANCE_LIST_SET_DATA',
        setLoading: 'MANAGE_LOYALTY_REWARDS_INSTANCE_LIST_SET_LOADING',
    },
    item: {
        reset: 'MANAGE_LOYALTY_REWARDS_ITEM_RESET',
        setData: 'MANAGE_LOYALTY_REWARDS_ITEM_SET_DATA',
        setError: 'MANAGE_LOYALTY_REWARDS_ITEM_SET_ERROR',
        setLoading: 'MANAGE_LOYALTY_REWARDS_ITEM_SET_LOADING',
    },
    instance: {
        reset: 'MANAGE_LOYALTY_REWARDS_INSTANCE_ITEM_RESET',
        setData: 'MANAGE_LOYALTY_REWARDS_INSTANCE_ITEM_SET_DATA',
        setError: 'MANAGE_LOYALTY_REWARDS_INSTANCE_ITEM_SET_ERROR',
        setLoading: 'MANAGE_LOYALTY_REWARDS_INSTANCE_ITEM_SET_LOADING',
    },
    categories: {
        reset: 'MANAGE_LOYALTY_REWARDS_CATEGORIES_RESET',
        setData: 'MANAGE_LOYALTY_REWARDS_CATEGORIES_SET_DATA',
        setError: 'MANAGE_LOYALTY_REWARDS_CATEGORIES_SET_ERROR',
        setLoading: 'MANAGE_LOYALTY_REWARDS_CATEGORIES_SET_LOADING',
    },
};
