import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './ModalPopup.module.css';

const switchBodyClass = addClass => (
    document
        .querySelector('body')
        .classList[addClass ? 'add' : 'remove']('modal-shown')
);

const modalRoot = document.getElementById('modal-root');

class ModalPopup extends React.Component {
    el;

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        this.updateBodyClass();

        if (modalRoot) {
            modalRoot.appendChild(this.el);
        }
    }

    componentDidUpdate() {
        if (typeof this.props.show !== 'undefined') {
            this.updateBodyClass();
        }
    }

    componentWillUnmount() {
        if (this.props.show) {
            switchBodyClass(false);
        }
        if (modalRoot) {
            modalRoot.removeChild(this.el);
        }
    }

    onOverlayClick(event) {
        if (event.target.classList.contains(styles.overlay)) {
            // We should close popup if clicked outside.
            switchBodyClass(false);
            this.props.onClose();
        }
    }

    updateBodyClass = () => {
        if (this.props.show) {
            // Alter body to avoid scroll.
            switchBodyClass(true);
        } else {
            switchBodyClass(false);
        }
    };

    render() {
        const { children, className, containerClassName, size, show, onClose } = this.props;
        if (!show) {
            return null;
        }

        return ReactDOM.createPortal(
            <div
                className={cn(styles.overlay, className)}
                onClick={event => this.onOverlayClick(event)}
            >
                <div
                    className={cn(
                        styles.container,
                        styles[`container-${size}`],
                        'client-secondary-colour-background',
                        containerClassName
                    )}
                >
                    {React.Children.map(
                        children,
                        child => (
                            child && React.cloneElement(child, {
                                onClose,
                            })
                        )
                    )}
                </div>
            </div>,
            this.el
        );
    }
}

ModalPopup.switchBodyClass = switchBodyClass;

ModalPopup.defaultProps = {
    children: '',
    className: '',
    containerClassName: '',
    size: 'small',
    show: false,
};

ModalPopup.SIZE = {
    LIGHT: 'light',
    SMALL: 'small',
    TEMPLATE_EDIT: 'template-edit',
};

ModalPopup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    size: PropTypes.oneOf(Object.keys(ModalPopup.SIZE).map(v => ModalPopup.SIZE[v])),
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default ModalPopup;
