import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import usePrevious from 'hooks/usePrevious';

const FormikChangeSubmit = ({ delay = 0 }) => {
    const {
        submitForm,
        // Ignore values that are not controlled by form.
        values: { campaignId, offset, limit, ...values },
    } = useFormikContext();
    const timeoutId = useRef(0);

    const prevValues = usePrevious(values);
    useEffect(() => {
        if (prevValues && !isEqual(values, prevValues)) {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
            timeoutId.current = setTimeout(() => submitForm(), delay);
        }
    }, [values, prevValues, submitForm, delay]);

    return null;
};

export default FormikChangeSubmit;
