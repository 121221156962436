import PropTypes from 'prop-types';
import { SystemLanguage } from 'propTypes/systemLanguage';

export const FilterTranslation = PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.string,
    description: PropTypes.string,
    language: SystemLanguage,
});

const FilterTag = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    displayOrder: PropTypes.number.isRequired,
    allowPreFilter: PropTypes.bool.isRequired,
    allowLibraryPreFilter: PropTypes.bool.isRequired,
    translations: PropTypes.arrayOf(FilterTranslation),
};

export const FilterEntry = PropTypes.shape({
    ...FilterTag,
    group: FilterTag,
});
