import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { Api, getFileContent, t } from '../../utils';
import Button from '../Button/Button';

import styles from './Input.module.css';

const isFileTypeAllowed = (file, allowedFileTypes) => {
    if (!allowedFileTypes) {
        return true;
    }
    const escapedString = allowedFileTypes.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    return new RegExp(`(${escapedString.replace(/,/g, '|')})$`).test(file.name.toLowerCase());
};

const isFileSizeOk = (file, maxFileSize) => {
    if (!maxFileSize) {
        return true;
    }
    return file.size <= maxFileSize;
};

class FileUploader extends Component {
    state = {
        isDragOver: false,
        uploading: false,
        uploadProgress: 0,
        uploaded: false,
        previewSource: '',
    };

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if (prevProps.value !== value && !value) {
            this.resetPreview();
        }
    }

    onChange = e => {
        const { fileTypes, maxFileSize, showPreview } = this.props;
        const file = e.target.files[0];

        if (!file) {
            return false;
        }

        if (!isFileTypeAllowed(file, fileTypes)) {
            this.setError(t('pmApp.html5uploader.directive.pmFileUploaderController.file_type_is_not_allowed'));
            return false;
        }

        if (!isFileSizeOk(file, maxFileSize)) {
            this.setError(t('pmApp.html5uploader.directive.pmFileUploaderController.file_too_big'));
            return false;
        }

        if (showPreview) {
            this.previewFile(file);
        } else {
            this.uploadFile(file);
        }

        return true;
    };

    setError = error => {
        const { form, name, errorFieldName } = this.props;

        const _name = errorFieldName || name;

        if (form?.setFieldValue && error) {
            form.setFieldValue(_name, null);
        }

        if (form?.setFieldTouched) {
            form.setFieldTouched(_name, true, false);
        }

        if (form?.setFormikState) {
            form.setFormikState(state => ({
                ...state,
                customErrors: set(cloneDeep(state.customErrors || {}), _name, error),
            }));
        }
    };

    resetPreview = () =>
        this.setState({
            uploaded: false,
            previewSource: '',
            isDragOver: false,
            uploading: false,
            uploadProgress: 0,
        });

    previewFile = file => {
        const { previewCallback } = this.props;
        this.setState({ uploading: true });

        getFileContent(file).then(fileContent => {
            this.setState({ uploading: false }, () => {
                if (previewCallback) {
                    previewCallback(fileContent, () => this.uploadFile(file));
                } else {
                    this.setState({
                        uploaded: true,
                        previewSource: fileContent,
                    });
                    this.uploadFile(file);
                }
            });
        });
    };

    onChangeFormValue = value => {
        const { id, name, onChange, form } = this.props;

        // Make it compatible with Formik.
        onChange({ target: { id, name, value } });

        if (form.setFormikState) {
            form.setFormikState(state => ({
                ...state,
                values: {
                    ...state.values,
                    [name]: value,
                },
                touched: {
                    ...state.touched,
                    [name]: true,
                },
                customErrors: {
                    ...state.customErrors,
                    [name]: '',
                },
            }));
        }
    };

    uploadFile = file => {
        const { uploadUrl, uploadParamName, uploadSuccess, uploadError, form, name, uploadResultKey } = this.props;

        if (!uploadUrl) {
            this.onChangeFormValue(file);
            this.setState({
                uploading: false,
                uploaded: true,
                uploadProgress: 100,
            });
            return Promise.resolve(file);
        }

        this.setState({
            uploading: true,
            uploadProgress: 0,
        });

        return Api.upload(uploadUrl, file, {
            uploadParamName,
        })
            .then(data => {
                if (data.status === 'error') {
                    this.setError(data.messages);
                    this.setState({ uploading: false });
                } else {
                    this.setState({
                        uploading: false,
                        uploaded: true,
                        uploadProgress: 100,
                    });
                    if (uploadSuccess) {
                        uploadSuccess(data);
                    }
                    this.setError('');
                    if (uploadResultKey && form?.setFieldValue) {
                        form.setFieldValue(name, data[uploadResultKey]);
                    }
                }
            })
            .catch(e => {
                if (e.json) {
                    e.json().then(msg => {
                        const customMessage = uploadError && uploadError(msg);
                        this.setError(customMessage || msg.errors || msg.error || msg.message);
                    });
                } else {
                    throw e;
                }
                this.setState({ uploading: false });
            });
    };

    render() {
        const { isDragOver, uploading, uploadProgress, uploaded, previewSource } = this.state;
        const { className, icon, fileTypes, message, hideDragAndDropHelpMessage, disabled } = this.props;
        return (
            <div className={cn(styles.fileUploader, disabled && styles.inputDisabled, className)}>
                {!!icon && (
                    <p>
                        <img
                            src={icon}
                            alt={t('general.pm_file_uploader.file_names_ending_in', { allowed_file_types: fileTypes })}
                        />
                    </p>
                )}
                {!uploaded && !!message && <p>{message}</p>}
                <p>
                    {!hideDragAndDropHelpMessage && <span>{t('general.pm_file_uploader.you_can_drag_and_drop')}</span>}
                    {isDragOver && <span>{t('general.pm_file_uploader.now_drop_it')}</span>}
                </p>
                {uploading && uploadProgress < 100 && <p>{t('general.pm_file_uploader.uploading')}</p>}
                {uploading && uploadProgress === 100 && <p>{t('general.pm_file_uploader.saving')}</p>}
                {uploaded && (
                    <div id="successElement">
                        {!previewSource && (
                            <p>{t('pmApp.html5uploader.directive.pmFileUploaderController.file_saved')}</p>
                        )}
                        {!!previewSource && (
                            <img alt="Preview" className={styles.fileUploaderButton} src={previewSource} width="300" />
                        )}
                    </div>
                )}
                {!uploading && (
                    <Fragment>
                        <Button color={Button.COLOR.GRAY} tiny className={styles.fileUploaderButton} icon="view">
                            {t('general.pm_file_uploader.browse')}
                        </Button>
                        {!disabled && <input type="file" onChange={this.onChange} />}
                    </Fragment>
                )}
            </div>
        );
    }
}

FileUploader.defaultProps = {
    fileTypes: '.png,.jpg,.jpeg,.gif',
    hideDragAndDropHelpMessage: false,
    maxFileSize: 10000000,
    showPreview: false,
    previewCallback: null,
    uploadResultKey: 'fileSystemUrl',
};

FileUploader.propTypes = {
    className: PropTypes.string,
    fileTypes: PropTypes.string,
    icon: PropTypes.string,
    message: PropTypes.any,
    hideDragAndDropHelpMessage: PropTypes.bool,
    maxFileSize: PropTypes.number,
    showPreview: PropTypes.bool,
    previewCallback: PropTypes.func,
    uploadSuccess: PropTypes.func,
    uploadError: PropTypes.func,
    uploadUrl: PropTypes.string,
    uploadParamName: PropTypes.string,
    form: PropTypes.object.isRequired,
    name: PropTypes.string,
    /**
     * Can be used for setting the errors for another field.
     */
    errorFieldName: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    uploadResultKey: PropTypes.string,
};

export default FileUploader;
