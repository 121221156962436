import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import useCoBrandableImage from 'pages/manage/Templates/Template/hooks/useCoBrandableImage';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';

export default function EditableAreaImage({ src, ...rest }) {
    const { url, isCoBrandable, loading } = useCoBrandableImage(src);
    const [brokenImageHidden, setBrokenImageHidden] = useState(false);

    const { onError: onErrorOuter } = rest;

    const onError = useCallback((err) => {
        setBrokenImageHidden(true);
        if (onErrorOuter) {
            onErrorOuter(err);
        }
    }, [onErrorOuter]);

    const style = brokenImageHidden ? { display: 'none', ...rest.style } : rest.style;

    const img = (
        <img
          src={url}
          {...rest}
          onError={onError}
          style={style}
        />
    );
    return isCoBrandable && loading ? (
        <LoadingContainer loading opacity>
            {img}
        </LoadingContainer>
    ) : (
        img
    );
}

EditableAreaImage.propTypes = {
    src: PropTypes.string.isRequired,
};
