// eslint-disable-next-line import/prefer-default-export
export const ONBOARDING_STEPS = [
    {
        number: '1',
        name: 'onboarding.steps.who_we_are',
        title: 'onboarding.step1.welcome_to_verse_program',
        description: 'onboarding.step1.versapay_is_thrilled',
        footer: false,
        items: [
            {
                key: 'video_ginny_drinker',
                header: 'onboarding.step1.ginny_drinker.header',
                title: 'onboarding.step1.welcome_to_versapay',
                subTitle: 'onboarding.step1.ginny_drinker.sub',
                text: 'onboarding.step1.ginny_drinker.text',
                videoThumbnail: 'onboarding.step1.ginny_drinker.thumb',
                videoUrl: 'onboarding.step1.ginny_drinker.video',
            },
        ],
    },
    {
        number: '2',
        name: 'onboarding.steps.versa_partner_hub',
        title: 'onboarding.step2.hub_title',
        description: 'onboarding.step2.hub_text',
        footer: false,
        items: [
            {
                key: 'video_versa_hub',
                header: 'onboarding.step2.hub.header',
                title: 'onboarding.step2.hub.title',
                text: 'onboarding.step2.hub.text',
                videoThumbnail: 'onboarding.step2.hub.thumb',
                videoUrl: 'onboarding.step2.hub.video',
            },
        ],
    },
    {
        number: '3',
        name: 'onboarding.steps.product_introductions',
        title: 'onboarding.step3.introduction_versapay',
        description: 'onboarding.step3.make_your_priority',
        footer: false,
        items: [
            {
                key: 'video_collaborative_ar',
                header: 'onboarding.step3.overview_with_demo',
                title: 'onboarding.step3.product_overview',
                subTitle: 'onboarding.step3.collaborative_ar',
                text: 'onboarding.step3.collaborative_ar_text',
                videoThumbnail: 'onboarding.step3.collaborative_ar_thumb',
                videoUrl: 'onboarding.step3.collaborative_ar_video',
            },
            {
                key: 'video_intacct_erp_ar',
                header: 'onboarding.step3.intacct_erp_ar.header',
                title: 'onboarding.step3.intacct_erp_ar.title',
                text: 'onboarding.step3.intacct_erp_ar.text',
                videoThumbnail: 'onboarding.step3.intacct_erp_ar.thumb',
                subThumbnail: 'onboarding.step3.intacct_erp_ar.sub_thumb',
                videoUrl: 'onboarding.step3.intacct_erp_ar.video',
            },
            {
                key: 'video_ms_erp',
                header: 'onboarding.step3.ms_erp.header',
                title: 'onboarding.step3.ms_erp.title',
                text: 'onboarding.step3.ms_erp.text',
                videoThumbnail: 'onboarding.step3.ms_erp.thumb',
                subThumbnail: 'onboarding.step3.ms_erp.sub_thumb',
                videoUrl: 'onboarding.step3.ms_erp.video',
            },
            {
                key: 'video_ns_erp',
                header: 'onboarding.step3.ns_erp.header',
                title: 'onboarding.step3.ns_erp.title',
                text: 'onboarding.step3.ns_erp.text',
                videoThumbnail: 'onboarding.step3.ns_erp.thumb',
                subThumbnail: 'onboarding.step3.ns_erp.sub_thumb',
                videoUrl: 'onboarding.step3.ns_erp.video',
            },
        ],
    },
];
