export default {
    creatable: {
        setData: 'MARKETING_ACTIVITY_CREATABLE_SET_DATA',
        setLoading: 'MARKETING_ACTIVITY_CREATABLE_SET_LOADING',
    },
    filters: {
        setData: 'MARKETING_ACTIVITY_FILTERS_SET_DATA',
        setLoading: 'MARKETING_ACTIVITY_FILTERS_SET_LOADING',
    },
    item: {
        setData: 'MARKETING_ACTIVITY_DETAILS_SET_DATA',
        setLoading: 'MARKETING_ACTIVITY_DETAILS_SET_LOADING',
    },
};
