import { Api } from 'utils';
import { error, clear } from 'store/alert/actions';
import { getCurrentUserCompany } from 'selectors/userSelectors';
import * as userActions from '../user/actions';
import ACTIONS from './constants';

const requestUpsert = () => ({
    type: ACTIONS.upsert.load,
});
const successUpsert = data => ({
    type: ACTIONS.upsert.success,
    data,
});
const errorUpsert = () => ({
    type: ACTIONS.upsert.error,
});

export const upsert = (id, data) => async (dispatch, getState) => {
    dispatch(clear());
    dispatch(requestUpsert());

    const { id: currentCompanyId } = getCurrentUserCompany(getState());

    const url = id ? 'partnermarketing_company_update_api' : 'partnermarketing_company_create_api';
    let result = null;
    try {
        result = await Api[id ? 'put' : 'post'](Api.route(url, { id }), data);
        dispatch(successUpsert(result));
        if (currentCompanyId === id) {
            dispatch(userActions.updateCurrentData({ company: result }));
        }
    } catch (e) {
        dispatch(errorUpsert());
        dispatch(error("Can't upsert company."));
        throw e;
    }

    return result;
};

const getCompaniesPromise = {};
/**
 * Get all companies for clientId.
 *
 * @param  {Number} clientId [unique identifier of the Client]
 * @param  {Object} params
 * @return {Object} [returns a promise]
 */
export const getCompanies = (clientId, params = {}) => {
    const _params = {
        id: clientId,
        ...params,
    };
    if (params.withMembers) {
        _params.addMembersCount = 1;
    }

    const key = JSON.stringify(_params);

    if (getCompaniesPromise[key]) {
        return getCompaniesPromise[key];
    }

    getCompaniesPromise[key] = Api.get(Api.route('partnermarketing_client_companies_api', _params));

    return getCompaniesPromise[key];
};
