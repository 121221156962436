import { createSelector } from 'reselect';

const getAssetLibraryData = state => state.asset.library;

export const getCoBrandedAssetLinkSelector = createSelector(
    [getAssetLibraryData],
    ({ coBrandedAssetLink }) => coBrandedAssetLink.data
);
export const getCoBrandedAssetLinkLoadingSelector = createSelector(
    [getAssetLibraryData],
    ({ coBrandedAssetLink }) => coBrandedAssetLink.loading
);
export const getCoBrandedAssetLoadingSelector = createSelector(
    [getAssetLibraryData],
    ({ coBrandedAsset }) => coBrandedAsset.loading
);

export const selectAssetLibraryList = createSelector([getAssetLibraryData], ({ list }) => list.data);

export const selectAssetLibraryCounts = createSelector([getAssetLibraryData], ({ list }) => list.counts);

export const selectAssetLibraryListLoading = createSelector([getAssetLibraryData], ({ list }) => list.loading);

export const selectAssetLibraryListTabs = createSelector([getAssetLibraryData], ({ list }) => [
    { status: 'active', count: list.counts.active },
    { status: 'archived', count: list.counts.archived },
]);

export const getItem = state => state.asset.library.item;

export const selectAssetLibraryAsset = createSelector([getAssetLibraryData], ({ item }) => item.data);
export const selectAssetLibraryAssetLoading = createSelector([getAssetLibraryData], ({ item }) => item.loading);
