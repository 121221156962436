import { useParams } from 'react-router-dom';
import useApi from 'hooks/useApi';

const useApiUpdateTemplateDataAreas = (area, params = {}) => {
    const { id, templateId } = useParams();
    const { doFetch, loading } = useApi({
        slug: 'partnermarketing_template_area_api',
        slugParams: {
            campaignInstanceId: id,
            templateInstanceId: templateId,
        },
        data: {
            area: area.name,
            areaId: area.id,
            position: area.position || 1,
        },
        method: 'PUT',
        lazy: true,
        ...params,
    });

    return {
        apiUpdateTemplateDataAreas: doFetch,
        loading,
    };
};

export default useApiUpdateTemplateDataAreas;
