import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Input.module.css';
import IconStack from '../IconStack/IconStack';

/**
 * Render multiple check boxes as single form input. Can be used for multiple selected values.
 * Value should be an array.
 */
const CheckboxArray = props => (
    <div className={cn(props.horizontal && styles.checkboxArrayHorizontal, props.className)}>
        {props.options
            .filter(o => !!o)
            .map((option, index) => {
                const id = `${props.id}_${index}`;
                return (
                    <label htmlFor={id} key={option.value} className={styles.checkboxArray}>
                        <input
                            id={id}
                            name={props.name}
                            type="checkbox"
                            checked={props.value?.includes(option.value)}
                            disabled={option.disabled}
                            onBlur={props.onBlur}
                            onChange={event => {
                                let newValue;
                                if (event.target.checked) {
                                    newValue = [...props.value, option.value];
                                } else {
                                    newValue = props.value.filter(value => value !== option.value);
                                }
                                props.form.setFieldValue(props.name, newValue);
                            }}
                        />
                        {option.label}
                        {!!option.tip && (
                            <span data-tip={option.tip} className={cn('client-secondary-colour', styles.tipInfo)}>
                                <IconStack
                                    size="05"
                                    outer="bg-circle"
                                    inner="info"
                                    innerClassName={styles.tipInfoIcon}
                                />
                            </span>
                        )}
                    </label>
                );
            })}
    </div>
);

CheckboxArray.propTypes = {
    /**
     * Field name.
     */
    name: PropTypes.string,
    /**
     * Field value. Should be an array.
     */
    value: PropTypes.array,
    /**
     * Gets called when the user blur the field.
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     */
    onBlur: PropTypes.func,
    /**
     * Options to render. Should be an array of {label, value}.
     */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        })
    ).isRequired,
    /**
     * Custom class name.
     */
    className: PropTypes.string,
    /**
     * Field id.
     */
    id: PropTypes.string,
};

export default CheckboxArray;
