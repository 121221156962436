import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isString from 'lodash/isString';

const Select = ({ data, valueField = 'value', textField = 'label', children, ...rest }) => {
    const field = rest.field || {};
    const _children = useMemo(() => {
        if (data) {
            const c = data.map(item => (
                <option value={get(item, valueField)} key={get(item, valueField)}>
                    {isString(textField) ? get(item, textField) : textField(item)}
                </option>
            ));
            if (rest.placeholder || rest.placeholder === '') {
                c.unshift(
                    <option value="" key="placeholder">
                        {rest.placeholder}
                    </option>
                );
            }
            return c;
        }
        return children;
    }, [data, rest.placeholder, children, textField, valueField]);

    return (
        <select
            {...rest}
            {...field}
            value={rest.value ?? field.value ?? ''}
            onChange={e => {
                // e.target.value is ALWAYS string. Fix this issue.
                const selectedChild = React.Children.toArray(_children)
                    .filter(child => e.target.value === String(child.props.value))
                    .pop();
                let _e = e;
                if (selectedChild) {
                    _e = {
                        target: {
                            id: e.target.id,
                            name: e.target.name,
                            value: selectedChild.props.value,
                        },
                    };
                }
                if (field.onChange) {
                    field.onChange(_e);
                }
                if (rest.onChange && field.onChange !== rest.onChange) {
                    rest.onChange(_e);
                }
            }}
        >
            {_children}
        </select>
    );
};

Select.propTypes = {
    data: PropTypes.array,
    valueField: PropTypes.string,
    textField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    children: PropTypes.any,
};

export default Select;
