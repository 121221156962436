import React from 'react';
import PropTypes from 'prop-types';
import { moneyFormatter } from '../../../utils';

const FormatMoney = ({ value }) => <div>{moneyFormatter(value)}</div>;

FormatMoney.propTypes = {
    value: PropTypes.any,
};

export default FormatMoney;
