import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input/Input';
import { Field, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { addUserImage } from 'store/user/actions';
import { Api, t } from 'utils';
import { bool, object } from 'yup';
import ImageTypeUploadPreview from './ImageTypeUploadPreview';
import ImageTypeUploadSelect from './ImageTypeUploadSelect';
import useImageTypeUploadReducer, { STEPS } from './useImageTypeUploadReducer';
import styles from './ImageTypeField.module.scss';

const getStepText = step => {
    switch (step) {
        case STEPS.UPLOAD:
            return t('template.template_wrapper.please_upload_image');
        case STEPS.CONFIRM:
            return t('template.template_wrapper.please_confirm_image');
        case STEPS.SELECT:
            return t('template.template_wrapper.please_click_image');
        default:
            throw new Error(`Unsupported step ${step}`);
    }
};

const getValidationSchema = () =>
    object({
        hasRights: bool()
            .required(t('template.template_wrapper.please_confirm'))
            .oneOf([true], t('template.template_wrapper.please_confirm')),
    });

const ImageTypeUpload = ({ onSave }) => {
    const {
        state: { preview, uploadCallback, url, step },
        setPreview,
        setUrl,
        reset,
    } = useImageTypeUploadReducer();

    const dispatch = useDispatch();
    const successCallback = resp => {
        dispatch(addUserImage(resp));
        setUrl(resp.fileSystemUrl);
    };

    const selectCallback = resetForm => {
        onSave(url);
        resetForm();
    };

    return (
        <Formik
            initialValues={{ value: '' }}
            enableReinitialize
            validationSchema={getValidationSchema()}
            onSubmit={uploadCallback}
            onReset={reset}
        >
            {({ handleReset, handleSubmit }) => (
                <>
                    <p className={styles.text}>{getStepText(step)}</p>
                    <div className={preview ? styles.hidden : ''}>
                        <Field
                            name="file"
                            component={Input}
                            type={Input.TYPE.FILE}
                            fileTypes=".jpg,.jpeg,.png,.gif"
                            maxFileSize={5242880}
                            uploadParamName="file[file]"
                            showPreview
                            previewCallback={setPreview}
                            uploadSuccess={successCallback}
                            uploadUrl={Api.route('partnermarketing_user_library_upload_image')}
                            padding={Input.PADDING.SMALL}
                        />
                    </div>
                    {step === STEPS.CONFIRM && (
                        <ImageTypeUploadPreview preview={preview} onUpload={handleSubmit} onCancel={handleReset} />
                    )}
                    {step === STEPS.SELECT && (
                        <ImageTypeUploadSelect
                            preview={preview}
                            onReset={handleReset}
                            onSelect={() => selectCallback(handleReset)}
                        />
                    )}
                </>
            )}
        </Formik>
    );
};

ImageTypeUpload.propTypes = {
    onSave: PropTypes.func.isRequired,
};

export default ImageTypeUpload;
