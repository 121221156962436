import ACTIONS from './constants';

const initialState = {
    id: 0,
    list: [],
    modal: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.add: {
            const { id } = state;
            const { message, kind, duration, onClose } = action;
            return {
                ...state,
                id: id + 1,
                list: [...state.list, { id, message, kind, duration, onClose }],
            };
        }

        case ACTIONS.decreaseDuration: {
            return {
                ...state,
                list: state.list
                    .filter(({ duration }) => duration > 1)
                    .map(item => ({ ...item, duration: item.duration - 1 })),
            };
        }

        case ACTIONS.clear: {
            return {
                ...state,
                list: [],
            };
        }

        case ACTIONS.remove: {
            const { id } = action;
            return {
                ...state,
                id: id + 1,
                list: state.list.filter(({ id: alertId }) => alertId !== id),
            };
        }

        case ACTIONS.modal: {
            const { type, ...details } = action;
            return {
                ...state,
                modal: details,
            };
        }

        case ACTIONS.modalClose: {
            return {
                ...state,
                modal: null,
            };
        }

        default: {
            return state;
        }
    }
};

export default reducer;
