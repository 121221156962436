import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './StepperBig.module.scss';

const arrows = ['arrowTopLeft', 'arrowTopRight', 'arrowBottomLeft', 'arrowBottomRight'];

const StepperBig = ({ activeStep, steps, arrowClassName, activeClassName, stepClassName, stepPartClassName }) => (
    <ul className={styles.wrapper}>
        {steps.map((step, index) => (
            <li
                key={index}
                className={cn(
                    styles.step,
                    stepClassName,
                    index === activeStep && 'client-main-colour',
                    index === activeStep && activeClassName,
                    index < activeStep && styles.pass,
                )}
                style={{ zIndex: 10 - index }}
            >
                {arrows.map((key) => (
                    <span
                        key={key}
                        className={cn(
                            styles[key],
                            stepPartClassName,
                            index === activeStep && 'client-20pc-colour-background client-20pc-colour-border',
                            arrowClassName,
                        )}
                    />
                ))}
                <span className={styles.content}>{step}</span>
            </li>
        ))}
    </ul>
);

StepperBig.propTypes = {
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
    arrowClassName: PropTypes.string,
    activeClassName: PropTypes.string,
    stepClassName: PropTypes.string,
    stepPartClassName: PropTypes.string,
};

export default StepperBig;
