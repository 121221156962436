import { useCallback } from 'react';
import { useField } from 'formik';
import useIsInstanceEditor from 'hooks/useIsInstanceEditor';

const usePreBuiltToggleField = fieldName => {
    const isCampaignInstance = useIsInstanceEditor();
    const [{ value: fieldData }, , { setValue }] = useField(fieldName);

    return useCallback(() => {
        const update = isCampaignInstance ? { isActive: !fieldData.isActive } : { isDeleted: !fieldData.isDeleted };
        setValue({ ...fieldData, ...update });
    }, [fieldData, isCampaignInstance, setValue]);
};

export default usePreBuiltToggleField;
