import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { lang } from '../../utils';
import DropDown from '../DropDown/DropDown';
import Button from '../Button/Button';
import styles from './LanguageSwitcher.module.css';
import Icon from '../Icon/Icon';
import { getCurrentUser } from '../../selectors/userSelectors';
import { languageActions, userActions } from '../../store';

class LanguageSwitcher extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        languages: PropTypes.array,
        save: PropTypes.func,
        getLanguages: PropTypes.func,
    };

    componentDidMount() {
        const { getLanguages } = this.props;
        getLanguages();
    }

    switchLanguage = language => {
        const { currentUser, save } = this.props;
        lang(language.language.code);
        save(currentUser.id, { displayLanguage: language.id });
    };

    render() {
        const { currentUser, languages } = this.props;

        if (isEmpty(currentUser)) {
            return null;
        }

        const { displayLanguage } = currentUser;
        const currentLanguage = displayLanguage.language.name;
        const languagesToSwitch = [];

        languages.forEach(language => {
            if (language.language.id !== displayLanguage.language.id) {
                languagesToSwitch.push({
                    onClick: () => this.switchLanguage(language),
                    label: language.language.localName,
                });
            }
        });

        return (
            <DropDown
                items={languagesToSwitch}
                noIcon
                className={cn(styles.switcherDropDown, languages.length === 1 ? styles.empty : '')}
            >
                <Button
                    bordered
                    className={cn(styles.switcherDropDownButton, 'client-top-nav-text-colour', 'not-global')}
                    color={Button.COLOR.GRAY}
                >
                    <Icon kind="globus" className={styles.switcherDropDownIcon} />
                    <span className={styles.switcherDropDownButtonSpan}>{currentLanguage}</span>
                    {!isEmpty(languages) && languages.length > 1 && (
                        <Icon kind="arrow-down" className={styles.switcherDropDownArrow} />
                    )}
                </Button>
            </DropDown>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: getCurrentUser(state),
    languages: state.language.list.data,
});

const mapDispatchToProps = dispatch => ({
    save: (id, values) => dispatch(userActions.upsert(id, values)),
    getLanguages: () => dispatch(languageActions.list()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
