import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import Icon from '../Icon/Icon';
import styles from './Input.module.css';

const ValidationIcon = props => {
    const { required, fieldTouched, fieldError, ...rest } = props;
    const field = rest.field || rest;
    const isEmptyValue = isArray(field.value) ? isEmpty(field.value) : !field.value && field.value !== 0;
    let kind = '';
    if (fieldTouched && fieldError) {
        kind = 'cross';
    } else if (isEmptyValue && required) {
        kind = 'asterisk';
    } else if (required && !isEmptyValue && !fieldError) {
        kind = 'tick';
    }
    return (
        <Icon kind={kind} className={cn(styles.validationIcon, styles[`validationIcon-${kind}`], 'validation-icon')} />
    );
};

ValidationIcon.propTypes = {
    required: PropTypes.bool,
    field: PropTypes.object,
    fieldTouched: PropTypes.bool,
    fieldError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default ValidationIcon;
