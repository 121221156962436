import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Pagination.module.css';
import Button from '../../Button/Button';

const Page = ({ page, active, onClick, className, disabled }) => (
    <Button
        type="button"
        className={cn(styles.page, active && styles.active, 'not-global', className)}
        onClick={onClick}
        tiny
        color={Button.COLOR.GRAY}
        disabled={disabled}
    >
        {page}
    </Button>
);

Page.propTypes = {
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    active: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Page;
