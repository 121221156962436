import React from 'react';
import { Field, useFormikContext } from 'formik';
import useApiSalesResources from 'apiRequests/useApiSalesResources';
import Input from 'components/Input/Input';
import EmptyContent from 'components/EmptyContent/EmptyContent';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import PreviewableList from 'components/PreviewableList/PreviewableList';
import { ROUTE } from 'constants.js';
import { t } from 'utils';
import styles from './LinkTypeAssetLibrary.module.scss';

const LinkTypeSalesResource = () => {
    const { values, setFieldValue } = useFormikContext();
    const { salesResources, loading } = useApiSalesResources();
    const isSelected = resource => resource.pdfUrl === values.url;
    const onSelect = resource => {
        setFieldValue('url', resource.pdfUrl, false);
        setFieldValue('salesResourceThumbnail', resource.image, false);
    };

    return (
        <LoadingContainer loading={loading}>
            {salesResources.length ? (
                <PreviewableList
                    list={salesResources}
                    isSelected={isSelected}
                    onSelect={onSelect}
                    renderPreview={resource => (
                        <div className={styles.salesPreview}>
                            <img src={resource.image} alt={resource.name} />
                            <p>
                                <strong>{resource.name}</strong>
                            </p>
                            <p>{t('general.labels.type')}: test</p>
                        </div>
                    )}
                    renderListItem={({ itemClass, item: resource }) => (
                        <>
                            <span className={itemClass}>
                                <img src={resource.image} alt={resource.name} />
                            </span>
                            <strong className={styles.text}>{resource.name}</strong>
                        </>
                    )}
                />
            ) : (
                <div className={styles.empty}>
                    <EmptyContent
                        image={EmptyContent.IMAGE.DOCUMENTS}
                        text={t('template.sales_resources.you_havenot_sales_resources')}
                        action={{
                            text: t('template.sales_resources.create_sales_resource'),
                            link: ROUTE.salesResources.resource.create,
                            small: true,
                        }}
                    />
                </div>
            )}
            <Field component={Input} type="hidden" name="url" hideValidation padding={Input.PADDING.NONE} />
        </LoadingContainer>
    );
};

export default LinkTypeSalesResource;
