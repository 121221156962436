import React, { useCallback } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './AppSwitcher.module.css';
import Icon from '../Icon/Icon';

const AppSwitcher = ({ apps, currentApp, switchCallback }) => {
    const switchAppCallback = useCallback(app => () => switchCallback(app), [switchCallback]);
    if (apps.length <= 1) {
        return null;
    }

    const detailsClass = apps[apps.length - 1].count ? styles.applicationSwitcherDetails : '';

    return (
        <div className={cn(styles.applicationSwitcher, detailsClass)}>
            {
                apps.map((app, index) => (
                    <div
                        key={index}
                        className={cn(
                            styles.applicationTab,
                            app.name === currentApp ? styles.applicationTabActive : '',
                            app.name === currentApp
                                ? 'client-10pc-colour-background client-main-colour-border' : '',
                            index === apps.length - 1 ? styles.applicationTabLast : '',
                            index === 0 ? styles.applicationTabFirst : ''
                        )}
                        onClick={switchAppCallback(app)}
                    >
                        <div
                            className={cn(
                                styles.appIcon,
                                app.iconClass,
                                styles[`${app.iconClass}${app.name}`],
                                app.name === currentApp ? 'client-main-colour-background' : '',
                                (app.count || app.iconClass.indexOf('appSwitcherIcon') === -1) && styles.appIconDetails
                            )}
                        >
                            {
                                app.count && (
                                    <div className={styles.itemsCount}>{app.count ? app.count : ''}</div>
                                )
                            }
                            {
                                app.iconClass && app.iconClass.indexOf('appSwitcherIcon') === -1 && (
                                    <div className={styles.itemsCount}>
                                        <Icon kind="info" />
                                    </div>
                                )
                            }
                        </div>
                        <div className={cn(styles.appTitle, styles.appTitleDetails)}>
                            {app.title}
                        </div>
                        <div className={styles.borderArrowWrapper}>
                            <div
                                className={cn(
                                    styles.borderArrow,
                                    'client-10pc-colour-background client-main-colour-border'
                                )}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

AppSwitcher.propTypes = {
    apps: PropTypes.array,
    currentApp: PropTypes.string,
    switchCallback: PropTypes.func,
};

export default AppSwitcher;
