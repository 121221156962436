import { matchPath, useLocation } from 'react-router-dom';
import { ROUTE } from 'constants.js';

const useIsSocialPostInstanceEditor = () => {
    const location = useLocation();

    return !!matchPath(location.pathname, ROUTE.socialPosts.edit());
};

export default useIsSocialPostInstanceEditor;
