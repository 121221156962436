import { combineReducers } from 'redux';
import ACTIONS from './constants';
import { LOYALTY_REWARDS_INSTANCE_TYPE, LOYALTY_REWARDS_STATUS } from '../../manageConstants';

const listInitialState = {
    data: {
        counts: {},
        list: [],
        total: 0,
    },
    loading: false,
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const instancesListReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.instancesList.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.instancesList.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const itemInitialState = {
    data: {
        status: LOYALTY_REWARDS_STATUS.ACTIVE,
        name: '',
        isEndOngoing: false,
        isNeedCta: false,
        tiers: [],
        rewardClubs: [],
    },
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.item.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

const instanceInitialState = {
    data: {
        isVisible: false,
        type: LOYALTY_REWARDS_INSTANCE_TYPE.MARKETING,
        cost: 0,
        countries: [],
        loyalty_reward: {
            name: '',
        },
        module: {
            module: {
                module: '',
            },
        },
    },
    loading: false,
};

const instanceReducer = (state = instanceInitialState, action) => {
    switch (action.type) {
        case ACTIONS.instance.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.instance.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.instance.reset: {
            return instanceInitialState;
        }

        default: {
            return state;
        }
    }
};

const categoriesInitialState = {
    data: [],
    loading: false,
};

const categoriesReducer = (state = categoriesInitialState, action) => {
    switch (action.type) {
        case ACTIONS.categories.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.categories.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.categories.reset: {
            return categoriesInitialState;
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    list: listReducer,
    instancesList: instancesListReducer,
    item: itemReducer,
    instance: instanceReducer,
    categories: categoriesReducer,
});
