import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import keys from 'lodash/keys';
import cn from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { t } from 'utils';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableLoader from './TableLoader';
import Pagination from './Pagination/Pagination';
import styles from './Table.module.css';
import CheckBox from '../CheckBox/CheckBox';
import Button from '../Button/Button';

const DroppableFake = props =>
    props.children({
        droppableProps: {},
    });

function DragDropContextFake({ children }) {
    return children;
}
DragDropContextFake.propTypes = {
    children: PropTypes.node,
};

class TableFetchable extends Component {
    state = {
        selectedRows: {},
    };

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    shouldComponentUpdate(nextProps) {
        return !(
            nextProps.skipComponentUpdate ||
            (nextProps.checkPropsChangeToSkipComponentUpdate && Object.is(nextProps.data, this.props.data))
        );
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    onDragEnd = ({ draggableId, destination, source }) => {
        if (destination) {
            const itemId = draggableId.replace('row-', '');
            this.props.onSwap({
                itemId: itemId.match(/^\d+$/) ? parseInt(itemId, 10) : itemId,
                from: source.index,
                to: destination.index,
            });
        }
    };

    changePage = page => this.props.changePageCallback(page);

    selectRow = row => {
        const { selectedRows } = this.state;

        if (selectedRows[row.id]) {
            const { [row.id]: remove, ...rest } = selectedRows;
            this.setState({ selectedRows: rest });
        } else {
            selectedRows[row.id] = row;
            this.setState({
                selectedRows: {
                    ...selectedRows,
                    [row.id]: row,
                },
            });
        }
    };

    selectAll = () => {
        const { data } = this.props;
        const gridData = data.result ? data.result : data;
        const selectedCount = Object.keys(this.state.selectedRows).length;

        const selectedRows = {};
        if (selectedCount < gridData.length) {
            gridData.forEach(item => (selectedRows[item.id] = item));
        }

        this.setState({ selectedRows });
    };

    deselectAll = () => {
        this.setState({ selectedRows: {} });
    };

    sortData(columnName) {
        if (!this.props.sortDataCallback) {
            throw new Error('sortDataCallback is undefined');
        }

        const sortableColumns = this.props.columns
            .filter(({ sortable }) => sortable)
            .map(({ key, sortField }) => sortField || key);

        if (this.props.dataLoading || sortableColumns.indexOf(columnName) === -1) {
            return;
        }

        const { sortBy, sortAsc } = this.props;
        const sortDirection = sortBy === columnName ? !sortAsc : false;

        this.props.sortDataCallback(columnName, sortDirection);
    }

    render() {
        const { selectedRows } = this.state;
        const {
            columns,
            rowActions,
            data,
            dataLoading,
            rowClassName,
            enablePaging,
            pageSize,
            currentPage,
            className,
            swappable,
            rowActionsClassName,
            sortBy,
            sortAsc,
            batchActions = [],
            subHeader,
            noHeader,
            markDefaultSortable,
            footerComponent,
        } = this.props;
        const hasRowActions = rowActions.filter(action => !!action).length > 0;
        const selectedCount = keys(selectedRows).length;
        const disableHeader = dataLoading;
        const gridData = enablePaging && data.result ? data.result : data;
        const hasBatchActions = batchActions.filter(action => !!action).length > 0 && gridData.length > 0;

        if (!Array.isArray(gridData)) {
            throw new Error('Invalid data type, grid data should be an array');
        }

        const droppableId = 'table-drop-1';
        const DragDropContextComponent = swappable ? DragDropContext : DragDropContextFake;
        const DroppableComponent = swappable ? Droppable : DroppableFake;
        const DraggableComponent = swappable ? Draggable : DroppableFake;

        return (
            <div
                className={cn(
                    styles.datagrid,
                    swappable && styles.datagridSwappable,
                    dataLoading && styles.datagridLoading,
                    className
                )}
            >
                {hasBatchActions && (
                    <div className={styles.batchActions}>
                        <CheckBox
                            color={CheckBox.COLOR.CLIENT}
                            checked={selectedCount === gridData.length}
                            onChange={this.selectAll}
                            label={t('general.labels.select_all')}
                        />
                        {selectedCount > 0 && (
                            <div className={styles.batchActionsWrapper}>
                                {batchActions.map(batchAction => {
                                    if (!batchAction) {
                                        return null;
                                    }

                                    return (
                                        <Button
                                            key={batchAction.title}
                                            color={Button.COLOR.GRAY}
                                            tiny
                                            {...batchAction}
                                            onClick={() => batchAction.onClick(selectedRows, this.deselectAll)}
                                        >
                                            {batchAction.title}
                                        </Button>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
                <DragDropContextComponent onDragEnd={this.onDragEnd}>
                    <table>
                        {!noHeader && (
                            <TableHeader
                                columns={columns}
                                hasRowActions={hasRowActions}
                                sortAsc={sortAsc}
                                sortBy={sortBy}
                                sortCallback={key => this.sortData(key)}
                                disabled={disableHeader}
                                swappable={swappable}
                                rowActionsClassName={rowActionsClassName}
                                hasBatchActions={hasBatchActions}
                                subHeader={subHeader}
                                markDefaultSortable={markDefaultSortable}
                            />
                        )}

                        <DroppableComponent
                            droppableId={droppableId}
                            direction="vertical"
                            placeholder={<div className={styles.placeholder} />}
                        >
                            {droppableProvided => (
                                <tbody
                                    className={styles.body}
                                    ref={droppableProvided.innerRef}
                                    {...droppableProvided.droppableProps}
                                >
                                    {dataLoading && (
                                        <TableLoader
                                            columns={columns}
                                            hasRowActions={hasRowActions}
                                            hasBatchActions={hasBatchActions}
                                            swappable={swappable}
                                        />
                                    )}
                                    {!dataLoading &&
                                        gridData.map((dataItem, dataKey) => (
                                            <DraggableComponent
                                                key={dataItem.id || dataKey}
                                                draggableId={`row-${dataItem.id || dataKey}`}
                                                index={dataKey}
                                            >
                                                {draggableProvided => (
                                                    <TableRow
                                                        innerRef={draggableProvided.innerRef}
                                                        provided={draggableProvided}
                                                        dataItem={dataItem}
                                                        columns={columns}
                                                        rowActions={rowActions}
                                                        rowActionsClassName={rowActionsClassName}
                                                        className={rowClassName(dataItem)}
                                                        swappable={swappable}
                                                        hasRowActions={hasRowActions}
                                                        hasBatchActions={hasBatchActions}
                                                        onSelectRow={this.selectRow}
                                                        selected={!!selectedRows[dataItem.id]}
                                                        index={dataKey}
                                                    />
                                                )}
                                            </DraggableComponent>
                                        ))}
                                    {droppableProvided.placeholder}
                                </tbody>
                            )}
                        </DroppableComponent>
                    </table>
                </DragDropContextComponent>
                {(enablePaging || !!this.props.footer) && (
                    <div className={styles.footer}>
                        {footerComponent && <div>{footerComponent}</div>}
                        <div>
                            {enablePaging && (
                                <Pagination
                                    total={data.result ? data.total : data.length}
                                    pageSize={pageSize}
                                    current={currentPage}
                                    onChange={this.changePage}
                                />
                            )}
                        </div>
                        {!!this.props.footer && this.props.footer}
                    </div>
                )}
            </div>
        );
    }
}

TableFetchable.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            title: PropTypes.string,
            renderer: PropTypes.func,
        })
    ).isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    dataLoading: PropTypes.bool,
    enablePaging: PropTypes.bool,
    changePageCallback: PropTypes.func,
    footer: PropTypes.object,
    rowActions: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({
                title: PropTypes.string,
                className: PropTypes.string,
                link: PropTypes.func,
                callback: PropTypes.func,
                displayCallback: PropTypes.func,
            }),
        ])
    ),
    rowActionsClassName: PropTypes.string,
    rowClassName: PropTypes.func,
    sortAsc: PropTypes.bool,
    sortBy: PropTypes.string,
    sortDataCallback: PropTypes.func,
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    skipComponentUpdate: PropTypes.bool,
    checkPropsChangeToSkipComponentUpdate: PropTypes.bool,
    swappable: PropTypes.bool,
    onSwap: PropTypes.func,
    batchActions: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({
                title: PropTypes.string,
                className: PropTypes.string,
                onClick: PropTypes.func,
            }),
        ])
    ),
    subHeader: PropTypes.string,
    noHeader: PropTypes.bool,
    markDefaultSortable: PropTypes.bool,
    footerComponent: PropTypes.object,
};

TableFetchable.defaultProps = {
    rowActions: [],
    rowClassName: () => null,
    dataLoading: false,
    footer: null,
    enablePaging: true,
    pageSize: 10,
    changePageCallback: () => {},
    sortDataCallback: () => {},
    skipComponentUpdate: false,
    checkPropsChangeToSkipComponentUpdate: false,
    swappable: false,
    markDefaultSortable: false,
    footerComponent: null,
};

export default TableFetchable;
