import activity from 'pages/activity/redux/reducer';
import asset from 'pages/asset/redux/reducer';
import campaign from 'pages/campaign/redux/reducer';
import campaignInstance from 'pages/campaignInstance/redux/reducer';
import incentive from 'pages/incentive/redux/reducer';
import lead from 'pages/lead/redux/reducer';
import loyaltyProgram from 'pages/loyaltyProgram/redux/reducer';
import manage from 'pages/manage/redux/reducer';
import marketingStore from 'pages/marketingStore/redux/reducer';
import opportunity from 'pages/opportunity/redux/reducer';
import opportunityLead from 'pages/opportunityLead/redux/reducer';
import report from 'pages/report/redux/reducer';
import salesResources from 'pages/salesResources/redux/reducer';
import spiff from 'pages/spiff/redux/reducer';
import basket from 'pages/user/Basket/redux/reducer';
import { combineReducers } from '@reduxjs/toolkit';
import alert from 'store/alert/reducer';
import company from 'store/company/reducer';
import countryCode from 'store/countryCode/reducer';
import language from 'store/language/reducer';
import timezone from 'store/timezone/reducer';
import user from 'store/user/reducer';

export const rootReducer = combineReducers({
    alert,
    asset,
    basket,
    lead,
    report,
    marketingStore,
    company,
    countryCode,
    timezone,
    language,
    spiff,
    manage,
    user,
    campaign,
    campaignInstance,
    activity,
    loyaltyProgram,
    salesResources,
    incentive,
    opportunity,
    opportunityLead,
});

export type RootState = ReturnType<typeof rootReducer>;
