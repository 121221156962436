import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import IconStack from 'components/IconStack/IconStack';
import styles from './Input.module.css';

type InputTooltipIconProps = {
    tooltip: string;
    tooltipClass?: string;
    tooltipPlace?: string;
    tipClass?: string;
    tipIcon?: string;
    tipInnerClass?: string;
};

const InputTooltipIcon: FC<InputTooltipIconProps> = ({
    tooltip,
    tooltipClass,
    tooltipPlace,
    tipClass,
    tipIcon = 'info',
    tipInnerClass,
}) => {
    useEffect(() => {
        ReactTooltip.rebuild();
    });

    return (
        <span
            data-tip={tooltip}
            data-class={tooltipClass}
            data-place={tooltipPlace}
            className={cn('client-secondary-colour', tipClass, styles.tipInfo)}
        >
            <IconStack size="05" outer="bg-circle" inner={tipIcon} innerClassName={cn(tipInnerClass, styles.tipInfoIcon)} />
        </span>
    );
};

export default InputTooltipIcon;
