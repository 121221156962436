import {MODULES} from "constants.js";
import SetUpItems from "./SetUpItems";

const items = SetUpItems;

export default {
    getSetUpMenuItems: args => {
        const {
            modules,
            campaignBuilderSettings: { isAssetRequestEnabled, isBespokeAssetRequestEnabled },
            isClientLoyaltyProgramEnabled,
            isSocialMediaPostsEnabled,
            hasMasterAssetProducerPermission,
        } = args;

        items.campaigns.visible = modules[MODULES.CAMPAIGN_BUILDER];
        items.loyalty.visible = isClientLoyaltyProgramEnabled;
        items.marketing.visible = modules[MODULES.MARKETING_STORE];
        items.requests.visible = isAssetRequestEnabled || isBespokeAssetRequestEnabled;
        items.resources.visible = modules[MODULES.ASSET_LIBRARY];
        items.social.visible = isSocialMediaPostsEnabled;
        items.campaigns.items[2].visible = hasMasterAssetProducerPermission;
        items.general.items[0].visible = modules[MODULES.MARKETING_STORE] || modules[MODULES.CAMPAIGN_BUILDER];
        items.general.items[2].visible = modules[MODULES.CAMPAIGN_BUILDER];
        items.general.items[3].visible = hasMasterAssetProducerPermission;
        items.general.items[4].visible = hasMasterAssetProducerPermission;
        items.general.items[5].visible = hasMasterAssetProducerPermission;
        items.general.items[6].visible = modules[MODULES.CAMPAIGN_BUILDER];
        items.general.items[7].visible = hasMasterAssetProducerPermission;
        items.general.items[8].visible = hasMasterAssetProducerPermission;

        return items;
    },
};
