import { combineReducers } from 'redux';
import ACTIONS from './constants';

const creatableInitialState = {
    data: [],
    loading: false,
};

const creatableReducer = (state = creatableInitialState, action) => {
    switch (action.type) {
        case ACTIONS.creatable.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.creatable.setData: {
            return {
                ...state,
                data: action.append ? [...state.data, ...action.data] : action.data,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const filtersInitialState = {
    data: [],
    lastStatus: '',
    loading: false,
};

const filtersReducer = (state = filtersInitialState, action) => {
    switch (action.type) {
        case ACTIONS.filters.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.filters.setData: {
            return {
                ...state,
                data: action.data,
                lastStatus: action.status,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const searchOptionsInitialState = {
    data: {},
    prevData: {},
    loading: false,
};

const searchOptionsReducer = (state = searchOptionsInitialState, action) => {
    switch (action.type) {
        case ACTIONS.searchOptions.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.searchOptions.setData: {
            return {
                ...state,
                data: action.data,
                prevData: state.data,
                loading: false,
            };
        }

        case ACTIONS.searchOptions.restoreData: {
            return {
                ...state,
                data: state.prevData,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const campaignInitialState = {
    data: {},
    loading: false,
};

const campaignReducer = (state = campaignInitialState, action) => {
    switch (action.type) {
        case ACTIONS.campaign.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.campaign.setData: {
            return {
                data: action.data,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

const rootCampaignsInitialState = {
    data: {
        campaigns: [],
        counts: {},
    },
    loading: false,
};

const rootCampaignsReducer = (state = rootCampaignsInitialState, action) => {
    switch (action.type) {
        case ACTIONS.rootCampaigns.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.rootCampaigns.setData: {
            return {
                data: action.data,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    creatable: creatableReducer,
    filters: filtersReducer,
    searchOptions: searchOptionsReducer,
    campaign: campaignReducer,
    rootCampaigns: rootCampaignsReducer,
});
