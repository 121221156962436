import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'utils';
import styles from './ModalActions.module.css';
import Button from '../Button/Button';

const ModalActions = ({ children, hideCancel, onClose, leftContent }) => (
    <div className={styles.wrapper}>
        <div className={styles.left}>{!!leftContent && leftContent}</div>
        <div className={styles.actions}>
            {!hideCancel && (
                <Button small icon="cross" onClick={onClose} color={Button.COLOR.GRAY} className={styles.action}>
                    {t('general.button.cancel')}
                </Button>
            )}
            {React.Children.map(
                children,
                button =>
                    React.isValidElement(button) &&
                    React.cloneElement(button, {
                        small: true,
                        className: cn(button.props.className, styles.action),
                    })
            )}
        </div>
    </div>
);

ModalActions.propTypes = {
    leftContent: PropTypes.node,
    children: PropTypes.node,
    hideCancel: PropTypes.bool,
    onClose: PropTypes.func,
};

export default ModalActions;
