import { combineReducers } from 'redux';
import ACTIONS from './constants';

const itemInitialState = {
    data: {},
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.item.updateData: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data,
                },
            };
        }

        case ACTIONS.item.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    item: itemReducer,
});
