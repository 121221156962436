import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './ModalContent.module.css';

const ModalContent = ({ children, className }) => <div className={cn(styles.wrapper, className)}>{children}</div>;

ModalContent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default ModalContent;
