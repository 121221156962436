import Input from 'components/Input/Input';
import SearchField from 'components/SearchField/SearchField';
import { Field, Form, Formik } from 'formik';
import debounce from 'lodash/debounce';
import React, { FC } from 'react';
import styles from './FiltersLine.module.scss';

type FiltersLineProps = {
    initialValues: any;
    onSubmit: (values: any) => void;
    searchProps: {
        name: string;
        placeholder: string;
    };
    filters: {
        name: string;
        data: any[];
        label: string;
    }[];
};

const FiltersLine: FC<FiltersLineProps> = ({ initialValues, onSubmit, searchProps, filters }) => {
    return (
        <div className={styles.filters}>
            <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
                {({ submitForm }) => (
                    <Form>
                        <div className={styles.filtersWrapper}>
                            <div className={styles.filtersControls}>
                                {filters.map(filter => (
                                    <Field
                                        key={filter.name}
                                        component={Input}
                                        padding={Input.PADDING.NONE}
                                        type={Input.TYPE.SELECT}
                                        hideValidation
                                        onChange={debounce(submitForm, 0)}
                                        {...filter}
                                    />
                                ))}
                            </div>
                            <div className={styles.searchContainer}>
                                <Field
                                    className={styles.searchField}
                                    component={SearchField}
                                    padding={Input.PADDING.NONE}
                                    onChange={debounce(submitForm, 500)}
                                    {...searchProps}
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default FiltersLine;
