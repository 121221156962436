import ACTIONS from './constants';

const initialState = {
    list: {
        clientId: '',
        data: [],
        loading: true,
    },
    templateTypeTranslations: {
        data: {},
        loading: false,
    },
    templateStatusTranslations: {
        data: {},
        loading: false,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.list.load: {
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                },
            };
        }

        case ACTIONS.list.success: {
            return {
                ...state,
                list: {
                    clientId: action.clientId,
                    data: action.data,
                    loading: false,
                },
            };
        }

        case ACTIONS.translations.templateType.load: {
            return {
                ...state,
                templateTypeTranslations: {
                    ...state.templateTypeTranslations,
                    loading: true,
                },
            };
        }

        case ACTIONS.translations.templateType.success: {
            return {
                ...state,
                templateTypeTranslations: {
                    data: action.data,
                    loading: false,
                },
            };
        }

        case ACTIONS.translations.templateStatus.load: {
            return {
                ...state,
                templateStatusTranslations: {
                    ...state.templateStatusTranslations,
                    loading: true,
                },
            };
        }

        case ACTIONS.translations.templateStatus.success: {
            return {
                ...state,
                templateStatusTranslations: {
                    data: action.data,
                    loading: false,
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default reducer;
