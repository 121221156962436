import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Input.module.css';
import IconStack from '../IconStack/IconStack';

function isOptionChecked(value, option) {
    return value === option.value;
}

export default function RadioButtons(props) {
    const options = props.options.filter(Boolean);
    const extra = options.find(option => isOptionChecked(props.value, option) && option.extra)?.extra;
    const extraElement = extra ? <div className={styles.radioButtonExtra}>{extra}</div> : null;
    return (
        <>
            <div className={cn(styles.radioWrapper, props.vertical && styles.verticalRadio, props.className)}>
                {options.map((option, index) => {
                    const id = `${props.id}_${index}`;
                    const isChecked = isOptionChecked(props.value, option);
                    return (
                        <label
                            htmlFor={id}
                            key={id}
                            className={cn(
                                styles.radioButton,
                                props.isButtonStyle && styles.buttonStyle,
                                props.isButtonStyle && isChecked && styles.buttonStyleActive
                            )}
                        >
                            <input
                                id={id}
                                name={props.name}
                                type="radio"
                                checked={isChecked}
                                onBlur={props.onBlur}
                                onChange={e => {
                                    if (props.disabled) {
                                        return;
                                    }
                                    props.onChange({
                                        target: {
                                            id: e.target.id,
                                            name: e.target.name,
                                            value: option.value,
                                        },
                                    });
                                    if (props.field?.onChange && props.onChange !== props.field.onChange) {
                                        props.field.onChange({
                                            target: {
                                                id: e.target.id,
                                                name: e.target.name,
                                                value: option.value,
                                            },
                                        });
                                    }
                                }}
                            />
                            {option.label}
                            {!!option.tip && (
                                <span
                                    data-tip={option.tip}
                                    className={cn('client-secondary-colour', props.tipClass, styles.tipInfo)}
                                >
                                    <IconStack
                                        size="05"
                                        outer="bg-circle"
                                        inner={props.tipIcon || 'info'}
                                        innerClassName={cn(
                                            'client-secondary-colour',
                                            props.tipInnerClass,
                                            styles.tipInfoIcon
                                        )}
                                    />
                                </span>
                            )}
                            {!!option.description && (
                                <span className={styles.optionDescription}>
                                    <hr className={styles.optionDescriptionHr} />
                                    <p>{option.description}</p>
                                </span>
                            )}
                            {isChecked && !!props.vertical && extraElement}
                            {props.isButtonStyle && isChecked && (
                                <>
                                    <span className={styles.triangle} />
                                    <span className={styles.iconTick}>
                                        <IconStack
                                            size="05"
                                            inner="tick"
                                            innerClassName={cn(
                                                'client-secondary-colour',
                                                props.tipInnerClass,
                                                styles.tipInfoIcon
                                            )}
                                            outer="none"
                                        />
                                    </span>
                                </>
                            )}
                        </label>
                    );
                })}
            </div>
            {!props.vertical && extraElement}
        </>
    );
}

RadioButtons.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                label: PropTypes.node,
                value: PropTypes.any,
                tip: PropTypes.string,
                /**
                 * Extra content that appear under Radio if corresponding value is selected
                 */
                extra: PropTypes.node,
                description: PropTypes.node,
            }),
            PropTypes.bool,
        ])
    ).isRequired,
    field: PropTypes.shape({ onChange: PropTypes.func }),
    className: PropTypes.string,
    id: PropTypes.string,
    tipIcon: PropTypes.string,
    tipClass: PropTypes.string,
    tipInnerClass: PropTypes.string,
    vertical: PropTypes.bool,
    disabled: PropTypes.bool,
    isButtonStyle: PropTypes.bool,
};
