import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { t } from 'utils';
import styles from './Stepper.module.css';
import IconStack from '../IconStack/IconStack';
import isUndefined from 'lodash/isUndefined';

const Stepper = ({
    activeStep,
    steps,
    stepNumMap,
    addNumber,
    tooltips,
    tooltipClassName,
    arrowClassName,
    arrowEndClassName,
    activeClassName,
    className,
    translationKey,
}) => (
    <ul className={cn(styles.wrapper, className)}>
        {steps.map((step, index) => (
            <li
                key={index}
                className={cn(
                    styles.step,
                    addNumber && styles.stepNumbered,
                    tooltips && styles.withTooltip,
                    index === activeStep && !activeClassName && styles.active,
                    index === activeStep && 'client-20pc-colour-background',
                    index < activeStep && styles.pass,
                    !!stepNumMap && styles.stepWithDescription
                )}
            >
                <span className={cn(styles.arrowStart, arrowClassName)} />
                {addNumber && (
                    <span className={cn(styles.number, index === activeStep && 'client-main-colour-background')}>
                        {index + 1}
                    </span>
                )}
                {!!translationKey && t(translationKey + step)}
                {!translationKey && step}
                {
                    !!stepNumMap && !isUndefined(stepNumMap[step]) && (
                        <span className={cn(styles.stepDescription, 'client-main-colour')}>{stepNumMap[step]}</span>
                    )
                }
                {tooltips && (
                    <span data-tip={tooltips[index]} className={cn(styles.tipInfo, tooltipClassName)}>
                        <IconStack
                            size="1"
                            outer="bg-circle"
                            inner="info"
                            innerClassName={styles.tipInfoIcon}
                            className={index === activeStep && 'client-main-colour'}
                        />
                    </span>
                )}
                <span
                    className={cn(
                        styles.arrowEnd,
                        index === activeStep && activeClassName,
                        index === activeStep && 'client-20pc-colour-border-pseudo',
                        arrowEndClassName
                    )}
                />
            </li>
        ))}
    </ul>
);

Stepper.propTypes = {
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
    stepNumMap: PropTypes.object,
    tooltips: PropTypes.array,
    addNumber: PropTypes.bool,
    className: PropTypes.string,
    arrowClassName: PropTypes.string,
    arrowEndClassName: PropTypes.string,
    activeClassName: PropTypes.string,
    tooltipClassName: PropTypes.string,
    translationKey: PropTypes.string,
};

export default Stepper;
