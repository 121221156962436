import { combineReducers } from 'redux';
import ACTIONS from './constants';

const dataInitialState = {
    data: {},
    loading: false,
};

const collateralReducer = (state = dataInitialState, action) => {
    switch (action.type) {
        case ACTIONS.collateral.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.collateral.setData: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const reportInitialState = {
    data: {
        resource: {},
        breakdown: [],
        totals: {},
    },
    loading: false,
};

const reportReducer = (state = reportInitialState, action) => {
    switch (action.type) {
        case ACTIONS.report.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.report.setData: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const snippetInitialState = {
    data: '',
    loading: false,
};

const snippetReducer = (state = snippetInitialState, action) => {
    switch (action.type) {
        case ACTIONS.snippet.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.snippet.setData: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    collateral: collateralReducer,
    report: reportReducer,
    snippet: snippetReducer,
});
