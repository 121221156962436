import { combineReducers } from 'redux';
import ACTIONS from './constants';

const listInitialState = {
    data: [],
    total: 0,
    loading: false,
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.list.setTotal: {
            return {
                ...state,
                total: action.total,
            };
        }

        default: {
            return state;
        }
    }
};

const itemInitialState = {
    data: {},
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const accountManagerInitialState = {
    data: {},
    loading: false,
};

const accountManagerReducer = (state = accountManagerInitialState, action) => {
    switch (action.type) {
        case ACTIONS.accountManager.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.accountManager.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.accountManager.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    item: itemReducer,
    list: listReducer,
    accountManager: accountManagerReducer,
});
