export default {
    item: {
        setData: 'LEAD_SET_DATA',
        setLoading: 'LEAD_SET_LOADING',
    },
    list: {
        setData: 'LEAD_LIST_SET_DATA',
        setFilters: 'LEAD_LIST_SET_FILTERS',
        setLoading: 'LEAD_LIST_SET_LOADING',
        setCounts: 'LEAD_LIST_SET_COUNTS',
    },
    leads: {
        setData: 'LEADS_SET_DATA',
        setLoading: 'LEADS_SET_LOADING',
        setCounts: 'LEADS_SET_COUNTS',
    },
    fields: {
        setData: 'FIELDS_SET_DATA',
        setLoading: 'FIELDS_SET_LOADING',
    },
    listLeads: {
        setData: 'LEAD_LIST_LEADS_SET_DATA',
        setLoading: 'LEAD_LIST_LEADS_SET_LOADING',
        setCounts: 'LEAD_LIST_LEADS_SET_COUNTS',
    },
};
