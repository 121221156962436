import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isInteger from 'lodash/isInteger';
import { hexToRgb, rgbToHex } from 'utils';
import styles from './Input.module.css';

const HASH = '#';

const removeHash = value => String(value || '').replace(HASH, '');
const isValidRgb = rgb => rgb.filter(p => isInteger(p) && p >= 0 && p < 256).length === 3;
const getHex = rgb => (isValidRgb(rgb) ? rgbToHex(...rgb) : HASH);

function ColourPicker({ isHex, isRGB, withPreview, isVertical, onChange, value, name, className, disabled }) {
    const [hex, setHex] = useState(removeHash(value));
    const [rgb, setRgb] = useState(hexToRgb(value));
    const rgbRefs = [useRef(null), useRef(null), useRef(null)];

    useEffect(() => {
        if (removeHash(value)) {
            setHex(removeHash(value));
            setRgb(hexToRgb(value));
        }
    }, [value, setHex, setRgb]);

    const onHexChange = useCallback(
        e => {
            const colour = `${HASH}${e.target.value}`;
            const _rgb = hexToRgb(colour);

            setRgb(_rgb);
            setHex(e.target.value);

            onChange({ target: { name, value: colour === HASH ? undefined : colour } });
        },
        [onChange, name, setRgb, setHex]
    );

    const onRgbPartChange = useCallback(
        index => e => {
            if (e.target.value.length > 3 || e.target.value.match(/\D/)) {
                const { selectionStart, selectionEnd } = e.target;
                // Set delay.
                window.requestAnimationFrame(() =>
                    rgbRefs[index].current.setSelectionRange(selectionStart - 1, selectionEnd - 1)
                );
                return;
            }
            const _rgb = [...rgb];
            _rgb[index] = e.target.value ? parseInt(e.target.value, 10) : undefined;
            const _hex = getHex(_rgb);

            setRgb(_rgb);
            setHex(_hex && removeHash(_hex));

            onChange({ target: { name, value: _hex === HASH ? undefined : _hex } });
        },
        [onChange, name, setRgb, setHex, rgb, rgbRefs]
    );

    let hexInput = null;
    if (isHex) {
        hexInput = (
            <span className={styles.hexInput}>
                <span>{HASH}</span>
                <input type="text" value={hex} disabled={disabled} onChange={onHexChange} className={styles.input} />
            </span>
        );
    }

    let rgbInput = null;
    if (isRGB) {
        rgbInput = (
            <span className={styles.rgbInput}>
                {['R', 'G', 'B'].map((part, index) => (
                    <span key={part} className={styles.rgbPart}>
                        <span>{part}</span>
                        <input
                            type="text"
                            disabled={disabled}
                            className={styles.input}
                            value={rgb[index] ?? ''}
                            onChange={onRgbPartChange(index, rgb)}
                            ref={rgbRefs[index]}
                        />
                    </span>
                ))}
            </span>
        );
    }

    return (
        <div className={cn(styles.colourPickerWrapper, withPreview && styles.withPreview)}>
            {!!withPreview && (
                <span
                    className={styles.colourPickerColour}
                    style={{ background: !value || value === HASH ? 'transparent' : value }}
                />
            )}
            <span className={cn(styles.colourPicker, isVertical && styles.vertical, className)}>
                {hexInput}
                {rgbInput}
            </span>
        </div>
    );
}

ColourPicker.propTypes = {
    /**
     * Class name.
     */
    className: PropTypes.string,
    /**
     * Field name.
     */
    name: PropTypes.string.isRequired,
    /**
     * Field value. Should be string.
     */
    value: PropTypes.string,
    /**
     * Gets called when value was changed.
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Should this input have a hex edit view.
     */
    isHex: PropTypes.bool,
    /**
     * Should this input have a rgb edit view.
     */
    isRGB: PropTypes.bool,
    /**
     * Show colour preview.
     */
    withPreview: PropTypes.bool,
    /**
     * Show inputs vertical.
     */
    isVertical: PropTypes.bool,
    /**
     * Is disabled.
     */
    disabled: PropTypes.bool,
};

export default ColourPicker;
