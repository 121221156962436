import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './FieldError.module.scss';

const FieldError = ({ error, className }) => {
    if (!error) {
        return null;
    }

    return <div className={cn(styles.error, className)}>{error}</div>;
};

FieldError.propTypes = {
    error: PropTypes.node,
    className: PropTypes.string,
};

export default FieldError;
