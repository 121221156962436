import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './Page.module.css';
import Button from '../Button/Button';
import BasketLink from '../Header/components/BasketLink/BasketLink';

const Page = ({
    children,
    title,
    text,
    text2,
    oneTextArea,
    info,
    action: { text: actionText, ...actionProps },
    breadcrumbs,
    breadcrumbsClassname,
    actions,
    addBasket,
    noHeader,
    basketCustom,
    basketUrl,
    basketCount,
    showHalfOnly,
    halfWidth,
    halfHeight,
    halfIndent,
}) => {
    const actionButton = !!actionText && (
        <Button icon="plus" small className={styles.action} {...actionProps}>
            {actionText}
        </Button>
    );

    return (
        <>
            <div
                className={cn(styles.wrapper, showHalfOnly ? styles.half : 'container')}
                style={{
                    marginLeft: showHalfOnly ? `calc(50% - ${halfWidth}px - ${halfIndent}px)` : 'auto',
                    width: showHalfOnly ? `${halfWidth}px` : '',
                    minHeight: showHalfOnly ? `${halfHeight}px` : '',
                }}
            >
                {!!breadcrumbs && (
                    <ul className={cn(styles.breadcrumbs, breadcrumbsClassname, 'client-breadcrumbs')}>
                        {breadcrumbs.map((breadcrumb, index) => {
                            let element;
                            if (breadcrumb.link) {
                                element = <Link to={breadcrumb.link}>{breadcrumb.text}</Link>;
                            } else if (breadcrumb.onClick) {
                                element = (
                                    <button type="button" onClick={breadcrumb.onClick}>
                                        {breadcrumb.text}
                                    </button>
                                );
                            } else {
                                element = <span>{breadcrumb.text}</span>;
                            }

                            return <li key={index}>{element}</li>;
                        })}
                    </ul>
                )}
                <div className={cn(styles.contentHeader, noHeader ? styles.noHeader : '')}>
                    {!!addBasket && (
                        <span className={styles.basketWrapper}>
                            <BasketLink />
                        </span>
                    )}
                    {!!basketCustom && !!basketUrl && (
                        <span className={styles.basketWrapper}>
                            <BasketLink customUrl={basketUrl} customCount={basketCount} />
                        </span>
                    )}
                    {!!actionProps.titleAction && actionButton}

                    {!!actions &&
                        actions.filter(Boolean).map(({ text: titleActionText, ...titleActionProps }, index) => (
                            <Button key={index} small className={styles.action} {...titleActionProps}>
                                {titleActionText}
                            </Button>
                        ))}

                    <div className={styles.titleWrapper}>
                        {!!title && <h1 className={styles.title}>{title}</h1>}
                        {!!info && <span className={styles.info}>{info}</span>}
                    </div>
                </div>
                {!actionProps.titleAction && actionButton}
                {!!text && <p className={cn(styles.text, oneTextArea ? styles.oneTextAreaText1 : '')}>{text}</p>}
                {!!text2 && <p className={cn(styles.text, oneTextArea ? styles.oneTextAreaText2 : '')}>{text2}</p>}
                {!showHalfOnly && children}
            </div>
            {showHalfOnly && <div className="container">{children}</div>}
        </>
    );
};

Page.defaultProps = {
    action: {},
};

Page.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any,
    text: PropTypes.string,
    text2: PropTypes.string,
    oneTextArea: PropTypes.bool,
    info: PropTypes.any,
    action: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    breadcrumbs: PropTypes.array,
    breadcrumbsClassname: PropTypes.string,
    actions: PropTypes.array,
    addBasket: PropTypes.bool,
    basketCustom: PropTypes.bool,
    basketUrl: PropTypes.any,
    basketCount: PropTypes.any,
    noHeader: PropTypes.bool,
    showHalfOnly: PropTypes.bool,
    halfWidth: PropTypes.string,
    halfHeight: PropTypes.string,
    halfIndent: PropTypes.string,
};

export default Page;
