import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { switchBodyClass } from 'utils';
import IconStack from 'components/IconStack/IconStack';
import Button from 'components/Button/Button';
import styles from './AlertDialog.module.css';

const KIND_MAP = {
    warning: {
        outer: 'bg-triangle',
        inner: 'exclamation',
    },
    info: {
        outer: 'bg-circle',
        inner: 'info',
    },
    success: {
        outer: 'bg-circle',
        inner: 'tick',
    },
    error: {
        outer: 'bg-circle',
        inner: 'exclamation',
    },
};

const BUTTON_MAP = {
    warning: Button.COLOR.WARNING,
    info: Button.COLOR.INFO,
    error: Button.COLOR.ERROR,
    success: Button.COLOR.SUCCESS,
};

/**
 * Displays general popup. No need to display manually.
 * Happens as reaction on dispatching of modal/dialog alert action.
 */
class AlertDialog extends Component {
    componentDidMount() {
        switchBodyClass(true, styles.dialogShown);
    }

    componentWillUnmount() {
        switchBodyClass(false, styles.dialogShown);
    }

    render() {
        const { message, kind, confirm, cancel, resolve, reject, title, sample } = this.props;
        return (
            <div className={cn(styles.overlay, sample && styles.sample)}>
                <div className={cn(styles.dialog, styles[kind], sample && styles.sample)}>
                    <IconStack {...(KIND_MAP[kind] || {})} size="2" className={styles.icon} />
                    {!title ? (
                        <div className={styles.text}>{message}</div>
                    ) : (
                        <>
                            <div className={styles.title}>{title}</div>
                            {/* eslint-disable-next-line react/no-danger */}
                            <div className={styles.message} dangerouslySetInnerHTML={{ __html: message }} />
                        </>
                    )}
                    <div className={styles.buttons}>
                        {!!cancel && (
                            <Button onClick={reject} color={Button.COLOR.GRAY} icon={cancel.icon || 'cross'} small>
                                {cancel.text || cancel}
                            </Button>
                        )}
                        {!!confirm && (
                            <Button onClick={resolve} color={BUTTON_MAP[kind]} icon={confirm.icon || 'tick'} small>
                                {confirm.text || confirm}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

AlertDialog.propTypes = {
    /**
     * Dialog title
     */
    title: PropTypes.string,
    /**
     * Message to display in body
     */
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    /**
     * Type of dialog: warning, info or error.
     */
    kind: PropTypes.string,
    /**
     * Confirm button text
     */
    confirm: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ icon: PropTypes.string, text: PropTypes.string }),
    ]),
    /**
     * Cancel button text
     */
    cancel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ icon: PropTypes.string, text: PropTypes.string }),
    ]),
    /**
     * @ignore
     */
    resolve: PropTypes.func,
    /**
     * @ignore
     */
    reject: PropTypes.func,
    /**
     * @ignore Indicates that render is for style guide
     */
    sample: PropTypes.bool,
};

export default AlertDialog;
