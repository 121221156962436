import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { ROUTE } from '../../constants';
import { client, t } from '../../utils';
import DropDown from '../DropDown/DropDown';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import PmLink from '../PmLink/PmLink';
import Menu from './components/Menu/Menu';
import Help from './components/Help/Help';
import styles from './Header.module.css';
import BackToIdpButton from './components/BackToIdpButton/BackToIdpButton';
import RoleSwitcher from '../RoleSwitcher/RoleSwitcher';
import LoggedInAsBar from '../LoggedInAsBar/LoggedInAsBar';
import { getCurrentUserRolesMap } from '../../selectors/userSelectors';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import layoutContext from '../../context/layoutContext';

const Header = ({ name, roles }) => {
    const dropDownItems = [
        { to: ROUTE.account.details, label: t('base._client_logged_in_header.my_account') },
        { to: ROUTE.logout, label: t('base._client_logged_in_header.sign_out') },
    ];
    const { extended } = useContext(layoutContext);
    return (
        <header id="globalHeader" className={cn(extended && styles.hidden, 'client-logo-bar-bg-colour')}>
            <LoggedInAsBar />

            {!roles.isEasyAccessPartner && (
                <div className={cn(styles.topNav, 'client-top-nav-bg-colour')}>
                    <div className={cn('container', styles.topNavContainer)}>
                        <BackToIdpButton />

                        <LanguageSwitcher />

                        <RoleSwitcher />

                        <DropDown items={dropDownItems} noIcon className={styles.topNavDropDown}>
                            <Button
                                bordered
                                className={cn(styles.userDropDownButton, 'client-top-nav-text-colour', 'not-global')}
                                color={Button.COLOR.GRAY}
                            >
                                <Icon kind="account" className={styles.userDropDownIcon} />
                                {name}
                                <Icon kind="arrow-down" className={styles.userDropDownArrow} />
                            </Button>
                        </DropDown>
                    </div>
                </div>
            )}

            <div className={cn('container', styles.logoContainer)}>
                <div id="globalLogo" className={styles.globalLogoWrapper}>
                    {!!client.getUseDashboardLogoLink() && client.getDashboardLogoLink() && (
                        <a href={client.getDashboardLogoLink()} target="_top" className={styles.globalLogo}>
                            <img
                                alt={client.getName()}
                                src={client.getDashboardLogo() || client.getLogo()}
                                className={client.getDashboardLogo() && styles.dashboardLogo}
                            />
                        </a>
                    )}
                    {!client.getUseDashboardLogoLink() && (
                        <PmLink to={ROUTE.home} className={styles.globalLogo}>
                            <img
                                alt={client.getName()}
                                src={client.getDashboardLogo() || client.getLogo()}
                                className={client.getDashboardLogo() && styles.dashboardLogo}
                            />
                        </PmLink>
                    )}
                </div>
            </div>
            <Menu />

            <Help className={styles.helpDropDown} />
        </header>
    );
};

Header.propTypes = {
    name: PropTypes.string,
};

const mapStateToProps = state => ({
    name: state.user.current.data.name,
    roles: getCurrentUserRolesMap(state),
});

export default connect(mapStateToProps)(Header);
