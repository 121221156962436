import { combineReducers } from 'redux';
import ACTIONS from './constants';
import { TOGGLE_GROUPS_STATUS } from '../../manageConstants';

const listInitialState = {
    data: {
        counts: {},
        list: [],
        total: 0,
    },
    loading: false,
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const itemInitialState = {
    data: {
        status: TOGGLE_GROUPS_STATUS.ACTIVE,
        filterGroup: null,
        masterName: '',
    },
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.item.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    list: listReducer,
    item: itemReducer,
});
