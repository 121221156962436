import noop from 'lodash/noop';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'store/rootReducer';
import { t } from 'utils';
import ACTIONS from './constants';

const DEFAULT_MESSAGE = 'Some error happened. Please contact support';

export const add = (message: string, kind: string, duration = 1, onClose = noop): AnyAction => ({
    type: ACTIONS.add,
    message,
    kind,
    duration,
    onClose,
});

export const clear = (): AnyAction => ({
    type: ACTIONS.clear,
});

export const decreaseDuration = (): AnyAction => ({
    type: ACTIONS.decreaseDuration,
});

export const success = (message: string) => add(message, 'success');

export const notify = (message, duration = 1, onClose = noop) => add(message, 'notify', duration, onClose);

export const error = (message = DEFAULT_MESSAGE, duration = 1) => add(message, 'error', duration);

export const remove =
    (id: string | number): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch, getState) => {
        const { alert } = getState();
        const item = alert.list.filter(({ id: alertId }) => alertId === id).pop();
        if (item && item.onClose) {
            item.onClose();
        }
        dispatch({
            type: ACTIONS.remove,
            id,
        });
    };

export const modalClose = () => ({
    type: ACTIONS.modalClose,
});

export const modal =
    (
        message: string,
        kind: string,
        confirm = t('exception_publisher.accept'),
        cancel = t('exception_publisher.cancel'),
        title = ''
    ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
    dispatch =>
        new Promise<void>((resolve, reject) => {
            dispatch({
                type: ACTIONS.modal,
                message,
                kind,
                confirm,
                cancel,
                title,
                resolve: () => {
                    resolve();
                    dispatch(modalClose());
                },
                reject: () => {
                    reject();
                    dispatch(modalClose());
                },
            });
        });

export const dialog =
    (
        title,
        message,
        kind,
        confirm = t('exception_publisher.accept'),
        cancel = t('exception_publisher.cancel')
    ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
    dispatch =>
        dispatch(modal(message, kind, confirm, cancel, title));
