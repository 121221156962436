export default {
    report: {
        setData: 'REPORT_LIST_SET_DATA',
        setLoading: 'REPORT_LIST_SET_LOADING',
        setCounts: 'REPORT_LIST_SET_COUNTS',
    },
    partnerLPReportKeenIO: {
        setData: 'PARTNER_LP_REPORT_KEENIO_SET_DATA',
        setLoading: 'PARTNER_LP_REPORT_KEENIO_SET_LOADING',
    },
    partnerLPReportSummary: {
        setData: 'PARTNER_LP_REPORT_SUMMARY_SET_DATA',
        setLoading: 'PARTNER_LP_REPORT_SUMMARY_SET_LOADING',
    },
    partnerLPReportFormFields: {
        setData: 'PARTNER_LP_REPORT_FORM_FIELDS_SET_DATA',
        setLoading: 'PARTNER_LP_REPORT_FORM_FIELDS_SET_LOADING',
    },
};
