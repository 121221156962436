import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { t } from '../../../utils';
import IconStack from '../../IconStack/IconStack';
import styles from './FormatMultiple.module.css';
import { USER_ROLE } from '../../../constants';

const FormatMultiple = ({ value, column, row }) => {
    const forceMultiple =
        (column === 'tiers' || column === 'regions') && row.baseRole && row.baseRole.role === USER_ROLE.ROLE_DESIGNER;

    if (!forceMultiple) {
        if (!isArray(value)) {
            return value || '';
        }
        if (!value.length) {
            return '';
        }

        if (value.length === 1) {
            if (isObject(value[0])) {
                if (value[0].code) {
                    return (
                        <>
                            <div>{value[0].name}</div>
                            <div>{value[0].code}</div>
                        </>
                    );
                }
                return value[0].name;
            }
            return value[0];
        }
    }

    return (
        <span className={styles.wrapper}>
            {t('pmApp.user.directive.multipleHelper.multiple')}
            <IconStack
                inner="info"
                outerDark
                size="1"
                outer="bg-circle"
                tooltip={t(`pmApp.user.directive.multipleHelper.tip_${column}`)}
                className={styles.icon}
            />
        </span>
    );
};

FormatMultiple.propTypes = {
    value: PropTypes.array,
    column: PropTypes.string,
};

export default FormatMultiple;
