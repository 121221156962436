import { combineReducers } from 'redux';
import ACTIONS from './constants';

const activitiesCountState = {
    data: {},
    loading: false,
};

const activitiesCountReducer = (state = activitiesCountState, action) => {
    switch (action.type) {
        case ACTIONS.activitiesCount.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.activitiesCount.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const listInitialState = {
    data: [],
    summary: {},
    total: 0,
    loading: false,
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.list.setSummary: {
            return {
                ...state,
                summary: action.summary,
            };
        }

        case ACTIONS.list.setTotal: {
            return {
                ...state,
                total: action.total,
            };
        }

        default: {
            return state;
        }
    }
};

const itemInitialState = {
    data: {},
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        }

        case ACTIONS.item.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

const templateItemInitialState = {
    data: {},
    loading: false,
};

const templateItemReducer = (state = templateItemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.templateItem.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.templateItem.setData: {
            return {
                data: action.data,
                loading: false,
            };
        }

        case ACTIONS.item.reset: {
            return templateItemInitialState;
        }

        default: {
            return state;
        }
    }
};

const accountManagerInitialState = {
    data: {},
    loading: false,
};

const accountManagerReducer = (state = accountManagerInitialState, action) => {
    switch (action.type) {
        case ACTIONS.accountManager.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.accountManager.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.accountManager.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

const coBrandedTemplateInitialState = {
    loading: false,
};

const coBrandedTemplateReducer = (state = coBrandedTemplateInitialState, action) => {
    switch (action.type) {
        case ACTIONS.coBrandedTemplate.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    activitiesCount: activitiesCountReducer,
    list: listReducer,
    item: itemReducer,
    templateItem: templateItemReducer,
    accountManager: accountManagerReducer,
    coBrandedTemplate: coBrandedTemplateReducer,
});
