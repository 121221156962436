import { combineReducers } from 'redux';
import ACTIONS from './constants';

const itemInitialState = {
    data: {
        history: [],
    },
    loading: true,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.item.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

const listInitialState = {
    data: {
        counts: {
            Prospecting: 0,
            Qualification: 0,
            Proposal: 0,
            'Closed - Won': 0,
            'Closed - Lost': 0,
        },
        list: [],
    },
    loading: false,
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const reportInitialState = {
    data: {
        Prospecting: 0,
        Qualification: 0,
        Proposal: 0,
        'Closed - Won': 0,
        'Closed - Lost': 0,
    },
    loading: true,
};

const reportReducer = (state = reportInitialState, action) => {
    switch (action.type) {
        case ACTIONS.report.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.report.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    item: itemReducer,
    list: listReducer,
    report: reportReducer,
});
