export default {
    item: {
        setData: 'ASSET_LIBRARY_ITEM_SET_DATA',
        setLoading: 'ASSET_LIBRARY_ITEM_SET_LOADING',
    },
    list: {
        setData: 'ASSET_LIBRARY_LIST_SET_DATA',
        setLoading: 'ASSET_LIBRARY_LIST_SET_LOADING',
    },
    types: {
        setData: 'ASSET_LIBRARY_TYPES_SET_DATA',
        setLoading: 'ASSET_LIBRARY_TYPES_SET_LOADING',
    },
    coBrandedAsset: {
        setLoading: 'ASSET_LIBRARY_CO_BRANDED_ASSET_SET_LOADING',
    },
    coBrandedAssetLink: {
        setData: 'ASSET_LIBRARY_CO_BRANDED_ASSET_LINK_SET_DATA',
        setLoading: 'ASSET_LIBRARY_CO_BRANDED_ASSET_LINK_SET_LOADING',
    },
};
