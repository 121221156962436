/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component, Fragment } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import ReactTooltip from 'react-tooltip';
import { languageActions } from '../../store';
import { t } from '../../utils';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import Col from '../FlexBox/components/Col';
import Row from '../FlexBox/components/Row';
import stylesTrans from '../TranslationFields/TranslationFields.module.css';
import styles from './LanguagesSubForm.module.css';
import Input from '../Input/Input';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import IconStack from '../IconStack/IconStack';

class LanguagesSubForm extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        languageList: PropTypes.array,
        values: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        submitCount: PropTypes.number.isRequired,
        field: PropTypes.string.isRequired,
        getList: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
    };

    state = {
        newLanguage: '',
        isExpanded: {},
        addSubmitted: false,
    };

    componentDidMount() {
        const { getList } = this.props;
        getList();
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    onChangeAddLanguage = (e) => this.setState({ newLanguage: e.target.value });

    getExpandedState = (id) => ({
        ...this.state.isExpanded,
        [id]: !this.state.isExpanded[id],
    });

    toggleFormExpansion = (id) => this.setState({ isExpanded: this.getExpandedState(id) });

    addLanguage = () => {
        const { newLanguage } = this.state;
        const { setFieldValue, values, field, languageList } = this.props;
        if (newLanguage) {
            setFieldValue(field, [
                ...(values[field] || []),
                {
                    systemLanguage: languageList.filter(({ id }) => id === newLanguage).pop(),
                },
            ]);
            this.setState({
                newLanguage: '',
                addSubmitted: false,
                isExpanded: this.getExpandedState(newLanguage),
            });
        } else {
            this.setState({ addSubmitted: true });
        }
    };

    deleteLanguage = (id) => {
        const { setFieldValue, values, field } = this.props;
        setFieldValue(
            field,
            values[field].filter(({ systemLanguage }) => systemLanguage.id !== id),
        );
    };

    render() {
        const { loading, languageList, values, errors, field, children, submitCount } = this.props;
        const { newLanguage, addSubmitted, isExpanded } = this.state;
        const languages = values[field] || [];
        const usedLanguages = languages.map(({ systemLanguage: { id } }) => id);
        const availableLanguages = languageList.filter(({ id }) => usedLanguages.indexOf(id) === -1);

        return (
            <LoadingContainer loading={loading}>
                {!!languages.length && (
                    <Fragment>
                        <hr className={stylesTrans.dark} />
                        <h2 className={stylesTrans.h2}>{t('template.campaign_form.languages')}</h2>
                        <div className={styles.wrapper}>
                            {languages.map(({ systemLanguage, id }, index) => (
                                <div
                                    key={id}
                                    className={cn(
                                        styles.languageWrapper,
                                        submitCount > 0 && errors[field] && errors[field][index] && styles.hasError,
                                    )}
                                >
                                    <div className={cn(styles.topLine, !isExpanded[systemLanguage.id] && 'closed')}>
                                        <h4>{systemLanguage.language.name}</h4>

                                        <div className={styles.actions}>
                                            <a
                                                className={cn(
                                                    'client-20pc-colour-background',
                                                    styles.arrow,
                                                    styles.right,
                                                )}
                                                onClick={() => this.toggleFormExpansion(systemLanguage.id)}
                                                title={t('template.language_form.edit_name_description')}
                                            >
                                                <Icon
                                                    dark
                                                    kind={isExpanded[systemLanguage.id] ? 'arrow-up' : 'arrow-down'}
                                                />
                                            </a>
                                            {!id && (
                                                <a
                                                    className={cn(styles.right, styles.remove)}
                                                    onClick={() => this.deleteLanguage(systemLanguage.id)}
                                                    title={t('template.language_form.remove_language')}
                                                >
                                                    <IconStack size="1" outer="bg-circle" outerDark inner="cross" />
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                    {!!isExpanded[systemLanguage.id] && (
                                        <div className={styles.content}>{children(index, systemLanguage)}</div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </Fragment>
                )}
                <hr className={stylesTrans.dark} />
                <h2 className={stylesTrans.h2}>{t('template.campaign_form.add_a_language')}</h2>
                <div className={styles.addLanguage}>
                    {!!availableLanguages.length && (
                        <Row>
                            <Col md={7}>
                                <Field
                                    placeholder={t(
                                        'asset_library.set_up.create_or_update_tag.form.please_add_translation_placeholder',
                                    )} // eslint-disable-line
                                    component={Input}
                                    type={Input.TYPE.SELECT}
                                    form={{
                                        submitCount: addSubmitted || submitCount,
                                        errors: {
                                            addLanguage:
                                                ((addSubmitted && !newLanguage) || !languages.length) &&
                                                t('general.errors.required'),
                                        },
                                    }}
                                    field={{
                                        name: 'addLanguage',
                                        value: newLanguage,
                                        onChange: this.onChangeAddLanguage,
                                    }}
                                    padding={Input.PADDING.NONE}
                                >
                                    <option value="">{t('template.campaign_form.select_a_language')}</option>
                                    {availableLanguages.map(({ id, language: { name } }) => (
                                        <option value={id} key={id}>
                                            {name}
                                        </option>
                                    ))}
                                </Field>
                            </Col>
                            <Col md={5}>
                                <Button
                                    small
                                    icon="plus"
                                    onClick={this.addLanguage}
                                    className={styles.right}
                                    color={Button.COLOR.SECONDARY}
                                >
                                    {t('template.campaign_form.add')}
                                </Button>
                            </Col>
                        </Row>
                    )}
                    {!availableLanguages.length && (
                        <div className="no-languages">
                            {
                                t(
                                    'template.campaign_form.if_you_need_to_add_new_languages_you_can_do_this_on_the_admin_site',
                                ) /* eslint-disable-line */
                            }
                        </div>
                    )}
                </div>
            </LoadingContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.language.list.loading,
    languageList: state.language.list.data,
});

const mapDispatchToProps = (dispatch) => ({
    getList: () => dispatch(languageActions.list()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesSubForm);
