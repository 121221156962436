import { combineReducers } from 'redux';
import ACTIONS from './constants';

const itemInitialState = {
    data: {},
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.item.updateData: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data,
                },
            };
        }

        case ACTIONS.item.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

const ownListInitialState = {
    data: [],
    loading: false,
};

const ownListReducer = (state = ownListInitialState, action) => {
    switch (action.type) {
        case ACTIONS.ownList.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.ownList.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const listInitialState = {
    data: [],
    loading: false,
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    item: itemReducer,
    ownList: ownListReducer,
    list: listReducer,
});
