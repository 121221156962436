import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import PmLink from '../PmLink/PmLink';
import styles from './DropDownMenu.module.css';

import clickOutside from '../../utils/clickOutside';

const Item = ({ item }) => {
    const props = {};
    if (item.onClick) {
        props.onClick = item.onClick;
    }

    if (item.to) {
        props.to = item.to;
        return <PmLink {...props}>{item.label}</PmLink>;
    }

    return <a {...props}>{item.label}</a>;
};

/**
 * Helper component for DropDown. Can be ignored.
 */
class DropDownMenu extends Component {
    componentDidUpdate(prevProps) {
        const { show, onClickOutside, pathname } = this.props;
        if (show && prevProps.pathname !== pathname) {
            onClickOutside();
        }
    }

    render() {
        const { show, onClickOutside, items, className, children } = this.props;
        if (!show) {
            return null;
        }

        const Div = clickOutside('div');
        return (
            <Div onClickOutside={onClickOutside} className={cn(styles.root, className)}>
                {children}
                {!!items &&
                    <ul>
                        {items.map((item, index) => (
                            <li className={styles.item} key={index}>
                                <Item item={item} />
                            </li>
                        ))}
                    </ul>
                }
            </Div>
        );
    }
}

DropDownMenu.defaultProps = {
    items: [],
};

DropDownMenu.propTypes = {
    show: PropTypes.bool.isRequired,
    onClickOutside: PropTypes.func.isRequired,
    items: PropTypes.array,
    className: PropTypes.string,
    children: PropTypes.any,
    pathname: PropTypes.string,
};

const mapStateToProps = (state) => ({
    pathname: state.user.pathname,
});

export default connect(mapStateToProps)(DropDownMenu);
