import useApi from 'hooks/useApi';

const useApiTemplateAssetLibrary = (campaignId, templateId, lazy = false) => {
    const { response: templateAssetLibraryAssets, loading } = useApi({
        slug: 'partnermarketing_campaign_template_get_linked_asset_library_api',
        slugParams: {
            campaignId,
            templateId,
        },
        method: 'GET',
        cache: true,
        lazy,
    });

    return {
        templateAssetLibraryAssets: templateAssetLibraryAssets || [],
        loading,
    };
};

export default useApiTemplateAssetLibrary;
