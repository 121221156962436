import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isPlainObject from 'lodash/isPlainObject';
import { client } from 'utils';
import history from 'history.js';

const processTo = to => {
    if (isPlainObject(to)) {
        return `${to.pathname || ''}${to.search ? `?${to.search}` : ''}`;
    }

    return to;
};

export default function PmLink({ to, children, activeClassName, className, disabled, ...props }) {
    const pmHost = window.location.host.replace('react', client.getSubdomainSlug());
    const processedTo = processTo(to);
    return (
        <a
            {...props}
            className={cn(className, history.location.pathname === processedTo ? activeClassName : '')}
            href={`${window.location.protocol}//${pmHost}${processedTo}`}
            onClick={e => {
                e.preventDefault();
                if (disabled) {
                    return;
                }
                if (props.onClick) {
                    props.onClick();
                }
                if (to && to.split) {
                    const [pathname, search] = to.split('?');
                    history.push({
                        pathname,
                        search,
                    });
                } else {
                    history.push(to);
                }
            }}
        >
            {children}
        </a>
    );
}

PmLink.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    children: PropTypes.any,
};
