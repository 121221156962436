import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HoverableList from 'components/HoverableList/HoverableList';
import Button from 'components/Button/Button';
import { t } from 'utils';
import styles from './PreviewableList.module.scss';

const PreviewableList = ({ onSelect, list, isSelected, renderPreview, renderListItem }) => {
    const [preview, setPreview] = useState(null);
    const showPreview = item => () => setPreview(item);
    const resetInfo = () => setPreview(null);
    const selectItem = item => () => {
        resetInfo();
        onSelect(item);
    };

    return preview ? (
        <>
            <div className={styles.details}>{renderPreview(preview)}</div>
            <div className={styles.actions}>
                <Button color={Button.COLOR.GRAY} tiny icon="arrow-left" onClick={resetInfo}>
                    {t('general.button.back')}
                </Button>
                <Button color={Button.COLOR.PRIMARY} tiny icon="tick" onClick={selectItem(preview)}>
                    {t('general.button.select')}
                </Button>
            </div>
        </>
    ) : (
        <HoverableList
            isSelected={isSelected}
            list={list}
            actions={({ item }) => (
                <>
                    <Button color={Button.COLOR.SECONDARY} tiny icon="info" onClick={showPreview(item)}>
                        {t('general.button.preview')}
                    </Button>
                    <Button color={Button.COLOR.PRIMARY} tiny icon="tick" onClick={selectItem(item)}>
                        {t('general.button.select')}
                    </Button>
                </>
            )}
        >
            {renderListItem}
        </HoverableList>
    );
};

PreviewableList.propTypes = {
    list: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    isSelected: PropTypes.func.isRequired,
    renderPreview: PropTypes.func.isRequired,
    renderListItem: PropTypes.func.isRequired,
};

export default PreviewableList;
