import React, { useCallback, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import useApiTemplateInstanceAssetLibrary from 'apiRequests/useApiTemplateInstanceAssetLibrary';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import PreviewableList from 'components/PreviewableList/PreviewableList';
import Input from 'components/Input/Input';
import AssetDetails from 'pages/asset/library/view/AssetDetails';
import styles from './LinkTypeAssetLibrary.module.scss';

const LinkTypeAssetLibrary = () => {
    const { values, setFieldValue } = useFormikContext();
    const { id, templateId } = useParams();
    const { templateInstanceAssetLibraryAssets, loading } = useApiTemplateInstanceAssetLibrary(id, templateId);
    const isSelected = useCallback(asset => `${asset.id}` === values.url, [values.url]);
    const onSelect = asset => setFieldValue('url', `${asset.id}`, false);
    const assets = useMemo(
        () =>
            values.isLinkEditable
                ? templateInstanceAssetLibraryAssets
                : templateInstanceAssetLibraryAssets.filter(isSelected),
        [templateInstanceAssetLibraryAssets, isSelected, values.isLinkEditable]
    );

    return (
        <LoadingContainer loading={loading}>
            <PreviewableList
                list={assets}
                isSelected={isSelected}
                onSelect={onSelect}
                renderPreview={asset => (
                    <>
                        <strong>{asset.name}</strong>
                        <AssetDetails asset={asset} />
                    </>
                )}
                renderListItem={({ itemClass, item: asset }) => (
                    <>
                        <span className={itemClass}>
                            <img src={asset.previewImageFileSystemUrl} alt={asset.name} />
                        </span>
                        <strong className={styles.text}>{asset.name}</strong>
                    </>
                )}
            />
            <Field component={Input} type="hidden" name="url" hideValidation padding={Input.PADDING.NONE} />
        </LoadingContainer>
    );
};

export default LinkTypeAssetLibrary;
