import CryptoJS from 'crypto-js';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';

export const makeCancelable = promise => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            // eslint-disable-next-line prefer-promise-reject-errors
            val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            // eslint-disable-next-line prefer-promise-reject-errors
            error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export const apiToken = {
    apiAuth: null,
    getItem(key) {
        if (isEmpty(this.apiAuth)) {
            try {
                this.apiAuth = JSON.parse(localStorage.getItem('apiAuth')) || {};
            } catch (e) {
                this.apiAuth = {};
            }
        }
        return this.apiAuth[key];
    },
    getEncryptedToken() {
        return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(`timestamp=${this.getTimestamp()}`, this.getToken()));
    },
    getToken() {
        return this.getItem('token') || this.getItem('authToken');
    },
    getUser() {
        return this.getItem('user') || this.getItem('userId');
    },
    getUserType() {
        return this.getItem('userType');
    },
    getTimestamp() {
        return new Date().getTime().toString().substr(0, 10);
    },
};

const processResult = response => {
    if (!response || !response.ok) {
        throw response;
    }
    switch (response.status) {
        case 200:
        case 201:
            return response.headers.get('Content-Type').indexOf('/json') !== -1 ? response.json() : response.text();
        default:
            throw response;
    }
};

export default class Request {
    requestUrl = null;

    queryParams = {};

    _headers = {};

    _token = null;

    constructor(method, url, data, params = {}) {
        this.buildRequestUrl(method, url, data);
        this.params = {
            method,
            body: data && method !== 'get' ? JSON.stringify(this.queryParams) : null,
            cache: 'no-cache',
            mode: 'cors',
            timeout: 60000,
            ...params,
        };

        if (!this.params.body) {
            delete this.params.body;
        }
    }

    setHeader(name, value) {
        this._headers[name] = value;
    }

    get headers() {
        return this._headers;
    }

    set token(value) {
        this._token = value;
    }

    get token() {
        return this._token;
    }

    buildRequestUrl(method, url, data) {
        this.requestUrl = url;

        // Build the url. In case of get request data should be in url string.
        if (data) {
            // Some data items can be used in url as placeholders in form %NAME%.
            // Those data items should be removed from query string.
            Object.entries(data).forEach(([key, value]) => {
                const paramName = `%${key}%`;
                if (this.requestUrl.indexOf(paramName) !== -1) {
                    this.requestUrl = this.requestUrl.replace(paramName, value);
                } else {
                    this.queryParams[key] = value;
                }
            });

            if (method === 'get' && Object.keys(this.queryParams).length) {
                const separator = this.requestUrl.split('?')[1] ? '&' : '?';
                this.requestUrl = `${this.requestUrl}${separator}${qs.stringify(this.queryParams)}`;
            }
        }
    }

    buildRequestHeaders() {
        if (apiToken.getUser()) {
            this.setHeader('x-pm-user', apiToken.getUser());
            this.setHeader('x-pm-usertype', apiToken.getUserType());
            this.setHeader('x-pm-timestamp', apiToken.getTimestamp());
            this.setHeader('x-pm-token', apiToken.getEncryptedToken());
        }

        return new Headers(this.headers);
    }

    async execute() {
        this.params.headers = this.buildRequestHeaders();
        try {
            const response = await fetch(this.requestUrl, this.params);
            const responseResult = await processResult(response);
            return responseResult;
        } catch (e) {
            if (e.status === 401) {
                window.parent.postMessage({ call: 'iframeAuthenticationFailed' }, '*');
            }
            throw e;
        }
    }

    executeCancelable() {
        this.params.headers = this.buildRequestHeaders();
        const request = makeCancelable(fetch(this.requestUrl, this.params));
        request.promise = request.promise
            .then(async response => {
                const result = await processResult(response);
                return result;
            })
            .catch(e => {
                if (e.status === 401) {
                    window.top.postMessage({ call: 'iframeAuthenticationFailed' }, '*');
                }
                throw e;
            });

        return request;
    }
}
