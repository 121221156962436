import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isUndefined from 'lodash/isUndefined';
import useToggle from 'hooks/useToggle';
import ArrowButton from '../ArrowButton/ArrowButton';
import Panel from '../Panel/Panel';
import styles from './CollapsiblePanel.module.scss';

const CollapsiblePanel = props => {
    const {
        children,
        className,
        titleClass,
        bodyClass,
        actions,
        disabled,
        isOpened,
        onToggleOpened,
        bordered,
        ...rest
    } = props;
    const [opened, toggleOpened] = useToggle(isOpened);

    const externalOpen = !isUndefined(onToggleOpened);
    const isOpen = externalOpen ? isOpened : opened;

    return (
        <Panel
            actions={
                <>
                    {!!actions && actions}
                    <ArrowButton
                        onClick={externalOpen ? onToggleOpened : toggleOpened}
                        isOpen={isOpen}
                        className={styles.button}
                        disabled={disabled}
                    />
                </>
            }
            className={cn(styles.wrapper, bordered && styles.bordered, className)}
            titleClass={cn(styles.title, titleClass)}
            bodyClass={cn(styles.body, bodyClass)}
            {...rest}
        >
            {isOpen && children}
        </Panel>
    );
};

CollapsiblePanel.propTypes = {
    ...Panel.propTypes,
    bordered: PropTypes.bool,
    isOpened: PropTypes.bool,
    onToggleOpened: PropTypes.func,
};

export default CollapsiblePanel;
