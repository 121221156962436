export default {
    activitiesCount: {
        setData: 'CAMPAIGN_INSTANCE_ACTIVITIES_COUNT_SET_DATA',
        setLoading: 'CAMPAIGN_INSTANCE_ACTIVITIES_COUNT_SET_LOADING',
    },
    list: {
        setData: 'CAMPAIGN_INSTANCE_LIST_SET_DATA',
        setSummary: 'CAMPAIGN_INSTANCE_LIST_SET_SUMMARY',
        setTotal: 'CAMPAIGN_INSTANCE_LIST_SET_TOTAL',
        setLoading: 'CAMPAIGN_INSTANCE_LIST_SET_LOADING',
    },
    item: {
        setData: 'CAMPAIGN_INSTANCE_DETAILS_SET_DATA',
        setLoading: 'CAMPAIGN_INSTANCE_DETAILS_SET_LOADING',
    },
    templateItem: {
        setData: 'CAMPAIGN_INSTANCE_TEMPLATE_ITEM_SET_DATA',
        setLoading: 'CAMPAIGN_INSTANCE_TEMPLATE_ITEM_SET_LOADING',
    },
    accountManager: {
        setData: 'CAMPAIGN_INSTANCE_ACCOUNT_MANAGER_DATA',
        setLoading: 'CAMPAIGN_INSTANCE_ACCOUNT_MANAGER_SET_LOADING',
    },
    coBrandedTemplate: {
        setLoading: 'CAMPAIGN_INSTANCE_CO_BRANDED_TEMPLATE_SET_LOADING',
    },
};
