import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import PmLink from '../PmLink/PmLink';
import Icon from '../Icon/Icon';
import styles from './Button.module.scss';

/**
 * Base button
 */
export default function Button({
    name,
    type,
    className,
    children,
    color,
    icon,
    small,
    bordered,
    rightIcon,
    tiny,
    xxs,
    href,
    link,
    onClick,
    disabled,
    id,
    blank,
    tip,
    tipClass,
    tipPlace,
    form,
    iconSpin,
    style,
    loading,
    noMargin,
}) {
    const _icon = loading ? 'loading' : icon;
    const _spinning = loading || iconSpin;
    const buttonClassName = cn(
        'button',
        small && 'small',
        tiny && 'mini',
        styles.button,
        styles[`button-${color}`],
        color,
        small && styles.small,
        tiny && styles.tiny,
        xxs && styles.xxs,
        disabled && styles.disabled,
        _icon && styles.hasIcon,
        _icon && !children && styles.iconOnly,
        bordered && styles.bordered,
        noMargin && styles.noMargin,
        className
    );

    const iconElement = _icon ? (
        <Icon kind={_icon} className={cn(styles.icon, _spinning && styles.iconSpin, rightIcon && styles.rightIcon)} />
    ) : null;

    const handleClick = e => {
        if (e) {
            // Disable event bubbling, especially with a Form
            e.preventDefault();
            e.stopPropagation();
        }
        ReactTooltip.hide();

        if (disabled) {
            return;
        }

        onClick(e);
    };

    const handleLinkClick = e => {
        ReactTooltip.hide();
        if (disabled) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            return;
        }

        if (onClick) {
            onClick(e);
        }
    };

    switch (true) {
        case !!href:
            return (
                <a
                    name={name}
                    className={buttonClassName}
                    onClick={handleLinkClick}
                    href={href}
                    target={blank ? '_blank' : '_parent'}
                    rel="noopener noreferrer"
                    data-tip={tip}
                    data-place={tipPlace}
                    data-class={tipClass}
                    id={id}
                    style={style || {}}
                >
                    {!rightIcon && iconElement}
                    {children}
                    {!!rightIcon && iconElement}
                </a>
            );
        case !!link:
            return (
                <PmLink
                    name={name}
                    className={buttonClassName}
                    to={link}
                    id={id}
                    data-tip={tip}
                    data-place={tipPlace}
                    data-class={tipClass}
                    disabled={disabled}
                >
                    {!rightIcon && iconElement}
                    {children}
                    {!!rightIcon && iconElement}
                </PmLink>
            );
        default:
            return (
                <button
                    type="button"
                    {...{ type: type || 'button' }}
                    name={name}
                    className={buttonClassName}
                    onClick={onClick ? handleClick : null}
                    disabled={disabled}
                    data-tip={tip}
                    data-place={tipPlace}
                    data-class={tipClass}
                    id={id}
                    form={form}
                >
                    {!rightIcon && iconElement}
                    <span className="btn-text">{children}</span>
                    {!!rightIcon && iconElement}
                </button>
            );
    }
};

Button.defaultProps = {
    color: 'primary',
    type: 'button',
    blank: true,
};

Button.COLOR = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    WHITE: 'white',
    GRAY: 'gray',
    NONE: 'none',
    WARNING: 'warning',
    INFO: 'info',
    ERROR: 'error',
    SUCCESS: 'success',
    CRIMSON: 'crimson',
    NAVY: 'navy',
};

Button.propTypes = {
    /**
     * Render button with borders only
     */
    bordered: PropTypes.bool,
    /**
     * Button text
     */
    children: PropTypes.any,
    /**
     * Button class name
     */
    className: PropTypes.string,
    /**
     * Button color. Values are in Button.COLOR property
     */
    color: PropTypes.oneOf(Object.keys(Button.COLOR).map(key => Button.COLOR[key])),
    disabled: PropTypes.bool,
    /**
     * Button as link to outer resource
     */
    href: PropTypes.string, // Outer link
    /**
     * Open href in another tab when true
     */
    blank: PropTypes.bool,
    /**
     * Id attr
     */
    id: PropTypes.string,
    /**
     * Valid string, for <Icon /> component
     */
    icon: PropTypes.string,
    /**
     * Button as link for internal route
     */
    link: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
            hash: PropTypes.string,
            state: PropTypes.object,
        }),
    ]), // Inner application link
    /**
     * Name attr
     */
    name: PropTypes.string,
    /**
     * Render small button when true
     */
    small: PropTypes.bool,
    /**
     * Render tiny button when true
     */
    tiny: PropTypes.bool,
    /**
     * Render extra small button when true
     */
    xxs: PropTypes.bool,
    /**
     * Render spin for icon
     */
    iconSpin: PropTypes.bool,
    /**
     * Is loading state
     */
    loading: PropTypes.bool,
    /**
     * Put icon to the right when true
     */
    rightIcon: PropTypes.bool,
    /**
     * Button tooltip
     */
    tip: PropTypes.string,
    /**
     * Tooltip placement
     */
    tipPlace: PropTypes.string,
    /**
     * Tooltip class
     */
    tipClass: PropTypes.string,
    /**
     * Type attr
     */
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    /**
     * Gets called when the user clicks on the button
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     */
    onClick: PropTypes.func,
    /**
     * Form id to work with
     */
    form: PropTypes.string,
    /**
     * Reset margins for button
     */
    noMargin: PropTypes.bool,

    style: PropTypes.object,
};
