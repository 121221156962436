import PropTypes from 'prop-types';
import { Language } from 'propTypes/utility';

// eslint-disable-next-line import/prefer-default-export
export const SystemLanguage = PropTypes.shape({
    id: PropTypes.number.isRequired,
    isEnabledForTemplates: PropTypes.bool,
    isEnabledForSystem: PropTypes.bool,
    language: Language,
});
