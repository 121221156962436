import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import isFunction from 'lodash/isFunction';
import ExtendedTopBar from '../ExtendedTopBar/ExtendedTopBar';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import styles from './ExtendedEditor.module.scss';
import useExtendedLayout from '../../hooks/useExtendedLayout';

export default function ExtendedEditor({ children, formikProps, topBarProps, loading, navBar, alert }) {
    useExtendedLayout();
    return (
        <Formik enableReinitialize {...formikProps}>
            {props => (
                <Form className={styles.wrapper}>
                    {!!alert && alert}
                    <ExtendedTopBar
                        className={styles.topBar}
                        loading={loading}
                        {...(isFunction(topBarProps) ? topBarProps(props) : topBarProps)}
                    />
                    <div className={styles.content}>
                        {!!navBar && navBar}
                        <LoadingContainer loading={loading} className={styles.subContent}>
                            {isFunction(children) ? children(props) : children}
                        </LoadingContainer>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

ExtendedEditor.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    formikProps: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    navBar: PropTypes.node,
    topBarProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    alert: PropTypes.node,
};
