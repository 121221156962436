import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './TwoCols.module.scss';
import TwoColsLeft from './TwoColsLeft';

const TwoCols = ({ side, left, children, actions, contentClassName, contentStyle = {} }) => (
    <div className={styles.wrapper}>
        {left || <TwoColsLeft actions={actions} side={side} />}
        <div className={cn(styles.content, contentClassName)} style={contentStyle}>
            {children}
        </div>
    </div>
);

TwoCols.propTypes = {
    left: PropTypes.node,
    side: PropTypes.node,
    children: PropTypes.node,
    actions: PropTypes.node,
    contentClassName: PropTypes.string,
    contentStyle: PropTypes.object,
};

export default TwoCols;
