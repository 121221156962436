import { combineReducers } from 'redux';
import ACTIONS from './constants';

const itemInitialState = {
    data: {
        masterName: '',
        marketingCode: '',
    },
    loading: false,
};

const itemReducer = (state = itemInitialState, action) => {
    switch (action.type) {
        case ACTIONS.item.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.item.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        case ACTIONS.item.reset: {
            return itemInitialState;
        }

        default: {
            return state;
        }
    }
};

const listInitialState = {
    data: {
        counts: {},
        campaigns: [],
        total: 0,
    },
    loading: false,
};

const listReducer = (state = listInitialState, action) => {
    switch (action.type) {
        case ACTIONS.list.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.list.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

const filtersInitialState = {
    data: {},
    loading: false,
};

const filtersReducer = (state = filtersInitialState, action) => {
    switch (action.type) {
        case ACTIONS.filters.setLoading: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case ACTIONS.filters.setData: {
            return {
                ...state,
                data: action.data,
            };
        }

        default: {
            return state;
        }
    }
};

export default combineReducers({
    list: listReducer,
    filters: filtersReducer,
    item: itemReducer,
});
