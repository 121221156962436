import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useResourceTypes from "pages/asset/hooks/useResourceTypes";
import styles from '../../Header.module.css';
import { client } from '../../../../utils';
import {
    getCurrentUserRolesMap,
    getUserModulesMap,
    isTermsAndConditionsAccepted,
    getModulesSettings,
    getCustomPages,
    isSpiffInternalApprover,
    isSpiffLevel2Approver,
    isClientLoyaltyProgramEnabled,
    getLoyaltyClubs,
    getRewardClubPrograms,
    hasUserApprovalPermission,
    hasFullTemplateBuilderAccess,
    isSsoEnabled,
    isSSOUserRegistrationEnabled,
} from '../../../../selectors/userSelectors';
import {
    getAdminItems,
    getPartnerItems,
    getDesignerItems,
    getReportManagerItems,
    getConciergeItems,
    getVendorItems,
    getConciergePartnerItems,
    getCustomPagesItems,
} from './MenuItems';

function Menu(propsData) {
    const { roles, customPages } = propsData;

    // TODO: Need to return this back after T&C page will be on React.
    // if (!tcAccepted && !roles.isGlobalAdmin) {
    //     return null;
    // }

    const { types } = useResourceTypes();
    const props = {
        ...propsData,
        resourceTypes: types
    };

    let items;
    switch (true) {
        case roles.isGlobalAdmin:
            items = getAdminItems(props);
            break;
        case roles.isPartner:
            items = getPartnerItems(props);
            break;
        case roles.isDesigner:
            items = getDesignerItems(props);
            break;
        case roles.isReportManager:
            items = getReportManagerItems(props);
            break;
        case roles.isConcierge:
            items = getConciergeItems(props);
            break;
        case roles.isVendor:
            items = getVendorItems(props);
            break;
        case roles.isConciergePartner:
            items = getConciergePartnerItems(props);
            break;
        default:
            items = [];
    }

    return (
        <div
            className={cn(
                styles.navWrapper,
                'client-navigation-colour-background',
                'client-navigation-border-bottom-colour'
            )}
        >
            <div className="container">
                <nav id="globalNav" className={styles.nav}>
                    <ul className={cn(styles.mainItems, 'primary-nav')}>
                        {items}
                        {getCustomPagesItems(customPages)}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

Menu.propTypes = {
    /**
     * @ignore
     */
    roles: PropTypes.object,
    /**
     * @ignore
     */
    customPages: PropTypes.array,
};

const mapStateToProps = state => ({
    customPages: getCustomPages(state),
    roles: getCurrentUserRolesMap(state),
    modules: getUserModulesMap(state),
    loyaltyClubs: getLoyaltyClubs(state),
    rewardClubPrograms: getRewardClubPrograms(state),
    tcAccepted: isTermsAndConditionsAccepted(state),
    spiffInternalApprover: isSpiffInternalApprover(state),
    spiffLevel2Approver: isSpiffLevel2Approver(state),
    isClientLoyaltyProgramEnabled: isClientLoyaltyProgramEnabled(state),
    path: state.user.pathname,
    hasUserApprovalPermission: hasUserApprovalPermission(state),
    hasFullTemplateBuilderAccess: hasFullTemplateBuilderAccess(state),
    isSocialMediaPostsEnabled: client.isSocialMediaPostsEnabled(),
    isSSOEnabled: isSsoEnabled(state),
    isSSOUserRegistrationEnabled: isSSOUserRegistrationEnabled(state),
    ...getModulesSettings(state),
});

export default connect(mapStateToProps)(Menu);
