import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import styles from './Table.module.css';

const TableHeader = ({
    columns,
    disabled,
    sortBy,
    sortAsc,
    sortCallback,
    hasRowActions,
    rowActionsClassName,
    hasBatchActions,
    subHeader,
    markDefaultSortable,
}) => (
    <thead className={cn('client-20pc-colour-background', styles.datagridHeader, styles.disabled)}>
        <tr>
            {hasBatchActions && <th className={cn(styles.col, styles.batchCol)} />}
            {columns.map((column, columnKey) => {
                const sortField = column.sortField || column.key;
                let renderedItem = '';
                if (column.columnRenderer) {
                    const CurrentComponent = column.columnRenderer;
                    renderedItem = (
                        <CurrentComponent
                            row={column.attributes}
                        />
                    );
                } else {
                    renderedItem = <span className={styles.columnTitle}>
                        {column.title}
                        {column.tip
                            ? <Icon kind="circle-info" tooltip={column.tip} dark={true} className="react-tooltip" />
                            : null
                        }
                    </span>;
                }
                return (
                    <th
                        key={columnKey}
                        className={cn(
                            styles.col,
                            `col-${column.key}`,
                            column.sortable && styles.colSortable,
                            column.className,
                            column.headerClassName,
                            sortBy === sortField && 'client-40pc-colour-background',
                        )}
                        onClick={() => (disabled ? null : sortCallback(sortField))}
                    >
                        {renderedItem}
                    </th>
                );
            })}
            {hasRowActions && <th className={cn(styles.col, styles.colActions, rowActionsClassName)}>&nbsp;</th>}
        </tr>
        <tr className={cn(styles.sortRow)}>
            {hasBatchActions && <th className={cn(styles.col, styles.batchCol)} />}
            {columns.map((column, columnKey) => {
                const sortField = column.sortField || column.key;

                let sortIndicator =
                    sortBy === sortField ? (
                        <span className={styles.sortIndicator}>
                            <Icon kind={sortAsc ? 'arrow-up' : 'arrow-down'} />
                        </span>
                    ) : null;

                sortIndicator = markDefaultSortable && sortBy !== sortField ? (
                    <span className={styles.sortIndicator}>
                        <Icon kind="arrow-down" />
                    </span>
                ) : sortIndicator;

                return (
                    <th
                        key={columnKey}
                        className={cn(
                            styles.col,
                            `col-${column.key}`,
                            column.className,
                            column.headerClassName,
                            column.sortable && styles.colSortable,
                            sortBy === sortField && styles.sorted
                        )}
                        onClick={() => (disabled ? null : sortCallback(sortField))}
                    >
                        {sortIndicator}
                    </th>
                );
            })}
            {hasRowActions && <th className={cn(styles.col, styles.colActions, rowActionsClassName)}>&nbsp;</th>}
        </tr>
        {!!subHeader && (
            <tr className={styles.subHeader}>
                <th colSpan={columns.length}>{subHeader}</th>
            </tr>
        )}
    </thead>
);

TableHeader.propTypes = {
    columns: PropTypes.array,
    sortAsc: PropTypes.bool,
    sortBy: PropTypes.string,
    sortCallback: PropTypes.func,
    disabled: PropTypes.bool,
    hasRowActions: PropTypes.bool,
    hasBatchActions: PropTypes.bool,
    rowActionsClassName: PropTypes.string,
    subHeader: PropTypes.string,
    markDefaultSortable: PropTypes.bool,
};

TableHeader.defaultProps = {
    columns: [],
    sortAsc: false,
    sortBy: '',
    sortCallback: () => {},
    markDefaultSortable: false,
};

export default TableHeader;
