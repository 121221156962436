export default {
    partner: {
        setData: 'LOYALTY_PARTNER_SET_DATA',
        setLoading: 'LOYALTY_PARTNER_SET_LOADING',
    },
    cart: {
        setData: 'LOYALTY_CART_SET_DATA',
        setBack: 'LOYALTY_CART_SET_BACK',
    },
    list: {
        setData: 'LOYALTY_LIST_SET_DATA',
        setLoading: 'LOYALTY_LIST_SET_LOADING',
    },
    pointsTransfers: {
        setData: 'LOYALTY_POINTS_TRANSFERS_SET_DATA',
        setLoading: 'LOYALTY_POINTS_TRANSFERS_SET_LOADING',
    },
    notifications: {
        setData: 'LOYALTY_NOTIFICATIONS_SET_DATA',
        setLoading: 'LOYALTY_NOTIFICATIONS_SET_LOADING',
    },
};
