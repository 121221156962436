export default {
    cart: {
        setData: 'INCENTIVE_CART_SET_DATA',
        setBack: 'INCENTIVE_CART_SET_BACK',
    },
    user: {
        setData: 'INCENTIVE_USER_SET_DATA',
        setLoading: 'INCENTIVE_USER_SET_LOADING',
    },
    leaderBoard: {
        setData: 'INCENTIVE_LEADER_BOARD_SET_DATA',
        setLoading: 'INCENTIVE_LEADER_BOARD_SET_LOADING',
    },
};
